import { Component } from '@angular/core';
import { Country } from '../../../models';
import { LocatorService } from '../../../shared/services';
import { IdentityService } from '../../../shared/services/identity.service';
import { ProfileRoles } from '../../../shared/types';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent {
  private identityService: IdentityService = LocatorService.injector.get(IdentityService);

  protected get globalCountryId() {
    return this.identityService.globalCountryId;
  }

  protected set globalCountryId(value: number | undefined) {
    this.identityService.globalCountryId = value;
  }

  protected get globalCountryId$() {
    return this.identityService.globalCountryId$;
  }

  public hasRole(role: ProfileRoles): boolean {
    return this.identityService.hasRole(role);
  }

  public setGlobalCountry(country?: Country): void {
    this.identityService.setGlobalCountry(country);
  }

  public clearGlobalCountry(): void {
    this.identityService.setGlobalCountry();
  }
}
