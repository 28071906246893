<div class="section-container" *ngIf="office && office.studio">
  <div class="flex align-items-start justify-content-between">
    <h3>Studio Types</h3>
    <button
      *ngIf="hasRole('db-edit-offices')"
      pButton
      label="Edit Types"
      type="button"
      (click)="updateClicked()"
      class="p-button-raised p-button-warning"
      icon="pi pi-save"
      style="margin-bottom: 10px"
    ></button>
  </div>

  <p-chip *ngFor="let studioType of office.studio.types">
    {{ studioType.name }}
  </p-chip>
  <h4 *ngIf="!office.studio.types || office.studio.types.length === 0">No types assigned</h4>
</div>
