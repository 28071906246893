<div class="col-12 flex flex-column start">
  <div class="col-12 flex flex-row start">
    <div class="col-12">
      <app-person-info [person]="person"></app-person-info>
    </div>
  </div>
  <div class="col-12">
    <div class="py-4 px-7">
      <app-person-professionals
        *ngIf="person"
        [person]="person"
        (professionalsLoaded)="setProfessionals($event)"
        (professionalsChanged)="updateLastReview($event)"
      ></app-person-professionals>
    </div>
  </div>
  <div class="col-12 flex flex-row start">
    <div class="col-5">
      <div class="py-4 px-7">
        <app-person-waves *ngIf="person" [person]="person"></app-person-waves>
      </div>
    </div>
    <div class="col-7">
      <div class="py-4 px-7" style="padding-left: 0 !important">
        <app-person-juries *ngIf="person" [person]="person"></app-person-juries>
      </div>
    </div>
  </div>
  <div class="col-5">
    <div class="py-4 px-7">
      <app-person-filters *ngIf="person" [person]="person"></app-person-filters>
    </div>
  </div>
</div>
