import { Component, Input, OnInit } from '@angular/core';
import { MediaSupport, Office } from '../../../../models';
import { ApiService, ToastService } from '../../../../shared/services';

@Component({
  selector: 'app-exclusive-supports',
  templateUrl: './exclusive-supports.component.html',
  styleUrls: ['./exclusive-supports.component.scss'],
})
export class ExclusiveSupportsComponent implements OnInit {
  @Input() public office: Office | undefined = undefined;
  public isLoading = true;
  public supports: MediaSupport[] = [];

  constructor(private apiService: ApiService, private toastService: ToastService) {}

  ngOnInit(): void {
    if (this.office) {
      this.isLoading = true;

      this.apiService.exclusives.getSupports(this.office.id).subscribe({
        next: (supports) => {
          this.supports = supports;
          this.isLoading = false;
        },
        error: () => {
          this.toastService.send({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred while getting the supports assigned',
          });
        },
      });
    }
  }
}
