import { Component, Input, OnInit } from '@angular/core';
import { Office } from '../../../models';
import { BaseComponent } from '../../general/base/base.component';
import { OfficeFusionDetail } from '../../../models/office-fusion-detail.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-office-fusion-children',
  templateUrl: './office-fusion-children.component.html',
  styleUrls: ['./office-fusion-children.component.scss'],
})
export class OfficeFusionChildrenComponent extends BaseComponent implements OnInit {
  @Input() public office?: Office | undefined;

  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {}

  navigateToOffice(fusionChildren: OfficeFusionDetail) {
    this.router.navigate(['companies', 'office', fusionChildren.id]);
  }
}
