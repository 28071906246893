<p-table
  #dt
  id="scopen-companies-table"
  [value]="companies"
  [paginator]="true"
  [(rows)]="pageSize"
  [(first)]="first"
  [showCurrentPageReport]="true"
  [currentPageReportTemplate]="translateCurrentPageReportTemplate()"
  [rowsPerPageOptions]="availablePageSizes"
  [totalRecords]="totalRecords"
  [resizableColumns]="false"
  [scrollable]="true"
  [lazy]="true"
  [loading]="isLoading"
  (onLazyLoad)="onLazyLoad($event)"
  selectionMode="multiple"
  [(selection)]="selectedProducts"
  [exportHeader]="'customExportHeader'"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th i18n>Enabled</th>
      <th i18n pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
      <th class="field-lastReview" i18n pSortableColumn="lastReview">
        Last review <p-sortIcon field="lastReview"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr
      [class]="!rowData?.isEnabled ? 'row-disabled' : ''"
      [pSelectableRow]="rowData"
      (click)="goToCompanyDetailsPage(rowData)"
    >
      <td class="current">
        <i *ngIf="rowData?.isEnabled" class="pi pi-check"></i>
        <i *ngIf="!rowData?.isEnabled" class="pi pi-times"></i>
      </td>
      <td>{{ rowData?.name }}</td>
      <td><i class="pi pi-calendar"></i> {{ rowData?.lastReview | date: 'dd/MM/yy' }}</td>
    </tr>
  </ng-template>
</p-table>
