import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PickListModule } from 'primeng/picklist';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TreeSelectModule } from 'primeng/treeselect';
import { AgenciesServicesFormComponent } from './admins/agencies/agencies-services-form/agencies-services-form.component';
import { AgenciesServicesTableComponent } from './admins/agencies/agencies-services-table/agencies-services-table.component';
import { AgenciesTypesFormComponent } from './admins/agencies/agencies-types-form/agencies-types-form.component';
import { AgenciesTypesTableComponent } from './admins/agencies/agencies-types-table/agencies-types-table.component';
import { BrandsFormComponent } from './admins/brands/brands-form/brands-form.component';
import { BrandsTableComponent } from './admins/brands/brands-table/brands-table.component';
import { CountriesFormComponent } from './admins/countries/countries-form/countries-form-component';
import { CountriesProvincesFormComponent } from './admins/countries/countries-provinces-form/countries-provinces-form.component';
import { CountriesProvincesTableComponent } from './admins/countries/countries-provinces-table/countries-provinces-table.component';
import { CountriesTableComponent } from './admins/countries/countries-table/countries-table.component';
import { FiltersFormComponent } from './admins/filters/filters-form/filters-form.component';
import { FiltersTableComponent } from './admins/filters/filters-table/filters-table.component';
import { JobRolesFormComponent } from './admins/job-roles/job-roles-form/job-roles-form.component';
import { JobRolesTableComponent } from './admins/job-roles/job-roles-table/job-roles-table.component';
import { ProductsFormComponent } from './admins/products/products-form/products-form.component';
import { ProductsTableComponent } from './admins/products/products-table/products-table.component';
import { SectorsTableComponent } from './admins/sectors/sectors-table/sectors-table.component';
import { UsersCountriesFormComponent } from './admins/users/users-countries-form/users-countries-form.component';
import { UsersFormComponent } from './admins/users/users-form/users-form.component';
import { UsersTableComponent } from './admins/users/users-table/users-table.component';
import { AddCollaborationFormComponent } from './collaborations/add-collaboration-form/add-collaboration-form.component';
import { CollaborationsSectionComponent } from './collaborations/collaborations-section/collaborations-section.component';
import { CollaborationsTableComponent } from './collaborations/collaborations-table/collaborations-table.component';
import { BaseComponent } from './general/base/base.component';
import { BreadcrumbsComponent } from './general/breadcrumbs/breadcrumbs.component';
import { LayoutComponent } from './general/layout/layout.component';
import { NotFoundComponent } from './general/not-found/not-found.component';
import { NotImplementedComponent } from './general/not-implemented/not-implemented.component';
import { ToastComponent } from './general/toast/toast.component';
import { AdvertiserBrandsFormComponent } from './offices/advertisers/advertiser-brands-form/advertiser-brands-form.component';
import { AdvertiserBrandsComponent } from './offices/advertisers/advertiser-brands/advertiser-brands.component';
import { AdvertiserInfoadexFormComponent } from './offices/advertisers/advertiser-infoadex-form/advertiser-infoadex-form.component';
import { AdvertiserInfoadexComponent } from './offices/advertisers/advertiser-infoadex/advertiser-infoadex.component';
import { AdvertiserSubsectorsFormComponent } from './offices/advertisers/advertiser-subsectors-form/advertiser-subsectors-form.component';
import { AdvertiserSubsectorsComponent } from './offices/advertisers/advertiser-subsectors/advertiser-subsectors.component';
import { AgencyServicesFormComponent } from './offices/agencies/agency-services-form/agency-services-form.component';
import { AgencyServicesComponent } from './offices/agencies/agency-services/agency-services.component';
import { AgencyTypesFormComponent } from './offices/agencies/agency-types-form/agency-types-form.component';
import { AgencyTypesComponent } from './offices/agencies/agency-types/agency-types.component';
import { ExclusiveSupportsComponent } from './offices/exclusives/exclusive-supports/exclusive-supports.component';
import { MediaExclusiveFormComponent } from './offices/medias/media-exclusive-form/media-exclusive-form.component';
import { MediaInfoComponent } from './offices/medias/media-info/media-info.component';
import { MediaSupportsFormComponent } from './offices/medias/media-supports-form/media-supports-form.component';
import { MediaSupportsComponent } from './offices/medias/media-supports/media-supports.component';
import { OfficeEmployeesComponent } from './offices/office-employees/office-employees.component';
import { OfficeFormComponent } from './offices/office-form/office-form.component';
import { OfficeInfoComponent } from './offices/office-info/office-info.component';
import { OfficeProfessionalsComponent } from './offices/office-professionals/office-professionals.component';
import { OfficeSummaryComponent } from './offices/office-summary/office-summary.component';
import { OfficeTabsComponent } from './offices/office-tabs/office-tabs.component';
import { OfficesFiltersComponent } from './offices/offices-filters/offices-filters.component';
import { OfficesTableComponent } from './offices/offices-table/offices-table.component';
import { StudioTypesFormComponent } from './offices/studios/studio-types-form/studio-types-form.component';
import { StudioTypesComponent } from './offices/studios/studio-types/studio-types.component';
import { TitleValueFieldComponent } from './offices/title-value-field/title-value-field.component';
import { PersonFiltersFormComponent } from './people/person-filters-form/person-filters-form.component';
import { PersonFiltersComponent } from './people/person-filters/person-filters.component';
import { PersonInfoFormComponent } from './people/person-info-form/person-info-form.component';
import { PersonInfoComponent } from './people/person-info/person-info.component';
import { PersonJuriesFormComponent } from './people/person-juries-form/person-juries-form.component';
import { PersonJuriesComponent } from './people/person-juries/person-juries.component';
import { PersonProfessionalsFormComponent } from './people/person-professionals-form/person-professionals-form.component';
import { PersonProfessionalsComponent } from './people/person-professionals/person-professionals.component';
import { PersonSummaryComponent } from './people/person-summary/person-summary.component';
import { PersonWavesFormComponent } from './people/person-waves-form/person-waves-form.component';
import { PersonWavesComponent } from './people/person-waves/person-waves.component';
import { ProfessionalsFiltersComponent } from './professionals/professionals-filters/professionals-filters.component';
import { ProfessionalsTableComponent } from './professionals/professionals-table/professionals-table.component';
import { ProfessionalsUnassignedComponent } from './professionals/professionals-unassigned/professionals-unassigned.component';
import { SectorsFormComponent } from './admins/sectors/sectors-form/sectors-form.component';
import { SubsectorsTableComponent } from './admins/sectors/subsectors-table/subsectors-table.component';
import { SubsectorsFormComponent } from './admins/sectors/subsectors-form/subsectors-form.component';
import { MediaTypesFormComponent } from './admins/media-types/media-types-form/media-types-form.component';
import { MediaTypesTableComponent } from './admins/media-types/media-types-table/media-types-table.component';
import { OfficeAdvancedExcelComponent } from './offices/office-advanced-excel/office-advanced-excel.component';
import { ExcelTooLargeComponent } from './general/excel-too-large/excel-too-large.component';
import { ProfessionalsAdvancedExcelComponent } from './professionals/professionals-advanced-excel/professionals-advanced-excel.component';
import { WavesTableComponent } from './admins/waves/waves-table/waves-table.component';
import { WavesFormComponent } from './admins/waves/waves-form/waves-form.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { TitlesTableComponent } from './admins/titles/titles-table/titles-table.component';
import { TitlesFormComponent } from './admins/titles/titles-form/titles-form.component';
import { OfficeEmployeesFormComponent } from './offices/office-employees-form/office-employees-form.component';
import { CompanyListComponent } from './companies/company-list/company-list.component';
import { NetworkListComponent } from './networks/network-list/network-list.component';
import { HoldingListComponent } from './holdings/holding-list/holding-list.component';
import { AutocompleteVirtualscrollSearchFiltersComponent } from './general/autocomplete-virtualscroll-search-filters/autocomplete-virtualscroll-search-filters.component';
import { HoldingSummaryComponent } from './holdings/holding-summary/holding-summary.component';
import { HoldingTabsComponent } from './holdings/holding-tabs/holding-tabs.component';
import { HoldingStructureComponent } from './holdings/holding-structure/holding-structure.component';
import { NetworkSummaryComponent } from './networks/network-summary/network-summary.component';
import { NetworkTabsComponent } from './networks/network-tabs/network-tabs.component';
import { NetworkStructureComponent } from './networks/network-structure/network-structure.component';
import { CompanySummaryComponent } from './companies/company-summary/company-summary.component';
import { CompanyStructureComponent } from './companies/company-structure/company-structure.component';
import { CompanyTabsComponent } from './companies/company-tabs/company-tabs.component';
import { HoldingFormComponent } from './holdings/holding-form/holding-form.component';
import { NetworkFormComponent } from './networks/network-form/network-form.component';
import { DeleteButtonComponent } from './general/delete-button/delete-button.component';
import { CompanyFormComponent } from './companies/company-form/company-form.component';
import { SharedModule } from '../shared/shared.module';
import { CollaborationsCompanyTableComponent } from './collaborations/collaborations-company-table/collaborations-company-table.component';
import { ProfessionalsMultipleComponent } from './professionals/professionals-multiple/professionals-multiple.component';
import { OfficeFusionChildrenComponent } from './offices/office-fusion-children/office-fusion-children.component';
import { OfficeFusionFormComponent } from './offices/office-fusion-form/office-fusion-form.component';
import { AccessDeniedComponent } from './general/access-denied/access-denied.component';
import { FestivalsTableComponent } from './admins/festivals/festivals-table/festivals-table.component';
import { FestivalsFormComponent } from './admins/festivals/festivals-form/festivals-form.component';
import { EventsFormComponent } from './admins/events/events-form/events-form.component';
import { EventsTableComponent } from './admins/events/events-table/events-table.component';

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    OfficesTableComponent,
    NotImplementedComponent,
    LayoutComponent,
    NotFoundComponent,
    ToastComponent,
    OfficesFiltersComponent,
    ProfessionalsFiltersComponent,
    CollaborationsTableComponent,
    AddCollaborationFormComponent,
    CollaborationsSectionComponent,
    PersonInfoComponent,
    PersonProfessionalsComponent,
    PersonWavesComponent,
    PersonJuriesComponent,
    PersonProfessionalsFormComponent,
    PersonWavesFormComponent,
    PersonJuriesFormComponent,
    PersonFiltersComponent,
    PersonFiltersFormComponent,
    PersonSummaryComponent,
    PersonInfoFormComponent,
    OfficeProfessionalsComponent,
    OfficeInfoComponent,
    OfficeSummaryComponent,
    TitleValueFieldComponent,
    OfficeTabsComponent,
    AgencyTypesComponent,
    AgencyServicesComponent,
    AgencyTypesFormComponent,
    AgencyServicesFormComponent,
    OfficeFormComponent,
    AdvertiserBrandsComponent,
    AdvertiserBrandsFormComponent,
    AdvertiserSubsectorsComponent,
    AdvertiserSubsectorsFormComponent,
    AdvertiserInfoadexComponent,
    AdvertiserInfoadexFormComponent,
    ProfessionalsTableComponent,
    MediaInfoComponent,
    MediaExclusiveFormComponent,
    MediaSupportsComponent,
    MediaSupportsFormComponent,
    ExclusiveSupportsComponent,
    StudioTypesComponent,
    StudioTypesFormComponent,
    BrandsTableComponent,
    BrandsFormComponent,
    ProfessionalsUnassignedComponent,
    BaseComponent,
    ProductsTableComponent,
    ProductsFormComponent,
    FestivalsTableComponent,
    FestivalsFormComponent,
    FiltersTableComponent,
    FiltersFormComponent,
    CountriesTableComponent,
    CountriesProvincesTableComponent,
    CountriesFormComponent,
    CountriesProvincesFormComponent,
    UsersTableComponent,
    UsersFormComponent,
    UsersCountriesFormComponent,
    OfficeEmployeesComponent,
    JobRolesTableComponent,
    JobRolesFormComponent,
    AgenciesServicesFormComponent,
    AgenciesServicesTableComponent,
    AgenciesTypesFormComponent,
    AgenciesTypesTableComponent,
    SectorsTableComponent,
    SectorsFormComponent,
    SubsectorsTableComponent,
    SubsectorsFormComponent,
    MediaTypesFormComponent,
    MediaTypesTableComponent,
    OfficeAdvancedExcelComponent,
    ExcelTooLargeComponent,
    ProfessionalsAdvancedExcelComponent,
    WavesTableComponent,
    WavesFormComponent,
    TitlesTableComponent,
    TitlesFormComponent,
    OfficeEmployeesFormComponent,
    CompanyListComponent,
    NetworkListComponent,
    HoldingListComponent,
    AutocompleteVirtualscrollSearchFiltersComponent,
    HoldingSummaryComponent,
    HoldingTabsComponent,
    HoldingStructureComponent,
    NetworkSummaryComponent,
    NetworkTabsComponent,
    NetworkStructureComponent,
    CompanySummaryComponent,
    CompanyStructureComponent,
    CompanyTabsComponent,
    HoldingFormComponent,
    NetworkFormComponent,
    DeleteButtonComponent,
    CompanyFormComponent,
    CollaborationsCompanyTableComponent,
    ProfessionalsMultipleComponent,
    OfficeFusionChildrenComponent,
    OfficeFusionFormComponent,
    AccessDeniedComponent,
    EventsFormComponent,
    EventsTableComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BreadcrumbModule,
    TableModule,
    ButtonModule,
    MenuModule,
    PanelMenuModule,
    AvatarModule,
    ToastModule,
    InputTextModule,
    TreeSelectModule,
    SelectButtonModule,
    DividerModule,
    AutoCompleteModule,
    MultiSelectModule,
    CalendarModule,
    DynamicDialogModule,
    ConfirmPopupModule,
    DropdownModule,
    ChipModule,
    CardModule,
    FieldsetModule,
    InputTextareaModule,
    DialogModule,
    TabMenuModule,
    TabViewModule,
    SplitButtonModule,
    PickListModule,
    InputNumberModule,
    SharedModule,
  ],
  exports: [
    BreadcrumbsComponent,
    OfficesTableComponent,
    NotImplementedComponent,
    LayoutComponent,
    NotFoundComponent,
    ToastComponent,
    OfficesFiltersComponent,
    ProfessionalsFiltersComponent,
    CollaborationsTableComponent,
    AddCollaborationFormComponent,
    CollaborationsSectionComponent,
    PersonInfoComponent,
    PersonProfessionalsComponent,
    PersonWavesComponent,
    PersonJuriesComponent,
    PersonFiltersComponent,
    PersonSummaryComponent,
    OfficeProfessionalsComponent,
    OfficeInfoComponent,
    OfficeTabsComponent,
    AgencyTypesComponent,
    AgencyServicesComponent,
    CardModule,
    ProfessionalsTableComponent,
    BrandsTableComponent,
    SplitButtonModule,
    TabViewModule,
    ProfessionalsUnassignedComponent,
    ProductsTableComponent,
    EventsTableComponent,
    FestivalsTableComponent,
    FiltersTableComponent,
    CountriesTableComponent,
    CountriesProvincesTableComponent,
    UsersTableComponent,
    JobRolesTableComponent,
    AgenciesServicesTableComponent,
    AgenciesTypesTableComponent,
    SectorsTableComponent,
    SubsectorsTableComponent,
    MediaTypesTableComponent,
    OfficeAdvancedExcelComponent,
    ProfessionalsAdvancedExcelComponent,
    WavesTableComponent,
    TitlesTableComponent,
    CompanyListComponent,
    NetworkListComponent,
    HoldingListComponent,
    HoldingSummaryComponent,
    HoldingTabsComponent,
    NetworkSummaryComponent,
    NetworkTabsComponent,
    CompanySummaryComponent,
    CompanyTabsComponent,
    DialogModule,
    DeleteButtonComponent,
    TitleValueFieldComponent,
  ],
  providers: [DialogService, DynamicDialogRef, DynamicDialogConfig, ConfirmationService, MessageService],
  bootstrap: [AddCollaborationFormComponent],
})
export class ComponentsModule {}
