<div class="form-container pt-3">
  <span
    style="
      display: block;
      font-family: Mark;
      font-size: 1rem;
      color: rgb(64, 61, 61);
      padding-left: 16px;
      padding-top: 10px;
      padding-bottom: 15px;
      justify-content: center;
    "
  >
    <span style="font-family: Mark; font-size: 1rem; color: black">Name: </span> {{ event ? event.name : '' }}</span
  >
  <div class="col-9 pl-3 p-4">
    <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
      <p-dropdown
        id="event"
        placeholder="Event"
        optionLabel="name"
        appendTo="body"
        class="w-full"
        [style]="{ width: '100%' }"
        [options]="events"
        [(ngModel)]="event"
        (ngModelChange)="elementChanged()"
      ></p-dropdown>
      <label for="event">Event</label>
    </span>
  </div>
  <div class="col-9 pl-3 p-4">
    <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
      <p-inputNumber
        id="year"
        [(ngModel)]="command.year"
        (ngModelChange)="elementChanged()"
        [showButtons]="true"
        [min]="0"
        placeholder="year"
        i18n-placeholder
      ></p-inputNumber>
      <label for="year" i18n>Year</label>
    </span>
  </div>
  <span
    style="
      display: block;
      font-family: Mark;
      font-size: 1rem;
      color: rgb(64, 61, 61);
      padding-left: 16px;
      padding-top: 10px;
      padding-bottom: 15px;
      justify-content: center;
    "
  >
    <span *ngIf="alreadyExists" style="font-family: Mark; font-size: 1rem; color: rgb(253, 1, 1)">
      This festival already exists</span
    >
    <div class="col-12">
      <button
        pButton
        *ngIf="mode === 'create'"
        label="Add Festival"
        i18n-label
        type="button"
        class="p-button-raised p-button-warning standard-button-within-form"
        icon="pi pi-plus-circle"
        [disabled]="!addFestivalEnabled"
        (click)="createClicked()"
      ></button>

      <button
        pButton
        *ngIf="mode === 'edit'"
        label="Update Festival"
        i18n-label
        type="button"
        class="p-button-raised p-button-warning standard-button-within-form"
        icon="pi pi-save"
        [disabled]="!addFestivalEnabled"
        (click)="createClicked()"
      ></button>

      <button
        pButton
        label="Cancel"
        i18n-label
        type="button"
        (click)="cancelClicked()"
        class="p-button-raised p-button-warning standard-button-within-form"
        icon="pi pi-times"
      ></button>
    </div>
  </span>
</div>
