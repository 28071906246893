import { BaseModel } from './base.model';

export class Product extends BaseModel {
  public name: string;

  constructor(data: Product) {
    super(data);

    this.name = data.name;
  }
}

export class ProductCommand {
  public name: string;

  constructor(data: ProductCommand) {
    ({ name: this.name } = data);
  }
}

export class CreateProductCommand extends ProductCommand {
  constructor(data: CreateProductCommand) {
    super(data);
  }
}

export class UpdateProductCommand extends ProductCommand {
  public id: number;

  constructor(data: UpdateProductCommand) {
    super(data);
    this.id = data.id;
  }
}
