<div class="grid grid-nogutter mt-4">
  <div class="col-12">
    <p-table
      *ngIf="office && office.fusionChildren && office.fusionChildren.length > 0"
      #dt
      id="office-fusion-children"
      [value]="office.fusionChildren"
      [resizableColumns]="false"
      [scrollable]="false"
      selectionMode="single"
      [style]="{ width: '100%' }"
    >
      <ng-template pTemplate="header">
        <tr>
          <th class="capitalize">Name</th>
          <th class="capitalize">Country</th>
          <th class="capitalize">Province</th>
          <th class="capitalize">Agency Types</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-fusionChildren>
        <tr [pSelectableRow]="fusionChildren" (click)="navigateToOffice(fusionChildren)">
          <td>
            {{ fusionChildren.name || '-' }}
          </td>
          <td>
            {{ fusionChildren.country?.name || '-' }}
          </td>
          <td>
            {{ fusionChildren.address?.province?.name || '-' }}
          </td>
          <td>
            {{ fusionChildren.getAgencyTypes() || '-' }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
