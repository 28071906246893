<div class="grid m-0">
  <div class="scopen-col-sidebar scopen-main-bg">
    <img src="../../../assets/images/scopen-logo.svg" i18n-alt alt="Scopen logo" class="flex logo" />
    <div class="h-4rem"></div>
    <div class="justify-content-center">
      <p-menu [model]="items" styleClass="flex-shrink w-full scopen-sidebar-menu"></p-menu>
      <p-panelMenu
        [transitionOptions]="'0ms'"
        [model]="adminMenuItems"
        styleClass="flex-shrink w-full scopen-sidebar-menu"
      ></p-panelMenu>
    </div>
  </div>
  <div class="scopen-col-main p-0 scopen-content">
    <div class="grid grid-nogutter">
      <div class="col flex align-items-center scopen-head-bg">
        <app-breadcrumbs></app-breadcrumbs>
      </div>
      <div class="col scopen-head-bg p-2 flex justify-content-end align-content-center">
        <p-dropdown
          *ngIf="selectedCountry"
          [options]="countryNodes"
          [ngModel]="selectedCountry"
          (ngModelChange)="onCountryChanges($event)"
          display="chip"
          styleClass="mr-4 country-dropdown"
        >
          <ng-template pTemplate="selectedItem">
            <div class="flex aligne-items-center">
              <img [src]="selectedCountry.icon" class="flag inline-block mr-2" />
              <span class="inline-block">{{ selectedCountry.label }}</span>
            </div>
            <div *ngIf="!selectedCountry" i18n [ngClass]="{ 'country-placeholder': displayPlaceHolder }">
              Select a country
            </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
            <div class="flex align-items-center" *ngIf="selectedCountry">
              <img [src]="country.icon" class="flag inline-block mr-2" />
              <div class="inline-block">{{ country.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
        <p-avatar [label]="acronym" size="large" shape="circle"></p-avatar>
        <p class="ml-2">{{ user?.name }}</p>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-chevron-down"
          class="p-button-text p-button-secondary"
          (click)="menu.toggle($event)"
        ></button>
        <p-menu #menu [popup]="true" [model]="menuButton"></p-menu>
      </div>
    </div>
    <div class="w-12 max-w-12 min-w-12 main-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
