import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/general/base/base.component';
import { Country, CreateTitleCommand, Title, UpdateTitleCommand } from 'src/app/models';
import { ISearchQuery } from 'src/app/shared/interfaces';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/shared/services';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TitlesFormComponent } from '../titles-form/titles-form.component';
import { CountryService } from 'src/app/shared/services/country.service';

@Component({
  selector: 'app-titles-table',
  templateUrl: './titles-table.component.html',
  styleUrls: ['./titles-table.component.scss'],
})
export class TitlesTableComponent extends BaseComponent implements OnInit {
  public titles: Title[] = [];

  public filteredTitles: Title[] = [];

  public isLoading = true;

  public first = 0;

  public page = 1;

  public pageSize = 10;

  public availablePageSizes = environment.availablePageSizes;

  public titlesResult?: ISearchQuery<Title[]>;

  public selectedSearchText = '';

  public searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  public error = false;

  public countries: Country[] = [];

  constructor(
    private apiService: ApiService,
    private readonly countryService: CountryService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.refresh();
    this.searchText$.subscribe(() => {
      this.first = 0;
      this.filteredTitles = this.titles.filter((title) =>
        title.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  public refresh(): void {
    this.titles = [];
    this.isLoading = true;
    this.apiService.people.getTitles(this.globalCountryId).subscribe((titles: Title[]) => {
      this.titles = titles;
      this.filteredTitles = [...this.titles];
      this.isLoading = false;
    });
    this.countryService.find({ countryId: this.globalCountryId, includeProvinces: false }).subscribe((countries) => {
      this.countries = countries;
    });
  }

  public createClicked(): void {
    this.countryService.find({ countryId: this.globalCountryId, includeProvinces: false }).subscribe((countries) => {
      const ref = this.dialogService.open(TitlesFormComponent, {
        header: $localize`Add new Title`,
        width: '45%',
        height: 'auto',
        data: {
          countries,
        },
      });

      ref.onClose.subscribe((command: CreateTitleCommand) => {
        if (command) {
          this.apiService.people.createTitle(command).subscribe({
            next: (title) => {
              this.addTitle(title);
              this.filteredTitles = [...this.titles];
              this.messageService.add({
                severity: 'success',
                summary: 'Created',
                detail: $localize`You have added a new title`,
              });
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: $localize`An error occurred while creating the title`,
              });
            },
          });
        }
      });
    });
  }

  public editClicked($event: MouseEvent, title: Title): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.countryService.find({ countryId: this.globalCountryId, includeProvinces: false }).subscribe((countries) => {
      const ref = this.dialogService.open(TitlesFormComponent, {
        header: $localize`Edit Title`,
        width: '45%',
        height: 'auto',
        data: {
          title,
          countries,
        },
      });

      ref.onClose.subscribe((command: UpdateTitleCommand) => {
        if (command) {
          this.apiService.people.updateTitle(command.id, command).subscribe({
            next: (title) => {
              this.updateTitle(title);
              this.messageService.add({
                severity: 'success',
                summary: 'Updated',
                detail: $localize`You have updated the title`,
              });
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: $localize`An error occurred while updating the title`,
              });
            },
          });
        }
      });
    });
  }

  public confirmDelete($event: MouseEvent, title: Title): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: $localize`Are you sure that you want to delete this title?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiService.people.removeTitle(title.id).subscribe({
          next: () => {
            this.removeTitle(title);
            this.filteredTitles = [...this.titles];
            this.messageService.add({
              severity: 'success',
              summary: 'Deleted',
              detail: $localize`You have deleted the title`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while deleting the title`,
            });
          },
        });
      },
      reject: () => undefined,
    });
  }

  public addTitle(title: Title): void {
    this.titles.push(title);
  }

  public updateTitle(title: Title): void {
    const index = this.titles.findIndex((t: Title) => t.id === title.id);

    if (index >= 0) {
      this.titles[index] = new Title({ ...title });
    }
    this.filteredTitles = [...this.titles];
  }

  public removeTitle(title: Title): void {
    const index = this.titles.findIndex((t) => t.id === title.id);

    if (index >= 0) {
      this.titles.splice(index, 1);
    }
  }

  public next(): void {
    this.first = this.first + this.pageSize;
  }

  public prev(): void {
    this.first = this.first - this.pageSize;
  }

  public reset(): void {
    this.first = 0;
  }

  public isLastPage(): boolean {
    return this.titlesResult?.count ? this.first === this.titlesResult.count - this.pageSize : true;
  }

  public isFirstPage(): boolean {
    return this.titlesResult?.count ? this.first === 0 : true;
  }

  public translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.filteredTitles.length
    } entries`;
  }

  public searchTextChanged(): void {
    this.searchText$.next(this.selectedSearchText);
  }
}
