export class UserProfile {
  public email: string;

  public email_verified: boolean;

  public family_name: string;

  public given_name: string;

  public name: string;

  public preferred_username: string;

  public roles: string[];

  public sub: string;

  constructor(data: UserProfile) {
    ({
      email: this.email,
      email_verified: this.email_verified,
      family_name: this.family_name,
      given_name: this.given_name,
      name: this.name,
      preferred_username: this.preferred_username,
      roles: this.roles,
      sub: this.sub,
    } = data);
  }
}
