import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Person, Professional } from '../../../models';
import { CreatePersonJuryCommand, Jury, UpdatePersonJuryCommand } from '../../../models';
import { ApiService } from '../../../shared/services';
import { PersonJuriesFormComponent } from '../person-juries-form/person-juries-form.component';
import { BaseComponent } from '../../general/base/base.component';
import { Festival } from 'src/app/models/festival.model';

@Component({
  selector: 'app-person-juries',
  templateUrl: './person-juries.component.html',
  styleUrls: ['./person-juries.component.scss'],
})
export class PersonJuriesComponent extends BaseComponent implements OnInit {
  @Input() public person: Person | undefined = undefined;
  @Input() public professionals: Professional[] = [];

  public juries: Jury[] = [];
  public isLoading = true;

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
    super();
  }

  ngOnInit() {
    this.refresh();
  }

  public refresh() {
    this.juries = [];
    this.isLoading = true;
    if (this.person) {
      this.apiService.people.getJuries(this.person.id).subscribe((juries: Jury[]) => {
        this.juries = juries;
        this.isLoading = false;
      });
    }
  }

  editClicked($event: MouseEvent, updatedJury: Jury) {
    $event.preventDefault();
    $event.stopPropagation();

    this.apiService.festivals.find().subscribe((festivals: Festival[]) => {
      const ref = this.dialogService.open(PersonJuriesFormComponent, {
        header: `Edit jury`,
        width: '50em',
        height: 'auto',
        data: {
          person: this.person,
          professionals: this.professionals,
          jury: updatedJury,
          festivals: festivals,
        },
      });

      ref.onClose.subscribe((command: UpdatePersonJuryCommand) => {
        if (command) {
          if (this.person?.id) {
            this.apiService.people.updateJury(this.person.id, updatedJury.id, command).subscribe({
              next: (jury: Jury) => {
                this.updateJury(jury);
                this.messageService.add({
                  severity: 'success',
                  summary: 'Updated',
                  detail: 'You have updated the jury for this person',
                });
              },
              error: () => {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'An error occurred while updating the jury',
                });
              },
            });
          }
        }
      });
    });
  }

  confirmDelete($event: MouseEvent, jury: Jury) {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: 'Are you sure that you want to delete this jury?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.person?.id) {
          this.apiService.people.deleteJury(this.person.id, jury.id).subscribe({
            next: () => {
              this.removeJury(jury);
              this.messageService.add({
                severity: 'success',
                summary: 'Deleted',
                detail: 'You have deleted the jury for this person',
              });
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while deleting the jury',
              });
            },
          });
        }
      },
      reject: () => undefined,
    });
  }

  createClicked() {
    this.apiService.festivals.find().subscribe((festivals: Festival[]) => {
      const ref = this.dialogService.open(PersonJuriesFormComponent, {
        header: `Add new jury`,
        width: '50em',
        height: 'auto',
        data: {
          person: this.person,
          professionals: this.professionals,
          festivals: festivals,
        },
      });

      ref.onClose.subscribe((command: CreatePersonJuryCommand) => {
        if (command) {
          if (this.person?.id) {
            this.apiService.people.addJury(this.person.id, command).subscribe({
              next: (jury: Jury) => {
                this.addJury(jury);
                this.messageService.add({
                  severity: 'success',
                  summary: 'Created',
                  detail: 'You have added a new jury for this person',
                });
              },
              error: () => {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'An error occurred while creating the jury',
                });
              },
            });
          }
        }
      });
    });
  }

  public addJury(jury: Jury) {
    this.juries.unshift(jury);
  }

  public updateJury(jury: Jury) {
    const index = this.juries.findIndex((f) => f.id === jury.id);
    if (index >= 0) {
      this.juries[index] = jury;
    }
  }

  public removeJury(jury: Jury) {
    const index = this.juries.findIndex((f) => f.id === jury.id);
    if (index >= 0) {
      this.juries.splice(index, 1);
    }
  }
}
