import { BaseModel } from './base.model';
import { Company } from './company.model';
import { Holding } from './holding.model';
import { Office } from './office.model';

export class Network extends BaseModel {
  public name: string;

  public companies: Company[] = [];

  public offices: Office[] = [];

  public holdingId: number;

  public holding?: Holding;

  public isEnabled: boolean;
  public lastReview?: string;

  constructor(data: Network) {
    super(data);

    ({ name: this.name, holdingId: this.holdingId, isEnabled: this.isEnabled, lastReview: this.lastReview } = data);

    if (data.companies && data.companies.length) {
      this.companies = data.companies.map((company) => new Company(company));
    }

    if (data.offices && data.offices.length) {
      this.offices = data.offices.map((office) => new Office(office));
    }

    if (data.holding) {
      this.holding = new Holding(data.holding);
    }
  }

  public static createNewBlankNetwork(): Network {
    return new Network({ id: 0, name: '', holdingId: 0, isEnabled: true, lastReview: '', offices: [], companies: [] });
  }
}

export class NetworkCommand {
  public name: string;
  public holdingId?: number;
  public isEnabled: boolean;

  constructor(data: NetworkCommand) {
    ({ name: this.name, holdingId: this.holdingId, isEnabled: this.isEnabled } = data);
  }
}
