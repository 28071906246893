<div class="grid grid-nogutter mt-4">
  <div class="col-6 flex flex-column justify-content-end align-content-end">
    <div class="flex flex-row align-items-end justify-content-end">
      <button
        *ngIf="hasRole('db-edit-offices')"
        pButton
        label="Add Employees by Year"
        type="button"
        (click)="createClicked()"
        class="p-button-raised p-button-warning"
        icon="pi pi-plus-circle"
        style="margin-bottom: 10px"
      ></button>
    </div>

    <p-table
      class="employee-stats-table"
      [value]="employeeStats"
      *ngIf="employeeStats.length"
      [scrollable]="true"
      selectionMode="single"
      styleClass="p-datatable-sm"
    >
      <ng-template pTemplate="header">
        <tr>
          <th i18n>Year</th>
          <th i18n>Number of employees</th>
          <th class="actions" *ngIf="hasRole('db-edit-offices')">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-stat>
        <tr>
          <td>{{ stat.year }}</td>
          <td>{{ stat.totalCount }}</td>
          <td class="actions" *ngIf="hasRole('db-edit-offices')">
            <button
              pButton
              type="button"
              icon="pi pi-file-edit"
              class="p-button-outlined p-button-warning"
              (click)="editClicked($event, stat)"
            ></button>
            <button
              pButton
              type="button"
              icon="pi pi-trash"
              class="p-button-outlined p-button-warning"
              (click)="confirmDelete($event, stat)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
