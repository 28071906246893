import { BaseModel } from './base.model';

export class Filter extends BaseModel {
  public name: string;

  public countryId: number;

  constructor(data: Filter) {
    super(data);

    ({ name: this.name, countryId: this.countryId } = data);
  }
}

export class UpdatePersonFiltersCommand {
  public personId: number;
  public filterIds: number[];

  constructor(personId: number, filterIds: number[]) {
    this.personId = personId;
    this.filterIds = filterIds;
  }
}

export class FilterCommand {
  public name: string;

  public countryId: number;

  constructor(data: FilterCommand) {
    ({ name: this.name, countryId: this.countryId } = data);
  }
}

export class CreateFilterCommand extends FilterCommand {
  constructor(data: CreateFilterCommand) {
    super(data);
  }
}

export class UpdateFilterCommand extends FilterCommand {
  public id: number;

  constructor(data: UpdateFilterCommand) {
    super(data);
    this.id = data.id;
  }
}
