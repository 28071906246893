<div class="form-container" *ngIf="office && office.advertiser">
  <div class="col-12 flex start">
    <app-office-summary [office]="office"></app-office-summary>
  </div>
  <div class="col-12 flex start">
    <div class="col-3 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="year"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Year"
          [(ngModel)]="command.year"
          (ngModelChange)="yearChanged()"
        />
        <label for="year">Year</label>
      </span>
    </div>
    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="ranking"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Ranking"
          [(ngModel)]="command.ranking"
          (ngModelChange)="rankingChanged()"
        />
        <label for="ranking">Ranking</label>
      </span>
    </div>
    <div class="col-5 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="investment"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Investment"
          [(ngModel)]="command.investment"
          (ngModelChange)="investmentChanged()"
        />
        <label for="investment">Investment</label>
      </span>
    </div>
  </div>
  <div class="col-12">
    <button
      pButton
      *ngIf="mode === 'create'"
      label="Add InfoAdex"
      type="button"
      (click)="createClicked()"
      [disabled]="!addInfoAdexEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-plus-circle"
    ></button>

    <button
      pButton
      *ngIf="mode === 'edit'"
      label="Update InfoAdex"
      type="button"
      (click)="createClicked()"
      [disabled]="!addInfoAdexEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
