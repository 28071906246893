import { BaseModel } from './base.model';
import { Company } from './company.model';
import { Network } from './network.model';
import { Office } from './office.model';

export class Holding extends BaseModel {
  public name: string;

  public isBigPlayer: boolean;

  public networks: Network[] = [];

  public companies: Company[] = [];

  public offices: Office[] = [];

  public isEnabled: boolean;
  public lastReview?: string;

  constructor(data: Holding) {
    super(data);

    ({ name: this.name, isBigPlayer: this.isBigPlayer, isEnabled: this.isEnabled, lastReview: this.lastReview } = data);

    if (data.networks && data.networks.length) {
      this.networks = data.networks.map((network) => new Network(network));
    }

    if (data.companies && data.companies.length) {
      this.companies = data.companies.map((company) => new Company(company));
    }

    if (data.offices && data.offices.length) {
      this.offices = data.offices.map((office) => new Office(office));
    }
  }

  public static createNewBlankHolding(): Holding {
    return new Holding({
      id: 0,
      name: '',
      isBigPlayer: false,
      isEnabled: true,
      lastReview: '',
      networks: [],
      companies: [],
      offices: [],
    });
  }
}

export class HoldingCommand {
  public name: string;
  public isBigPlayer: boolean;
  public isEnabled: boolean;

  constructor(data: HoldingCommand) {
    ({ name: this.name, isBigPlayer: this.isBigPlayer, isEnabled: this.isEnabled } = data);
  }
}
