import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateOfficeSubTypeCommand, OfficeSubType, UpdateOfficeSubTypeCommand } from '../../../../models';

@Component({
  selector: 'app-agencies-types-form',
  templateUrl: './agencies-types-form.component.html',
  styleUrls: ['./agencies-types-form.component.scss'],
})
export class AgenciesTypesFormComponent implements OnInit {
  public mode: 'create' | 'edit' = 'create';

  public addAgencyTypeEnabled = false;

  public currentName = '';

  public currentOrder = 0;

  public command: CreateOfficeSubTypeCommand | UpdateOfficeSubTypeCommand = new CreateOfficeSubTypeCommand({
    name: '',
    order: 0,
  });

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data?.agencyType) {
      this.mode = 'edit';
      this.fillData(this.config.data.agencyType);
    } else {
      this.mode = 'create';
    }
  }

  private fillData(agencyType: OfficeSubType) {
    this.currentName = agencyType.name;
    this.currentOrder = agencyType.order;
    this.command = new UpdateOfficeSubTypeCommand(agencyType);
  }

  public createClicked(): void {
    if (this.addAgencyTypeEnabled) {
      this.ref.close(this.command);
    }
  }

  public cancelClicked(): void {
    this.ref.close();
  }

  public nameChanged(): void {
    this.isAddAgencyTypeEnabled();
  }

  public orderChanged(): void {
    this.isAddAgencyTypeEnabled();
  }

  public isAddAgencyTypeEnabled(): void {
    if (this.mode === 'edit') {
      this.addAgencyTypeEnabled = !!(
        this.command.name &&
        this.command.name.trim().length &&
        this.command.name.trim() !== this.currentName.trim() &&
        this.command.order
      );
    } else {
      this.addAgencyTypeEnabled = !!(this.command.name && this.command.name.trim().length && this.command.order);
    }
  }
}
