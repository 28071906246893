import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Brand, Office } from '../../../models';
import { Advertiser } from '../../../models/advertiser.model';
import { AgencyService } from '../../../models/agency-service.model';
import { Agency } from '../../../models/agency.model';
import { CollaborationCommand } from '../../../models/collaboration.model';
import { IOfficesApiFilters, IPrimengCustomEvent, ISearchQuery } from '../../../shared/interfaces';
import { ApiService } from '../../../shared/services';
import { BaseComponent } from '../../general/base/base.component';
import { UtcDateConverter } from '../../../shared/pipes/utc-date-converter';

interface CurrentOption {
  name: string;
  value: boolean;
}

@Component({
  selector: 'app-add-collaboration-form',
  templateUrl: './add-collaboration-form.component.html',
  styleUrls: ['./add-collaboration-form.component.scss'],
})
export class AddCollaborationFormComponent extends BaseComponent implements OnInit {
  public office: Office | undefined = undefined;
  public command: CollaborationCommand = CollaborationCommand.createNewBlankCollaborationCommand();
  public agency: Agency = new Agency({ id: 0, name: '', fullName: '', types: [], services: [] });
  public advertiser: Advertiser = new Advertiser({
    id: 0,
    name: '',
    fullName: '',
    brands: [],
    sectors: [],
    infoAdexRecords: [],
  });
  public selectedAdvertiser?: Advertiser = undefined;
  public selectedAgency?: Agency = undefined;

  public filteredAvertisers: Advertiser[] = [];
  public advertisers: Advertiser[] = [];

  public agencies: Agency[] = [];
  public filteredAgencies: Agency[] = [];

  public filteredBrands: Brand[] = [];
  public brands: Brand[] = [];

  public filteredAgencyServices: AgencyService[] = [];
  public agencyServices: AgencyService[] = [];

  public selectedBrands: Brand[] = [];
  public selectedServices: AgencyService[] = [];

  public currentOptions = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ];
  public selectedCurrentOption: CurrentOption = this.currentOptions[0];
  public mode = 'create';
  public officeMode = 'agency';
  public addCollaborationEnabled = false;

  constructor(private apiService: ApiService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    super();
  }

  ngOnInit(): void {
    this.office = this.config.data.office;
    this.agency = this.config.data.office.agency;
    this.advertiser = this.config.data.office.advertiser;
    this.officeMode = this.config.data.mode;

    if (this.config.data.collaboration) {
      this.mode = 'edit';
      const collaboration = this.config.data.collaboration;
      if (this.officeMode == 'agency') {
        this.selectedAdvertiser = collaboration.advertiser;
      } else {
        this.selectedAgency = collaboration.agency;
      }
      this.selectedBrands = collaboration.brands;
      this.selectedServices = collaboration.services;
      this.selectedCurrentOption =
        this.currentOptions.find((option) => option.value === collaboration.isActive) || this.currentOptions[0];
      this.command.id = collaboration.id;

      if (collaboration.startDate) {
        this.command.startDate = new Date(collaboration.startDate);
      }
      if (collaboration.endDate) {
        this.command.endDate = new Date(collaboration.endDate);
      }
      this.command.campaign = collaboration.campaign;
    }

    if (this.officeMode == 'agency') {
      this.apiService.advertisers.getAll().subscribe((advertisers: Advertiser[]) => {
        advertisers.forEach((advertiser) => {
          this.advertisers.push(advertiser);
        });
        this.filteredAvertisers = this.advertisers;
        this.checkAddCollaborationEnabled();
      });
    } else {
      this.apiService.agencies.getAll().subscribe((agencies: Agency[]) => {
        agencies.forEach((agency) => {
          this.agencies.push(agency);
        });
        this.filteredAgencies = this.agencies;
        this.checkAddCollaborationEnabled();
      });
    }

    if (this.officeMode == 'agency') {
      // this.brands = this.selectedAdvertiser?.brands || [];
      // this.filteredBrands = this.selectedAdvertiser?.brands || [];
      this.advertiserChanged();
      //this.agencyServices = this.agency.services;
      //this.filteredAgencyServices = this.agencyServices;
    } else {
      this.brands = this.advertiser.brands;
      this.filteredBrands = this.brands;
      //this.agencyServices = [];
      //this.filteredAgencyServices = [];
    }
    /* this.apiService.brands.find().subscribe((brands: Brand[]) => {
       brands.forEach((brand) => {
         this.brands.push(new Brand(brand));
       });
       this.filteredBrands = this.brands;
     });*/

    this.apiService.agencies.getAllServices().subscribe((agencyServices: AgencyService[]) => {
      agencyServices.forEach((agencyService) => {
        this.agencyServices.push(new AgencyService(agencyService));
      });
      this.filteredAgencyServices = this.agencyServices;
    });
  }

  searchAdvertiser($event: IPrimengCustomEvent) {
    const filters: IOfficesApiFilters = {
      page: 1,
      pageSize: 1000,
      name: $event.query,
      enabled: true,
      officeTypes: [{ officeTypeId: 2 }],
    };

    this.apiService.offices.search(filters).subscribe((result: ISearchQuery<Office[]>) => {
      const advertisers: Advertiser[] = [];
      result.items.forEach((jsonOffice) => {
        const office = new Office(jsonOffice);
        advertisers.push(
          new Advertiser({
            id: office.id,
            name: office.name,
            fullName: office.fullName,
            brands: [],
            sectors: [],
            infoAdexRecords: [],
          }),
        );
      });
      this.filteredAvertisers = advertisers;
    });
  }

  searchAgency($event: IPrimengCustomEvent) {
    const filters: IOfficesApiFilters = {
      page: 1,
      pageSize: 1000,
      name: $event.query,
      enabled: true,
      officeTypes: [{ officeTypeId: 1 }],
    };

    this.apiService.offices.search(filters).subscribe((result: ISearchQuery<Office[]>) => {
      const agencies: Agency[] = [];
      result.items.forEach((jsonOffice) => {
        const office = new Office(jsonOffice);
        agencies.push(
          new Agency({
            id: office.id,
            name: office.name,
            fullName: office.fullName,
            types: office.agency?.types || [],
            services: [],
          }),
        );
      });
      this.filteredAgencies = agencies;
    });
  }

  searchBrand($event: IPrimengCustomEvent) {
    this.filteredBrands = this.brands.filter((brand) => brand.name.toLowerCase().includes($event.query.toLowerCase()));
  }

  searchService($event: IPrimengCustomEvent) {
    this.filteredAgencyServices = this.agencyServices.filter((service) =>
      service.name.toLowerCase().includes($event.query.toLowerCase()),
    );
  }

  save() {
    if (this.officeMode == 'agency') {
      this.command.agencyId = this.agency.id;
      this.command.advertiserId = this.selectedAdvertiser?.id;
    } else if (this.officeMode == 'advertiser') {
      this.command.agencyId = this.selectedAgency?.id;
      this.command.advertiserId = this.advertiser.id;
    }
    this.command.brandIds = this.selectedBrands.map((brand) => brand.id);
    this.command.agencyServiceIds = this.selectedServices.map((service) => service.id);
    this.command.isActive = this.selectedCurrentOption.value;

    this.ref.close(this.command);
  }

  campaignChanged() {
    //console.log('not implemented');
    this.checkAddCollaborationEnabled();
  }

  advertiserChanged() {
    if (this.selectedAdvertiser && this.selectedAdvertiser.id > 0) {
      this.apiService.advertisers.getById(this.selectedAdvertiser.id).subscribe((advertiser: Advertiser) => {
        this.selectedAdvertiser = advertiser;
        this.brands = advertiser.brands;
        this.filteredBrands = this.brands;
        this.checkAddCollaborationEnabled();
      });
    }
  }

  agencyChanged() {
    if (this.selectedAgency && this.selectedAgency.id > 0) {
      this.apiService.agencies.getById(this.selectedAgency.id).subscribe((agency: Agency) => {
        this.selectedAgency = agency;
        /*  this.agencyServices = agency.services;
        this.filteredAgencyServices = this.agencyServices;*/
        this.checkAddCollaborationEnabled();
      });
    }
  }

  createClicked() {
    if (this.office && this.officeMode == 'agency') {
      this.command.agencyId = this.office.agency?.id;
      this.command.advertiserId = this.selectedAdvertiser?.id;
      this.command.brandIds = this.selectedBrands.map((brand) => brand.id);
      this.command.agencyServiceIds = this.selectedServices.map((service) => service.id);
      this.command.isActive = this.selectedCurrentOption.value;
    } else if (this.office && this.officeMode == 'advertiser') {
      this.command.agencyId = this.selectedAgency?.id;
      this.command.advertiserId = this.office.advertiser?.id;
      this.command.brandIds = this.selectedBrands.map((brand) => brand.id);
      this.command.agencyServiceIds = this.selectedServices.map((service) => service.id);
      this.command.isActive = this.selectedCurrentOption.value;
    }

    this.command.startDate = UtcDateConverter.convert(this.command.startDate);
    this.command.endDate = UtcDateConverter.convert(this.command.endDate);
    console.log(this.command.startDate);

    this.ref.close(this.command);
  }

  cancelClicked() {
    this.ref.close();
  }

  private checkAddCollaborationEnabled() {
    if (this.officeMode == 'agency') {
      this.addCollaborationEnabled = this.selectedAdvertiser != undefined && this.selectedAdvertiser.id > 0;
    } else {
      this.addCollaborationEnabled = this.selectedAgency != undefined && this.selectedAgency.id > 0;
    }
  }
}
