<div class="form-container" *ngIf="network">
  <div class="col-12 flex start">
    <app-network-summary [network]="network"></app-network-summary>
  </div>

  <div class="col-12 flex flex-row align-items-end justify-content-start">
    <div class="col-12 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="name"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Network Name"
          [(ngModel)]="network.name"
          (ngModelChange)="networkNameChanged()"
        />
        <label for="name">Network Name</label>
      </span>
    </div>
  </div>
  <div class="col-12 flex flex-row align-items-end justify-content-start">
    <span class="p-float-label p-input-icon-right w-full p-2">
      <p-autoComplete
        id="holdings"
        [(ngModel)]="selectedHolding"
        [suggestions]="filteredHoldings"
        (completeMethod)="searchHolding($event)"
        [dropdown]="true"
        field="name"
        (ngModelChange)="holdingChanged()"
        [style]="{ width: '100%' }"
        [inputStyle]="{ width: '100%' }"
      ></p-autoComplete>
      <label for="holdings">Holding</label>
    </span>
  </div>
  <div class="col-12 flex flex-row align-items-end justify-content-start">
    <div class="p-2">
      <span><small>Is Enabled?</small></span>
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-selectButton
          id="isEnabled"
          [options]="enableOptions"
          [(ngModel)]="selectedEnableOption"
          optionLabel="label"
          (onChange)="enableOptionChanged()"
        ></p-selectButton>
      </span>
    </div>
  </div>

  <div class="col-12">
    <button
      *ngIf="mode === 'edit'"
      pButton
      label="Update Network"
      type="button"
      (click)="updateClicked()"
      [disabled]="!updateNetworkEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      *ngIf="mode === 'create'"
      pButton
      label="Create Network"
      type="button"
      (click)="updateClicked()"
      [disabled]="!updateNetworkEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
