import { Component, Input } from '@angular/core';
import { Office } from '../../../models';

@Component({
  selector: 'app-office-tabs',
  templateUrl: './office-tabs.component.html',
  styleUrls: ['./office-tabs.component.scss'],
})
export class OfficeTabsComponent {
  @Input() office: Office | undefined = undefined;
}
