<div *ngIf="!multipleRows">
  <div class="col-12 py-0 flex flex-row align-items-start justify-content-start">
    <span i18n class="mr-3 capitalize"
      ><strong>{{ title }}</strong></span
    >
    <span class="secondary-text">{{ value }}</span>
  </div>
</div>
<div *ngIf="multipleRows">
  <div class="col-12 py-0 flex flex-row align-items-start justify-content-start">
    <span i18n class="mr-3 capitalize"
      ><strong>{{ title }}</strong></span
    >
  </div>
  <div class="col-12 py-0 flex flex-row align-items-start justify-content-start">
    <span class="secondary-text">{{ value }}</span>
  </div>
</div>
