import { Component, Input } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Office, UpsertStudioTypesCommand } from '../../../../models';
import { ApiService, ToastService } from '../../../../shared/services';
import { StudioTypesFormComponent } from '../studio-types-form/studio-types-form.component';
import { BaseComponent } from '../../../general/base/base.component';

@Component({
  selector: 'app-studio-types',
  templateUrl: './studio-types.component.html',
  styleUrls: ['./studio-types.component.scss'],
})
export class StudioTypesComponent extends BaseComponent {
  @Input() office: Office | undefined = undefined;
  public isLoading = false;

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    private toastService: ToastService,
  ) {
    super();
  }

  updateClicked() {
    const ref = this.dialogService.open(StudioTypesFormComponent, {
      header: `Edit Studio Types`,
      width: '700px',
      height: 'auto',
      data: {
        office: this.office,
      },
    });

    ref.onClose.subscribe((command: UpsertStudioTypesCommand) => {
      if (command && this.office) {
        this.isLoading = true;
        this.apiService.studios.upsertTypes(this.office?.id, command).subscribe(
          (studio) => {
            if (this.office) {
              this.office.studio = studio;
            }
            this.isLoading = false;
            this.toastService.send({
              severity: 'success',
              summary: 'Updated',
              detail: 'You have updated the types for this studio',
            });
          },
          () => {
            this.isLoading = false;
            this.toastService.send({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error updating the types for this studio',
            });
          },
        );
      }
    });
  }
}
