import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../components/general/layout/layout.component';
import { NotFoundComponent } from '../components/general/not-found/not-found.component';
import { AppPermissions } from '../shared/constants';
import { PermissionGuard } from '../shared/guards';
import { AdminsComponent } from './admins/admins.component';
import { CompaniesComponent } from './companies/companies.component';
import { OfficeDetailsComponent } from './office-details/office-details.component';
import { PersonDetailsComponent } from './person-details/person-details.component';
import { ProfessionalsComponent } from './professionals/professionals.component';
import { NetworksHoldingsComponent } from './networks-holdings/networks-holdings.component';
import { HoldingDetailsComponent } from './holding-details/holding-details.component';
import { NetworkDetailsComponent } from './network-details/network-details.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { AccessDeniedComponent } from '../components/general/access-denied/access-denied.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/app/companies',
        pathMatch: 'full',
      },
      {
        path: 'companies',
        data: {
          breadcrumb: $localize`Companies`,
        },
        children: [
          {
            path: '',
            component: CompaniesComponent,
            data: {
              breadcrumb: $localize`List`,
            },
          },
          {
            path: 'office/:id',
            component: OfficeDetailsComponent,
            data: {
              breadcrumb: $localize`Office detail`,
            },
          },
          {
            path: 'company/:id',
            component: CompanyDetailsComponent,
            data: {
              breadcrumb: 'Company detail',
            },
          },
        ],
      },
      {
        path: 'networks-holdings',
        data: {
          breadcrumb: $localize`Networks & Holdings`,
        },
        children: [
          {
            path: '',
            component: NetworksHoldingsComponent,
            data: {
              breadcrumb: $localize`List`,
            },
          },
          {
            path: 'holding/:id',
            component: HoldingDetailsComponent,
            data: {
              breadcrumb: `Holding detail`,
            },
          },
          {
            path: 'network/:id',
            component: NetworkDetailsComponent,
            data: {
              breadcrumb: `Network detail`,
            },
          },
        ],
      },
      {
        path: 'professionals',
        data: {
          breadcrumb: $localize`Professionals`,
        },
        children: [
          {
            path: '',
            component: ProfessionalsComponent,
            data: {
              breadcrumb: $localize`List`,
            },
          },
          {
            path: 'people/:id',
            component: PersonDetailsComponent,
            data: {
              breadcrumb: $localize`Person detail`,
            },
          },
        ],
      },
      {
        path: 'admin',
        component: AdminsComponent,
        data: {
          breadcrumb: $localize`Admin`,
          roles: [AppPermissions.DB_ADMIN, AppPermissions.DB_EDITOR],
          operation: 'or',
        },
        canActivate: [PermissionGuard],
        children: [
          {
            path: 'brands',
            component: AdminsComponent,
            data: {
              breadcrumb: $localize`Brands`,
              roles: [AppPermissions.DB_EDIT_BRANDS],
              operation: 'and',
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'products',
            component: AdminsComponent,
            data: {
              breadcrumb: $localize`Products`,
              roles: [AppPermissions.DB_EDIT_PROJECTS],
              operation: 'and',
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'events',
            component: AdminsComponent,
            data: {
              breadcrumb: $localize`Events`,
              roles: [AppPermissions.DB_EDIT_EVENTS],
              operation: 'and',
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'festivals',
            component: AdminsComponent,
            data: {
              breadcrumb: $localize`Festivals`,
              roles: [AppPermissions.DB_EDIT_FESTIVALS],
              operation: 'and',
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'filters',
            component: AdminsComponent,
            data: {
              breadcrumb: $localize`Filters`,
              roles: [AppPermissions.DB_EDIT_FILTERS],
              operation: 'and',
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'countries',
            component: AdminsComponent,
            data: {
              breadcrumb: $localize`Countries`,
              roles: [AppPermissions.DB_EDIT_COUNTRIES],
              operation: 'and',
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'users',
            component: AdminsComponent,
            data: {
              breadcrumb: $localize`Users`,
              roles: [AppPermissions.DB_EDIT_USERS],
              operation: 'and',
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'job-roles',
            component: AdminsComponent,
            data: {
              breadcrumb: $localize`Job roles`,
              roles: [AppPermissions.DB_EDIT_JOBROLES],
              operation: 'and',
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'agency-services',
            component: AdminsComponent,
            data: {
              breadcrumb: $localize`Agency services`,
              roles: [AppPermissions.DB_EDIT_AGENCY_SERVICES],
              operation: 'and',
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'agency-types',
            component: AdminsComponent,
            data: {
              breadcrumb: $localize`Agency types`,
              roles: [AppPermissions.DB_EDIT_AGENCY_TYPES],
              operation: 'and',
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'sectors',
            component: AdminsComponent,
            data: {
              breadcrumb: $localize`Sectors`,
              roles: [AppPermissions.DB_EDIT_SECTORS],
              operation: 'and',
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'media-types',
            component: AdminsComponent,
            data: {
              breadcrumb: $localize`Media Types`,
              roles: [AppPermissions.DB_EDIT_MEDIA_TYPES],
              operation: 'and',
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'waves',
            component: AdminsComponent,
            data: {
              breadcrumb: $localize`Waves`,
              roles: [AppPermissions.DB_EDIT_WAVES],
              operation: 'and',
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'titles',
            component: AdminsComponent,
            data: {
              breadcrumb: $localize`Titles`,
              roles: [AppPermissions.DB_EDIT_TITLES],
              operation: 'and',
            },
            canActivate: [PermissionGuard],
          },
        ],
      },
      {
        path: 'not-found',
        component: NotFoundComponent,
        data: {
          breadcrumbs: $localize`Not Found`,
        },
      },
      {
        path: 'access-denied',
        component: AccessDeniedComponent,
        data: {
          breadcrumbs: 'Access Denied',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PageRoutingModule {}
