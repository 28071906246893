import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Workbook } from 'exceljs';
import capitalize from 'lodash.capitalize';
import { BehaviorSubject } from 'rxjs';
import { Office, Professional } from '../../../models';
import { IPrimengSelectButtonOptions, IProfessionalsToExcel } from '../../../shared/interfaces';
import { ApiService } from '../../../shared/services';
import { UtilService } from '../../../shared/services/util.service';

@Component({
  selector: 'app-office-professionals',
  templateUrl: './office-professionals.component.html',
  styleUrls: ['./office-professionals.component.scss'],
})
export class OfficeProfessionalsComponent implements OnChanges, OnInit {
  @Input() office: Office | undefined = undefined;

  public professionals: Professional[] = [];

  public filteredProfessionals: Professional[] = [];

  enabledOptions: IPrimengSelectButtonOptions<'current' | 'all'>[] = [
    { label: 'Current', value: 'current' },
    { label: 'All', value: 'all' },
  ];

  selectedEnabledOptionValue = 'current';

  selectedSearchText = '';

  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private readonly apiService: ApiService,
    private readonly utilService: UtilService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.searchText$.subscribe(() => {
      this.selectedEnabledOptionValueChanged();
      this.filteredProfessionals = this.filteredProfessionals.filter(
        (c) =>
          c.person?.firstName?.toLowerCase().includes(this.selectedSearchText.toLowerCase()) ||
          c.person?.lastName?.toLowerCase().includes(this.selectedSearchText.toLowerCase()) ||
          c.email?.toLowerCase().includes(this.selectedSearchText.toLowerCase()) ||
          c.phone?.toLowerCase().includes(this.selectedSearchText.toLowerCase()) ||
          c.mobilePhone?.toLowerCase().includes(this.selectedSearchText.toLowerCase()) ||
          c.positionName?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['office'] && changes['office'].currentValue) {
      this.apiService.offices.professionalsByOffice(changes['office'].currentValue.id).subscribe({
        next: (data) => {
          this.professionals = data.items.sort((a, b) => {
            if (a.role && b.role) {
              const roleA = a.role?.name.toUpperCase();
              const roleB = b.role?.name.toUpperCase();
              if (roleA < roleB) {
                return -1;
              }
              if (roleA > roleB) {
                return 1;
              }
              return this.getOrderByFirstName(a, b);
            }
            return 0;
          });
          this.selectedEnabledOptionValueChanged();
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  private getOrderByFirstName(a: Professional, b: Professional): number {
    if (a.person?.firstName && b.person?.firstName) {
      return a.person?.firstName?.localeCompare(b.person?.firstName) || this.getOrderByLastName(a, b);
    }
    return 0;
  }

  private getOrderByLastName(a: Professional, b: Professional): number {
    if (a.person?.lastName && b.person?.lastName) {
      return a.person?.lastName?.localeCompare(b.person?.lastName) || 0;
    }
    return 0;
  }

  public parseAddress(professional: Professional) {
    this.utilService.parseAddress(professional.person?.address);
  }

  navigateToPerson(professional: Professional) {
    this.router.navigate([`professionals/people/${professional.person?.id}`]);
  }

  selectedEnabledOptionValueChanged() {
    if (this.selectedEnabledOptionValue == 'current') {
      this.filteredProfessionals = this.professionals.filter((c) => c.isActive);
    } else {
      this.filteredProfessionals = this.professionals;
    }
  }

  searchTextChanged() {
    this.searchText$.next(this.selectedSearchText);
  }

  async exportExcel() {
    const { columns, rows } = this.getProfessionalsForExcel();
    const workbook = new Workbook();
    const workSheet = workbook.addWorksheet(`Professionals from office ${this.office?.name}`);
    workSheet.columns = columns;
    workSheet.insertRow(1, null);
    workSheet.mergeCells('A1', 'M1');
    const titleRow = workSheet.getCell('A1');
    titleRow.value = 'CRM';
    titleRow.alignment = { horizontal: 'center', vertical: 'middle' };
    workSheet.addRows(rows);
    const headerRow = workSheet.getRow(2);
    headerRow.font = { bold: true };
    headerRow.alignment = { horizontal: 'left', vertical: 'middle' };
    const buffer = (await workbook.xlsx.writeBuffer()) as unknown as Buffer;
    this.utilService.saveAsExcelFile(buffer, `Professionals-from-office-${this.office?.name.replaceAll(' ', '_')}`);
  }

  private getProfessionalsForExcel(): {
    columns: { header: string; key: string; width: number }[];
    rows: IProfessionalsToExcel[];
  } {
    const rows = this.filteredProfessionals.map((professional) => professional.professionalForExcel(this.office));
    const keys = Object.keys(rows[0]);
    const columns = keys.map((key) => ({ header: capitalize(key), key, width: 30 }));
    return { columns, rows };
  }
}
