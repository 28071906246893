<div *ngIf="office" class="col-12 p-2 py-4">
  <div class="justify-content-start w-12">
    <div class="flex flex-column">
      <h3 [className]="officeCssClass()">{{ office.name }}</h3>
      <div *ngIf="!office.isEnabled" class="flex flex-row align-content-start py-2">
        <i class="pi pi-exclamation-circle"></i>
        <span class="py-2 disabled-text">This office record is disabled</span>
      </div>
      <div class="flex office-type-names">
        {{ mapOfficeTypesNames() }}
      </div>
      <div *ngIf="office.country" class="flex flex-row justify-content-start">
        <img class="country-icon" [src]="office.country.flagUrl" />
        <div class="flex flex-column justify-content-center">{{ office.country.name }}</div>
      </div>
      <div>
        <span><strong>Last Review: </strong></span>
        {{ office.lastReview | date: 'dd/MM/yyyy' }}
      </div>
    </div>
  </div>
</div>
