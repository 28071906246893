import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Exclusive, Office, SetMediaExclusiveCommand } from '../../../../models';
import { ApiService, ToastService } from '../../../../shared/services';
import { IPrimengCustomEvent } from '../../../../shared/interfaces/primeng-scopen.interface';

@Component({
  selector: 'app-media-exclusive-form',
  templateUrl: './media-exclusive-form.component.html',
  styleUrls: ['./media-exclusive-form.component.scss'],
})
export class MediaExclusiveFormComponent implements OnInit {
  public office: Office | undefined = undefined;
  public exclusives: Exclusive[] = [];
  public filteredExclusives: Exclusive[] = [];

  constructor(
    private apiService: ApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private toastService: ToastService,
  ) {}

  cancelClicked() {
    this.ref.close();
  }

  ngOnInit(): void {
    if (this.config.data.office) {
      this.office = this.config.data.office;
    }

    this.apiService.exclusives.getAll().subscribe(
      (exclusives: Exclusive[]) => {
        this.exclusives.push(new Exclusive({ id: 0, name: 'none', fullName: '---', media: [] }));
        exclusives.forEach((exclusive: Exclusive) => {
          this.exclusives.push(exclusive);
        });
        this.filteredExclusives = this.exclusives;
      },
      () => {
        this.toastService.send({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error getting the exclusive list',
        });
      },
    );
  }

  searchExclusive($event: IPrimengCustomEvent) {
    this.filteredExclusives = this.exclusives.filter((exclusive: Exclusive) =>
      exclusive.fullName.toLowerCase().includes($event.query.toLowerCase()),
    );
  }

  updateClicked() {
    if (this.office) {
      this.ref.close(new SetMediaExclusiveCommand({ exclusiveId: this.office.media?.exclusive?.id || 0 }));
    }
  }
}
