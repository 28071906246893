<div class="form-container" *ngIf="person">
  <div class="col-12 flex start">
    <app-person-summary [person]="person"></app-person-summary>
  </div>

  <div class="col-12 flex start">
    <div class="col-2 p-2">
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-dropdown
          id="person-title"
          placeholder="Title"
          [options]="titles"
          [(ngModel)]="person.title"
          (ngModelChange)="titleChanged()"
          optionLabel="name"
          appendTo="body"
          class="w-full"
          [style]="{ width: '100%' }"
        ></p-dropdown>
        <label for="person-title">Title</label>
      </span>
    </div>

    <div class="col-5 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="firstName"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="First Name"
          [(ngModel)]="person.firstName"
          (ngModelChange)="firstNameChanged()"
        />
        <label for="firstName">First Name</label>
      </span>
    </div>

    <div class="col-5 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="lastName"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Last Name"
          [(ngModel)]="person.lastName"
          (ngModelChange)="lastNameChanged()"
        />
        <label for="lastName">Last Name</label>
      </span>
    </div>
  </div>

  <div class="col-12 flex start">
    <div class="col-8 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="observations"
          type="text"
          pInputTextarea
          class="p-inputtext-sm w-full"
          placeholder="Observations"
          [(ngModel)]="person.observations"
          (ngModelChange)="observationsChanged()"
        />
        <label for="observations">Observations</label>
      </span>
    </div>

    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-dropdown
          id="country"
          placeholder="Country"
          [options]="countries"
          [(ngModel)]="person.country"
          (ngModelChange)="countryChanged()"
          optionLabel="name"
          appendTo="body"
          class="w-full"
          [style]="{ width: '100%' }"
        ></p-dropdown>
        <label for="country">Country</label>
      </span>
    </div>
  </div>

  <div class="col-12 flex start">
    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="phone"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Phone"
          [(ngModel)]="person.phone"
          (ngModelChange)="phoneChanged()"
        />
        <label for="phone">Phone</label>
      </span>
    </div>

    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="mobilePhone"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Mobile Phone"
          [(ngModel)]="person.mobilePhone"
          (ngModelChange)="mobilePhoneChanged()"
        />
        <label for="mobilePhone">Mobile Phone</label>
      </span>
    </div>

    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="email"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Email"
          [(ngModel)]="person.email"
          (ngModelChange)="emailChanged()"
          email
        />
        <label for="email">Email</label>
      </span>
    </div>
  </div>

  <div class="col-12 flex start">
    <div class="col-3 p-2">
      <span><small>Is Enabled?</small></span>
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-selectButton
          [options]="enableOptions"
          [(ngModel)]="selectedEnableOption"
          optionLabel="label"
        ></p-selectButton>
      </span>
    </div>
  </div>

  <div class="col-12">
    <button
      *ngIf="mode === 'edit'"
      pButton
      label="Update Professional"
      type="button"
      (click)="updateClicked()"
      [disabled]="!updatePersonEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      *ngIf="mode === 'create'"
      pButton
      label="Create Professional"
      type="button"
      (click)="updateClicked()"
      [disabled]="!updatePersonEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
