import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Brand, CreateBrandCommand, UpdateBrandCommand } from '../../../../models/brand.model';
import { ISearchQuery } from '../../../../shared/interfaces';
import { ApiService } from '../../../../shared/services/api.service';
import { BrandsFormComponent } from '../brands-form/brands-form.component';
import { BaseComponent } from '../../../general/base/base.component';

@Component({
  selector: 'app-brands-table',
  templateUrl: './brands-table.component.html',
  styleUrls: ['./brands-table.component.scss'],
})
export class BrandsTableComponent extends BaseComponent implements OnInit {
  public brands: Brand[] = [];

  public filteredBrands: Brand[] = [];

  public isLoading = true;

  public first = 0;

  public page = 1;

  public pageSize = 10;

  public availablePageSizes = environment.availablePageSizes;

  public brandsResult?: ISearchQuery<Brand[]>;

  selectedSearchText = '';

  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private apiService: ApiService,
    private confirmationService: ConfirmationService,
  ) {
    super();
  }

  ngOnInit() {
    this.refresh();
    this.searchText$.subscribe(() => {
      this.first = 0;
      this.filteredBrands = this.brands.filter((brand) =>
        brand.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  public refresh() {
    this.brands = [];
    this.isLoading = true;
    this.apiService.brands.findWithinAdvertisers(this.globalCountryId).subscribe((brands: Brand[]) => {
      this.brands = brands;
      this.filteredBrands = [...this.brands];
      this.isLoading = false;
    });
  }

  searchTextChanged() {
    this.searchText$.next(this.selectedSearchText);
  }

  createClicked() {
    const ref = this.dialogService.open(BrandsFormComponent, {
      header: `Add new Brand`,
      width: '30%',
      height: 'auto',
    });

    ref.onClose.subscribe((command: CreateBrandCommand) => {
      if (command) {
        this.apiService.brands.create(command).subscribe(
          () => {
            this.refresh();
            this.messageService.add({
              severity: 'success',
              summary: 'Created',
              detail: 'You have added a new brand',
            });
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while creating the brand',
            });
          },
        );
      }
    });
  }

  editClicked($event: MouseEvent, brand: Brand) {
    $event.preventDefault();
    $event.stopPropagation();

    const ref = this.dialogService.open(BrandsFormComponent, {
      header: `Edit Brand`,
      width: '30%',
      height: 'auto',
      data: {
        brand: brand,
      },
    });

    ref.onClose.subscribe((command: UpdateBrandCommand) => {
      if (command) {
        this.apiService.brands.update(brand.id, command).subscribe(
          () => {
            this.refresh();
            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: 'You have updated the brand',
            });
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while updating the brand',
            });
          },
        );
      }
    });
  }

  confirmDelete($event: MouseEvent, brand: Brand) {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: 'Are you sure that you want to delete this brand?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiService.brands.delete(brand.id).subscribe(
          () => {
            this.refresh();
            this.messageService.add({
              severity: 'success',
              summary: 'Deleted',
              detail: 'You have deleted the brand',
            });
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while deleting the brand',
            });
          },
        );
      },
      reject: () => undefined,
    });
  }

  next() {
    this.first = this.first + this.pageSize;
  }

  prev() {
    this.first = this.first - this.pageSize;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.brandsResult?.count ? this.first === this.brandsResult.count - this.pageSize : true;
  }

  isFirstPage(): boolean {
    return this.brandsResult?.count ? this.first === 0 : true;
  }

  translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.filteredBrands.length
    } entries`;
  }
}
