import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BaseComponent } from '../../components/general/base/base.component';
import { OfficeAdvancedExcelComponent } from '../../components/offices/office-advanced-excel/office-advanced-excel.component';
import { OfficeFormComponent } from '../../components/offices/office-form/office-form.component';
import { OfficesTableComponent } from '../../components/offices/offices-table/offices-table.component';
import { Company, CompanyCommand, Country, Office, UpdateOfficeCommand } from '../../models';
import { IOfficesApiFilters, IOfficesFilter } from '../../shared/interfaces';
import { ApiService, ToastService } from '../../shared/services';
import { CountryService } from '../../shared/services/country.service';
import { CompanyListComponent } from '../../components/companies/company-list/company-list.component';
import { NetworkListComponent } from '../../components/networks/network-list/network-list.component';
import { HoldingListComponent } from '../../components/holdings/holding-list/holding-list.component';
import { CompanyFormComponent } from '../../components/companies/company-form/company-form.component';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent extends BaseComponent implements AfterViewInit {
  @ViewChild(OfficesTableComponent) officesTable?: OfficesTableComponent;
  @ViewChild(CompanyListComponent) companyList?: CompanyListComponent;
  @ViewChild(NetworkListComponent) networkList?: NetworkListComponent;
  @ViewChild(HoldingListComponent) holdingList?: HoldingListComponent;
  @ViewChild(OfficeAdvancedExcelComponent) officeExcel?: OfficeAdvancedExcelComponent;

  public componentsReady = false;

  public createOptions: MenuItem[] = [
    {
      icon: 'pi pi-wifi',
      label: 'Office',
      command: () => {
        this.createOfficeClicked();
      },
    },
    {
      icon: 'pi pi-building',
      label: 'Company',
      command: () => {
        this.createCompanyClicked();
      },
    },
  ];

  public filters: IOfficesFilter | null = null;
  public latestApiFilters: IOfficesApiFilters | undefined = undefined;

  constructor(
    private readonly apiService: ApiService,
    private readonly countryService: CountryService,

    private readonly dialogService: DialogService,
    private readonly toastService: ToastService,
    private readonly cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.officesTable) {
      this.componentsReady = true;
      this.cd.detectChanges();
    }
  }

  public onFilters(filters: IOfficesFilter) {
    this.filters = filters;

    if (this.officesTable) {
      this.officesTable.load(filters);
    }

    if (this.companyList) {
      this.companyList.load(filters);
    }

    if (this.networkList) {
      this.networkList.load(filters);
    }

    if (this.holdingList) {
      this.holdingList.load(filters);
    }
  }

  createOfficeClicked() {
    this.countryService
      .find({ countryId: this.globalCountryId, includeProvinces: true })
      .subscribe((countries: Country[]) => {
        const ref = this.dialogService.open(OfficeFormComponent, {
          header: `Create office`,
          width: '50%',
          height: 'auto',
          data: {
            office: Office.createNewBlankOffice(),
            countries: countries,
            mode: 'create',
          },
        });

        ref.onClose.subscribe((command: UpdateOfficeCommand) => {
          if (command) {
            this.apiService.offices.create(command).subscribe(
              () => {
                this.toastService.send({
                  severity: 'success',
                  summary: 'Updated',
                  detail: 'You have created an office',
                });
              },
              () => {
                this.toastService.send({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'An error occurred while creating the office',
                });
              },
            );
          }
        });
      });
  }

  createCompanyClicked() {
    const ref = this.dialogService.open(CompanyFormComponent, {
      header: `Create company`,
      width: '50%',
      height: 'auto',
      data: {
        company: Company.createNewBlankCompany(),
        mode: 'create',
      },
    });

    ref.onClose.subscribe((command: CompanyCommand) => {
      if (command) {
        this.apiService.companies.create(command).subscribe(
          () => {
            this.toastService.send({
              severity: 'success',
              summary: 'Created',
              detail: 'You have created a company',
            });
          },
          () => {
            this.toastService.send({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while creating the company',
            });
          },
        );
      }
    });
  }

  setLatestApiFilters($event: IOfficesApiFilters) {
    this.latestApiFilters = $event;
  }
}
