<div *ngIf="person">
  <div class="flex align-items-start justify-content-between">
    <h3>Offices</h3>
    <button
      *ngIf="hasRole('db-edit-professionals')"
      pButton
      label="Add Office"
      type="button"
      (click)="createClicked()"
      class="p-button-raised p-button-warning"
      icon="pi pi-plus-circle"
      style="margin-bottom: 10px"
    ></button>
  </div>
  <p-table
    #dt
    class="person-professionals-table"
    styleClass="p-datatable-sm"
    [value]="professionals"
    [loading]="isLoading"
    [scrollable]="true"
    selectionMode="single"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-end">
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-file-excel"
          (click)="exportExcel()"
          class="p-button-success mr-2"
          pTooltip="Export to Excel"
          tooltipPosition="bottom"
          label="Excel"
        ></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th i18n class="current">Current</th>
        <th i18n>Office</th>
        <th i18n>Office Phone</th>
        <th i18n>Position</th>
        <th i18n>Position Type</th>
        <th i18n>Direct Phone</th>
        <th i18n>Mobile</th>
        <th i18n>Email</th>
        <th i18n class="address">Address</th>
        <th i18n class="actions" *ngIf="hasRole('db-edit-professionals')">Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-professional>
      <tr
        [pSelectableRow]="professional"
        [className]="professional.role.id === 2 ? 'role-director' : ''"
        (click)="navigateToOffice(professional)"
      >
        <td class="current">
          <i *ngIf="professional.isActive" class="pi pi-check"></i>
          <i *ngIf="!professional.isActive" class="pi pi-times"></i>
        </td>
        <td>{{ professional.office.name }}</td>
        <td class="office-phone">
          <span class="flex flex-row align-items-center justify-content-between">
            <span> {{ professional.office.phone }}</span>
            <p-button
              *ngIf="professional.office.phone"
              icon="pi pi-copy"
              styleClass="p-button p-button-outlined p-button-text p-button-warning"
              (click)="copyLinkToClipboard($event, professional.office.phone)"
            ></p-button>
          </span>
        </td>
        <td>{{ professional.positionName }}</td>
        <td>{{ professional.role.name }}</td>
        <td>{{ professional.phone }}</td>
        <td>{{ professional.mobilePhone }}</td>
        <td>
          <span class="flex flex-row align-items-center justify-content-between">
            <span> {{ professional.email }}</span>
            <p-button
              *ngIf="professional.email"
              icon="pi pi-copy"
              styleClass="p-button p-button-outlined p-button-text p-button-warning"
              (click)="copyLinkToClipboard($event, professional.email)"
            ></p-button>
          </span>
        </td>
        <td class="address">{{ professional.office.parsedAddress }}</td>
        <td class="actions" *ngIf="hasRole('db-edit-professionals')">
          <button
            pButton
            type="button"
            icon="pi pi-file-edit"
            class="p-button-outlined p-button-warning"
            (click)="editClicked($event, professional)"
          ></button>
          <button
            pButton
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-warning"
            (click)="confirmDelete($event, professional)"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="flex align-items-center justify-content-between">
        In total there are {{ professionals ? professionals.length : 0 }} companies.
      </div>
    </ng-template>
  </p-table>

  <p-toast></p-toast>
  <p-confirmPopup></p-confirmPopup>
</div>
