import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Country, CreateCountryCommand, UpdateCountryCommand } from 'src/app/models';

@Component({
  selector: 'app-countries-form',
  templateUrl: './countries-form-component.html',
  styleUrls: ['./countries-form-component.scss'],
})
export class CountriesFormComponent implements OnInit {
  public mode: 'create' | 'edit' = 'create';

  public addCountryEnabled = false;

  public currentName = '';

  public currentIsoCode?: string;

  public currentFlagUrl?: string;

  public command: CreateCountryCommand | UpdateCountryCommand = {
    name: '',
    isoCode: undefined,
    flagUrl: '',
    provinces: [],
  };

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data?.country) {
      this.mode = 'edit';
      this.fillData(this.config.data.country);
    } else {
      this.mode = 'create';
    }
  }

  private fillData(country: Country): void {
    this.currentName = country.name;
    this.currentIsoCode = country.isoCode;
    this.currentFlagUrl = country.flagUrl;

    this.command = country;
  }

  public createClicked(): void {
    if (this.addCountryEnabled) {
      this.ref.close(this.command);
    }
  }

  public cancelClicked(): void {
    this.ref.close();
  }

  public nameChanged(): void {
    this.checkIfAddCountryEnabled();
  }

  public isoCodeChanged(): void {
    this.checkIfAddCountryEnabled();
  }

  public flagUrlChanged(): void {
    this.checkIfAddCountryEnabled();
  }

  public checkIfAddCountryEnabled(): void {
    if (this.mode === 'edit') {
      this.addCountryEnabled = !!(
        (this.command.name &&
          this.command.name.trim().length &&
          this.command.name.trim() !== this.currentName.trim()) ||
        (this.command.isoCode &&
          this.currentIsoCode &&
          this.command.isoCode.trim().length &&
          this.command.isoCode.trim() !== this.currentIsoCode.trim()) ||
        (this.command.flagUrl &&
          this.currentFlagUrl &&
          this.command.flagUrl.trim().length &&
          this.command.flagUrl.trim() !== this.currentFlagUrl.trim())
      );
    } else {
      this.addCountryEnabled = !!(
        this.command.name &&
        this.command.name.trim().length &&
        this.command.isoCode &&
        this.command.isoCode.trim().length &&
        this.command.flagUrl &&
        this.command.flagUrl.trim().length
      );
    }
  }
}
