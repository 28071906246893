import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Filter, Person, UpdatePersonFiltersCommand } from '../../../models';
import { ApiService } from '../../../shared/services';
import { PersonFiltersFormComponent } from '../person-filters-form/person-filters-form.component';
import { BaseComponent } from '../../general/base/base.component';

@Component({
  selector: 'app-person-filters',
  templateUrl: './person-filters.component.html',
  styleUrls: ['./person-filters.component.scss'],
})
export class PersonFiltersComponent extends BaseComponent implements OnInit {
  @Input() public person: Person | undefined = undefined;

  public filters: Filter[] = [];
  public isLoading = true;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.refresh();
  }

  public refresh() {
    this.filters = [];
    this.isLoading = true;
    if (this.person) {
      this.apiService.people.getFilters(this.person.id).subscribe(
        (filters: Filter[]) => {
          this.filters = filters;
          this.isLoading = false;
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error getting the filters for this person',
          });
        },
      );
    }
  }

  updateClicked() {
    const ref = this.dialogService.open(PersonFiltersFormComponent, {
      header: `Edit Filters`,
      width: '700px',
      height: 'auto',
      data: {
        person: this.person,
        filters: this.filters,
      },
    });

    ref.onClose.subscribe((command: UpdatePersonFiltersCommand) => {
      if (command) {
        this.isLoading = true;
        this.apiService.people.updateFilters(command.personId, command).subscribe(
          (filters) => {
            this.filters = filters;
            this.isLoading = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: 'You have updated the filters for this person',
            });
          },
          () => {
            this.isLoading = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error updating the filters for this person',
            });
          },
        );
      }
    });
  }
}
