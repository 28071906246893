import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { differenceInMinutes } from 'date-fns';
import { Observable, map, of, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  Country,
  CreateCountryCommand,
  CreateProvinceCommand,
  Province,
  UpdateCountryCommand,
  UpdateProvinceCommand,
} from '../../models';
import { IApiProblemDetails } from '../interfaces';
import { UtilService } from './util.service';

type FindQueryParams = {
  countryId?: number;
  includeProvinces: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private readonly url = `${environment.apiBase}${environment.apiCountries}`;

  private collection: Country[] = [];

  private indexesMap: Map<number, number> = new Map();

  private lastCall?: number;

  private emitter = new Subject<Country[]>();

  public $countries = this.emitter.asObservable();

  constructor(private readonly http: HttpClient, private readonly utilService: UtilService) {}

  get countries() {
    return [...this.collection];
  }

  public clearCollection(): void {
    this.collection = [];
  }

  public getCountryFromCollection(id: number): Country | undefined {
    const index = this.indexesMap.get(id);

    if (index != null) {
      return this.collection[index];
    }

    return undefined;
  }

  public find(queryParams?: FindQueryParams): Observable<Country[]> {
    console.log('country service - find', queryParams);
    if (
      this.collection.length &&
      this.lastCall &&
      differenceInMinutes(Date.now(), this.lastCall, { roundingMethod: 'round' }) <= environment.cacheExpireMinutes
    ) {
      console.log('country service - find - from cache', queryParams);
      if (queryParams?.countryId) {
        const index = this.indexesMap.get(queryParams.countryId);
        console.log('country service - find - from cache - index', index);
        if (index != null) {
          return of([this.collection[index]]);
        }
      }

      return of([...this.collection]);
    }

    console.log('country service - find - from api', queryParams);
    return this._find(queryParams).pipe(
      map((data) => {
        this.lastCall = Date.now();
        this.collection = data;
        this.utilService.createIndexesMapFromCollection(this.collection, this.indexesMap);

        if (queryParams?.countryId) {
          const index = this.indexesMap.get(queryParams.countryId);
          console.log('country service - find - from cache - index', index);
          if (index != null) {
            return [this.collection[index]];
          }
        }

        return [...this.collection];

        //return this.collection;
      }),
    );
  }

  public findById(id: number): Observable<Country> {
    return this._findById(id);
  }

  public create(command: CreateCountryCommand): Observable<Country> {
    return this._create(command).pipe(
      map((createdCountry) => {
        this.collection.push(createdCountry);
        this.indexesMap.set(createdCountry.id, this.collection.length - 1);
        this.emitter.next(this.collection);
        return createdCountry;
      }),
    );
  }

  public update(id: number, command: UpdateCountryCommand): Observable<Country> {
    return this._update(id, command).pipe(
      map((updatedCountry) => {
        const index = this.indexesMap.get(id);

        if (index != null) {
          const found = this.collection[index];
          ({
            flagUrl: found.flagUrl,
            isoCode: found.isoCode,
            name: found.name,
            createdAt: found.createdAt,
            deletedAt: found.deletedAt,
            isDeleted: found.isDeleted,
            updatedAt: found.updatedAt,
          } = updatedCountry);

          this.emitter.next(this.collection);
        } else {
          console.warn(
            `${CountryService.name}: we can't find the updated entity with id: ${id}, in the local collection`,
          );
        }

        return updatedCountry;
      }),
    );
  }

  public remove(id: number): Observable<boolean | IApiProblemDetails> {
    return this._remove(id).pipe(
      map((res) => {
        if (res == null) {
          const index = this.indexesMap.get(id);

          if (index != null) {
            this.collection.splice(index, 1);
            this.indexesMap.delete(id);
            this.emitter.next(this.collection);
          } else {
            console.warn(
              `${CountryService.name}: we can't find the removed entity with id: ${id}, in the local collection`,
            );
          }

          return !res;
        }

        return res;
      }),
    );
  }

  public createProvince(countryId: number, command: CreateProvinceCommand): Observable<Province> {
    return this._createProvince(countryId, command).pipe(
      map((createdProvince) => {
        const index = this.indexesMap.get(countryId);

        if (index != null) {
          const country = this.collection[index];
          country.provinces.push(createdProvince);
          country.addProvinceIndex(createdProvince.id, country.provinces.length - 1);
        } else {
          console.warn(
            `${CountryService.name}: we can't find the country, owner of the province, with id: ${countryId} in the local collection`,
          );
        }

        return createdProvince;
      }),
    );
  }

  public updateProvince(countryId: number, id: number, command: UpdateProvinceCommand): Observable<Province> {
    return this._updateProvince(countryId, id, command).pipe(
      map((updatedProvince) => {
        const index = this.indexesMap.get(countryId);

        if (index != null) {
          const country = this.collection[index];
          const provinceIndex = country.getProvinceIndex(updatedProvince.id);

          if (provinceIndex != null) {
            const province = country.provinces[provinceIndex];
            ({
              countryId: province.countryId,
              isoCode: province.isoCode,
              name: province.name,
              regionCode: province.regionCode,
              country: province.country,
              createdAt: province.createdAt,
              updatedAt: province.updatedAt,
              deletedAt: province.deletedAt,
            } = updatedProvince);
          } else {
            console.warn(
              `${CountryService.name}: we can't find the province with id: ${updatedProvince.id} in the country with id: ${countryId} to update it`,
            );
          }
        } else {
          console.warn(
            `${CountryService.name}: we can't find the country with id: ${countryId} in the local collection to update its provinces`,
          );
        }

        return updatedProvince;
      }),
    );
  }

  public removeProvince(countryId: number, id: number): Observable<boolean | IApiProblemDetails> {
    return this._removeProvince(countryId, id).pipe(
      map((res) => {
        if (res == null) {
          const countryIndex = this.indexesMap.get(countryId);

          if (countryIndex != null) {
            const country = this.collection[countryIndex];
            const provinceIndex = country.getProvinceIndex(id);

            if (provinceIndex != null) {
              country.provinces.splice(provinceIndex, 1);
              country.removeProvinceIndex(id);
            } else {
              console.warn(
                `${CountryService.name}: we can't find the province with id: ${id} in the country with id: ${countryId} to remove it`,
              );
            }
          } else {
            console.warn(
              `${CountryService.name}: we can't find the country with id: ${countryId} in the local collection to remove one of its provinces`,
            );
          }
        }

        return res;
      }),
    );
  }

  public addUserCountry(countryId: number, userId: number): Observable<boolean> {
    return this._addUserCountry(countryId, userId).pipe(map((value) => value instanceof Country));
  }

  public deleteUserCountry(countryId: number, userId: number): Observable<boolean> {
    return this._deleteUserCountry(countryId, userId).pipe(map((value) => value instanceof Country));
  }

  private _find(queryParams?: FindQueryParams): Observable<Country[]> {
    let url = `${this.url}`;

    if (queryParams) {
      url = `${this.url}?includeProvinces=${!!queryParams.includeProvinces}`;
      /*if (queryParams.countryId) {
        url = url.concat(`&countryId=${queryParams.countryId}`);
      }*/
    }

    return this.http.get<Country[]>(url).pipe(map((value) => value.map((country) => new Country(country))));
  }

  private _findById(id: number): Observable<Country> {
    return this.http.get<Country>(`${this.url}/${id}`).pipe(
      map((value) => {
        return new Country(value);
      }),
    );
  }

  private _create(command: CreateCountryCommand): Observable<Country> {
    return this.http.post<Country>(`${this.url}`, command).pipe(
      map((value) => {
        return new Country(value);
      }),
    );
  }
  private _update(id: number, command: UpdateCountryCommand): Observable<Country> {
    return this.http.put<Country>(`${this.url}/${id}`, command).pipe(
      map((value) => {
        return new Country(value);
      }),
    );
  }
  private _remove(id: number): Observable<null | IApiProblemDetails> {
    return this.http.delete<null | IApiProblemDetails>(`${this.url}/${id}`);
  }
  private _createProvince(countryId: number, command: CreateProvinceCommand): Observable<Province> {
    return this.http.post<Province>(`${this.url}/${countryId}/provinces`, command).pipe(
      map((value) => {
        return new Province(value);
      }),
    );
  }
  private _updateProvince(countryId: number, id: number, command: UpdateProvinceCommand): Observable<Province> {
    return this.http.put<Province>(`${this.url}/${countryId}/provinces/${id}`, command).pipe(
      map((value) => {
        return new Province(value);
      }),
    );
  }
  private _removeProvince(countryId: number, id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}/${countryId}/provinces/${id}`);
  }

  private _addUserCountry(countryId: number, userId: number): Observable<Country | IApiProblemDetails> {
    return this.http.post<Country | IApiProblemDetails>(`${this.url}/${countryId}/permissions/${userId}`, null);
  }

  private _deleteUserCountry(countryId: number, userId: number): Observable<Country | IApiProblemDetails> {
    return this.http.delete<Country | IApiProblemDetails>(`${this.url}/${countryId}/permissions/${userId}`);
  }
}
