<div class="col-12">
  <div class="col-12 justify-content-center surface-50">
    <app-professionals-filters (filters)="onFilters($event)"></app-professionals-filters>
  </div>
  <div class="col-12 px-3 py-0 flex flex-row justify-content-end align-items-end" style="height: 20px">
    <app-professionals-advanced-excel
      class="office-excel-button"
      *ngIf="componentsReady && hasRole('db-view-excel-queries')"
      [filters]="latestApiFilters"
      [itemsCount]="professionalsTable?.totalRecords"
    ></app-professionals-advanced-excel>
    <p-button
      *ngIf="hasRole('db-edit-people')"
      #createButton
      label="New"
      icon="pi pi-plus"
      class="create-button"
      styleClass="p-button-raised p-button-warning mr-2 mb-2"
      (onClick)="createPerson()"
    ></p-button>
  </div>
  <div class="col-12 justify-content-center w-full">
    <p-tabView
      class="professional-tabs"
      *ngIf="filters"
      (activeIndexChange)="setActiveIndex($event)"
      [activeIndex]="activeTabIndex"
    >
      <p-tabPanel header="Professionals">
        <app-professionals-table
          #professionalsTable
          [filters]="filters"
          (apiFiltersBuilt)="setLatestApiFilters($event)"
        ></app-professionals-table>
      </p-tabPanel>
      <!--<p-tabPanel header="All People">
        <div class="col-12 p-0 flex flex-row justify-content-start align-items-start">
          <div class="col-6 p-0">
            <app-professionals-unassigned [filters]="filters" #unassignedTable></app-professionals-unassigned>
          </div>
        </div>
      </p-tabPanel>-->
    </p-tabView>
  </div>
</div>
