import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { CreateSectorCommand, Sector, UpdateSectorCommand } from 'src/app/models';
import { ISearchQuery } from 'src/app/shared/interfaces';
import { ApiService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { SectorsFormComponent } from '../sectors-form/sectors-form.component';

@Component({
  selector: 'app-sectors-table',
  templateUrl: './sectors-table.component.html',
  styleUrls: ['./sectors-table.component.scss'],
})
export class SectorsTableComponent implements OnInit {
  @Input() sectors: Sector[] = [];
  @Output() updateSectors: EventEmitter<Sector[]> = new EventEmitter<Sector[]>();

  public filteredSectors: Sector[] = [];

  public isLoading = true;

  public first = 0;

  public page = 1;

  public pageSize = 10;

  public availablePageSizes = environment.availablePageSizes;

  public sectorsResult?: ISearchQuery<Sector[]>;

  selectedSearchText = '';

  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.refresh();
    this.searchText$.subscribe(() => {
      this.first = 0;
      this.filteredSectors = this.sectors.filter((sector) =>
        sector.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  refresh = () => {
    this.sectors = [];
    this.isLoading = true;
    this.apiService.sectors.find().subscribe((sectors: Sector[]) => {
      this.sectors = sectors;
      this.filteredSectors = [...this.sectors];
      this.updateSectors.emit(this.sectors);
      this.isLoading = false;
    });
  };

  createClicked(): void {
    const ref = this.dialogService.open(SectorsFormComponent, {
      header: $localize`Add new Sector`,
      width: '30%',
      height: 'auto',
    });

    ref.onClose.subscribe((command: CreateSectorCommand) => {
      if (command) {
        this.apiService.sectors.create(command).subscribe({
          next: (sector) => {
            this.addSector(sector);
            this.filteredSectors = [...this.sectors];
            this.messageService.add({
              severity: 'success',
              summary: 'Created',
              detail: $localize`You have added a new sector`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while creating the sector`,
            });
          },
        });
      }
    });
  }

  editClicked($event: MouseEvent, sector: Sector): void {
    $event.preventDefault();
    $event.stopPropagation();

    const ref = this.dialogService.open(SectorsFormComponent, {
      header: $localize`Edit Sector`,
      width: '30%',
      height: 'auto',
      data: {
        sector,
      },
    });

    ref.onClose.subscribe((command: UpdateSectorCommand) => {
      if (command) {
        this.apiService.sectors.update(command.id, command).subscribe({
          next: (sector) => {
            this.updateSector(sector);
            this.filteredSectors = [...this.sectors];
            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: $localize`You have updated the sector`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while updating the sector`,
            });
          },
        });
      }
    });
  }

  confirmDelete($event: MouseEvent, sector: Sector) {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: $localize`Are you sure that you want to delete this sector?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiService.sectors.delete(sector.id).subscribe({
          next: () => {
            this.removeSector(sector);
            this.filteredSectors = [...this.sectors];
            this.messageService.add({
              severity: 'success',
              summary: 'Deleted',
              detail: $localize`You have deleted the sector`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while deleting the sector`,
            });
          },
        });
      },
      reject: () => undefined,
    });
  }

  public addSector(sector: Sector): void {
    this.sectors.push(sector);
    this.updateSectors.emit(this.sectors);
  }

  public updateSector(sector: Sector): void {
    const index = this.sectors.findIndex((sec) => sec.id === sector.id);

    if (index >= 0) {
      this.sectors[index] = sector;
      this.updateSectors.emit(this.sectors);
    }
  }

  public removeSector(sector: Sector) {
    const index = this.sectors.findIndex((sec) => sec.id === sector.id);
    if (index >= 0) {
      this.sectors.splice(index, 1);
      this.updateSectors.emit(this.sectors);
    }
  }

  public next(): void {
    this.first = this.first + this.pageSize;
  }

  public prev(): void {
    this.first = this.first - this.pageSize;
  }

  public reset(): void {
    this.first = 0;
  }

  public isLastPage(): boolean {
    return this.sectorsResult?.count ? this.first === this.sectorsResult.count - this.pageSize : true;
  }

  public isFirstPage(): boolean {
    return this.sectorsResult?.count ? this.first === 0 : true;
  }

  public translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.filteredSectors.length
    } entries`;
  }

  public searchTextChanged(): void {
    this.searchText$.next(this.selectedSearchText);
  }
}
