import { BaseModel } from './base.model';

export class Role extends BaseModel {
  public name: string;

  constructor(data: Role) {
    super(data);

    this.name = data.name;
  }
}

export class RoleCommand {
  public name: string;

  constructor(data: RoleCommand) {
    ({ name: this.name } = data);
  }
}

export class CreateRoleCommand extends RoleCommand {
  constructor(data: CreateRoleCommand) {
    super(data);
  }
}

export class UpdateRoleCommand extends RoleCommand {
  public id: number;

  constructor(data: UpdateRoleCommand) {
    super(data);
    this.id = data.id;
  }
}
