import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Event, EventCommand, UpdateEventCommand } from 'src/app/models/event.model';

@Component({
  selector: 'app-events-form',
  templateUrl: './events-form.component.html',
  styleUrls: ['./events-form.component.scss'],
})
export class EventsFormComponent implements OnInit {
  public mode: 'create' | 'edit' = 'create';

  public event?: Event;
  public addEventEnabled = false;
  public alreadyExists = false;
  public currentName = '';

  public command: EventCommand | UpdateEventCommand = new EventCommand({
    name: '',
  });

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data.mode === 'edit') {
      this.mode = 'edit';
      this.fillData();
    } else {
      this.mode = 'create';
    }
  }

  private fillData() {
    this.event = this.config.data.event;
    if (this.event) {
      this.currentName = this.event.name;
      this.command = new UpdateEventCommand(this.event);
    }
  }

  public createClicked(): void {
    if (this.addEventEnabled) {
      this.ref.close(this.command);
    }
  }

  public cancelClicked(): void {
    this.ref.close();
  }

  public elementChanged(): void {
    this.checkIfAlreadyExists();
    this.checkIfAddEventEnabled();
  }

  public checkIfAlreadyExists(): void {
    if (this.command.name) {
      this.alreadyExists = this.config.data.events.find((event: Event) => event.name === this.command.name.trim());
    }
  }

  public checkIfAddEventEnabled(): void {
    if (this.mode === 'edit') {
      this.addEventEnabled =
        this.command.name !== undefined &&
        this.command.name.trim().length !== 0 &&
        this.command.name.trim() !== this.currentName.trim() &&
        !this.alreadyExists;
    } else {
      this.addEventEnabled =
        this.command.name !== undefined && this.command.name.trim().length !== 0 && !this.alreadyExists;
    }
  }
}
