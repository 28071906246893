<div class="col-12 flex start">
  <div class="col-12 p-2">
    <app-office-summary [office]="office"></app-office-summary>
  </div>
</div>
<div class="col-12 flex start">
  <div class="col-6 p-2" *ngIf="officeMode === 'agency'">
    <span class="p-float-label p-input-icon-right w-full">
      <p-autoComplete
        id="advertiser"
        [(ngModel)]="selectedAdvertiser"
        [suggestions]="filteredAvertisers"
        (completeMethod)="searchAdvertiser($event)"
        [dropdown]="true"
        field="fullName"
        (ngModelChange)="advertiserChanged()"
        [style]="{ width: '100%' }"
        [inputStyle]="{ width: '100%' }"
      ></p-autoComplete>
      <label for="advertiser">Advertiser</label>
    </span>
  </div>
  <div class="col-6 p-2" *ngIf="officeMode !== 'agency'">
    <span class="p-float-label p-input-icon-right w-full">
      <p-autoComplete
        id="agency"
        [(ngModel)]="selectedAgency"
        [suggestions]="filteredAgencies"
        (completeMethod)="searchAgency($event)"
        [dropdown]="true"
        field="fullName"
        (ngModelChange)="agencyChanged()"
        [style]="{ width: '100%' }"
        [inputStyle]="{ width: '100%' }"
      ></p-autoComplete>
      <label for="agency">Agency</label>
    </span>
  </div>
  <div class="col-6 p-2">
    <span class="p-float-label p-input-icon-right w-full">
      <input
        id="campaign"
        type="text"
        pInputText
        class="p-inputtext-sm w-full"
        placeholder="Campaign name"
        [(ngModel)]="command.campaign"
        (ngModelChange)="campaignChanged()"
      />
      <label for="campaign">Campaign</label>
    </span>
  </div>
</div>

<div class="col-12 flex start">
  <div class="col-6 p-2">
    <span class="p-float-label p-input-icon-right w-full">
      <p-calendar
        id="startDate"
        [(ngModel)]="command.startDate"
        dateFormat="dd/mm/yy"
        appendTo="body"
        [style]="{ width: '100%' }"
        [inputStyle]="{ width: '100%' }"
      ></p-calendar>
      <label for="startDate">Start Date</label>
    </span>
  </div>

  <div class="col-6 p-2">
    <span class="p-float-label p-input-icon-right w-full">
      <p-calendar
        id="endDate"
        [(ngModel)]="command.endDate"
        dateFormat="dd/mm/yy"
        appendTo="body"
        [style]="{ width: '100%' }"
        [inputStyle]="{ width: '100%' }"
      ></p-calendar>
      <label for="endDate">End Date</label>
    </span>
  </div>
</div>

<div class="col-12 flex start">
  <div class="col-6 p-2">
    <span class="p-float-label p-input-icon-right w-full">
      <p-autoComplete
        id="brands"
        [(ngModel)]="selectedBrands"
        [suggestions]="filteredBrands"
        (completeMethod)="searchBrand($event)"
        [dropdown]="true"
        [multiple]="true"
        field="name"
        [style]="{ width: '100%' }"
        [inputStyle]="{ width: '100%' }"
        class="autocomplete-full"
        appendTo="body"
        [disabled]="filteredBrands.length === 0"
      ></p-autoComplete>
      <label for="brands">Brands</label>
    </span>
  </div>
  <div class="col-6 p-2">
    <span class="p-float-label p-input-icon-right w-full">
      <p-autoComplete
        id="services"
        [(ngModel)]="selectedServices"
        [suggestions]="filteredAgencyServices"
        (completeMethod)="searchService($event)"
        [dropdown]="true"
        [multiple]="true"
        field="name"
        [style]="{ width: '100%' }"
        [inputStyle]="{ width: '100%' }"
        class="autocomplete-full"
        appendTo="body"
        [disabled]="filteredAgencyServices.length === 0"
      ></p-autoComplete>
      <label for="services">Services</label>
    </span>
  </div>
</div>

<div class="col-12 flex start">
  <div class="col-6 p-2">
    <span class="p-float-label p-input-icon-right">
      <span>Is Active?</span>
      <p-selectButton
        [options]="currentOptions"
        [(ngModel)]="selectedCurrentOption"
        optionLabel="name"
      ></p-selectButton>
    </span>
  </div>
</div>

<div class="col-12">
  <button
    pButton
    *ngIf="mode === 'create'"
    label="Add Service"
    type="button"
    (click)="createClicked()"
    [disabled]="!addCollaborationEnabled"
    class="p-button-raised p-button-warning standard-button-within-form"
    icon="pi pi-plus-circle"
  ></button>

  <button
    pButton
    *ngIf="mode === 'edit'"
    label="Update Service"
    type="button"
    (click)="createClicked()"
    [disabled]="!addCollaborationEnabled"
    class="p-button-raised p-button-warning standard-button-within-form"
    icon="pi pi-save"
  ></button>

  <button
    pButton
    label="Cancel"
    type="button"
    (click)="cancelClicked()"
    class="p-button-raised p-button-warning standard-button-within-form"
    icon="pi pi-times"
  ></button>
</div>
