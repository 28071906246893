import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { CreateMediaTypeCommand, MediaType, UpdateMediaTypeCommand } from '../../../../models';
import { ISearchQuery } from '../../../../shared/interfaces';
import { MediaTypeService } from '../../../../shared/services';
import { MediaTypesFormComponent } from '../media-types-form/media-types-form.component';

@Component({
  selector: 'app-media-types-table',
  templateUrl: './media-types-table.component.html',
  styleUrls: ['./media-types-table.component.scss'],
})
export class MediaTypesTableComponent implements OnInit {
  public mediaTypes: MediaType[] = [];

  public filteredMediaTypes: MediaType[] = [];

  public isLoading = true;

  public first = 0;

  public page = 1;

  public pageSize = 10;

  public availablePageSizes = environment.availablePageSizes;

  public mediaTypesResult?: ISearchQuery<MediaType[]>;

  selectedSearchText = '';

  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private mediaTypeService: MediaTypeService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.refresh();
    this.searchText$.subscribe(() => {
      this.first = 0;
      this.filteredMediaTypes = this.mediaTypes.filter((mediaType) =>
        mediaType.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  refresh(): void {
    this.mediaTypes = [];
    this.isLoading = true;
    this.mediaTypeService.find().subscribe({
      next: (mediaTypes) => {
        this.mediaTypes = mediaTypes;
        this.filteredMediaTypes = [...this.mediaTypes];
        this.isLoading = false;
      },
      error: (error: unknown) => {
        console.error(error);
      },
    });
  }

  public createClicked(): void {
    const ref = this.dialogService.open(MediaTypesFormComponent, {
      header: $localize`Add new media type`,
      width: '30%',
      height: 'auto',
    });

    ref.onClose.subscribe({
      next: (command: CreateMediaTypeCommand) => {
        if (command) {
          this.mediaTypeService.create(command).subscribe({
            next: () => {
              this.mediaTypes = this.mediaTypeService.mediaTypes;
              this.filteredMediaTypes = [...this.mediaTypes];
              this.messageService.add({
                severity: 'success',
                summary: 'Created',
                detail: $localize`You have added a new media type`,
              });
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: $localize`An error occurred while creating the media type`,
              });
            },
          });
        }
      },
      error: (error: unknown) => {
        console.error(error);
      },
    });
  }

  public editClicked($event: MouseEvent, updatedMediaType: MediaType): void {
    $event.preventDefault();
    $event.stopPropagation();

    const ref = this.dialogService.open(MediaTypesFormComponent, {
      header: $localize`Edit media type`,
      width: '30%',
      height: 'auto',
      data: {
        mediaType: updatedMediaType,
      },
    });

    ref.onClose.subscribe((command: UpdateMediaTypeCommand) => {
      if (command) {
        this.mediaTypeService.update(command.id, command).subscribe({
          next: () => {
            this.mediaTypes = this.mediaTypeService.mediaTypes;
            this.filteredMediaTypes = [...this.mediaTypes];
            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: $localize`You have updated the media type`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while updating the media type`,
            });
          },
        });
      }
    });
  }

  public confirmDelete($event: MouseEvent, mediaType: MediaType): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: $localize`Are you sure that you want to delete this media type?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.mediaTypeService.remove(mediaType.id).subscribe({
          next: () => {
            this.mediaTypes = this.mediaTypeService.mediaTypes;
            this.filteredMediaTypes = [...this.mediaTypes];
            this.messageService.add({
              severity: 'success',
              summary: 'Deleted',
              detail: $localize`You have deleted the media type`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while deleting the media type`,
            });
          },
        });
      },
      reject: () => undefined,
    });
  }

  public next(): void {
    this.first = this.first + this.pageSize;
  }

  public prev(): void {
    this.first = this.first - this.pageSize;
  }

  public reset(): void {
    this.first = 0;
  }

  public isLastPage(): boolean {
    return this.mediaTypesResult?.count ? this.first === this.mediaTypesResult.count - this.pageSize : true;
  }

  public isFirstPage(): boolean {
    return this.mediaTypesResult?.count ? this.first === 0 : true;
  }

  public translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.filteredMediaTypes.length
    } entries`;
  }

  public searchTextChanged(): void {
    this.searchText$.next(this.selectedSearchText);
  }
}
