<div class="form-container" *ngIf="office && office.media">
  <div class="col-12 flex start">
    <app-office-summary [office]="office"></app-office-summary>
  </div>

  <div class="col-12 flex start">
    <div class="col-6 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="support"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Support"
          [(ngModel)]="command.name"
          (ngModelChange)="supportChanged()"
        />
        <label for="support">Support</label>
      </span>
    </div>

    <div class="col-6 p-2">
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-dropdown
          id="mediaType"
          placeholder="Media Type"
          [options]="mediaTypes"
          [(ngModel)]="selectedMediaType"
          (ngModelChange)="mediaTypeChanged()"
          optionLabel="name"
          appendTo="body"
          class="w-full"
          [style]="{ width: '100%' }"
        ></p-dropdown>
        <label for="mediaType">Media Type</label>
      </span>
    </div>
  </div>

  <div class="col-12">
    <button
      pButton
      *ngIf="mode === 'create'"
      label="Add Support"
      type="button"
      (click)="createClicked()"
      [disabled]="!addSupportEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-plus-circle"
    ></button>

    <button
      pButton
      *ngIf="mode === 'edit'"
      label="Update Support"
      type="button"
      (click)="createClicked()"
      [disabled]="!addSupportEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
