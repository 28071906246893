import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { CreateFilterCommand, Filter, UpdateFilterCommand } from 'src/app/models';
import { ISearchQuery } from 'src/app/shared/interfaces';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../../../shared/services/api.service';
import { CountryService } from '../../../../shared/services/country.service';
import { BaseComponent } from '../../../general/base/base.component';
import { FiltersFormComponent } from '../filters-form/filters-form.component';

@Component({
  selector: 'app-filters-table',
  templateUrl: './filters-table.component.html',
  styleUrls: ['./filters-table.component.scss'],
})
export class FiltersTableComponent extends BaseComponent implements OnInit {
  public filters: Filter[] = [];

  public filteredFilters: Filter[] = [];

  public isLoading = true;

  public first = 0;

  public page = 1;

  public pageSize = 10;

  public availablePageSizes = environment.availablePageSizes;

  public filtersResult?: ISearchQuery<Filter[]>;

  selectedSearchText = '';

  searchText$: BehaviorSubject<string> = new BehaviorSubject(this.selectedSearchText);

  constructor(
    private apiService: ApiService,
    private readonly countryService: CountryService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.refresh();
    this.searchText$.subscribe(() => {
      this.first = 0;
      this.filteredFilters = this.filters.filter((filter) =>
        filter.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  refresh() {
    this.filters = [];
    this.isLoading = true;
    this.apiService.filters.find(undefined, undefined, this.globalCountryId).subscribe((filters: Filter[]) => {
      this.filters = filters;
      this.filteredFilters = [...this.filters];
      this.isLoading = false;
    });
  }

  createClicked() {
    this.countryService.find({ countryId: this.globalCountryId, includeProvinces: true }).subscribe((countries) => {
      const ref = this.dialogService.open(FiltersFormComponent, {
        header: `Add new Filter`,
        width: '30%',
        height: 'auto',
        data: {
          countries: countries,
        },
      });

      ref.onClose.subscribe((command: CreateFilterCommand) => {
        if (command) {
          this.apiService.filters.create(command).subscribe({
            next: (filter) => {
              this.addFilter(filter);
              this.filteredFilters = [...this.filters];
              this.messageService.add({
                severity: 'success',
                summary: 'Created',
                detail: 'You have added a new filter',
              });
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while creating the filter',
              });
            },
          });
        }
      });
    });
  }

  editClicked($event: MouseEvent, filter: Filter) {
    $event.preventDefault();
    $event.stopPropagation();
    this.countryService.find({ countryId: this.globalCountryId, includeProvinces: true }).subscribe((countries) => {
      const ref = this.dialogService.open(FiltersFormComponent, {
        header: `Edit Filter`,
        width: '30%',
        height: 'auto',
        data: {
          filter: filter,
          countries: countries,
        },
      });

      ref.onClose.subscribe((command: UpdateFilterCommand) => {
        if (command) {
          this.apiService.filters.update(filter.id, command).subscribe({
            next: (filter) => {
              this.updateFilter(filter);
              this.filteredFilters = [...this.filters];
              this.messageService.add({
                severity: 'success',
                summary: 'Updated',
                detail: 'You have updated the filter',
              });
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while updating the filter',
              });
            },
          });
        }
      });
    });
  }

  confirmDelete($event: MouseEvent, filter: Filter) {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: 'Are you sure that you want to delete this filter?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiService.filters.delete(filter.id).subscribe({
          next: () => {
            this.removeFilter(filter);
            this.filteredFilters = [...this.filters];
            this.messageService.add({
              severity: 'success',
              summary: 'Deleted',
              detail: 'You have deleted the filter',
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while deleting the filter',
            });
          },
        });
      },
      reject: () => undefined,
    });
  }

  public addFilter(filter: Filter) {
    this.filters.push(filter);
    this.filters.sort((filterA, filterB) => {
      if (filterA.name > filterB.name) {
        return 1;
      }
      if (filterA.name < filterB.name) {
        return -1;
      }
      return 0;
    });
  }

  public updateFilter(filter: Filter) {
    const index = this.filters.findIndex((f) => f.id === filter.id);
    if (index >= 0) {
      this.filters[index] = filter;
    }
  }

  public removeFilter(filter: Filter) {
    const index = this.filters.findIndex((f) => f.id === filter.id);
    if (index >= 0) {
      this.filters.splice(index, 1);
    }
  }

  translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.filteredFilters.length
    } entries`;
  }

  searchTextChanged() {
    this.searchText$.next(this.selectedSearchText);
  }
}
