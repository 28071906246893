<div class="col-12 flex px-7 flex-column start">
  <div class="col-6 py-0 flex flex-row start">
    <app-network-summary [network]="network"></app-network-summary>
  </div>
  <div class="col-12 flex flex-row align-items-start justify-content-start">
    <div class="px-1">
      <button
        *ngIf="hasRole('db-edit-offices')"
        pButton
        label="Edit"
        type="button"
        (click)="editClicked($event)"
        class="p-button-raised p-button-warning"
        icon="pi pi-pencil"
        style="margin-bottom: 10px"
      ></button>
    </div>
    <app-delete-button [objectName]="'network'" (onDeleteClicked)="deleteClicked($event)"></app-delete-button>
  </div>
  <div class="col-12 py-0 px-0 flex flex-row start">
    <div class="py-0 px-0 w-full" *ngIf="network">
      <app-network-tabs [network]="network"></app-network-tabs>
    </div>
  </div>
</div>
<div *ngIf="!network">
  <div class="flex">
    <div class="flex justify-content-center align-content-center align-items-center w-full h-screen">
      <h4 i18n class="secondary-text" style="font-size: 2rem">
        wait...<i class="pi pi-spin pi-spinner inline-block secondary-text" style="font-size: 2rem"></i>
      </h4>
    </div>
  </div>
</div>

<p-dialog header="Network Deleted" [(visible)]="holdingDeletedConfirmed" [style]="{ width: '25vw' }" [closable]="false">
  <app-network-summary [network]="network"></app-network-summary>
  <div class="flex flex-row justify-content-end">
    <div class="px-1">
      <button
        pButton
        label="Go to Networks & Holdings"
        type="button"
        class="p-button-raised p-button-secondary navigate-to-network-holdings-button"
        (click)="navigateToNetworksHoldings()"
        icon="pi pi-users"
        style="margin-bottom: 10px"
      ></button>
    </div>
  </div>
</p-dialog>
