import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { ISearchQuery } from 'src/app/shared/interfaces';
import { environment } from 'src/environments/environment';
import { CreateProductCommand, Product, UpdateProductCommand } from '../../../../models';
import { ApiService } from '../../../../shared/services/api.service';
import { ProductsFormComponent } from '../products-form/products-form.component';

@Component({
  selector: 'app-products-table',
  templateUrl: './products-table.component.html',
  styleUrls: ['./products-table.component.scss'],
})
export class ProductsTableComponent implements OnInit {
  public products: Product[] = [];

  public filteredProducts: Product[] = [];

  public isLoading = true;

  public first = 0;

  public page = 1;

  public pageSize = 10;

  public availablePageSizes = environment.availablePageSizes;

  public productsResult?: ISearchQuery<Product[]>;

  selectedSearchText = '';

  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.refresh();
    this.searchText$.subscribe(() => {
      this.first = 0;
      this.filteredProducts = this.products.filter((product) =>
        product.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  refresh(): void {
    this.products = [];
    this.isLoading = true;
    this.apiService.products.find().subscribe((products: Product[]) => {
      this.products = products;
      this.filteredProducts = [...this.products];
      this.isLoading = false;
    });
  }

  createClicked() {
    const ref = this.dialogService.open(ProductsFormComponent, {
      header: `Add new Project`,
      width: '30%',
      height: 'auto',
    });

    ref.onClose.subscribe((command: CreateProductCommand) => {
      if (command) {
        this.apiService.products.create(command).subscribe({
          next: (product) => {
            this.addProduct(product);
            this.filteredProducts = [...this.products];
            this.messageService.add({
              severity: 'success',
              summary: 'Created',
              detail: 'You have added a new project',
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while creating the project',
            });
          },
        });
      }
    });
  }

  editClicked($event: MouseEvent, product: Product) {
    $event.preventDefault();
    $event.stopPropagation();

    const ref = this.dialogService.open(ProductsFormComponent, {
      header: `Edit Project`,
      width: '30%',
      height: 'auto',
      data: {
        product: product,
      },
    });

    ref.onClose.subscribe((command: UpdateProductCommand) => {
      if (command) {
        this.apiService.products.update(product.id, command).subscribe({
          next: (product) => {
            this.updateProduct(product);
            this.filteredProducts = [...this.products];
            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: 'You have updated the project',
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while updating the project',
            });
          },
        });
      }
    });
  }

  confirmDelete($event: MouseEvent, product: Product) {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: 'Are you sure that you want to delete this project?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiService.products.delete(product.id).subscribe({
          next: () => {
            this.removeProduct(product);
            this.filteredProducts = [...this.products];
            this.messageService.add({
              severity: 'success',
              summary: 'Deleted',
              detail: 'You have deleted the project',
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while deleting the project',
            });
          },
        });
      },
      reject: () => undefined,
    });
  }

  public addProduct(product: Product) {
    this.products.push(product);
  }

  public updateProduct(product: Product) {
    const index = this.products.findIndex((p) => p.id === product.id);
    if (index >= 0) {
      this.products[index] = product;
    }
  }

  public removeProduct(product: Product) {
    const index = this.products.findIndex((p) => p.id === product.id);
    if (index >= 0) {
      this.products.splice(index, 1);
    }
  }

  next() {
    this.first = this.first + this.pageSize;
  }

  prev() {
    this.first = this.first - this.pageSize;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.productsResult?.count ? this.first === this.productsResult.count - this.pageSize : true;
  }

  isFirstPage(): boolean {
    return this.productsResult?.count ? this.first === 0 : true;
  }

  translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.filteredProducts.length
    } entries`;
  }

  searchTextChanged() {
    this.searchText$.next(this.selectedSearchText);
  }
}
