import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { CreateSubSectorCommand, Sector, SubSector, UpdateSubSectorCommand } from 'src/app/models';
import { ISearchQuery } from 'src/app/shared/interfaces';
import { ApiService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { SubsectorsFormComponent } from '../subsectors-form/subsectors-form.component';

@Component({
  selector: 'app-subsectors-table',
  templateUrl: './subsectors-table.component.html',
  styleUrls: ['./subsectors-table.component.scss'],
})
export class SubsectorsTableComponent implements OnChanges, OnInit {
  @Input() sectors: Sector[] = [];

  public sector?: Sector;

  public subsectors: SubSector[] = [];

  public filteredSubsectors: SubSector[] = [];

  public first = 0;

  public page = 1;

  public pageSize = 10;

  public availablePageSizes = environment.availablePageSizes;

  public subsectorsResult?: ISearchQuery<Sector[]>;

  selectedSearchText = '';

  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.sector &&
      changes['sectors']?.currentValue?.length !== changes['sectors']?.previousValue?.length &&
      !changes['sectors']?.currentValue?.includes(this.sector)
    ) {
      this.sector = undefined;
      this.subsectors = [];
      this.filteredSubsectors = [...this.subsectors];
    } else if (this.sector) {
      const found = this.sectors.find((sector) => sector.id === this.sector?.id);

      if (found) {
        this.sector.name = found.name;
      }
    }
  }

  ngOnInit(): void {
    this.searchText$.subscribe(() => {
      this.first = 0;
      if (this.sector) {
        this.filteredSubsectors = this.sector.subsectors.filter((subsector) =>
          subsector.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
        );
      }
    });
  }

  setSubsectors(): void {
    if (this.sector) {
      this.filteredSubsectors = this.sector.subsectors;
      this.first = 0;
    }
  }

  createClicked(): void {
    const ref = this.dialogService.open(SubsectorsFormComponent, {
      header: $localize`Add new Subsector`,
      width: '30%',
      height: 'auto',
      data: {
        sector: this.sector,
      },
    });

    ref.onClose.subscribe((command: CreateSubSectorCommand) => {
      if (command && this.sector) {
        this.apiService.sectors.createSubsectorBySectorId(this.sector.id, command).subscribe({
          next: (sector) => {
            this.addSubsector(sector);
            this.messageService.add({
              severity: 'success',
              summary: 'Created',
              detail: $localize`You have added a new subsector`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while creating the subsector`,
            });
          },
        });
      }
    });
  }

  editClicked($event: MouseEvent, subsector: SubSector): void {
    $event.preventDefault();
    $event.stopPropagation();

    const ref = this.dialogService.open(SubsectorsFormComponent, {
      header: $localize`Edit Subsector`,
      width: '30%',
      height: 'auto',
      data: {
        subsector,
        sector: this.sector,
      },
    });

    ref.onClose.subscribe((command: UpdateSubSectorCommand) => {
      if (command && this.sector) {
        this.apiService.sectors.updateSubsectorBySectorId(this.sector.id, command.id, command).subscribe({
          next: (subsector) => {
            this.updateSubsector(subsector);
            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: $localize`You have updated the subsector`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while updating the subsector`,
            });
          },
        });
      }
    });
  }

  confirmDelete($event: MouseEvent, subsector: SubSector): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: $localize`Are you sure that you want to delete this subsector?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.sector) {
          this.apiService.sectors.deleteSubsectorBySectorId(this.sector.id, subsector.id).subscribe({
            next: () => {
              this.removeSubsector(subsector);
              this.messageService.add({
                severity: 'success',
                summary: 'Deleted',
                detail: $localize`You have deleted the subsector`,
              });
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: $localize`An error occurred while deleting the subsector`,
              });
            },
          });
        }
      },
      reject: () => undefined,
    });
  }

  public addSubsector(sector: SubSector): void {
    if (this.sector) {
      this.sector.subsectors.push(sector);
      this.filteredSubsectors = [...this.sector.subsectors];
    }
  }

  public updateSubsector(subsector: SubSector): void {
    if (this.sector) {
      const index = this.sector.subsectors.findIndex((sSector) => sSector.id === subsector.id);

      if (index >= 0) {
        this.sector.subsectors[index] = subsector;
      }
      this.filteredSubsectors = [...this.sector.subsectors];
    }
  }

  public removeSubsector(subsector: SubSector): void {
    if (this.sector) {
      const index = this.sector.subsectors.findIndex((sSector) => sSector.id === subsector.id);

      if (index >= 0) {
        this.sector.subsectors.splice(index, 1);
      }
      this.filteredSubsectors = [...this.sector.subsectors];
    }
  }

  public next(): void {
    this.first = this.first + this.pageSize;
  }

  public prev(): void {
    this.first = this.first - this.pageSize;
  }

  public reset(): void {
    this.first = 0;
  }

  public isLastPage(): boolean {
    return this.subsectorsResult?.count ? this.first === this.subsectorsResult.count - this.pageSize : true;
  }

  public isFirstPage(): boolean {
    return this.subsectorsResult?.count ? this.first === 0 : true;
  }

  public translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.sector?.subsectors.length
    } entries`;
  }

  public searchTextChanged(): void {
    this.searchText$.next(this.selectedSearchText);
  }
}
