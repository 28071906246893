import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { IdentityService } from '../services/identity.service';
import { PermissionsOperation, ProfileRoles } from '../types';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  private subscription = new Subscription();

  constructor(private readonly identityService: IdentityService, private readonly router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const roles = route.data['roles'] as ProfileRoles[];
    const operation = route.data['operation'] as PermissionsOperation;
    const user = window.sessionStorage.getItem('user');

    if (!this.identityService.getUser() && user) {
      this.identityService.setUser(JSON.parse(user));
    }

    const canActivate = roles.reduce((result, role, index) => {
      const hasRole = this.identityService.hasRole(role);

      if (operation === 'and' && (index === 0 || result)) {
        result = hasRole;
      } else if (operation === 'or' && hasRole) {
        result = hasRole;
      }

      return result;
    }, false);

    if (!canActivate) {
      this.router.navigate(['/login']);
    }

    return canActivate;
  }
}
