import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Workbook } from 'exceljs';
import capitalize from 'lodash.capitalize';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { Advertiser, Office } from '../../../models';
import { Collaboration } from '../../../models/collaboration.model';
import {
  IAdvertiserCollaborationsToExcel,
  IAgencyCollaborationsToExcel,
  IPrimengSelectButtonOptions,
} from '../../../shared/interfaces';
import { ApiService } from '../../../shared/services';
import { UtilService } from '../../../shared/services/util.service';
import { BaseComponent } from '../../general/base/base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-collaborations-table',
  templateUrl: './collaborations-table.component.html',
  styleUrls: ['./collaborations-table.component.scss'],
})
export class CollaborationsTableComponent extends BaseComponent implements OnInit {
  @Input() office: Office | undefined = undefined;
  @Input() mode: 'agency' | 'advertiser' = 'agency';
  @Output() itemDeleteConfirmed = new EventEmitter<Collaboration>();
  @Output() itemEditClicked = new EventEmitter<Collaboration>();
  @Output() itemSelected = new EventEmitter<{ collaboration: Collaboration; type: 'agency' | 'advertiser' }>();

  public isLoading = true;

  public collaborations: Collaboration[] = [];

  public filteredCollaborations: Collaboration[] = [];

  enabledOptions: IPrimengSelectButtonOptions<'current' | 'all'>[] = [
    { label: 'Current', value: 'current' },
    { label: 'All', value: 'all' },
  ];
  selectedEnabledOptionValue = 'current';
  selectedSearchText = '';
  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private readonly apiService: ApiService,
    private readonly messageService: MessageService,
    private readonly confirmationService: ConfirmationService,
    private readonly utilService: UtilService,
    private readonly router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.searchText$.subscribe(() => {
      this.selectedEnabledOptionValueChanged();
      this.filteredCollaborations = this.filteredCollaborations.filter(
        (c) =>
          c.campaign?.toLowerCase().includes(this.selectedSearchText.toLowerCase()) ||
          (this.mode === 'agency'
            ? c.advertiser?.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase())
            : c.agency?.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase())) ||
          c.getBrandNames().toLowerCase().includes(this.selectedSearchText.toLowerCase()) ||
          c.getServiceNames().toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });

    this.refresh();
  }

  public refresh() {
    this.collaborations = [];
    this.filteredCollaborations = [];

    if (this.office) {
      this.isLoading = true;
      if (this.mode === 'agency') {
        this.apiService.agencies.getCollaborations(this.office.id).subscribe(
          (collaborations: Collaboration[]) => {
            this.setCollaborations(collaborations);
          },
          () => {
            this.showErrorOnGetCollaborations();
          },
        );
      } else {
        this.apiService.advertisers.getCollaborations(this.office.id).subscribe(
          (collaborations: Collaboration[]) => {
            this.setCollaborations(collaborations);
          },
          () => {
            this.showErrorOnGetCollaborations();
          },
        );
      }
    }
  }

  private setCollaborations(collaborations: Collaboration[]) {
    collaborations.forEach((c) => {
      this.collaborations.push(new Collaboration(c));
    });
    this.filteredCollaborations = this.collaborations.filter((c) => c.isActive);
    this.isLoading = false;
  }

  private showErrorOnGetCollaborations() {
    this.isLoading = false;
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'We were unable to load the collaborations. Please try again later.',
    });
  }

  selectedEnabledOptionValueChanged() {
    if (this.selectedEnabledOptionValue == 'current') {
      this.filteredCollaborations = this.collaborations.filter((c) => c.isActive);
    } else {
      this.filteredCollaborations = this.collaborations;
    }
  }

  searchTextChanged() {
    this.searchText$.next(this.selectedSearchText);
  }

  editClicked($event: MouseEvent, collaboration: Collaboration) {
    $event.preventDefault();
    $event.stopPropagation();
    this.itemEditClicked.emit(collaboration);
  }

  confirmDelete($event: MouseEvent, collaboration: Collaboration) {
    $event.preventDefault();
    $event.stopPropagation();
    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: 'Are you sure that you want to delete this service?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.itemDeleteConfirmed.emit(collaboration);
      },
      reject: () => {
        //this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
      },
    });
  }

  public removeCollaboration(collaboration: Collaboration) {
    this.collaborations = this.collaborations.filter((c) => c.id != collaboration.id);
    this.filteredCollaborations = this.filteredCollaborations.filter((c) => c.id != collaboration.id);
  }

  public addCollaboration(collaboration: Collaboration) {
    this.collaborations.push(collaboration);
    this.searchText$.next('');
  }

  async exportAgencyCollaborationsToExcel() {
    const { columns, rows } = this.getCollaborationsForExcel();
    const workbook = new Workbook();
    const workSheet = workbook.addWorksheet(`Client & Services for office ${this.office?.name}`);
    workSheet.columns = columns;
    workSheet.insertRow(1, null);
    workSheet.mergeCells('A1', 'G1');
    const titleRow = workSheet.getCell('A1');
    titleRow.value = 'CRM';
    titleRow.alignment = { horizontal: 'center', vertical: 'middle' };
    workSheet.addRows(rows);
    const headerRow = workSheet.getRow(2);
    headerRow.font = { bold: true };
    headerRow.alignment = { horizontal: 'left', vertical: 'middle' };
    const buffer = (await workbook.xlsx.writeBuffer()) as unknown as Buffer;
    this.utilService.saveAsExcelFile(
      buffer,
      `Clients-and-services-for-office-${this.office?.name.replaceAll(' ', '_')}`,
    );
  }

  async exportAdvertiserCollaborationsToExcel() {
    const { columns, rows } = this.getCollaborationsForExcel<'advertiser'>();
    const workbook = new Workbook();
    const workSheet = workbook.addWorksheet(`Received services for office ${this.office?.name}`);
    workSheet.columns = columns;
    workSheet.insertRow(1, null);
    workSheet.mergeCells('A1', 'H1');
    const titleRow = workSheet.getCell('A1');
    titleRow.value = 'CRM';
    titleRow.alignment = { horizontal: 'center', vertical: 'middle' };
    workSheet.addRows(rows);
    const headerRow = workSheet.getRow(2);
    headerRow.font = { bold: true };
    headerRow.alignment = { horizontal: 'left', vertical: 'middle' };
    const buffer = (await workbook.xlsx.writeBuffer()) as unknown as Buffer;
    this.utilService.saveAsExcelFile(buffer, `Received-services-for-office-${this.office?.name.replaceAll(' ', '_')}`);
  }

  private getCollaborationsForExcel<T extends 'agency' | 'advertiser' = 'agency'>(): {
    columns: { header: string; key: string; width: number }[];
    rows: T extends 'agency' ? IAgencyCollaborationsToExcel[] : IAdvertiserCollaborationsToExcel[];
  } {
    const rows = this.filteredCollaborations.map((collaboration) =>
      this.mode === 'agency'
        ? collaboration.agencyCollaborationForExcel()
        : collaboration.advertiserCollaborationForExcel(),
    );
    const keys = Object.keys(rows[0]);
    const columns = keys.map((key) => ({ header: capitalize(key), key, width: 30 }));
    return { columns, rows };
  }

  goToOfficeDetailsPage(event: Event, collaboration: Collaboration, type: 'agency' | 'advertiser') {
    if (collaboration) {
      this.itemSelected.emit({
        collaboration: collaboration,
        type: type,
      });
    }
  }
}
