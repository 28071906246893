import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Office, UpsertAdvertiserSubSectorsCommand } from '../../../../models';
import { ApiService, ToastService } from '../../../../shared/services';
import { AdvertiserSubsectorsFormComponent } from '../advertiser-subsectors-form/advertiser-subsectors-form.component';
import { SubsectorTreenodeSelector } from './subsector-treenode-selector';
import { BaseComponent } from '../../../general/base/base.component';

@Component({
  selector: 'app-advertiser-subsectors',
  templateUrl: './advertiser-subsectors.component.html',
  styleUrls: ['./advertiser-subsectors.component.scss'],
})
export class AdvertiserSubsectorsComponent extends BaseComponent implements OnInit {
  @Input() public office: Office | undefined = undefined;
  public sectorsTreeNodes: TreeNode[] = [];

  public isLoading = true;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setSectorsTreeNodes();
    this.isLoading = false;
  }

  updateClicked() {
    const ref = this.dialogService.open(AdvertiserSubsectorsFormComponent, {
      header: `Edit Advertiser Sectors`,
      width: '700px',
      height: 'auto',
      data: {
        office: this.office,
      },
    });

    ref.onClose.subscribe((command: UpsertAdvertiserSubSectorsCommand) => {
      if (command && this.office) {
        this.isLoading = true;
        this.apiService.advertisers.updateSubsectors(this.office.id, command).subscribe(
          (advertiser) => {
            if (this.office) {
              this.office.advertiser = advertiser;
              this.setSectorsTreeNodes();
            }
            this.isLoading = false;
            this.toastService.send({
              severity: 'success',
              summary: 'Updated',
              detail: 'You have updated the sectors for this advertiser',
            });
          },
          () => {
            this.isLoading = false;
            this.toastService.send({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error updating the sectors for this advertiser',
            });
          },
        );
      }
    });
  }

  private setSectorsTreeNodes() {
    if (
      this.office &&
      this.office.advertiser &&
      this.office.advertiser.sectors &&
      this.office.advertiser.sectors.length > 0
    ) {
      this.sectorsTreeNodes = SubsectorTreenodeSelector.mapSectorsToTreeNodes(this.office?.advertiser?.sectors);
      if (this.sectorsTreeNodes.length > 0) {
        this.sectorsTreeNodes.forEach((node) => {
          node.expanded = true;
        });
      }
    }
  }
}
