import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Country, CreateWaveCommand, Product, UpdateWaveCommand, Wave } from 'src/app/models';

@Component({
  selector: 'app-waves-form',
  templateUrl: './waves-form.component.html',
  styleUrls: ['./waves-form.component.scss'],
})
export class WavesFormComponent implements OnInit {
  public mode: 'create' | 'edit' = 'create';

  public addWaveEnabled = false;

  public alreadyExist = false;

  public currentName = '';

  public currentYear: number | null = 0;

  public currentCountryId?: number = 0;

  public currentProductId?: number = 0;

  public product?: Product;

  public products: Product[] = [];

  public country?: Country;

  public countries: Country[] = [];

  public command: CreateWaveCommand | UpdateWaveCommand = new CreateWaveCommand({
    year: null,
    countryId: 0,
    productId: 0,
  });

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.countries = this.config.data?.countries;
    this.products = this.config.data?.products;
    if (this.config.data?.wave) {
      this.mode = 'edit';
      this.fillData(this.config.data.wave);
    } else {
      this.mode = 'create';
    }
  }

  private fillData(wave: Wave) {
    this.currentName = wave.name;
    this.currentYear = wave.year;
    this.currentCountryId = wave.country?.id;
    this.currentProductId = wave.product?.id;
    this.country = wave.country;
    this.product = wave.product;
    this.command = new UpdateWaveCommand(wave);
  }

  public createClicked(): void {
    if (this.addWaveEnabled) {
      this.command.productId = this.product?.id;
      this.command.countryId = this.country?.id;
      this.ref.close(this.command);
    }
  }

  public cancelClicked(): void {
    this.ref.close();
  }

  public countryChanged(): void {
    this.checkIfAlreadyExist();
    this.checkIfAddWaveEnabled();
  }

  public productChanged(): void {
    this.checkIfAlreadyExist();
    this.checkIfAddWaveEnabled();
  }

  public yearChanged(): void {
    this.checkIfAlreadyExist();
    this.checkIfAddWaveEnabled();
  }

  public checkIfAlreadyExist(): void {
    if (this.product && this.country && this.command.year) {
      const name = this.product?.name + ' - ' + this.country?.name + ' - ' + this.command.year;
      this.alreadyExist = this.config.data.waves.find(
        (wave: Wave) => wave.name === name && wave.id !== this.config.data?.wave?.id,
      );
    }
  }

  public checkIfAddWaveEnabled(): void {
    if (this.mode === 'edit') {
      this.addWaveEnabled = !!(
        ((this.product?.id && this.product.id !== this.currentProductId) ||
          (this.country?.id && this.country.id !== this.currentCountryId) ||
          (this.command.year && this.command.year !== this.currentYear)) &&
        !this.alreadyExist
      );
    } else {
      this.addWaveEnabled = !!(this.product?.id && this.country?.id && this.command.year && !this.alreadyExist);
    }
  }
}
