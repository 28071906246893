import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { Office, UpdateAgencyTypesCommand } from '../../../../models';
import { ApiService, ToastService } from '../../../../shared/services';
import { AgencyTypesFormComponent } from '../agency-types-form/agency-types-form.component';
import { BaseComponent } from '../../../general/base/base.component';

@Component({
  selector: 'app-agency-types',
  templateUrl: './agency-types.component.html',
  styleUrls: ['./agency-types.component.scss'],
})
export class AgencyTypesComponent extends BaseComponent {
  @Input() public office: Office | undefined = undefined;
  public isLoading = true;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
  ) {
    super();
  }

  updateClicked() {
    const ref = this.dialogService.open(AgencyTypesFormComponent, {
      header: `Edit Agency Types`,
      width: '700px',
      height: 'auto',
      data: {
        office: this.office,
      },
    });

    ref.onClose.subscribe((command: UpdateAgencyTypesCommand) => {
      if (command) {
        this.isLoading = true;
        this.apiService.agencies.associateTypesToAgency(command.agencyId, command).subscribe(
          (agency) => {
            if (this.office) {
              this.office.agency = agency;
            }
            this.isLoading = false;
            this.toastService.send({
              severity: 'success',
              summary: 'Updated',
              detail: 'You have updated the types for this agency',
            });
          },
          () => {
            this.isLoading = false;
            this.toastService.send({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error updating the types for this agency',
            });
          },
        );
      }
    });
  }
}
