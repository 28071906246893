import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { InfoadexRecord, InfoadexRecordCommand, Office } from '../../../../models';
import { ApiService, ToastService } from '../../../../shared/services';
import { AdvertiserInfoadexFormComponent } from '../advertiser-infoadex-form/advertiser-infoadex-form.component';
import { BaseComponent } from '../../../general/base/base.component';

@Component({
  selector: 'app-advertiser-infoadex',
  templateUrl: './advertiser-infoadex.component.html',
  styleUrls: ['./advertiser-infoadex.component.scss'],
})
export class AdvertiserInfoadexComponent extends BaseComponent implements OnInit {
  @Input() public office: Office | undefined = undefined;
  public isLoading = true;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = false;
  }

  createClicked() {
    const ref = this.dialogService.open(AdvertiserInfoadexFormComponent, {
      header: `Add New InfoAdex`,
      width: '700px',
      height: 'auto',
      data: {
        office: this.office,
        mode: 'create',
      },
    });

    ref.onClose.subscribe((command: InfoadexRecordCommand) => {
      if (command) {
        if (this.office) {
          this.apiService.advertisers.createInfoadex(this.office.id, command).subscribe({
            next: (advertiser) => {
              if (this.office) {
                this.office.advertiser = advertiser;
              }

              this.toastService.send({
                severity: 'success',
                summary: 'Created',
                detail: 'You have added a new InfoAdex record for this advertiser',
              });
            },
            error: () => {
              this.toastService.send({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while creating the InfoAdex record',
              });
            },
          });
        }
      }
    });
  }

  editClicked($event: MouseEvent, infoAdex: InfoadexRecord) {
    $event.preventDefault();
    $event.stopPropagation();

    const ref = this.dialogService.open(AdvertiserInfoadexFormComponent, {
      header: `Edit InfoAdex`,
      width: '700px',
      height: 'auto',
      data: {
        office: this.office,
        mode: 'edit',
        infoAdex: infoAdex,
      },
    });

    ref.onClose.subscribe((command: InfoadexRecordCommand) => {
      if (command) {
        if (this.office) {
          this.apiService.advertisers.updateInfoadex(this.office.id, infoAdex.id, command).subscribe({
            next: (advertiser) => {
              if (this.office) {
                this.office.advertiser = advertiser;
              }

              this.toastService.send({
                severity: 'success',
                summary: 'Created',
                detail: 'You have updated the InfoAdex record for this advertiser',
              });
            },
            error: () => {
              this.toastService.send({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while updating the InfoAdex record for this advertiser',
              });
            },
          });
        }
      }
    });
  }

  confirmDelete($event: MouseEvent, infoAdex: InfoadexRecord) {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: 'Are you sure that you want to delete this InfoAdex record?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.office) {
          this.apiService.advertisers.deleteInfoadex(this.office.id, infoAdex.id).subscribe({
            next: () => {
              this.office?.advertiser?.infoAdexRecords.splice(
                this.office?.advertiser?.infoAdexRecords.indexOf(infoAdex),
                1,
              );
              this.toastService.send({
                severity: 'success',
                summary: 'Deleted',
                detail: 'You have deleted the InfoAdex record for this advertiser',
              });
            },
            error: () => {
              this.toastService.send({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while deleting the InfoAdex record for this advertiser',
              });
            },
          });
        }
      },
      reject: () => undefined,
    });
  }
}
