import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Country, CreateTitleCommand, Title, UpdateTitleCommand } from 'src/app/models';

@Component({
  selector: 'app-titles-form',
  templateUrl: './titles-form.component.html',
  styleUrls: ['./titles-form.component.scss'],
})
export class TitlesFormComponent implements OnInit {
  public mode: 'create' | 'edit' = 'create';

  public countries: Country[] = [];

  public countriesForTitle: Country[] = [];

  public currentCountriesForTitle: Country[] = [];

  public title?: Title;

  public addTitleEnabled = false;

  public currentName = '';

  public command: CreateTitleCommand | UpdateTitleCommand = new CreateTitleCommand({
    name: '',
    countryIds: [],
  });

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.countries = this.config.data.countries;
    const countryIdsForTitle = this.config.data.title.countryIds;
    this.title = this.config.data.title;
    for (let i = 0; i < countryIdsForTitle.length; i++) {
      const index = this.countries.findIndex((c) => c.id === countryIdsForTitle[i]);

      if (index >= 0) {
        this.countriesForTitle.push(this.countries[index]);
        this.countries.splice(index, 1);
      }
    }
    this.currentCountriesForTitle = [...this.countriesForTitle];

    if (this.config.data.title) {
      this.mode = 'edit';
      this.fillData(this.config.data.title);
    } else {
      this.mode = 'create';
    }
  }

  private fillData(title: Title) {
    this.currentName = title.name;

    this.command = new UpdateTitleCommand(title);
  }

  public createClicked(): void {
    this.command.countryIds = this.countriesForTitle.map((country) => country.id);

    if (this.addTitleEnabled) {
      this.ref.close(this.command);
    }
  }

  public cancelClicked(): void {
    this.ref.close();
  }

  public nameChanged(): void {
    this.checkIfAddTitleEnabled();
  }

  public checkIfAddTitleEnabled(): void {
    if (this.mode === 'edit') {
      this.addTitleEnabled = !!(
        (this.command.name &&
          this.command.name.trim().length &&
          this.command.name.trim() !== this.currentName.trim()) ||
        !(
          this.countriesForTitle.length === this.currentCountriesForTitle.length &&
          this.countriesForTitle.every((country, index) => {
            return country.id === this.currentCountriesForTitle[index].id;
          })
        )
      );
    } else {
      this.addTitleEnabled = !!(this.command.name && this.command.name.trim().length && this.countriesForTitle.length);
    }
  }
}
