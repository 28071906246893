import { Office, Sector } from '../../../../models';
import { TreeNode } from 'primeng/api';

export class SubsectorTreenodeSelector {
  public office: Office | undefined = undefined;
  public selectedSubSectors: TreeNode[] = [];
  public sectorNodes: TreeNode[] = [];

  constructor(sectorNodes: TreeNode[], office: Office | undefined = undefined) {
    this.sectorNodes = sectorNodes;
    this.office = office;
  }

  public setSelectedSubsectors() {
    if (this.office && this.office.advertiser) {
      this.office.advertiser.sectors.forEach((sector) => {
        const index = this.sectorNodes.findIndex((w: TreeNode) => w.data === sector.id);
        if (index > -1) {
          const targetSector = this.sectorNodes[index];
          if (targetSector && sector.subsectors) {
            sector.subsectors.forEach((subSector) => {
              if (targetSector.children) {
                const subSectorIndex = targetSector.children.findIndex((w: TreeNode) => w.data === subSector.id);
                if (subSectorIndex > -1) {
                  const targetSubSector = targetSector.children[subSectorIndex];
                  this.selectedSubSectors.push(targetSubSector);
                }
              }
            });
          }
        }
      });
    }
  }

  public static mapSectorsToTreeNodes(sectors: Sector[]): TreeNode[] {
    return sectors.map(
      (sector) =>
        ({
          label: sector.name,
          collapsedIcon: 'pi pi-folder',
          expandedIcon: 'pi pi-folder-open',
          data: sector.id,
          children: sector.subsectors.map(
            (subSector) =>
              ({
                label: subSector.name,
                icon: 'pi pi-check-circle',
                data: subSector.id,
                parent: {
                  label: sector.name,
                  collapsedIcon: 'pi pi-folder',
                  expandedIcon: 'pi pi-folder-open',
                  data: sector.id,
                },
              } as TreeNode),
          ),
        } as TreeNode),
    );
  }
}
