import { BaseModel } from './base.model';

export class User extends BaseModel {
  public username: string;

  public email: string;

  public firstName: string;

  public lastName: string;

  public enabled: boolean;

  public emailVerified: boolean;

  public createdTimestamp: string;

  public roles: string[];

  public countryIds: number[];

  constructor(data: User) {
    super(data);

    this.username = data.username;

    this.email = data.email;

    this.firstName = data.firstName;

    this.lastName = data.lastName;

    this.enabled = data.enabled;

    this.emailVerified = data.emailVerified;

    this.createdTimestamp = data.createdTimestamp;

    this.roles = data.roles;

    this.countryIds = data.countryIds;
  }
}

export class UserCommand {
  public username: string;

  public firstName: string;

  public lastName: string;

  public password: string | null;

  public role: string;

  constructor(data: Pick<User, 'username' | 'firstName' | 'lastName'> & { role: string; password: string | null }) {
    ({
      username: this.username,

      firstName: this.firstName,

      lastName: this.lastName,

      password: this.password,

      role: this.role,
    } = data);
  }
}

export class CreateUserCommand extends UserCommand {
  constructor(data: Pick<User, 'username' | 'firstName' | 'lastName'> & { role: string; password: string }) {
    super(data);
  }
}

export class UpdateUserCommand extends UserCommand {
  public id: number;

  constructor(
    data: Pick<User, 'id' | 'username' | 'firstName' | 'lastName'> & { role: string; password: string | null },
  ) {
    super(data);
    this.id = data.id;
  }
}
