import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Office } from '../../../models';
import { Collaboration, CollaborationCommand } from '../../../models/collaboration.model';
import { ApiService } from '../../../shared/services';
import { AddCollaborationFormComponent } from '../add-collaboration-form/add-collaboration-form.component';
import { CollaborationsTableComponent } from '../collaborations-table/collaborations-table.component';
import { BaseComponent } from '../../general/base/base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-collaborations-section',
  templateUrl: './collaborations-section.component.html',
  styleUrls: ['./collaborations-section.component.scss'],
})
export class CollaborationsSectionComponent extends BaseComponent implements OnInit {
  @ViewChild(CollaborationsTableComponent) table!: CollaborationsTableComponent;
  @Input() public mode: 'agency' | 'advertiser' = 'agency';

  // public agency: Agency = new Agency({ id: 0, name: '', services: [], types: [] });
  public isLoaded = false;

  @Input() public office: Office | undefined = undefined;

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public router: Router,
  ) {
    super();
  }

  ngOnInit() {
    if (this.mode === 'agency') {
      if (this.office && this.office.agency) {
        this.isLoaded = true;
      }
    } else {
      if (this.office && this.office.advertiser) {
        this.isLoaded = true;
      }
    }
  }

  createClicked() {
    if (this.checkIfAddOrEditEnabled()) {
      const headerText =
        this.mode === 'agency'
          ? `Create New Service for agency ${this.office?.agency?.name}`
          : `Create New Service for advertiser ${this.office?.advertiser?.name}`;

      const ref = this.dialogService.open(AddCollaborationFormComponent, {
        header: headerText,
        width: '50%',
        data: {
          office: this.office,
          mode: this.mode,
        },
      });

      ref.onClose.subscribe((command: CollaborationCommand) => {
        if (command) {
          this.apiService.collaborations.create(command, this.mode).subscribe(() => {
            this.table.refresh();
            this.messageService.add({
              severity: 'success',
              summary: 'Created',
              detail: 'You have created the service',
            });
          });
        }
      });
    }
  }

  private checkIfAddOrEditEnabled(): boolean {
    return !!(this.office && (this.mode === 'agency' ? this.office.agency : this.office.advertiser));
  }

  updateClicked($event: Collaboration) {
    if (this.checkIfAddOrEditEnabled()) {
      const headerText =
        this.mode === 'agency'
          ? `Edit Service for agency ${this.office?.agency?.name}`
          : `Edit Service for advertiser ${this.office?.advertiser?.name}`;

      const ref = this.dialogService.open(AddCollaborationFormComponent, {
        header: headerText,
        width: '50%',
        data: {
          office: this.office,
          collaboration: $event,
          mode: this.mode,
        },
      });

      ref.onClose.subscribe((command: CollaborationCommand) => {
        if (command) {
          this.apiService.collaborations.update($event.id, command, this.mode).subscribe(() => {
            this.table.refresh();
            this.messageService.add({
              severity: 'success',
              summary: 'Created',
              detail: 'You have created the service',
            });
          });
        }
      });
    }
  }

  delete($event: Collaboration) {
    this.apiService.collaborations.delete($event.id).subscribe(
      () => {
        this.table.removeCollaboration($event);
        this.messageService.add({ severity: 'success', summary: 'Deleted', detail: 'You have deleted the service' });
      },
      () => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
      },
    );
  }

  navigate($event: { collaboration: Collaboration; type: 'agency' | 'advertiser' }) {
    console.log($event);
    if ($event) {
      if ($event.type === 'advertiser' && $event.collaboration.advertiser) {
        this.router.navigate(['companies', 'office', $event.collaboration.advertiser.id]);
      }
      if ($event.type === 'agency' && $event.collaboration.agency) {
        this.router.navigate(['companies', 'office', $event.collaboration.agency.id]);
      }
    }
  }
}
