<div *ngIf="company" class="col-12 p-2 py-4">
  <div class="justify-content-start w-12">
    <div class="flex flex-column">
      <h3 class="company-name title">{{ company.name }}</h3>
      <div *ngIf="!company.isEnabled" class="flex flex-row align-content-start py-2">
        <i class="pi pi-exclamation-circle"></i>
        <span class="py-2 disabled-text">This company record is disabled</span>
      </div>
      <div class="flex company-static-name">COMPANY</div>
      <div>
        <span *ngIf="company.list === 'RESEARCH'"
          ><strong>RESEARCH ID: {{ company.researchId }}</strong></span
        >
      </div>
      <div>
        <span><strong>Last Review: </strong></span>
        {{ company.lastReview | date: 'dd/MM/yyyy' }}
      </div>
    </div>
  </div>
</div>
