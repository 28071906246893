<div class="form-container pt-3">
  <div>
    <span
      style="
        display: block;
        font-family: Mark;
        font-size: 1.7rem;
        color: black;
        padding-left: 0px;
        padding-top: 4px;
        padding-bottom: 2px;
      "
      >{{ user?.username }}</span
    >
    <span
      style="
        display: block;
        font-family: Mark;
        font-size: 1rem;
        color: rgb(64, 61, 61);
        padding-left: 0px;
        padding-top: 2px;
        padding-bottom: 2px;
      "
      >{{ user?.firstName + ', ' + user?.lastName }}</span
    >
    <span
      style="
        display: block;
        font-family: Mark;
        font-size: 1rem;
        color: rgb(64, 61, 61);
        padding-left: 0px;
        padding-top: 2px;
        padding-bottom: 2px;
      "
      >{{ user?.roles?.join(', ') }}</span
    >
  </div>
  <p-pickList
    class="p-4"
    sourceHeader="Countries"
    targetHeader="Countries for the user"
    [source]="countries"
    [target]="countriesForUser"
    [responsive]="true"
    [showSourceControls]="false"
    [showTargetControls]="false"
    (onMoveToTarget)="checkIfSaveButtonDisable()"
    (onMoveToSource)="checkIfSaveButtonDisable()"
    (onMoveAllToTarget)="checkIfSaveButtonDisable()"
    (onMoveAllToSource)="checkIfSaveButtonDisable()"
    [sourceStyle]="{ height: '250px' }"
    [targetStyle]="{ height: '250px' }"
  >
    <ng-template let-country pTemplate="item">
      <div style="float: left">
        <img src="{{ country.flagUrl }}" style="display: inline-block" width="30" />
        <div style="font-size: 14px; float: right; padding-top: 7px; margin-left: 10px">
          {{ country.name }}
        </div>
      </div>
    </ng-template>
  </p-pickList>
  <div class="col-12">
    <button
      pButton
      label="Save"
      type="button"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
      [disabled]="saveButtonDisable"
      (click)="saveClicked()"
    ></button>
    <button
      pButton
      label="Cancel"
      type="button"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
      (click)="cancelClicked()"
    ></button>
  </div>
</div>
