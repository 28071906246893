<div class="form-container" *ngIf="person">
  <div class="col-12 flex start">
    <app-person-summary [person]="person"></app-person-summary>
  </div>

  <div class="col-12 flex start">
    <div class="col-12 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <p-autoComplete
          id="office-name"
          [(ngModel)]="selectedOffice"
          [suggestions]="offices"
          (completeMethod)="searchOffice($event)"
          [dropdown]="true"
          field="fullName"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          (ngModelChange)="officeChanged()"
        >
        </p-autoComplete>
        <label for="office-name">Office</label>
      </span>
    </div>
  </div>

  <div class="col-12 flex start">
    <div class="col-9 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="position"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="position within the office"
          [(ngModel)]="command.positionName"
          (ngModelChange)="positionChanged()"
        />
        <label for="position">Position</label>
      </span>
    </div>

    <div class="col-3 p-2">
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-selectButton [options]="roles" [(ngModel)]="selectedRole" optionLabel="name"></p-selectButton>
      </span>
    </div>
  </div>

  <div class="col-12 flex start">
    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="email"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="position within the office"
          [(ngModel)]="command.email"
          (ngModelChange)="emailChanged()"
          email
        />
        <label for="email">Email</label>
      </span>
    </div>

    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="phone"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="direct phone"
          [(ngModel)]="command.phone"
        />
        <label for="phone">Direct Phone</label>
      </span>
    </div>
    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="mobilePhone"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="mobile phone"
          [(ngModel)]="command.mobilePhone"
        />
        <label for="email">Mobile Phone</label>
      </span>
    </div>
  </div>

  <div class="col-12 flex start">
    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <p-calendar
          id="startDate"
          [(ngModel)]="command.startDate"
          dateFormat="dd/mm/yy"
          appendTo="body"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
        ></p-calendar>
        <label for="startDate">Date of incorporation</label>
      </span>
    </div>
    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <p-calendar
          id="endDate"
          [(ngModel)]="command.endDate"
          dateFormat="dd/mm/yy"
          appendTo="body"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
        ></p-calendar>
        <label for="startDate">Date of finalization</label>
      </span>
    </div>
    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-selectButton
          [options]="currentOptions"
          [(ngModel)]="selectedCurrentOption"
          optionLabel="name"
        ></p-selectButton>
      </span>
    </div>
  </div>

  <div class="col-12">
    <button
      pButton
      *ngIf="mode === 'create'"
      label="Save"
      type="button"
      (click)="createClicked()"
      [disabled]="!addOfficeEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-plus-circle"
    ></button>

    <button
      pButton
      *ngIf="mode === 'edit'"
      label="Save"
      type="button"
      (click)="createClicked()"
      [disabled]="!addOfficeEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
