<div *ngIf="network" class="col-12 p-2 py-4">
  <div class="justify-content-start w-12">
    <div class="flex flex-column">
      <h3 class="network-name title">{{ network.name }}</h3>
      <div *ngIf="!network.isEnabled" class="flex flex-row align-content-start py-2">
        <i class="pi pi-exclamation-circle"></i>
        <span class="py-2 disabled-text">This network record is disabled</span>
      </div>
      <div class="flex holding-static-name">NETWORK</div>
      <div class="flex holding-static-name" *ngIf="network.holding">
        within HOLDING
        <span class="pl-2"
          ><strong>{{ network.holding.name }}</strong></span
        >
      </div>
      <div>
        <span><strong>Last Review: </strong></span>
        {{ network.lastReview | date: 'dd/MM/yyyy' }}
      </div>
    </div>
  </div>
</div>
