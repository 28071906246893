<div class="grid grid-nogutter">
  <div class="col-12">
    <h5 i18n class="mt-2 mb-3">Search</h5>
  </div>
  <div class="col-6 p-2">
    <span class="p-float-label p-input-icon-right w-full">
      <i *ngIf="isLoading" class="pi pi-spin pi-spinner"></i>
      <input
        id="filter-name"
        type="text"
        pInputText
        class="p-inputtext-sm w-full"
        i18n-placeholder
        placeholder="write the name that you want to find or filter"
        [ngModel]="name"
        (ngModelChange)="onNameChanges($event)"
      />
      <label i18n for="filter-name">Name</label>
    </span>
    <div class="flex justify-content-around">
      <span class="p-float-label w-6 mt-4">
        <p-multiSelect
          id="filter-type"
          [options]="typeNodes"
          [ngModel]="selectedTypes"
          (ngModelChange)="onTypesChanges($event)"
          selectionMode="checkbox"
          display="chip"
          styleClass="w-full max-h-full"
        >
          <ng-template let-type pTemplate="item">
            <div class="flex">
              <i class="mr-2" [ngClass]="type.icon"></i>
              <div>{{ type.label }}</div>
            </div>
          </ng-template>
        </p-multiSelect>
        <label i18n for="filter-type" [ngClass]="{ 'types-label': selectedTypes.length === 0 }">Type</label>
      </span>
      <div class="p-2 pt-0 w-6 mt-4 flex flex-row justify-content-start">
        <p i18n class="ml-2">&nbsp;</p>
        <p-selectButton
          [options]="officeTypesOperationStateOptions"
          [ngModel]="selectedOfficeTypesOperationOption"
          (onOptionClick)="onOfficeTypesOperationChange($event)"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>
      </div>
    </div>
    <span class="p-float-label w-6">
      <p-multiSelect
        *ngIf="selectedTypes.length && isAgency()"
        [options]="selectedTypes"
        [group]="true"
        [ngModel]="selectedSubTypes"
        (ngModelChange)="onSubTypesChanges($event)"
        display="chip"
        styleClass="w-full"
        optionGroupChildren="children"
        i18n-placeholder
        placeholder="Select a sub-type from the list"
        id="filter-subtype"
      >
        <ng-template let-typeGroup pTemplate="group">
          <div class="flex">
            <i [ngClass]="typeGroup.icon" class="mr-2"></i>
            <div>{{ typeGroup.label }}</div>
          </div>
        </ng-template>
        <ng-template let-subType pTemplate="item">
          <div class="flex">
            <i class="mr-2" [ngClass]="subType.icon"></i>
            <div>{{ subType.label }}</div>
          </div>
        </ng-template>
      </p-multiSelect>
      <label *ngIf="selectedTypes.length && isAgency()" i18n for="filter-subtype">Sub-types</label>
    </span>
  </div>
  <div class="col-6 p-2">
    <div class="flex justify-content-start">
      <span class="p-float-label mr-2 w-5">
        <p-multiSelect
          [disabled]="!!globalCountryId"
          [options]="countryNodes"
          [ngModel]="selectedCountries"
          (ngModelChange)="onCountryChanges($event)"
          display="chip"
          styleClass="w-full"
        >
          <ng-template pTemplate="selectedItems">
            <div class="flex p-multiselect-token" *ngFor="let option of selectedCountries; index as i">
              <img [src]="option.icon" class="flag-selected mr-2" />
              <span class="p-multiselect-token-label">{{ option.label }}</span>
              <span
                class="p-multiselect-token-icon pi pi-times-circle ng-star-inserted"
                (click)="removeCountryItem(i)"
              ></span>
            </div>
            <div
              *ngIf="!selectedCountries || selectedCountries.length === 0"
              i18n
              [ngClass]="{ 'country-placeholder': displayPlaceHolder }"
            >
              Select a country
            </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
            <div class="flex">
              <img [src]="country.icon" class="flag mr-2" />
              <div>{{ country.label }}</div>
            </div>
          </ng-template>
        </p-multiSelect>
        <label i18n for="filter-location">Countries</label>
      </span>
      <span class="p-float-label mr-2 w-5">
        <p-multiSelect
          *ngIf="selectedCountries.length"
          [options]="selectedCountries"
          [group]="true"
          [ngModel]="selectedProvinces"
          (ngModelChange)="onProvinceChanges($event)"
          display="chip"
          styleClass="w-full"
          optionGroupChildren="children"
          id="filter-province"
        >
          <ng-template let-group pTemplate="group">
            <div class="flex">
              <img [src]="group.icon" class="flag mr-2" />
              <div>{{ group.label }}</div>
            </div>
          </ng-template>
          <ng-template let-province pTemplate="item">
            <div class="flex">
              <i class="flag mr-2" [ngClass]="province.icon"></i>
              <div>{{ province.label }}</div>
            </div>
          </ng-template>
        </p-multiSelect>
        <label *ngIf="selectedCountries.length" i18n for="filter-province">Provinces</label>
      </span>
    </div>
    <div class="col-10 pl-0 flex flex-row justify-content-start mt-2">
      <div class="p-1 pt-0 flex flex-column justify-content-start">
        <p i18n class="m-0 mb-1 mr-2" style="color: #3f3f46">Enabled</p>
        <p-selectButton
          [options]="officeStatusStateOptions"
          [ngModel]="selectedOfficeStatusOption"
          (onOptionClick)="onOfficeStatusOptionChange($event)"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>
      </div>
      <!--<div class="p-1 pt-0 flex flex-column justify-content-start">
        <p i18n class="m-0 mb-1 mr-2" style="color: #3f3f46">Headquarters</p>
        <p-selectButton
          [options]="officeHeadQuarterStateOptions"
          [ngModel]="selectedHeadQuarterOption"
          (onOptionClick)="onHeadQuarterOptionChange($event)"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>
      </div>-->
    </div>
  </div>
  <div *ngIf="isAdvertiserSelected" class="col-12">
    <p-divider align="left" type="dashed">
      <b>Advertiser filters</b>
    </p-divider>
  </div>
  <div *ngIf="isAdvertiserSelected" class="col-6 p-2">
    <span class="p-float-label w-full">
      <p-treeSelect
        id="filter-sector"
        [ngModel]="selectedSectors"
        (ngModelChange)="onSectorsChanges($event)"
        [options]="sectorNodes"
        [metaKeySelection]="false"
        selectionMode="checkbox"
        display="chip"
        i18n-placeholder
        placeholder="Select a sector"
        [filter]="true"
        [filterInputAutoFocus]="true"
        containerStyleClass="w-full"
      ></p-treeSelect>
      <label i18n for="filter-sector">Sector</label>
    </span>
  </div>
  <div *ngIf="isAdvertiserSelected" class="col-6 p-2">
    <p-autoComplete
      [ngModel]="selectedBrands"
      (ngModelChange)="onFilterBrandsChange($event)"
      [suggestions]="filteredBrands"
      (completeMethod)="filterBrand($event)"
      field="name"
      [dropdown]="true"
      [multiple]="true"
      i18n-placeholder
      placeholder="Start writing or select a brand from the list"
      [lazy]="true"
      styleClass="w-full"
    >
    </p-autoComplete>
  </div>
  <div class="col-6 p-2 pt-0">
    <span class="p-float-label p-input-icon-right w-full">
      <i *ngIf="isLoading" class="pi pi-spin pi-spinner"></i>
      <input
        id="observations"
        type="text"
        pInputText
        class="p-inputtext-sm w-full"
        placeholder="observations"
        [ngModel]="observations"
        (ngModelChange)="onObservationsChange($event)"
      />
      <label i18n for="observations">Observations</label>
    </span>
  </div>
  <div class="col-6 p-2 pt-0"></div>
  <div class="col-2 p-2 pt-4">
    <app-autocomplete-virtualscroll-search-filters
      displayLabel="Company"
      itemsName="Company"
      placeholderLabel="Select a company from the dropdown or write its name"
      [selectedCountries]="this.selectedCountries"
      [searchFunction]="companiesSearchFunction"
      [countryFilterFunction]="countryFilterFunction"
      (selectedItemChanged)="onCompanyItemChange($event)"
    >
    </app-autocomplete-virtualscroll-search-filters>
  </div>
  <div class="col-2 p-2 pt-4">
    <app-autocomplete-virtualscroll-search-filters
      displayLabel="Network"
      itemsName="Network"
      placeholderLabel="Select a network from the dropdown or write its name"
      [selectedCountries]="this.selectedCountries"
      [searchFunction]="networksSearchFunction"
      (selectedItemChanged)="onNetworkItemChange($event)"
    >
    </app-autocomplete-virtualscroll-search-filters>
  </div>
  <div class="col-2 p-2 pt-4">
    <app-autocomplete-virtualscroll-search-filters
      displayLabel="Holding"
      itemsName="Holding"
      placeholderLabel="Select a holding from the dropdown or write its name"
      [selectedCountries]="this.selectedCountries"
      [searchFunction]="holdingsSearchFunction"
      (selectedItemChanged)="onHoldingItemChange($event)"
    >
    </app-autocomplete-virtualscroll-search-filters>
  </div>
</div>
