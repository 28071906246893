import { BaseModel } from './base.model';
import { Media } from './media.model';

export class Exclusive extends BaseModel {
  public name: string;
  public fullName: string;
  public media: Media[] = [];

  constructor(data: Exclusive) {
    super(data);
    ({ name: this.name, fullName: this.fullName } = data);
    if (data.media && data.media.length) {
      this.media = data.media.map((m) => new Media(m));
    }
  }
}
