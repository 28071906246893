import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../general/base/base.component';
import { Network } from '../../../models';
import { IProfessionalsFilter } from '../../../shared/interfaces';
import { ProfessionalsTableComponent } from '../../professionals/professionals-table/professionals-table.component';

@Component({
  selector: 'app-network-tabs',
  templateUrl: './network-tabs.component.html',
  styleUrls: ['./network-tabs.component.scss'],
})
export class NetworkTabsComponent extends BaseComponent implements OnInit {
  @Input() network: Network | undefined = undefined;
  public filters: IProfessionalsFilter | undefined;
  @ViewChild(ProfessionalsTableComponent) professionalsTable?: ProfessionalsTableComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.filters = {
      networkId: this.network?.id,
    };
    if (this.globalCountryId) {
      this.filters.locations = [{ countryId: this.globalCountryId }];
    }
  }
}
