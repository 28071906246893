import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateUserCommand, Role, UpdateUserCommand, User } from 'src/app/models';

export const Roles = {
  admin: 'DB-Admin',
  editor: 'DB-Editor',
  viewer: 'DB-Viewer',
} as const;

@Component({
  selector: 'app-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss'],
})
export class UsersFormComponent implements OnInit {
  public role?: Role;

  public roles: Role[] = [];

  public mode: 'create' | 'edit' = 'create';

  public addUserEnabled = false;

  public currentUsername = '';

  public currentFirstName = '';

  public currentLastName = '';

  public currentPassword = '';

  public currentRole? = {};

  public command: CreateUserCommand | UpdateUserCommand = new CreateUserCommand({
    username: '',
    firstName: '',
    lastName: '',
    password: '',
    role: '',
  });

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.roles = this.config.data.roles;
    if (this.config.data.user) {
      this.mode = 'edit';
      this.fillData(this.config.data.user);
    } else {
      this.mode = 'create';
    }
  }

  private fillData(user: User) {
    this.currentUsername = user.username;
    this.currentFirstName = user.firstName;
    this.currentLastName = user.lastName;
    this.currentRole = user.roles[0];

    const userToUpdate = {
      id: user.id,
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.roles[0],
      password: null,
    };

    if (userToUpdate.role === Roles.admin) {
      this.role = this.roles[0];
      this.currentRole = this.roles[0];
    } else if (userToUpdate.role === Roles.editor) {
      this.role = this.roles[1];
      this.currentRole = this.roles[1];
    } else {
      this.role = this.roles[2];
      this.currentRole = this.roles[2];
    }

    this.command = new UpdateUserCommand(userToUpdate);
  }

  public createClicked(): void {
    if (this.addUserEnabled) {
      this.ref.close(this.command);
    }
  }

  public cancelClicked(): void {
    this.ref.close();
  }

  public usernameChanged(): void {
    this.checkIfAddUserEnabled();
  }

  public firstNameChanged(): void {
    this.checkIfAddUserEnabled();
  }

  public lastNameChanged(): void {
    this.checkIfAddUserEnabled();
  }

  public passwordChanged(): void {
    this.checkIfAddUserEnabled();
  }

  public roleChanged(): void {
    if (this.role?.name === 'Admin') {
      this.command.role = Roles.admin;
    } else if (this.role?.name == 'Editor') {
      this.command.role = Roles.editor;
    } else {
      this.command.role = Roles.viewer;
    }
    this.checkIfAddUserEnabled();
  }

  public checkIfAddUserEnabled(): void {
    if (this.mode === 'edit') {
      this.addUserEnabled = !!(
        (this.command.firstName &&
          this.command.firstName.trim().length &&
          this.command.firstName.trim() !== this.currentFirstName.trim()) ||
        (this.command.lastName &&
          this.command.lastName.trim().length &&
          this.command.lastName.trim() !== this.currentLastName.trim()) ||
        (this.command.password &&
          this.command.password.trim().length &&
          this.command.password.trim() !== this.currentPassword.trim()) ||
        this.role !== this.currentRole
      );
    } else {
      this.addUserEnabled = !!(
        this.command.username &&
        this.command.username.trim().length &&
        this.command.firstName &&
        this.command.firstName.trim().length &&
        this.command.lastName &&
        this.command.lastName.trim().length &&
        this.command.password &&
        this.command.password.trim().length &&
        this.role
      );
    }
  }
}
