import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Workbook } from 'exceljs';
import capitalize from 'lodash.capitalize';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Person } from '../../../models';
import { CreateProfessionalCommand, Professional, UpdateProfessionalCommand } from '../../../models/professional.model';
import { IProfessionalsToExcel } from '../../../shared/interfaces';
import { ApiService } from '../../../shared/services';
import { UtilService } from '../../../shared/services/util.service';
import { BaseComponent } from '../../general/base/base.component';
import { PersonProfessionalsFormComponent } from '../person-professionals-form/person-professionals-form.component';
import { CountryService } from '../../../shared/services/country.service';

@Component({
  selector: 'app-person-professionals',
  templateUrl: './person-professionals.component.html',
  styleUrls: ['./person-professionals.component.scss'],
})
export class PersonProfessionalsComponent extends BaseComponent implements OnInit {
  @Input() public person: Person | undefined = undefined;
  @Output() public professionalsLoaded: EventEmitter<Professional[]> = new EventEmitter<Professional[]>();
  @Output() public professionalsChanged: EventEmitter<Date> = new EventEmitter<Date>();

  public professionals: Professional[] = [];
  public isLoading = true;

  constructor(
    private readonly apiService: ApiService,
    private readonly router: Router,
    private readonly dialogService: DialogService,
    private readonly messageService: MessageService,
    private readonly confirmationService: ConfirmationService,
    private readonly utilService: UtilService,
    private readonly countryService: CountryService,
  ) {
    super();
  }

  ngOnInit() {
    this.refresh();
  }

  public refresh() {
    this.professionals = [];
    this.isLoading = true;
    if (this.person) {
      this.apiService.people.getProfessionals(this.person.id).subscribe((professionals: Professional[]) => {
        this.professionals = professionals;
        this.setProfessionalFullCountry();
        this.professionalsLoaded.emit(this.professionals);
        this.isLoading = false;
      });
    }
  }

  private setProfessionalFullCountry() {
    this.professionals.forEach((professional) => {
      if (professional.office) {
        if (professional.office?.countryId) {
          professional.office.country = this.countryService.getCountryFromCollection(professional.office.countryId);
        }
        if (professional.office?.address?.country) {
          professional.office.address.country = this.countryService.getCountryFromCollection(
            professional.office.address.country.id,
          );
        }
      }
    });
  }

  editClicked($event: MouseEvent, professional: Professional) {
    $event.preventDefault();
    $event.stopPropagation();

    const ref = this.dialogService.open(PersonProfessionalsFormComponent, {
      header: `Add new office`,
      width: '50%',
      height: 'auto',
      data: {
        person: this.person,
        professional: professional,
      },
    });

    ref.onClose.subscribe((command: UpdateProfessionalCommand) => {
      if (command) {
        this.apiService.professionals.update(professional.id, command).subscribe(
          (professional) => {
            this.refresh();
            this.professionalsChanged.emit(new Date());
            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: 'You have updated the office for this person',
            });
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while updating the office',
            });
          },
        );
      }
    });
  }

  confirmDelete($event: MouseEvent, professional: Professional) {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: 'Are you sure that you want to delete this office?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiService.professionals.delete(professional.id).subscribe(
          () => {
            this.refresh();
            this.professionalsChanged.emit(new Date());
            this.messageService.add({
              severity: 'success',
              summary: 'Deleted',
              detail: 'You have deleted the office for this person',
            });
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while deleting the office',
            });
          },
        );
      },
      reject: () => undefined,
    });
  }

  navigateToOffice(professional: Professional) {
    this.router.navigate(['companies', 'office', professional?.office?.id]);
  }

  createClicked() {
    const ref = this.dialogService.open(PersonProfessionalsFormComponent, {
      header: `Add new office`,
      width: '50%',
      height: 'auto',
      data: {
        person: this.person,
      },
    });

    ref.onClose.subscribe((command: CreateProfessionalCommand) => {
      if (command) {
        this.apiService.professionals.create(command).subscribe(
          () => {
            this.refresh();
            this.professionalsChanged.emit(new Date());
            this.messageService.add({
              severity: 'success',
              summary: 'Created',
              detail: 'You have added a new office for this person',
            });
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while creating the office',
            });
          },
        );
      }
    });
  }

  async exportExcel() {
    const { columns, rows } = this.getProfessionalsForExcel();
    const workbook = new Workbook();
    const workSheet = workbook.addWorksheet(`Offices for ${this.person?.fullNameWithTitle}`);
    workSheet.columns = columns;
    workSheet.insertRow(1, null);
    workSheet.mergeCells('A1', 'M1');
    const titleRow = workSheet.getCell('A1');
    titleRow.value = 'CRM';
    titleRow.alignment = { horizontal: 'center', vertical: 'middle' };
    workSheet.addRows(rows);
    const headerRow = workSheet.getRow(2);
    headerRow.font = { bold: true };
    headerRow.alignment = { horizontal: 'left', vertical: 'middle' };
    const buffer = (await workbook.xlsx.writeBuffer()) as unknown as Buffer;
    this.utilService.saveAsExcelFile(buffer, `Offices-for-${this.person?.fullNameWithTitle.replaceAll(' ', '_')}`);
  }

  private getProfessionalsForExcel(): {
    columns: { header: string; key: string; width: number }[];
    rows: IProfessionalsToExcel[];
  } {
    const rows = this.professionals.map((professional) => professional.professionalForExcel());
    const keys = Object.keys(rows[0]);
    const columns = keys.map((key) => ({ header: capitalize(key), key, width: 30 }));
    return { columns, rows };
  }

  copyLinkToClipboard($event: any, data: string) {
    $event.preventDefault();
    $event.stopPropagation();
    if (data) {
      const dummyInput = document.createElement('input');
      document.body.appendChild(dummyInput);
      dummyInput.value = data;
      dummyInput.select();
      document.execCommand('copy');
      document.body.removeChild(dummyInput);
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `copied ${data}`,
      });
    }
  }
}
