<div class="section-container" *ngIf="office && office.advertiser">
  <div class="flex align-items-start justify-content-between">
    <h3>Advertiser Brands</h3>
    <button
      *ngIf="hasRole('db-edit-offices')"
      pButton
      label="Edit Brands"
      type="button"
      (click)="updateClicked()"
      class="p-button-raised p-button-warning"
      icon="pi pi-save"
      style="margin-bottom: 10px"
    ></button>
  </div>

  <p-chip class="advertiser-brands-chip" *ngFor="let brand of office.advertiser.brands">
    {{ brand.displayName }}
  </p-chip>

  <h4 *ngIf="!office.advertiser.brands || office.advertiser.brands.length === 0">No brands assigned</h4>
</div>
