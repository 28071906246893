import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title-value-field',
  templateUrl: './title-value-field.component.html',
  styleUrls: ['./title-value-field.component.scss'],
})
export class TitleValueFieldComponent {
  @Input() title = '';
  @Input() value = '';
  @Input() multipleRows = false;
}
