import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../general/base/base.component';
import { Holding, HoldingCommand, Network, NetworkCommand } from '../../../models';
import { ApiService } from '../../../shared/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ISearchQuery } from '../../../shared/interfaces';

@Component({
  selector: 'app-network-form',
  templateUrl: './network-form.component.html',
  styleUrls: ['./network-form.component.scss'],
})
export class NetworkFormComponent extends BaseComponent implements OnInit {
  public network: Network | undefined = undefined;
  public mode = 'edit';

  public enableOptions: [{ label: string; value: boolean }, { label: string; value: boolean }] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  public selectedEnableOption = this.enableOptions[0];

  public updateNetworkEnabled = false;
  public selectedHolding: Holding | undefined = undefined;
  public holdings: Holding[] = [];
  public filteredHoldings: Holding[] = [];

  constructor(public apiService: ApiService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    super();
  }

  ngOnInit(): void {
    if (this.config.data.network) {
      const network = this.config.data.network;
      const mode = this.config.data.mode;
      if (mode) {
        this.mode = mode;
      }

      if (network) {
        this.network = network;
      }
    }

    this.apiService.holdings.getAll().subscribe((result: ISearchQuery<Holding[]>) => {
      const items = result.items;
      items.unshift(
        new Holding({
          id: 0,
          name: '-- No Holding --',
          isEnabled: true,
          isBigPlayer: false,
          networks: [],
          companies: [],
          offices: [],
        }),
      );

      this.holdings = items;
      this.filteredHoldings = items;
      if (this.mode === 'edit') {
        this.selectedHolding = this.holdings.find((h) => h.id === this.network?.holdingId);
      }
    });
  }

  networkNameChanged() {
    this.checkIfUpdateNetworkEnabled();
  }

  cancelClicked() {
    this.ref.close();
  }

  updateClicked() {
    if (this.network) {
      this.network.isEnabled = this.selectedEnableOption.value;

      const command: NetworkCommand = new NetworkCommand({
        name: this.network.name,
        isEnabled: this.network.isEnabled,
        holdingId: this.selectedHolding?.id == 0 ? undefined : this.selectedHolding?.id,
      });

      this.ref.close(command);
    }
  }

  private checkIfUpdateNetworkEnabled() {
    if (this.network) {
      this.updateNetworkEnabled = this.network.name !== '';
    }
  }

  enableOptionChanged() {
    this.checkIfUpdateNetworkEnabled();
  }

  searchHolding($event: any) {
    this.filteredHoldings = this.holdings.filter((holding) =>
      holding.name.toLowerCase().includes($event.query.toLowerCase()),
    );
  }

  holdingChanged() {
    if (this.network && this.selectedHolding) {
      this.network.holdingId = this.selectedHolding?.id;
      this.checkIfUpdateNetworkEnabled();
    }
  }
}
