import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AgencyService, CreateAgencyServiceCommand, UpdateAgencyServiceCommand } from '../../../../models';

@Component({
  selector: 'app-agencies-services-form',
  templateUrl: './agencies-services-form.component.html',
  styleUrls: ['./agencies-services-form.component.scss'],
})
export class AgenciesServicesFormComponent implements OnInit {
  public mode: 'create' | 'edit' = 'create';

  public addAgencyServiceEnabled = false;

  public currentName = '';

  public command: CreateAgencyServiceCommand | UpdateAgencyServiceCommand = new CreateAgencyServiceCommand({
    name: '',
  });

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data?.service) {
      this.mode = 'edit';
      this.fillData(this.config.data.service);
    } else {
      this.mode = 'create';
    }
  }

  private fillData(service: AgencyService) {
    this.currentName = service.name;
    this.command = new UpdateAgencyServiceCommand(service);
  }

  public createClicked(): void {
    if (this.addAgencyServiceEnabled) {
      this.ref.close(this.command);
    }
  }

  public cancelClicked(): void {
    this.ref.close();
  }

  public nameChanged(): void {
    this.isAddAgencyServiceEnabled();
  }

  public isAddAgencyServiceEnabled(): void {
    if (this.mode === 'edit') {
      this.addAgencyServiceEnabled = !!(
        this.command.name &&
        this.command.name.trim().length &&
        this.command.name.trim() !== this.currentName.trim()
      );
    } else {
      this.addAgencyServiceEnabled = !!(this.command.name && this.command.name.trim().length);
    }
  }
}
