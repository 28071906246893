import { Component, OnInit } from '@angular/core';
import { Event, EventCommand, UpdateEventCommand } from 'src/app/models/event.model';
import { ApiService } from 'src/app/shared/services/api.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { ISearchQuery } from 'src/app/shared/interfaces';
import { environment } from 'src/environments/environment';
import { EventsFormComponent } from '../events-form/events-form.component';

@Component({
  selector: 'app-events-table',
  templateUrl: './events-table.component.html',
  styleUrls: ['./events-table.component.scss'],
})
export class EventsTableComponent implements OnInit {
  public events: Event[] = [];

  public filteredEvents: Event[] = [];

  public isLoading = true;

  public first = 0;

  public page = 1;

  public pageSize = 10;

  public availablePageSizes = environment.availablePageSizes;

  public eventsResult?: ISearchQuery<Event[]>;

  selectedSearchText = '';

  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.refresh();
    this.searchText$.subscribe(() => {
      this.first = 0;
      this.filteredEvents = this.events.filter((event: Event) =>
        event.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  refresh(): void {
    this.events = [];
    this.isLoading = true;
    this.apiService.events.find().subscribe((events: Event[]) => {
      this.events = events;
      this.filteredEvents = [...this.events];
      this.isLoading = false;
    });
  }

  createClicked() {
    const ref = this.dialogService.open(EventsFormComponent, {
      header: `Add new Event`,
      width: '30%',
      height: 'auto',
      data: {
        events: this.events,
        mode: 'create',
      },
    });

    ref.onClose.subscribe((command: EventCommand) => {
      if (command) {
        this.apiService.events.create(command).subscribe({
          next: (event: Event) => {
            this.addEvent(event);
            this.filteredEvents = [...this.events];
            this.messageService.add({
              severity: 'success',
              summary: 'Created',
              detail: $localize`You have added a new event`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while creating the event`,
            });
          },
        });
      }
    });
  }

  editClicked($event: MouseEvent, UpdatedEvent: Event) {
    $event.preventDefault();
    $event.stopPropagation();

    const ref = this.dialogService.open(EventsFormComponent, {
      header: `Edit Event`,
      width: '30%',
      height: 'auto',
      data: {
        event: UpdatedEvent,
        events: this.events,
        mode: 'edit',
      },
    });

    ref.onClose.subscribe((command: UpdateEventCommand) => {
      if (command) {
        this.apiService.events.update(command).subscribe({
          next: (event: Event) => {
            this.updateEvent(event);
            this.filteredEvents = [...this.events];
            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: $localize`You have updated the event`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while updating the event`,
            });
          },
        });
      }
    });
  }

  confirmDelete($event: MouseEvent, event: Event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: $localize`Are you sure that you want to delete this event?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiService.events.delete(event.id).subscribe({
          next: () => {
            this.removeEvent(event);
            this.filteredEvents = [...this.events];
            this.messageService.add({
              severity: 'success',
              summary: 'Deleted',
              detail: $localize`You have deleted the event`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while deleting the event`,
            });
          },
        });
      },
      reject: () => undefined,
    });
  }

  public addEvent(event: Event) {
    this.events.unshift(event);
  }

  public updateEvent(event: Event) {
    const index = this.events.findIndex((e) => e.id === event.id);
    if (index >= 0) {
      this.events[index] = event;
    }
  }

  public removeEvent(event: Event) {
    const index = this.events.findIndex((e) => e.id === event.id);
    if (index >= 0) {
      this.events.splice(index, 1);
    }
  }

  next() {
    this.first = this.first + this.pageSize;
  }

  prev() {
    this.first = this.first - this.pageSize;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.eventsResult?.count ? this.first === this.eventsResult.count - this.pageSize : true;
  }

  isFirstPage(): boolean {
    return this.eventsResult?.count ? this.first === 0 : true;
  }

  translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.filteredEvents.length
    } entries`;
  }

  searchTextChanged() {
    this.searchText$.next(this.selectedSearchText);
  }
}
