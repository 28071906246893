import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { EmailConstants } from '../constants';

@Directive({
  selector: '[email][ngModel],[email][formControl],[email][formControlName]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true }],
})
export class EmailValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const isValid = EmailConstants.emailRegex.test(value);
    const result = isValid ? null : { email: 'Invalid email address' };
    console.log(result);
    return result;
  }
}
