import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Brand, Company, Holding, Network } from '../../../models';
import { IOfficesFilter, IPrimengSelectButtonOptions } from '../../../shared/interfaces';
import { ApiService, ToastService } from '../../../shared/services';
import { CountryService } from '../../../shared/services/country.service';
import { MainKeyFilterType, NodeFilterType, SubKeyFilterType } from '../../../shared/types';
import { BaseComponent } from '../../general/base/base.component';

@Component({
  selector: 'app-offices-filters',
  templateUrl: './offices-filters.component.html',
  styleUrls: ['./offices-filters.component.scss'],
})
export class OfficesFiltersComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() isLoading = false;

  @Output() filters = new EventEmitter<IOfficesFilter>();

  public name = '';

  public selectedCountries: TreeNode[] = [];

  public selectedProvinces: TreeNode[] = [];

  public countryNodes: TreeNode[] = [];

  public selectedTypes: TreeNode[] = [];

  public selectedSubTypes: TreeNode[] = [];

  public typeNodes: TreeNode[] = [];

  public selectedSectors: TreeNode[] = [];

  public sectorNodes: TreeNode[] = [];

  public observations: string | undefined = undefined;

  public officeTypesOperationStateOptions = [
    { label: $localize`Or`, value: 'or' },
    { label: $localize`And`, value: 'and' },
  ];

  public selectedOfficeTypesOperationOption: 'or' | 'and' = 'or';

  public officeStatusStateOptions = [
    { label: $localize`Yes`, value: true },
    { label: $localize`No`, value: false },
    { label: $localize`All`, value: undefined },
  ];

  public selectedOfficeStatusOption: boolean | undefined = true;

  public officeHeadQuarterStateOptions = [
    { label: $localize`Yes`, value: true },
    { label: $localize`No`, value: false },
    { label: $localize`All`, value: undefined },
  ];

  public selectedHeadQuarterOption: boolean | undefined = undefined;

  public selectedBrands: Brand[] = [];

  public filteredBrands: Brand[] = [];

  public isAdvertiserSelected = false;

  public displayPlaceHolder = false;

  private brands: Brand[] = [];

  private delayedFiltersTimeoutId: number | null = null;

  private subscriptions = new Subscription();

  public selectedCompany?: Company;
  public selectedNetwork?: Network;
  public selectedHolding?: Holding;

  constructor(
    private apiService: ApiService,
    private readonly countryService: CountryService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadCountries();
    this.loadOfficeTypes();
    this.loadSectors();
    this.loadBrands();

    const globalCountrySub = this.globalCountryId$.subscribe({
      next: () => {
        this.loadCountries();
      },
      error: (error: unknown) => {
        console.error(error);
      },
    });

    this.subscriptions.add(globalCountrySub);
  }

  private loadBrands() {
    this.subscriptions.add(
      this.apiService.brands.findWithinAdvertisers(this.globalCountryId).subscribe({
        next: (brands) => {
          this.brands = brands;
        },
        error: (error: unknown) => {
          console.error(error);
          this.toastService.send({
            severity: 'error',
            summary: $localize`Error`,
            detail: $localize`We couldn't get brands from the API`,
          });
        },
      }),
    );
  }

  private loadSectors() {
    this.subscriptions.add(
      this.apiService.sectors.find().subscribe({
        next: (sectors) => {
          this.sectorNodes = sectors.map(
            (sector) =>
              ({
                label: sector.name,
                collapsedIcon: 'pi pi-folder',
                expandedIcon: 'pi pi-folder-open',
                data: sector.id,
                children: sector.subsectors.map(
                  (subSector) =>
                    ({
                      label: subSector.name,
                      icon: 'pi pi-check-circle',
                      data: subSector.id,
                      parent: {
                        label: sector.name,
                        collapsedIcon: 'pi pi-folder',
                        expandedIcon: 'pi pi-folder-open',
                        data: sector.id,
                      },
                    } as TreeNode),
                ),
              } as TreeNode),
          );
        },
        error: (error: unknown) => {
          console.error(error);
          this.toastService.send({
            severity: 'error',
            summary: $localize`Error`,
            detail: $localize`We couldn't get offices sectors from the API`,
          });
        },
      }),
    );
  }

  private loadOfficeTypes() {
    this.subscriptions.add(
      this.apiService.offices.types().subscribe({
        next: (types) => {
          this.typeNodes = types.map(
            (type) =>
              ({
                label: type.name,
                icon: 'pi pi-compass',
                data: type.id,
                children: type.subtypes.map(
                  (sub) =>
                    ({
                      label: sub.name,
                      icon: 'pi pi-check-circle',
                      data: sub.id,
                      parent: {
                        label: type.name,
                        icon: 'pi pi-compass',
                        data: type.id,
                      },
                    } as TreeNode),
                ),
              } as TreeNode),
          );
        },
        error: (error: unknown) => {
          console.error(error);
          this.toastService.send({
            severity: 'error',
            summary: $localize`Error`,
            detail: $localize`We couldn't get offices types from the API`,
          });
        },
      }),
    );
  }

  private loadCountries() {
    this.subscriptions.add(
      this.countryService.find({ countryId: this.globalCountryId, includeProvinces: true }).subscribe({
        next: (countries) => {
          console.log('found countries: ', countries);
          this.countryNodes = countries.map(
            (country) =>
              ({
                label: country.name,
                icon: country.flagUrl,
                data: country.id,
                children: country.provinces.map(
                  (province) =>
                    ({
                      label: province.name,
                      icon: 'pi pi-map-marker',
                      data: province.id,
                      parent: { label: country.name, icon: 'pi pi-compass', data: country.id },
                    } as TreeNode),
                ),
              } as TreeNode),
          );
          if (this.globalCountryId || this.countryNodes.length === 1) {
            this.onCountryChanges(this.countryNodes);
          } else {
            this.onCountryChanges([]);
          }
        },
        error: (error: unknown) => {
          console.error(error);
          this.toastService.send({
            severity: 'error',
            summary: $localize`Error`,
            detail: $localize`We couldn't get countries from the API`,
          });
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onNameChanges(value: string): void {
    this.name = value;
    this.createFilters();
  }

  public onCountryChanges(value: TreeNode[]): void {
    this.selectedCountries = value;
    this.displayPlaceHolder = false;

    if (!this.selectedCountries.length) {
      this.selectedProvinces = [];
    }

    this.createFilters();
  }

  public removeCountryItem(index: number) {
    this.selectedCountries.splice(index, 1);

    if (!this.selectedCountries.length) {
      this.selectedProvinces = [];
    }

    this.displayPlaceHolder = true;
    this.createFilters();
  }

  public onProvinceChanges(value: TreeNode[]): void {
    this.selectedProvinces = value;
    this.createFilters();
  }

  public onTypesChanges(value: TreeNode[]): void {
    this.selectedTypes = value;
    this.isAdvertiserSelected = !!this.selectedTypes.find((type) => type.label?.toLowerCase() === 'advertiser');

    if (!this.selectedTypes.length) {
      this.selectedSubTypes = [];
    }

    if (!this.isAdvertiserSelected) {
      this.selectedSectors = [];
      this.selectedBrands = [];
    }

    this.createFilters();
  }

  public onSubTypesChanges(value: TreeNode[]): void {
    this.selectedSubTypes = value;
    this.createFilters();
  }

  public onSectorsChanges(value: TreeNode[]): void {
    this.selectedSectors = value;
    this.createFilters();
  }

  public onOfficeTypesOperationChange(event: {
    option: IPrimengSelectButtonOptions<'or' | 'and'>;
    index: number;
    originalEvent: Event;
  }): void {
    this.selectedOfficeTypesOperationOption = event.option.value;
    this.createFilters();
  }

  public onOfficeStatusOptionChange(event: {
    option: IPrimengSelectButtonOptions<boolean | undefined>;
    index: number;
    originalEvent: Event;
  }) {
    if (this.selectedOfficeStatusOption !== event.option.value) {
      this.selectedOfficeStatusOption = event.option.value;
      this.createFilters();
    }
  }

  public onHeadQuarterOptionChange(event: {
    option: IPrimengSelectButtonOptions<boolean | undefined>;
    index: number;
    originalEvent: Event;
  }) {
    if (this.selectedHeadQuarterOption !== event.option.value) {
      this.selectedHeadQuarterOption = event.option.value;
      this.createFilters();
    }
  }

  public onFilterBrandsChange(event: Brand[]) {
    this.selectedBrands = event;
    this.createFilters();
  }

  public filterBrand(event: { originalEvent: Event; query: string }): void {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    const filtered: Brand[] = [];
    const { query } = event;

    for (const brand of this.brands) {
      if (brand.name.trim().toLowerCase().indexOf(query.trim().toLowerCase()) !== -1) {
        filtered.push(brand);
      }
    }

    this.filteredBrands = filtered;
  }

  public createFilters(): void {
    const handler = () => {
      this.filters.emit({
        name: this.name,
        locations: this.parseTreeNodeToFilterOptions('countryId', 'provinceId'),
        officeTypes: this.parseTreeNodeToFilterOptions('officeTypeId', 'agencyTypeId'),
        sectors: this.parseTreeNodeToFilterOptions('sectorId', 'subsectorId'),
        brands: this.selectedBrands.map((brand) => brand.id),
        officeTypesOperation: this.selectedOfficeTypesOperationOption,
        enabled: this.selectedOfficeStatusOption,
        onlyHeadquarters: this.selectedHeadQuarterOption,
        observations: this.observations,
        companyId: this.selectedCompany?.id,
        networkId: this.selectedNetwork?.id,
        holdingId: this.selectedHolding?.id,
      });
    };

    if (this.delayedFiltersTimeoutId) {
      window.clearTimeout(this.delayedFiltersTimeoutId);
      this.delayedFiltersTimeoutId = null;
    }

    this.delayedFiltersTimeoutId = window.setTimeout(handler, 250);
  }

  private parseTreeNodeToFilterOptions<T extends MainKeyFilterType, U extends SubKeyFilterType<T>>(
    mainKey: T,
    subKey: U,
  ): NodeFilterType<T>[] {
    const arr =
      mainKey === 'countryId'
        ? [...this.selectedProvinces, ...this.selectedCountries]
        : mainKey === 'officeTypeId'
        ? [...this.selectedSubTypes, ...this.selectedTypes]
        : this.selectedSectors;
    const parsed: NodeFilterType<T>[] = [];

    for (const node of arr) {
      if (
        !node.parent &&
        node.children &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        !parsed.some((value: any) => value[mainKey] === node.data && value[subKey])
      ) {
        parsed.push({ [mainKey]: node.data, [subKey]: undefined } as NodeFilterType<T>);
      }

      if (node.parent && !node.children) {
        parsed.push({ [mainKey]: node.parent.data, [subKey]: node.data } as NodeFilterType<T>);
      }
    }

    return parsed;
  }

  onObservationsChange($event: string) {
    this.observations = $event;
    this.createFilters();
  }

  public isAgency(): boolean {
    return !!this.selectedTypes.find((type) => type.label?.toLowerCase() === 'agency');
  }

  public holdingsSearchFunction = this.apiService.holdings.getAll;
  public networksSearchFunction = this.apiService.networks.getAll;
  public companiesSearchFunction = this.apiService.companies.getAll;

  public countryFilterFunction = () => {
    const countryIds: number[] = [];
    if (this.selectedCountries.length > 0) {
      this.selectedCountries.forEach((country) => {
        countryIds.push(country.data);
      });
    }
    return countryIds;
  };

  onHoldingItemChange($event: any) {
    this.selectedHolding = $event;
    this.createFilters();
  }

  onNetworkItemChange($event: any) {
    this.selectedNetwork = $event;
    this.createFilters();
  }

  onCompanyItemChange($event: any) {
    this.selectedCompany = $event;
    this.createFilters();
  }
}
