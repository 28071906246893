import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateSubSectorCommand, SubSector, UpdateSubSectorCommand } from 'src/app/models';

@Component({
  selector: 'app-subsectors-form',
  templateUrl: './subsectors-form.component.html',
  styleUrls: ['./subsectors-form.component.scss'],
})
export class SubsectorsFormComponent implements OnInit {
  public mode: 'create' | 'edit' = 'create';

  public addSubsectorEnabled = false;

  public currentName = '';

  public command: CreateSubSectorCommand | UpdateSubSectorCommand = new CreateSubSectorCommand({
    name: '',
  });

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data?.subsector) {
      this.mode = 'edit';
      this.fillData(this.config.data.subsector);
    } else {
      this.mode = 'create';
    }
  }

  private fillData(subsector: SubSector) {
    this.currentName = subsector.name;
    this.command = new UpdateSubSectorCommand(subsector);
  }

  public createClicked(): void {
    if (this.addSubsectorEnabled) {
      this.ref.close(this.command);
    }
  }

  public cancelClicked(): void {
    this.ref.close();
  }

  public nameChanged(): void {
    this.checkIfAddSectorEnabled();
  }

  public checkIfAddSectorEnabled(): void {
    if (this.mode === 'edit') {
      this.addSubsectorEnabled = !!(
        this.command.name &&
        this.command.name.trim().length &&
        this.command.name.trim() !== this.currentName.trim()
      );
    } else {
      this.addSubsectorEnabled = !!(this.command.name && this.command.name.trim().length);
    }
  }
}
