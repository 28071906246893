<div class="content">
  <div class="gradient-mid"></div>
  <div class="content-login">
    <div class="form-login">
      <div class="content-form">
        <div class="title-input">
          <h1 i18n>Email</h1>
          <input
            type="email"
            [(ngModel)]="email"
            name="email"
            i18n-placeholder
            placeholder="Email"
            required="required"
          />
        </div>
        <div class="title-input">
          <h1 i18n>Password</h1>
          <input
            (keyup.enter)="login()"
            type="password"
            [(ngModel)]="password"
            name="password"
            i18n-placeholder
            placeholder="Password"
            required="required"
          />
        </div>
        <p-button type="submit" (click)="login()" [disabled]="isLoading">
          <span i18n *ngIf="isLoading">wait...</span>
          <i *ngIf="isLoading" class="pi pi-spin pi-spinner"></i>
          <span i18n *ngIf="!isLoading">Log in</span>
        </p-button>
      </div>
    </div>
  </div>
</div>
