import { Component, OnInit } from '@angular/core';
import { Company, CompanyCommand, Country, Holding, Network, UpdateOfficeCommand } from '../../../models';
import { BaseComponent } from '../../general/base/base.component';
import { ApiService, ToastService } from '../../../shared/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IPrimengCustomEvent } from '../../../shared/interfaces';
import { ScrollerOptions } from 'primeng/scroller';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
})
export class CompanyFormComponent extends BaseComponent implements OnInit {
  public company: Company | undefined = undefined;

  public mode = 'edit';

  public updateCompanyEnabled = false;
  public enableOptions: [{ label: string; value: boolean }, { label: string; value: boolean }] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  public selectedEnableOption = this.enableOptions[0];

  public selectedNetwork?: Network;
  public selectedHolding?: Holding;

  public networkResult: Network[] = [];
  public holdingResult: Holding[] = [];

  public itemPage = 1;
  public itemsPageSize = 15;
  public networkLoader = false;
  public holdingLoader = false;
  private networkQuery?: string;
  private holdingQuery?: string;

  constructor(
    public apiService: ApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.config.data.company) {
      const company = this.config.data.company;
      const mode = this.config.data.mode;
      if (mode) {
        this.mode = mode;
      }

      if (company) {
        this.company = company;
        if (this.company) {
          this.selectedEnableOption = this.company.isEnabled ? this.enableOptions[0] : this.enableOptions[1];

          this.networkResult.unshift(this.getEmptyNetwork());
          if (this.company.network) {
            const network = new Network(this.company.network);
            this.networkResult.push(network);
            this.selectedNetwork = network;
          }

          this.holdingResult.unshift(this.getEmptyHolding());
          if (this.company.holding) {
            const holding = new Holding(this.company.holding);
            this.holdingResult.push(holding);
            this.selectedHolding = holding;
          }

          this.checkIfUpdateCompanyEnabled();
        }
      }
    }
  }

  private checkIfUpdateCompanyEnabled() {
    if (this.company) {
      this.updateCompanyEnabled = this.company.name != undefined && this.company.name != '';
    }
  }

  updateClicked() {
    if (this.company) {
      const command = new CompanyCommand({
        name: this.company.name,
        list: this.company.researchId > 0 ? 'RESEARCH' : 'BD-ONLY',
        researchId: this.company.researchId,
        networkId: this.selectedNetwork
          ? this.selectedNetwork?.id > 0
            ? this.selectedNetwork?.id
            : undefined
          : undefined,
        holdingId: this.selectedHolding
          ? this.selectedHolding?.id > 0
            ? this.selectedHolding?.id
            : undefined
          : undefined,
        observations: this.company.observations,
        isEnabled: this.selectedEnableOption.value,
      });
      this.ref.close(command);
    } else {
      this.ref.close();
    }
  }

  cancelClicked() {
    this.ref.close();
  }

  companyNameChanged() {
    this.checkIfUpdateCompanyEnabled();
  }

  onNetworkItemChange($event: Network) {
    this.selectedNetwork = $event;
    this.checkIfUpdateCompanyEnabled();
  }

  onHoldingItemChange($event: Holding) {
    this.selectedHolding = $event;
    this.checkIfUpdateCompanyEnabled();
  }

  public filterNetworks(event: IPrimengCustomEvent<Event, string>): void {
    const query = event.query;
    this.searchNetwork(query, 1, true);
  }

  public filterHoldings(event: IPrimengCustomEvent<Event, string>): void {
    const query = event.query;
    this.searchHoldings(query, 1, true);
  }

  private searchNetwork(query?: string, page = this.itemPage, cleanResults = false): void {
    this.networkLoader = true;
    this.apiService.networks.getAll(query).subscribe({
      next: (data) => {
        if (query !== this.networkQuery || cleanResults) {
          this.networkResult = [];
        }

        this.networkResult = [...this.networkResult, ...data.items];
        this.networkQuery = query;
        this.networkLoader = false;
        this.networkResult.unshift(this.getEmptyNetwork());
      },
      error: () => {
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We could not get Networks from the API`,
        });
      },
    });
  }

  private searchHoldings(query?: string, page = this.itemPage, cleanResults = false): void {
    this.holdingLoader = true;
    this.apiService.holdings.getAll(query).subscribe({
      next: (data) => {
        if (query !== this.holdingQuery || cleanResults) {
          this.holdingResult = [];
        }

        this.holdingResult = [...this.holdingResult, ...data.items];
        this.holdingQuery = query;
        this.holdingLoader = false;
        this.holdingResult.unshift(this.getEmptyHolding());
      },
      error: () => {
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We could not get Holdings from the API`,
        });
      },
    });
  }

  public networksVirtualScroll: ScrollerOptions = {
    onScrollIndexChange: (event: { first: number; last: number }) => {
      if (event.last === this.itemPage * this.itemsPageSize) {
        this.searchNetwork(this.networkQuery, (this.itemPage += 1));
      }
    },
    loading: this.networkLoader,
  };

  public holdingsVirtualScroll: ScrollerOptions = {
    onScrollIndexChange: (event: { first: number; last: number }) => {
      if (event.last === this.itemPage * this.itemsPageSize) {
        this.searchHoldings(this.holdingQuery, (this.itemPage += 1));
      }
    },
    loading: this.holdingLoader,
  };

  private getEmptyHolding(): Holding {
    return new Holding({
      id: 0,
      name: '-- No holding --',
      isEnabled: true,
      isBigPlayer: false,
      offices: [],
      companies: [],
      networks: [],
    });
  }

  private getEmptyNetwork(): Network {
    return new Network({ id: 0, name: '-- No network --', isEnabled: true, offices: [], holdingId: 0, companies: [] });
  }
}
