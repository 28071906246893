<div class="col-12 flex flex-column align-items-end justify-content-start">
  <div class="flex align-items-end justify-content-end">
    <button
      *ngIf="hasRole('db-edit-offices')"
      pButton
      label="Add Service"
      type="button"
      (click)="createClicked()"
      class="p-button-raised p-button-warning"
      icon="pi pi-plus-circle"
      style="margin-bottom: 10px"
    ></button>
  </div>
  <div class="col-12 px-0 justify-content-start w-full">
    <div *ngIf="isLoaded">
      <app-collaborations-table
        [mode]="mode"
        id="collaborations-table"
        [office]="office"
        (itemDeleteConfirmed)="delete($event)"
        (itemEditClicked)="updateClicked($event)"
        (itemSelected)="navigate($event)"
      >
      </app-collaborations-table>
    </div>
  </div>
</div>
