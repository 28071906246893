<p-table
  #dt
  id="scopen-offices-table"
  [value]="offices"
  [paginator]="true"
  [(rows)]="pageSize"
  [(first)]="first"
  [showCurrentPageReport]="true"
  [currentPageReportTemplate]="translateCurrentPageReportTemplate()"
  [rowsPerPageOptions]="availablePageSizes"
  [totalRecords]="totalRecords"
  [resizableColumns]="false"
  [scrollable]="true"
  [lazy]="true"
  [loading]="isLoading"
  (onLazyLoad)="onLazyLoad($event)"
  selectionMode="multiple"
  [(selection)]="selectedProducts"
  [exportHeader]="'customExportHeader'"
>
  <!-- scrollHeight="47vh" -->
  <!-- <ng-template pTemplate="caption">
    <div class="flex">
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-file"
        (click)="dt.exportCSV()"
        class="mr-2"
        pTooltip="CSV"
        tooltipPosition="bottom"
      ></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-file-excel"
        (click)="exportExcel()"
        class="p-button-success mr-2"
        pTooltip="XLS"
        tooltipPosition="bottom"
      ></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-file-pdf"
        (click)="exportPdf()"
        class="p-button-warning mr-2"
        pTooltip="PDF"
        tooltipPosition="bottom"
      ></button>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-filter"
        (click)="dt.exportCSV({ selectionOnly: true })"
        class="p-button-info ml-auto"
        pTooltip="Selection Only"
        tooltipPosition="bottom"
      ></button>
    </div>
  </ng-template> -->
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th i18n pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
      <th i18n>Address</th>
      <th class="field-lastReview" i18n pSortableColumn="lastReview">
        Last review <p-sortIcon field="lastReview"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [pSelectableRow]="rowData" (click)="goToOfficeDetailsPage(rowData)">
      <td>{{ rowData?.name }}</td>
      <td>{{ rowData?.parsedAddress }}</td>
      <td><i class="pi pi-calendar"></i> {{ rowData?.lastReview | date: 'dd/MM/yy' }}</td>
    </tr>
  </ng-template>
</p-table>
