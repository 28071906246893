import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Office } from '../../models';
import { ApiService, ToastService } from '../../shared/services';
import { CountryService } from '../../shared/services/country.service';
import { OfficeTypesService } from './office-types-service';
import { BaseComponent } from '../../components/general/base/base.component';
import { DialogService } from 'primeng/dynamicdialog';
import { OfficeFusionFormComponent } from '../../components/offices/office-fusion-form/office-fusion-form.component';
import { OfficeFusionCommand } from '../../models/office-fusion-detail.model';

@Component({
  selector: 'app-office-details',
  templateUrl: './office-details.component.html',
  styleUrls: ['./office-details.component.scss'],
})
export class OfficeDetailsComponent extends BaseComponent implements OnInit {
  public office?: Office;

  public flag?: string;
  private service: OfficeTypesService = new OfficeTypesService(this.apiService, this.toastService);

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private readonly countryService: CountryService,
    private toastService: ToastService,
    private dialogService: DialogService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    this.apiService.offices.findById(id).subscribe({
      next: (office) => {
        if (office && office.address && office.address.country) {
          this.countryService.findById(office.address.country.id).subscribe({
            next: (country) => {
              this.office = office;
              if (this.office) {
                this.office.country = country;
              }
              this.service.loadOfficeTypes(this.office);
            },
            error: () => {
              this.office = office;
              this.service.loadOfficeTypes(this.office);
            },
          });
        } else {
          if (office) {
            this.office = office;
            this.service.loadOfficeTypes(this.office);
          } else {
            this.router.navigate(['/app/access-denied']);
          }
        }
      },
    });
  }

  public mapOfficeTypesNames(): string {
    if (this.office?.officeTypes.length) {
      return this.office?.officeTypes.map((type) => type.name).join(', ');
    }

    return '';
  }

  editClicked($event: MouseEvent) {
    const office = Object.create(this.office || {});
    const ref = this.dialogService.open(OfficeFusionFormComponent, {
      header: `Fusion office`,
      width: '50%',
      height: 'auto',
      data: {
        office: office,
      },
    });

    ref.onClose.subscribe((command: OfficeFusionCommand) => {
      if (command) {
        this.apiService.offices.fusion(office.id, command).subscribe(
          (value) => {
            this.toastService.send({
              severity: 'success',
              summary: 'Updated',
              detail: 'You have successfully fused this office',
            });
            window.location.reload();
          },
          () => {
            this.toastService.send({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while fusing the office',
            });
          },
        );
      }
    });
  }
}
