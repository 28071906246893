import { Component, Input } from '@angular/core';
import { Office } from '../../../models';

@Component({
  selector: 'app-office-summary',
  templateUrl: './office-summary.component.html',
  styleUrls: ['./office-summary.component.scss'],
})
export class OfficeSummaryComponent {
  @Input() public office: Office | undefined = undefined;
  @Input() public isTitle = false;

  public mapOfficeTypesNames(): string {
    if (this.office?.officeTypes.length) {
      return this.office?.officeTypes.map((type) => type.name).join(', ');
    }
    return '';
  }

  public officeCssClass(): string {
    return this.isTitle ? 'office-name title' : 'office-name';
  }
}
