import { Component, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BaseComponent } from '../../components/general/base/base.component';
import { IOfficesFilter } from '../../shared/interfaces';
import { NetworkListComponent } from '../../components/networks/network-list/network-list.component';
import { HoldingListComponent } from '../../components/holdings/holding-list/holding-list.component';
import { DialogService } from 'primeng/dynamicdialog';
import { HoldingFormComponent } from '../../components/holdings/holding-form/holding-form.component';
import { Holding, HoldingCommand, Network, NetworkCommand } from '../../models';
import { ApiService, ToastService } from '../../shared/services';
import { NetworkFormComponent } from '../../components/networks/network-form/network-form.component';

@Component({
  selector: 'app-networks-holdings',
  templateUrl: './networks-holdings.component.html',
  styleUrls: ['./networks-holdings.component.scss'],
})
export class NetworksHoldingsComponent extends BaseComponent {
  public filters: IOfficesFilter | null = null;
  @ViewChild(NetworkListComponent) networkList?: NetworkListComponent;
  @ViewChild(HoldingListComponent) holdingList?: HoldingListComponent;
  public createOptions: MenuItem[] = [
    {
      icon: 'pi pi-sitemap',
      label: 'Network',
      command: () => {
        this.createNetworkClicked();
      },
    },
    {
      icon: 'pi pi-globe',
      label: 'Holding',
      command: () => {
        this.createHoldingClicked();
      },
    },
  ];

  constructor(
    private dialogService: DialogService,
    private apiService: ApiService,
    private toastService: ToastService,
  ) {
    super();
  }

  createNetworkClicked() {
    const ref = this.dialogService.open(NetworkFormComponent, {
      header: `Create network`,
      width: '600px',
      height: 'auto',
      data: {
        network: Network.createNewBlankNetwork(),
        mode: 'create',
      },
    });

    ref.onClose.subscribe((command: NetworkCommand) => {
      this.apiService.networks.create(command).subscribe((network) => {
        this.networkList?.addNetwork(network);
        this.toastService.send({
          severity: 'success',
          summary: 'Created',
          detail: 'You have created a network',
        });
      });
    });
  }

  public createHoldingClicked() {
    const ref = this.dialogService.open(HoldingFormComponent, {
      header: `Create holding`,
      width: '600px',
      height: 'auto',
      data: {
        holding: Holding.createNewBlankHolding(),
        mode: 'create',
      },
    });

    ref.onClose.subscribe((command: HoldingCommand) => {
      this.apiService.holdings.create(command).subscribe((holding) => {
        this.holdingList?.addHolding(holding);
        this.toastService.send({
          severity: 'success',
          summary: 'Created',
          detail: 'You have created a holding',
        });
      });
    });
  }

  public onFilters(filters: IOfficesFilter) {
    this.filters = filters;

    if (this.networkList) {
      this.networkList.load(filters);
    }

    if (this.holdingList) {
      this.holdingList.load(filters);
    }
  }
}
