import { BaseModel } from './base.model';

export class Title extends BaseModel {
  public name: string;

  public countryIds: number[];

  constructor(data: Title) {
    super(data);

    ({ name: this.name, countryIds: this.countryIds } = data);
  }
}

export class TitleCommand {
  public name: string;

  public countryIds: number[];

  constructor(data: TitleCommand) {
    ({ name: this.name, countryIds: this.countryIds } = data);
  }
}

export class CreateTitleCommand extends TitleCommand {
  constructor(data: CreateTitleCommand) {
    super(data);
  }
}

export class UpdateTitleCommand extends TitleCommand {
  public id: number;

  constructor(data: UpdateTitleCommand) {
    super(data);
    this.id = data.id;
  }
}
