<div class="form-container" *ngIf="office && office.advertiser">
  <div class="col-12 flex start">
    <app-office-summary [office]="office"></app-office-summary>
  </div>
  <div class="col-12 flex start">
    <span class="p-float-label w-full">
      <p-treeSelect
        *ngIf="selector"
        id="filter-sector"
        [(ngModel)]="selector.selectedSubSectors"
        (ngModelChange)="onSectorsChanges($event)"
        [options]="selector.sectorNodes"
        [metaKeySelection]="false"
        selectionMode="checkbox"
        display="chip"
        i18n-placeholder
        placeholder="Select a sector"
        [filter]="true"
        [filterInputAutoFocus]="true"
        containerStyleClass="w-full"
        appendTo="body"
      ></p-treeSelect>
      <label i18n for="filter-sector">Sector & SubSector</label>
    </span>
  </div>
  <div class="col-12">
    <button
      pButton
      label="Update Sectors"
      type="button"
      (click)="updateClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
