import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { EmployeeStats, EmployeeStatsCommand, Office } from '../../../models';
import { ApiService, ToastService } from '../../../shared/services';
import { BaseComponent } from '../../general/base/base.component';
import { OfficeEmployeesFormComponent } from '../office-employees-form/office-employees-form.component';

@Component({
  selector: 'app-office-employees',
  templateUrl: './office-employees.component.html',
  styleUrls: ['./office-employees.component.scss'],
})
export class OfficeEmployeesComponent extends BaseComponent implements OnChanges {
  @Input() office?: Office;

  public employeeStats: EmployeeStats[] = [];

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['office'] && changes['office'].currentValue) {
      this.apiService.offices.employeeStats((changes['office'].currentValue as Office).id).subscribe({
        next: (employeeStats) => {
          this.employeeStats = employeeStats;
        },
        error: (error: unknown) => {
          console.error(error);
        },
      });
    }
  }

  editClicked($event: MouseEvent, stat: EmployeeStats) {
    const ref = this.dialogService.open(OfficeEmployeesFormComponent, {
      header: `Add Employees by Year`,
      width: '50em',
      height: 'auto',
      data: {
        office: this.office,
        stats: stat,
      },
    });

    ref.onClose.subscribe((command: EmployeeStatsCommand) => {
      if (command) {
        if (this.office?.id) {
          this.apiService.offices.updateEmployeeStats(this.office.id, stat.id, command).subscribe({
            next: (stats) => {
              this.updateStats(stats);
              this.toastService.send({
                severity: 'success',
                summary: 'Updated',
                detail: 'You have updated the employees by year entry',
              });
            },
            error: () => {
              this.toastService.send({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while updating employees by year entry',
              });
            },
          });
        }
      }
    });
  }

  confirmDelete($event: MouseEvent, stat: EmployeeStats) {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: 'Are you sure that you want to delete this employees by year entry?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.office?.id) {
          this.apiService.offices.deleteEmployeeStats(this.office.id, stat.id).subscribe({
            next: () => {
              this.removeStats(stat);
              this.toastService.send({
                severity: 'success',
                summary: 'Deleted',
                detail: 'You have deleted the employees by year entry for this office',
              });
            },
            error: () => {
              this.toastService.send({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while deleting the employees by year entry for this office',
              });
            },
          });
        }
      },
      reject: () => undefined,
    });
  }

  createClicked() {
    const ref = this.dialogService.open(OfficeEmployeesFormComponent, {
      header: `Add Employees by Year`,
      width: '50em',
      height: 'auto',
      data: {
        office: this.office,
      },
    });

    ref.onClose.subscribe((command: EmployeeStatsCommand) => {
      if (command) {
        if (this.office?.id) {
          this.apiService.offices.createEmployeeStats(this.office.id, command).subscribe({
            next: (stats) => {
              this.addStats(stats);
              this.toastService.send({
                severity: 'success',
                summary: 'Created',
                detail: 'You have added a employees by year entry',
              });
            },
            error: () => {
              this.toastService.send({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while creating employees by year entry',
              });
            },
          });
        }
      }
    });
  }

  public addStats(stats: EmployeeStats) {
    this.employeeStats.push(stats);
    this.sortEmployeeStats();
  }

  private sortEmployeeStats() {
    this.employeeStats.sort((filterA, filterB) => {
      if (filterA.year > filterB.year) {
        return -1;
      }
      if (filterA.year < filterB.year) {
        return 1;
      }
      return 0;
    });
  }

  public updateStats(stats: EmployeeStats) {
    const index = this.employeeStats.findIndex((f) => f.id === stats.id);
    if (index >= 0) {
      this.employeeStats[index] = stats;
      this.sortEmployeeStats();
    }
  }

  public removeStats(stats: EmployeeStats) {
    const index = this.employeeStats.findIndex((f) => f.id === stats.id);
    if (index >= 0) {
      this.employeeStats.splice(index, 1);
    }
  }
}
