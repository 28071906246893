<div class="form-container" *ngIf="person">
  <div class="col-12 flex start">
    <app-person-summary [person]="person"></app-person-summary>
  </div>
  <div class="col-12 flex start">
    <div class="col-12 p-2">
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-dropdown
          id="festival"
          placeholder="Festival"
          [filter]="true"
          filterBy="event.name,year"
          filterPlaceholder="Search festivals"
          [options]="festivals"
          [(ngModel)]="selectedFestival"
          (ngModelChange)="festivalChanged()"
          appendTo="body"
          class="w-full"
          [style]="{ width: '100%' }"
        >
          <!-- Template for options -->
          <ng-template let-festival pTemplate="item"> {{ festival.event.name }} - {{ festival.year }} </ng-template>
          <!-- Tempalte for selected option -->
          <ng-template let-festival pTemplate="selectedItem">
            {{ festival.event.name }} - {{ festival.year }}
          </ng-template>
        </p-dropdown>
        <label for="festival">Festival</label>
      </span>
    </div>
  </div>
  <div class="col-12 flex start">
    <div class="col-12 p-2">
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-dropdown
          id="company"
          placeholder="Company"
          [options]="companyOptions"
          [(ngModel)]="selectedCompany"
          (ngModelChange)="companyChanged()"
          optionLabel="name"
          appendTo="body"
          class="w-full"
          [style]="{ width: '100%' }"
        ></p-dropdown>
        <label for="company">Company</label>
      </span>
    </div>
  </div>

  <div class="col-12 flex start">
    <div class="col-12 p-2">
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-dropdown
          id="position"
          placeholder="Position"
          [options]="positionOptions"
          [(ngModel)]="selectedPosition"
          (ngModelChange)="positionChanged()"
          optionLabel="positionName"
          appendTo="body"
          class="w-full"
          [style]="{ width: '100%' }"
        ></p-dropdown>
        <label for="position">Position</label>
      </span>
    </div>
  </div>

  <div class="col-12">
    <button
      pButton
      *ngIf="mode === 'create'"
      label="Add Jury"
      type="button"
      (click)="createClicked()"
      [disabled]="!addJuryEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-plus-circle"
    ></button>

    <button
      pButton
      *ngIf="mode === 'edit'"
      label="Update Jury"
      type="button"
      (click)="createClicked()"
      [disabled]="!addJuryEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
