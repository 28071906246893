<div *ngIf="person" class="col-12 p-2 py-4">
  <div class="justify-content-start w-12">
    <div class="flex flex-column">
      <h3 [className]="personCssClass()">{{ person.fullNameWithTitle }}</h3>
      <div *ngIf="!person.isEnabled" class="flex flex-row align-content-start py-2">
        <i class="pi pi-exclamation-circle"></i>
        <span class="py-2 disabled-text">This professional record is disabled</span>
      </div>
      <div *ngIf="person.country" class="flex flex-row justify-content-start">
        <img class="country-icon" [src]="person.country.flagUrl" />
        <div class="flex flex-column justify-content-center">{{ person.country.name }}</div>
      </div>
      <div>
        <span><strong>Last Review: </strong></span>
        {{ person.lastReview | date: 'dd/MM/yyyy' }}
      </div>
    </div>
  </div>
</div>
