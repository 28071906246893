import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateSectorCommand, Sector, UpdateSectorCommand } from 'src/app/models';

@Component({
  selector: 'app-sectors-form',
  templateUrl: './sectors-form.component.html',
  styleUrls: ['./sectors-form.component.scss'],
})
export class SectorsFormComponent implements OnInit {
  public mode: 'create' | 'edit' = 'create';

  public addSectorEnabled = false;

  public currentName = '';

  public command: CreateSectorCommand | UpdateSectorCommand = new CreateSectorCommand({
    name: '',
    subsectors: [],
  });

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data?.sector) {
      this.mode = 'edit';
      this.fillData(this.config.data.sector);
    } else {
      this.mode = 'create';
    }
  }

  private fillData(sector: Sector) {
    this.currentName = sector.name;
    this.command = new UpdateSectorCommand(sector);
  }

  public createClicked(): void {
    if (this.addSectorEnabled) {
      this.ref.close(this.command);
    }
  }

  public cancelClicked(): void {
    this.ref.close();
  }

  public nameChanged(): void {
    this.checkIfAddSectorEnabled();
  }

  public checkIfAddSectorEnabled(): void {
    if (this.mode === 'edit') {
      this.addSectorEnabled = !!(
        this.command.name &&
        this.command.name.trim().length &&
        this.command.name.trim() !== this.currentName.trim()
      );
    } else {
      this.addSectorEnabled = !!(this.command.name && this.command.name.trim().length);
    }
  }
}
