<div class="form-container" *ngIf="person">
  <div class="col-12 flex start">
    <app-person-summary [person]="person"></app-person-summary>
  </div>
  <div class="col-12 flex start">
    <p-multiSelect
      [options]="waves"
      [(ngModel)]="selectedWaves"
      optionLabel="name"
      appendTo="body"
      [style]="{ 'max-width': '600px' }"
      class="autocomplete-full"
      styleClass="autocomplete-full"
    ></p-multiSelect>
  </div>
  <div class="col-12">
    <button
      pButton
      label="Update Waves"
      type="button"
      (click)="updateClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
