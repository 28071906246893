import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Sector } from 'src/app/models';
import { Country } from '../../models/country.model';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss'],
})
export class AdminsComponent implements OnInit, OnDestroy {
  routerActive?: string = '';

  public countries: Country[] = [];

  public sectors: Sector[] = [];

  private subscriptions = new Subscription();

  constructor(private router: Router) {}

  ngOnInit(): void {
    const subs = this.router.events.subscribe(() => {
      this.routerActive = this.router.url.split('/').pop();
    });

    this.subscriptions.add(subs);
  }

  updateCountries(event: Country[]) {
    this.countries = [...event];
  }

  updateSectors(event: Sector[]) {
    this.sectors = [...event];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
