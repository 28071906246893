import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Person } from '../../../models';
import { UpdatePersonWavesCommand, Wave } from '../../../models/wave.model';
import { ApiService } from '../../../shared/services';
import { PersonWavesFormComponent } from '../person-waves-form/person-waves-form.component';
import { BaseComponent } from '../../general/base/base.component';

@Component({
  selector: 'app-person-waves',
  templateUrl: './person-waves.component.html',
  styleUrls: ['./person-waves.component.scss'],
})
export class PersonWavesComponent extends BaseComponent implements OnInit {
  @Input() public person: Person | undefined = undefined;

  public waves: Wave[] = [];
  public isLoading = true;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
  ) {
    super();
  }

  ngOnInit() {
    this.refresh();
  }

  public refresh() {
    this.waves = [];
    this.isLoading = true;
    if (this.person) {
      this.apiService.people.getWaves(this.person.id).subscribe((waves: Wave[]) => {
        this.waves = waves;
        this.isLoading = false;
      });
    }
  }

  createClicked() {
    const ref = this.dialogService.open(PersonWavesFormComponent, {
      header: `Edit Waves`,
      width: '700px',
      height: 'auto',
      data: {
        person: this.person,
        waves: this.waves,
      },
    });

    ref.onClose.subscribe((command: UpdatePersonWavesCommand) => {
      if (command) {
        this.isLoading = true;
        this.apiService.people.updateWaves(command.personId, command).subscribe(
          (waves) => {
            this.waves = waves;
            this.isLoading = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: 'You have updated the waves for this person',
            });
          },
          () => {
            this.isLoading = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error updating the waves for this person',
            });
          },
        );
      }
    });
  }
}
