import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { CreateRoleCommand, Role, UpdateRoleCommand } from 'src/app/models';
import { ISearchQuery } from 'src/app/shared/interfaces';
import { ApiService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { JobRolesFormComponent } from '../job-roles-form/job-roles-form.component';

@Component({
  selector: 'app-job-roles-table',
  templateUrl: './job-roles-table.component.html',
  styleUrls: ['./job-roles-table.component.scss'],
})
export class JobRolesTableComponent implements OnInit {
  public jobRoles: Role[] = [];

  public filteredJobRoles: Role[] = [];

  public isLoading = true;

  public first = 0;

  public page = 1;

  public pageSize = 10;

  public availablePageSizes = environment.availablePageSizes;

  public jobRolesResult?: ISearchQuery<Role[]>;

  selectedSearchText = '';

  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.refresh();
    this.searchText$.subscribe(() => {
      this.first = 0;
      this.filteredJobRoles = this.jobRoles.filter((jobRole) =>
        jobRole.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  refresh(): void {
    this.jobRoles = [];
    this.isLoading = true;
    this.apiService.jobRoles.find().subscribe((jobRoles: Role[]) => {
      this.jobRoles = jobRoles;
      this.filteredJobRoles = [...this.jobRoles];
      this.isLoading = false;
    });
  }

  public createClicked(): void {
    const ref = this.dialogService.open(JobRolesFormComponent, {
      header: `Add new Position Type`,
      width: '30%',
      height: 'auto',
    });

    ref.onClose.subscribe((command: CreateRoleCommand) => {
      if (command) {
        this.apiService.jobRoles.create(command).subscribe({
          next: (jobRole) => {
            this.addJobRole(jobRole);
            this.filteredJobRoles = [...this.jobRoles];
            this.messageService.add({
              severity: 'success',
              summary: 'Created',
              detail: 'You have added a new position type',
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while creating the position type',
            });
          },
        });
      }
    });
  }

  public editClicked($event: MouseEvent, jobRole: Role): void {
    $event.preventDefault();
    $event.stopPropagation();

    const ref = this.dialogService.open(JobRolesFormComponent, {
      header: `Edit Position Type`,
      width: '30%',
      height: 'auto',
      data: {
        jobRole,
      },
    });

    ref.onClose.subscribe((command: UpdateRoleCommand) => {
      if (command) {
        this.apiService.jobRoles.update(jobRole.id, command).subscribe({
          next: (jobRole) => {
            this.updateJobRole(jobRole);
            this.filteredJobRoles = [...this.jobRoles];
            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: 'You have updated the position type',
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while updating the position type',
            });
          },
        });
      }
    });
  }

  public confirmDelete($event: MouseEvent, jobRole: Role): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: 'Are you sure that you want to delete this position type?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiService.jobRoles.delete(jobRole.id).subscribe({
          next: () => {
            this.removeJobRole(jobRole);
            this.filteredJobRoles = [...this.jobRoles];
            this.messageService.add({
              severity: 'success',
              summary: 'Deleted',
              detail: 'You have deleted the position type',
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while deleting the position type',
            });
          },
        });
      },
      reject: () => undefined,
    });
  }

  public addJobRole(JobRole: Role): void {
    this.jobRoles.push(JobRole);
  }

  public updateJobRole(jobRole: Role): void {
    const index = this.jobRoles.findIndex((jr) => jr.id === jobRole.id);

    if (index >= 0) {
      this.jobRoles[index] = jobRole;
    }
  }

  public removeJobRole(jobRole: Role): void {
    const index = this.jobRoles.findIndex((jr) => jr.id === jobRole.id);

    if (index >= 0) {
      this.jobRoles.splice(index, 1);
    }
  }

  public next(): void {
    this.first = this.first + this.pageSize;
  }

  public prev(): void {
    this.first = this.first - this.pageSize;
  }

  public reset(): void {
    this.first = 0;
  }

  public isLastPage(): boolean {
    return this.jobRolesResult?.count ? this.first === this.jobRolesResult.count - this.pageSize : true;
  }

  public isFirstPage(): boolean {
    return this.jobRolesResult?.count ? this.first === 0 : true;
  }

  public translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.filteredJobRoles.length
    } entries`;
  }

  public searchTextChanged(): void {
    this.searchText$.next(this.selectedSearchText);
  }
}
