import { BaseModel } from './base.model';
import { Festival } from './festival.model';

export class Jury extends BaseModel {
  public company?: string;
  public position?: string;
  public festival: Festival;

  constructor(data: Jury) {
    super(data);

    ({ company: this.company, position: this.position } = data);
    this.festival = new Festival(data.festival);
  }
}

export class PersonJuryCommand {
  public company?: string;
  public position?: string;
  public festivalId?: number;

  constructor(company?: string, position?: string, festivalId?: number) {
    this.company = company;
    this.position = position;
    this.festivalId = festivalId;
  }
}

export class CreatePersonJuryCommand extends PersonJuryCommand {
  constructor(data: CreatePersonJuryCommand) {
    super(data.company, data.position, data.festivalId);
  }
}

export class UpdatePersonJuryCommand extends PersonJuryCommand {
  public id: number;

  constructor(data: Jury) {
    super(data.company, data.position, data.festival.id);
    this.id = data.id;
  }
}

export class DeletePersonJuryCommand {
  public id: number;

  constructor(data: DeletePersonJuryCommand) {
    this.id = data.id;
  }
}
