<div class="grid grid-nogutter mt-4">
  <div class="col-12">
    <p-table
      #dt
      id="scopen-offices-table"
      [value]="filteredProfessionals"
      [resizableColumns]="false"
      [scrollable]="false"
      selectionMode="single"
      [style]="{ width: '100%' }"
      [loading]="isLoading"
    >
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
          <p-selectButton
            [options]="enabledOptions"
            [(ngModel)]="selectedEnabledOptionValue"
            (onChange)="selectedEnabledOptionValueChanged()"
            optionLabel="label"
            optionValue="value"
            [multiple]="false"
          ></p-selectButton>
          <span class="p-float-label">
            <input
              id="float-input-professionals"
              type="text"
              pInputText
              [(ngModel)]="selectedSearchText"
              (ngModelChange)="searchTextChanged()"
              class="mr-2"
            />
            <label for="float-input-professionals">Search</label>
            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-file-excel"
              (click)="exportExcel()"
              class="p-button-success mr-2"
              pTooltip="Export to Excel"
              tooltipPosition="bottom"
              label="Excel"
            ></button>
          </span>
        </div>
      </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th i18n class="capitalize">current</th>
          <th i18n class="capitalize">title</th>
          <th pSortableColumn="person.firstName" i18n class="capitalize firstName">
            first name <p-sortIcon field="person.firstName"></p-sortIcon>
          </th>
          <th pSortableColumn="person.lastName" i18n class="capitalize lastName">
            last name <p-sortIcon field="person.lastName"></p-sortIcon>
          </th>
          <th pSortableColumn="positionName" i18n class="capitalize">
            position name <p-sortIcon field="positionName"></p-sortIcon>
          </th>
          <th pSortableColumn="role.name" i18n class="capitalize role">
            role <p-sortIcon field="role.name"></p-sortIcon>
          </th>
          <th i18n class="capitalize">phone</th>
          <th i18n class="capitalize">mobile phone</th>
          <th pSortableColumn="email" i18n class="capitalize">email <p-sortIcon field="email"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-professional>
        <tr
          [className]="professional.role.id === 2 ? 'role-director' : ''"
          [pSelectableRow]="professional"
          (click)="navigateToPerson(professional)"
        >
          <td class="text-center">
            <i
              [ngClass]="{
                pi: true,
                'pi-check': professional.isActive,
                'pi-times': !professional.isActive
              }"
            ></i>
          </td>
          <td>
            {{ professional.person?.title?.name || '-' }}
          </td>
          <td>
            {{ professional?.person?.firstName || '-' }}
          </td>
          <td>
            {{ professional?.person?.lastName || '-' }}
          </td>
          <td>
            {{ professional.positionName || '-' }}
          </td>
          <td>
            {{ professional.role?.name || '-' }}
          </td>
          <td class="office-phone">
            <span class="flex flex-row align-items-center justify-content-between">
              <span> {{ professional.parsedPhone() || '-' }}</span>
              <p-button
                *ngIf="professional.parsedPhone()"
                icon="pi pi-copy"
                styleClass="p-button p-button-outlined p-button-text p-button-warning"
                (click)="copyLinkToClipboard($event, professional.parsedPhone())"
              ></p-button>
            </span>
          </td>
          <td class="office-phone">
            <span class="flex flex-row align-items-center justify-content-between">
              <span> {{ professional.parsedMobilePhone() || '-' }}</span>
              <p-button
                *ngIf="professional.parsedMobilePhone()"
                icon="pi pi-copy"
                styleClass="p-button p-button-outlined p-button-text p-button-warning"
                (click)="copyLinkToClipboard($event, professional.parsedMobilePhone())"
              ></p-button>
            </span>
          </td>
          <td>
            <span class="flex flex-row align-items-center justify-content-between">
              <span> {{ professional?.email }} </span>
              <p-button
                *ngIf="professional.email"
                icon="pi pi-copy"
                styleClass="p-button p-button-outlined p-button-text p-button-warning"
                (click)="copyLinkToClipboard($event, professional.email)"
              ></p-button>
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-toast></p-toast>
</div>
