import { IProfessionalsToExcel } from '../shared/interfaces';
import { LocatorService } from '../shared/services';
import { UtilService } from '../shared/services/util.service';
import { ISOCountryCode3Letters } from '../shared/types';
import { BaseModel } from './base.model';
import { Country, Province } from './country.model';
import { Office } from './office.model';
import { Person } from './person.model';
import { Role } from './role.model';

export class Gender extends BaseModel {
  public name: string;

  constructor(data: Gender) {
    super(data);

    this.name = data.name;
  }
}

export class Address {
  public addressLine: string;

  public postalCode: string;

  public town: string;

  public province?: Province;

  public country?: Country;

  constructor(data: Address) {
    ({ addressLine: this.addressLine, postalCode: this.postalCode, town: this.town } = data);

    if (data.province) {
      this.province = new Province(data.province);
    }

    if (data.country) {
      this.country = new Country(data.country);
    }
  }
}

export class JobPosition extends BaseModel {
  public name: string;

  constructor(data: JobPosition) {
    super(data);

    this.name = data.name;
  }
}

export class Professional extends BaseModel {
  public positionName: string;

  public person?: Person;

  public email: string;

  public phone: string;

  public mobilePhone: string;

  public startDate: Date;

  public endDate: Date;

  public isActive: boolean;

  public isActiveUpdateDate: Date;

  public role?: Role;

  public office?: Office;

  public lastReview: Date;

  private utilService: UtilService;

  constructor(data: Professional) {
    super(data);

    ({
      positionName: this.positionName,
      email: this.email,
      phone: this.phone,
      mobilePhone: this.mobilePhone,
      startDate: this.startDate,
      endDate: this.endDate,
      isActive: this.isActive,
      isActiveUpdateDate: this.isActiveUpdateDate,
      lastReview: this.lastReview,
      mobilePhone: this.mobilePhone,
      phone: this.phone,
      positionName: this.positionName,
      startDate: this.startDate,
      email: this.email,
    } = data);

    if (data.person) {
      this.person = new Person(data.person);
    }

    if (data.role) {
      this.role = new Role(data.role);
    }

    if (data.office) {
      this.office = new Office(data.office);
    }

    this.utilService = LocatorService.injector.get(UtilService);
  }

  public parsedPhone(countryCode?: ISOCountryCode3Letters): string {
    return this.person?.parsedPhone(countryCode) || this.utilService.phoneNumberToIntlFormat(this.phone, countryCode);
  }

  public parsedMobilePhone(countryCode?: ISOCountryCode3Letters): string {
    return (
      this.person?.parsedMobilePhone(countryCode) ||
      this.utilService.phoneNumberToIntlFormat(this.mobilePhone, countryCode)
    );
  }

  public professionalForExcel(office?: Office): IProfessionalsToExcel {
    return {
      current: this.isActive ? '✓' : '✕',
      title: this.person?.title?.name,
      name: this.person?.firstName,
      surname: this.person?.lastName,
      position: this.positionName,
      positionType: this.role?.name,
      directPhone: this.phone,
      mobile: this.mobilePhone,
      email: this.email,
      address: office?.address?.addressLine || this.office?.address?.addressLine,
      town: office?.address?.town || this.office?.address?.town,
      province: office?.address?.province?.name || this.office?.address?.province?.name,
      country:
        office?.address?.country?.isoCode ||
        office?.country?.isoCode ||
        this.office?.address?.country?.isoCode ||
        this.office?.country?.isoCode,
    };
  }
}

export class ProfessionalCommand {
  public positionName: string;
  public email?: string;
  public phone?: string;
  public mobilePhone?: string;
  public startDate?: Date;
  public endDate?: Date;
  public isActive: boolean;
  public isActiveUpdateDate?: Date;
  public personId: number;
  public roleId: number;
  public officeId: number;

  constructor(data: ProfessionalCommand) {
    ({
      endDate: this.endDate,
      isActive: this.isActive,
      isActiveUpdateDate: this.isActiveUpdateDate,
      mobilePhone: this.mobilePhone,
      phone: this.phone,
      positionName: this.positionName,
      startDate: this.startDate,
      email: this.email,
      personId: this.personId,
      roleId: this.roleId,
      officeId: this.officeId,
    } = data);
  }
}

export class CreateProfessionalCommand extends ProfessionalCommand {
  constructor(data: CreateProfessionalCommand) {
    super(data);
  }
}

export class UpdateProfessionalCommand extends ProfessionalCommand {
  public id: number;

  constructor(data: UpdateProfessionalCommand) {
    super(data);
    this.id = data.id;
  }
}

export class DeleteProfessionalCommand {
  public id: number;

  constructor(data: DeleteProfessionalCommand) {
    this.id = data.id;
  }
}
