import { Component, Input } from '@angular/core';
import { Company } from '../../../models';

@Component({
  selector: 'app-company-summary',
  templateUrl: './company-summary.component.html',
  styleUrls: ['./company-summary.component.scss'],
})
export class CompanySummaryComponent {
  @Input() public company: Company | undefined = undefined;
}
