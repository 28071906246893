import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { ISearchQuery } from 'src/app/shared/interfaces';
import { environment } from 'src/environments/environment';
import { Country, CreateProvinceCommand, Province, UpdateProvinceCommand } from '../../../../models';
import { CountryService } from '../../../../shared/services/country.service';
import { CountriesProvincesFormComponent } from '../countries-provinces-form/countries-provinces-form.component';

@Component({
  selector: 'app-countries-provinces-table',
  templateUrl: './countries-provinces-table.component.html',
  styleUrls: ['./countries-provinces-table.component.scss'],
})
export class CountriesProvincesTableComponent implements OnInit, OnChanges {
  @Input() countries: Country[] = [];

  public country?: Country;

  public provinces: Province[] = [];

  public filteredProvinces: Province[] = [];

  public first = 0;

  public page = 1;

  public pageSize = 10;

  public availablePageSizes = environment.availablePageSizes;

  public provincesResult?: ISearchQuery<Province[]>;

  public selectedSearchText = '';

  public searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private countryService: CountryService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.searchText$.subscribe(() => {
      this.first = 0;
      if (this.country) {
        this.filteredProvinces = this.country.provinces.filter((province) =>
          province.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
        );
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.country &&
      changes['countries']?.currentValue?.length !== changes['countries']?.previousValue?.length &&
      !changes['countries']?.currentValue?.includes(this.country)
    ) {
      this.country = undefined;
      this.provinces = [];
      this.filteredProvinces = [...this.provinces];
    } else if (this.country) {
      // TODO: update the above line
      const found = this.countries.find((country) => country.id === this.country?.id);

      if (found) {
        this.country.name = found.name;
        this.country.flagUrl = found.flagUrl;
        this.country.isoCode = found.isoCode;
      }
    }
  }

  public setProvinces(): void {
    if (this.country) {
      this.filteredProvinces = this.country.provinces;
      this.first = 0;
    }
  }

  public createClicked(): void {
    const ref = this.dialogService.open(CountriesProvincesFormComponent, {
      header: $localize`Add new Province`,
      width: '30%',
      height: 'auto',
      data: {
        country: this.country,
      },
    });

    ref.onClose.subscribe((command: CreateProvinceCommand) => {
      if (command && this.country) {
        this.countryService.createProvince(this.country.id, command).subscribe({
          next: (province) => {
            this.refreshProvincesCollections(province);
            this.messageService.add({
              severity: 'success',
              summary: 'Created',
              detail: $localize`You have added a new province`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while creating the province`,
            });
          },
        });
      }
    });
  }

  public editClicked($event: MouseEvent, province: Province): void {
    $event.preventDefault();
    $event.stopPropagation();

    const ref = this.dialogService.open(CountriesProvincesFormComponent, {
      header: $localize`Edit Province`,
      width: '30%',
      height: 'auto',
      data: {
        province,
        country: this.country,
      },
    });

    ref.onClose.subscribe((command: UpdateProvinceCommand) => {
      if (command && this.country) {
        this.countryService.updateProvince(this.country.id, command.id, command).subscribe({
          next: (updatedProvince) => {
            this.refreshProvincesCollections(updatedProvince);
            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: $localize`You have updated the province`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while updating the province`,
            });
          },
        });
      }
    });
  }

  public confirmDelete($event: MouseEvent, province: Province): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: $localize`Are you sure that you want to delete this province?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.country) {
          this.countryService.removeProvince(this.country.id, province.id).subscribe({
            next: () => {
              this.refreshProvincesCollections(province);
              this.messageService.add({
                severity: 'success',
                summary: 'Deleted',
                detail: $localize`You have deleted the province`,
              });
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: $localize`An error occurred while deleting the province`,
              });
            },
          });
        }
      },
      reject: () => undefined,
    });
  }

  public next(): void {
    this.first = this.first + this.pageSize;
  }

  public prev(): void {
    this.first = this.first - this.pageSize;
  }

  public reset(): void {
    this.first = 0;
  }

  public isLastPage(): boolean {
    return this.provincesResult?.count ? this.first === this.provincesResult.count - this.pageSize : true;
  }

  public isFirstPage(): boolean {
    return this.provincesResult?.count ? this.first === 0 : true;
  }

  public translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.country?.provinces.length
    } entries`;
  }

  public searchTextChanged(): void {
    this.searchText$.next(this.selectedSearchText);
  }

  private refreshProvincesCollections(province: Province): void {
    this.country = this.countryService.getCountryFromCollection(province.countryId);

    if (this.country) {
      this.filteredProvinces = [...this.country.provinces];
    }
  }
}
