import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Brand, CreateBrandCommand } from '../../../../models/brand.model';

@Component({
  selector: 'app-brands-form',
  templateUrl: './brands-form.component.html',
  styleUrls: ['./brands-form.component.scss'],
})
export class BrandsFormComponent implements OnInit {
  public brand: Brand | undefined = undefined;

  public mode = 'create';

  public addBrandEnabled = false;

  public currentName = '';

  public command: CreateBrandCommand = new CreateBrandCommand({
    name: '',
  });

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data) {
      this.mode = 'edit';
      this.fillData();
    } else {
      this.mode = 'create';
    }
  }

  private fillData() {
    this.brand = this.config.data.brand;
    if (this.brand != undefined) {
      this.currentName = this.brand.name;
      this.command.name = this.brand.name;
    }
  }

  createClicked() {
    if (this.addBrandEnabled) {
      this.ref.close(this.command);
    }
  }

  cancelClicked() {
    this.ref.close();
  }

  nameChanged() {
    this.checkIfAddBrandEnabled();
  }

  checkIfAddBrandEnabled() {
    if (this.mode === 'edit') {
      this.addBrandEnabled =
        this.command.name !== undefined &&
        this.command.name.trim().length !== 0 &&
        this.command.name.trim() !== this.currentName.trim();
    } else {
      this.addBrandEnabled = this.command.name !== undefined && this.command.name.trim().length !== 0;
    }
  }
}
