export const AppPermissions = {
  DB_VIEWER: 'db-viewer',
  DB_ADMIN: 'db-admin',
  DB_EDITOR: 'db-editor',
  DB_EDIT_OFFICES: 'db-edit-offices',
  DB_EDIT_PEOPLE: 'db-edit-people',
  DB_EDIT_PROFESSIONALS: 'db-edit-professionals',
  DB_EDIT_BRANDS: 'db-edit-brands',
  DB_EDIT_PROJECTS: 'db-edit-projects',
  DB_EDIT_EVENTS: 'db-edit-events',
  DB_EDIT_FESTIVALS: 'db-edit-festivals',
  DB_EDIT_FILTERS: 'db-edit-filters',
  DB_EDIT_COUNTRIES: 'db-edit-countries',
  DB_EDIT_USERS: 'db-edit-users',
  DB_EDIT_JOBROLES: 'db-edit-jobroles',
  DB_EDIT_AGENCY_SERVICES: 'db-edit-agency-services',
  DB_EDIT_AGENCY_TYPES: 'db-edit-agency-types',
  DB_EDIT_SECTORS: 'db-edit-sectors',
  DB_EDIT_MEDIA_TYPES: 'db-edit-media-types',
  DB_EDIT_WAVES: 'db-edit-waves',
  DB_DELETE_PEOPLE: 'db-delete-people',
  DB_EDIT_TITLES: 'db-edit-titles',
  DB_DELETE_OFFICES: 'db-delete-offices',
  DB_VIEW_EXCEL_QUERIES: 'db-view-excel-queries',
} as const;
