import { Component, Input, OnInit } from '@angular/core';
import { MediaSupport, MediaSupportCommand, Office } from '../../../../models';
import { ApiService, ToastService } from '../../../../shared/services';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { MediaSupportsFormComponent } from '../media-supports-form/media-supports-form.component';
import { ConfirmationService } from 'primeng/api';
import { BaseComponent } from '../../../general/base/base.component';

@Component({
  selector: 'app-media-supports',
  templateUrl: './media-supports.component.html',
  styleUrls: ['./media-supports.component.scss'],
})
export class MediaSupportsComponent extends BaseComponent implements OnInit {
  @Input() public office: Office | undefined = undefined;
  public isLoading = true;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
  ) {
    super();
  }

  createClicked() {
    const office = Object.create(this.office || {});
    if (office.media) {
      office.media = Object.create(office.media || {});
      if (office.media.supports) {
        office.media.supports = Object.create(office.media.supports || {});
      }
    }

    const ref = this.dialogService.open(MediaSupportsFormComponent, {
      header: `Add new media support`,
      width: '50em',
      height: 'auto',
      data: {
        office: office,
      },
    });

    ref.onClose.subscribe((command: MediaSupportCommand) => {
      if (command) {
        if (this.office) {
          this.apiService.medias.createSupport(this.office.id, command).subscribe({
            next: (support) => {
              this.office?.media?.addSupport(support);
              this.toastService.send({
                severity: 'success',
                summary: 'Created',
                detail: 'You have added a new media support for this media',
              });
            },
            error: () => {
              this.toastService.send({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while create the media support',
              });
            },
          });
        }
      }
    });
  }

  editClicked($event: MouseEvent, support: MediaSupport) {
    const office = Object.create(this.office || {});
    if (office.media) {
      office.media = Object.create(office.media || {});
      if (office.media.supports) {
        office.media.supports = Object.create(office.media.supports || {});
      }
    }

    const ref = this.dialogService.open(MediaSupportsFormComponent, {
      header: `Edit media support`,
      width: '50em',
      height: 'auto',
      data: {
        office: office,
        support: support,
      },
    });

    ref.onClose.subscribe((command: MediaSupportCommand) => {
      if (command) {
        if (this.office) {
          this.apiService.medias.updateSupport(this.office.id, support.id, command).subscribe({
            next: (support) => {
              this.office?.media?.updateSupport(support);
              this.toastService.send({
                severity: 'success',
                summary: 'Updated',
                detail: 'You have updated the media support for this media',
              });
            },
            error: () => {
              this.toastService.send({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while updating the media support',
              });
            },
          });
        }
      }
    });
  }

  confirmDelete($event: MouseEvent, support: MediaSupport) {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: 'Are you sure that you want to delete this media support?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.office) {
          this.apiService.medias.deleteSupport(this.office.id, support.id).subscribe({
            next: () => {
              this.office?.media?.removeSupport(support);
              this.toastService.send({
                severity: 'success',
                summary: 'Deleted',
                detail: 'You have deleted the media support for this media',
              });
            },
            error: () => {
              this.toastService.send({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while deleting the media support',
              });
            },
          });
        }
      },
      reject: () => undefined,
    });
  }

  ngOnInit(): void {
    if (this.office && this.office.media) {
      this.isLoading = true;
      this.apiService.medias.getSupports(this.office.media.id).subscribe(
        (supports) => {
          if (this.office && this.office.media) {
            this.office.media.supports = supports;
          }
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
          this.toastService.send({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error getting the supports for this media',
          });
        },
      );
    }
  }
}
