import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Company, Person } from '../../../models';
import {
  IOfficesFilter,
  IProfessionalsApiFilters,
  IProfessionalsFilter,
  ISearchQuery,
} from '../../../shared/interfaces';
import { ApiService, ToastService } from '../../../shared/services';
import { BaseComponent } from '../../general/base/base.component';
import { Subscription } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-professionals-unassigned',
  templateUrl: './professionals-unassigned.component.html',
  styleUrls: ['./professionals-unassigned.component.scss'],
})
export class ProfessionalsUnassignedComponent extends BaseComponent implements OnInit {
  @Input() public filters: IProfessionalsFilter | undefined = undefined;
  public first = 0;
  public totalRecords = 0;
  public page = 1;
  public pageSize = environment.defaultPageSize;
  public availablePageSizes = environment.availablePageSizes;
  public peopleResult?: ISearchQuery<Person[]>;
  public people: Person[] = [];
  public selectedPeople: Person[] = [];
  public isBusy = true;
  private tableFilters: LazyLoadEvent = {};
  private subscriptions: Subscription = new Subscription();

  constructor(private router: Router, private apiService: ApiService, private toastService: ToastService) {
    super();
  }

  goToProfessionalDetailsPage(person: Person) {
    this.router.navigate(['professionals', 'people', person?.id || 0]);
  }

  ngOnInit(): void {
    /*this.apiService.people.getUnassigned(this.globalCountryId).subscribe({
      next: (unassigned) => {
        this.unassigned = unassigned;
        this.filteredUnassigned = unassigned;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We could not get unassigned people from the API`,
        });
      },
    });*/
  }

  /*public updateFilters(filters: IProfessionalsApiFilters) {
    this.filters = filters;
    this.filteredUnassigned = this.unassigned;
    if (this.filters) {
      if (this.filters.firstName) {
        const firstNameFilter = this.filters.firstName.toLowerCase();
        this.filteredUnassigned = this.filteredUnassigned.filter((x) =>
          x.firstName?.toLowerCase().includes(firstNameFilter),
        );
      }

      if (this.filters.lastName) {
        const lastNameFilter = this.filters.lastName.toLowerCase();
        this.filteredUnassigned = this.filteredUnassigned.filter((x) =>
          x.lastName?.toLowerCase().includes(lastNameFilter),
        );
      }

      if (this.filters.countryIds && this.filters.countryIds.length > 0) {
        this.filteredUnassigned = this.filteredUnassigned.filter((x) =>
          this.filters?.countryIds?.includes(x.country?.id || 0),
        );
      }
    }
  }*/

  public addPerson(person: Person) {
    this.people.unshift(person);
    this.selectedPeople.unshift(person);
  }

  next() {
    this.first = this.first + this.pageSize;
  }

  prev() {
    this.first = this.first - this.pageSize;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.peopleResult?.count ? this.first === this.peopleResult.count - this.pageSize : true;
  }

  isFirstPage(): boolean {
    return this.peopleResult?.count ? this.first === 0 : true;
  }

  onLazyLoad(event: LazyLoadEvent) {
    this.isBusy = true;
    this.tableFilters = event;
    this.buildPeopleTableData(event);
  }

  async load(filters: IProfessionalsFilter): Promise<void> {
    this.filters = filters;
    this.buildPeopleTableData({ ...this.tableFilters, sortField: undefined, sortOrder: undefined });
  }

  loadApiFilters(filters: IProfessionalsApiFilters): void {
    this.filters = filters;
    this.buildPeopleTableData({ ...this.tableFilters, sortField: undefined, sortOrder: undefined });
  }

  translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${this.totalRecords} entries`;
  }

  private buildPeopleTableData(filters: LazyLoadEvent, apiFilters?: IProfessionalsApiFilters): void {
    if (!this.isBusy) {
      this.isBusy = true;
    }

    if (!apiFilters) {
      apiFilters = this.buildApiFilters(filters);
    }
    console.log('unassigned', apiFilters);
    const subs = this.apiService.people.searchUnassigned(apiFilters).subscribe({
      next: (data) => {
        this.totalRecords = data.count;
        this.people = data.items;
        this.isBusy = false;
      },
      error: () => {
        this.isBusy = false;
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We could not get unassigned people from the API`,
        });
      },
    });

    this.subscriptions.add(subs);
  }

  private buildApiFilters(filters: LazyLoadEvent): IProfessionalsApiFilters {
    const { first = this.first, rows = this.pageSize, sortField, sortOrder } = filters;
    const page = rows ? (first + rows) / rows : 0;
    let apiFilters: IProfessionalsApiFilters = {
      page,
      pageSize: rows,
    };

    if (sortField && sortOrder) {
      apiFilters = {
        ...apiFilters,
        orderBy: { field: sortField, order: sortOrder === 1 ? 'asc' : 'desc' },
      };
    }

    if (this.filters) {
      const {
        firstName,
        locations,
        email,
        lastName,
        hasEmail,
        officeTypes,
        officeTypesOperation,
        onlyCurrentProfessionals,
        filterIds,
        filtersOperation,
        waveIds,
        wavesOperation,
        observations,
        officeId,
        companyId,
        networkId,
        holdingId,
      } = this.filters;

      if (firstName) {
        apiFilters = {
          ...apiFilters,
          firstName,
        };
      }

      if (locations) {
        apiFilters = {
          ...apiFilters,
          countryIds: locations.map((value) => value.countryId),
        };
      }

      if (email) {
        apiFilters = {
          ...apiFilters,
          email,
        };
      }

      if (lastName) {
        apiFilters = {
          ...apiFilters,
          lastName,
        };
      }

      if (hasEmail != null) {
        apiFilters = {
          ...apiFilters,
          hasEmail,
        };
      }

      if (officeTypes) {
        apiFilters = {
          ...apiFilters,
          officeTypes,
        };
      }

      if (officeTypesOperation) {
        apiFilters = {
          ...apiFilters,
          officeTypesOperation,
        };
      }

      if (onlyCurrentProfessionals != null) {
        apiFilters = {
          ...apiFilters,
          onlyCurrentProfessionals,
        };
      }

      if (filterIds) {
        apiFilters = {
          ...apiFilters,
          filterIds,
        };
      }

      if (filtersOperation) {
        apiFilters = {
          ...apiFilters,
          filtersOperation,
        };
      }

      if (waveIds) {
        apiFilters = {
          ...apiFilters,
          waveIds,
        };
      }

      if (wavesOperation) {
        apiFilters = {
          ...apiFilters,
          wavesOperation,
        };
      }

      if (observations) {
        apiFilters = {
          ...apiFilters,
          observations,
        };
      }

      if (officeId) {
        apiFilters = {
          ...apiFilters,
          officeId,
        };
      }

      if (companyId) {
        apiFilters = {
          ...apiFilters,
          companyId,
        };
      }

      if (networkId) {
        apiFilters = {
          ...apiFilters,
          networkId,
        };
      }

      if (holdingId) {
        apiFilters = {
          ...apiFilters,
          holdingId,
        };
      }
    }
    return apiFilters;
  }
}
