import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ToastService } from '../../../shared/services';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  providers: [MessageService],
})
export class ToastComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  constructor(private messageService: MessageService, private toastService: ToastService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.toastService.messages.subscribe((data) => {
        if (Array.isArray(data)) {
          this.messageService.addAll(data);
        } else {
          this.messageService.add(data);
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
