<div class="p-4 py-8 w-10 mx-auto my-0">
  <div class="flex align-items-start justify-content-between">
    <h3 i18n>Provinces</h3>
    <button
      pButton
      *ngIf="country"
      label="Add Province"
      type="button"
      class="p-button-raised p-button-warning"
      icon="pi pi-plus-circle"
      style="margin-bottom: 10px"
      (click)="createClicked()"
      [disabled]="!country"
    ></button>
  </div>
  <p-table
    #dt
    class="provinces-table"
    styleClass="p-datatable-sm"
    [value]="filteredProvinces"
    [paginator]="country ? true : false"
    [(rows)]="pageSize"
    [(first)]="first"
    [showCurrentPageReport]="true"
    [currentPageReportTemplate]="translateCurrentPageReportTemplate()"
    [resizableColumns]="true"
    [rowsPerPageOptions]="availablePageSizes"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between py-3">
        <span class="p-float-label w-3">
          <p-dropdown
            id="country"
            placeholder="Country"
            [options]="countries"
            [(ngModel)]="country"
            (ngModelChange)="setProvinces()"
            optionLabel="name"
            styleClass="w-full"
            appendTo="body"
          ></p-dropdown>
          <label for="country" i18n>Country</label>
        </span>
        <div class="pt-3 pr-8 mr-8">
          <span *ngIf="!country" class="pr-8 mr-8" i18n>To add, modify or remove provinces, select a country</span>
        </div>
        <span [hidden]="!country" class="p-float-label">
          <input
            [hidden]="!country"
            id="float-input"
            type="text"
            pInputText
            [disabled]="!country"
            [(ngModel)]="selectedSearchText"
            (ngModelChange)="searchTextChanged()"
          />
          <label [hidden]="!country" for="float-input" i18n>Search by Name</label>
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngIf="country" class="current" i18n>Name</th>
        <th *ngIf="country" class="current" i18n>Iso Code</th>
        <th *ngIf="country" class="current" i18n>Region Code</th>
        <th *ngIf="country" class="current text-right pr-6" i18n>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-province>
      <tr>
        <td>{{ province.name }}</td>
        <td>{{ province.isoCode }}</td>
        <td>{{ province.regionCode }}</td>
        <td class="actions text-right">
          <button
            pButton
            type="button"
            icon="pi pi-file-edit"
            class="p-button-outlined p-button-warning"
            (click)="editClicked($event, province)"
          ></button>
          <button
            pButton
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-warning"
            (click)="confirmDelete($event, province)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-toast></p-toast>
  <p-confirmPopup></p-confirmPopup>
</div>
