import { Component, isDevMode, OnInit } from '@angular/core';
import { AuthService, ToastService } from '../../shared/services';
import { BaseComponent } from '../../components/general/base/base.component';
import { CountryService } from '../../shared/services/country.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent implements OnInit {
  email = '';
  password = '';

  isLoading = false;

  constructor(
    private readonly authService: AuthService,
    private readonly toastService: ToastService,
    private readonly countryService: CountryService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.authService.logout();
    this.countryService.clearCollection();
    this.toastService.welcomeMessageStatus = false;
    this.clearGlobalCountry();

    if (isDevMode()) {
      this.email = 'scopenviewer@wappy.es';
      this.password = 'Wappy123!';
    }
  }

  async login(): Promise<void> {
    try {
      this.isLoading = true;
      if (!this.password || !this.email) {
        this.toastService.send({
          severity: 'warn',
          summary: $localize`Warning!`,
          detail: $localize`Email and password can not be empty`,
        });
      } else {
        await this.authService.login(this.email, this.password);
      }
    } catch (error: unknown) {
      this.toastService.send({
        severity: 'error',
        summary: $localize`Error`,
        detail: $localize`Wrong email or password, try again`,
      });
      this.isLoading = false;
    }
  }
}
