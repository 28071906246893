import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Professional } from '../../../models';
import { IProfessionalsApiFilters, IProfessionalsFilter, ISearchQuery } from '../../../shared/interfaces';
import { ApiService, ToastService } from '../../../shared/services';

@Component({
  selector: 'app-professionals-table',
  templateUrl: './professionals-table.component.html',
  styleUrls: ['./professionals-table.component.scss'],
})
export class ProfessionalsTableComponent implements OnDestroy {
  @Input() filters?: IProfessionalsFilter = {};

  public first = 0;

  public totalRecords = 0;

  public page = 1;

  public pageSize = environment.defaultPageSize;

  public availablePageSizes = environment.availablePageSizes;

  public professionalsResult?: ISearchQuery<Professional[]>;

  public professionals: Professional[] = [];

  public selectedProfessionals: Professional[] = [];

  public exportColumns: { title: string; dataKey: string }[] = [
    { dataKey: 'title', title: 'Title' },
    { dataKey: 'firstName', title: 'First Name' },
    { dataKey: 'lastName', title: 'Last Name' },
    { dataKey: 'companyName', title: 'Company' },
    { dataKey: 'positionName', title: 'Position' },
    { dataKey: 'lastReview', title: 'Last Review' },
  ];

  public isLoading = true;

  private subscriptions: Subscription = new Subscription();

  private tableFilters: LazyLoadEvent = {};

  @Output() public apiFiltersBuilt: EventEmitter<IProfessionalsApiFilters> =
    new EventEmitter<IProfessionalsApiFilters>();

  constructor(private apiService: ApiService, private toastService: ToastService, private router: Router) {}

  async load(filters: IProfessionalsFilter): Promise<void> {
    this.filters = filters;
    this.buildProfessionalsTableData({ ...this.tableFilters, sortField: undefined, sortOrder: undefined });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  next() {
    this.first = this.first + this.pageSize;
  }

  prev() {
    this.first = this.first - this.pageSize;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.professionalsResult?.count ? this.first === this.professionalsResult.count - this.pageSize : true;
  }

  isFirstPage(): boolean {
    return this.professionalsResult?.count ? this.first === 0 : true;
  }

  onLazyLoad(event: LazyLoadEvent) {
    this.isLoading = true;
    this.tableFilters = event;
    this.buildProfessionalsTableData(event);
  }

  translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${this.totalRecords} entries`;
  }

  goToProfessionalDetailsPage(data: Professional): void {
    this.router.navigate(['professionals', 'people', data?.person?.id || 0]);
  }

  private buildProfessionalsTableData(filters: LazyLoadEvent): void {
    if (!this.isLoading) {
      this.isLoading = true;
    }

    const apiFilters = this.buildApiFilters(filters);
    const subs = this.apiService.professionals.search(apiFilters).subscribe({
      next: (data) => {
        this.totalRecords = data.count;
        this.professionals = data.items;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We could not get professionals from the API`,
        });
      },
    });

    this.subscriptions.add(subs);
  }

  private buildApiFilters(filters: LazyLoadEvent): IProfessionalsApiFilters {
    const { first = this.first, rows = this.pageSize, sortField, sortOrder } = filters;
    const page = rows ? (first + rows) / rows : 0;
    let apiFilters: IProfessionalsApiFilters = {
      page,
      pageSize: rows,
    };

    if (sortField && sortOrder) {
      apiFilters = {
        ...apiFilters,
        orderBy: { field: sortField, order: sortOrder === 1 ? 'asc' : 'desc' },
      };
    }

    if (this.filters) {
      const {
        firstName,
        locations,
        email,
        lastName,
        hasEmail,
        officeTypes,
        officeTypesOperation,
        onlyCurrentProfessionals,
        filterIds,
        filtersOperation,
        waveIds,
        wavesOperation,
        festivalIds,
        festivalsOperation,
        observations,
        officeId,
        companyId,
        networkId,
        holdingId,
        positionName,
      } = this.filters;

      if (firstName) {
        apiFilters = {
          ...apiFilters,
          firstName,
        };
      }

      if (locations) {
        apiFilters = {
          ...apiFilters,
          countryIds: locations.map((value) => value.countryId),
        };
      }

      if (email) {
        apiFilters = {
          ...apiFilters,
          email,
        };
      }

      if (lastName) {
        apiFilters = {
          ...apiFilters,
          lastName,
        };
      }

      if (hasEmail != null) {
        apiFilters = {
          ...apiFilters,
          hasEmail,
        };
      }

      if (officeTypes) {
        apiFilters = {
          ...apiFilters,
          officeTypes,
        };
      }

      if (officeTypesOperation) {
        apiFilters = {
          ...apiFilters,
          officeTypesOperation,
        };
      }

      /* if (onlyCurrentProfessionals != null) {
        apiFilters = {
          ...apiFilters,
          onlyCurrentProfessionals,
        };
      }*/

      if (filterIds) {
        apiFilters = {
          ...apiFilters,
          filterIds,
        };
      }

      if (filtersOperation) {
        apiFilters = {
          ...apiFilters,
          filtersOperation,
        };
      }

      if (waveIds) {
        apiFilters = {
          ...apiFilters,
          waveIds,
        };
      }

      if (wavesOperation) {
        apiFilters = {
          ...apiFilters,
          wavesOperation,
        };
      }

      if (festivalIds) {
        apiFilters = {
          ...apiFilters,
          festivalIds: festivalIds,
        };
      }

      if (festivalsOperation) {
        apiFilters = {
          ...apiFilters,
          festivalsOperation: festivalsOperation,
        };
      }

      if (observations) {
        apiFilters = {
          ...apiFilters,
          observations,
        };
      }

      if (officeId) {
        apiFilters = {
          ...apiFilters,
          officeId,
        };
      }

      if (companyId) {
        apiFilters = {
          ...apiFilters,
          companyId,
        };
      }

      if (networkId) {
        apiFilters = {
          ...apiFilters,
          networkId,
        };
      }

      if (holdingId) {
        apiFilters = {
          ...apiFilters,
          holdingId,
        };
      }

      if (positionName) {
        apiFilters = {
          ...apiFilters,
          positionName,
        };
      }
    }

    this.apiFiltersBuilt.emit(apiFilters);
    return apiFilters;
  }
}
