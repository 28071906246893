import { BaseModel } from './base.model';

export class OfficeSubType extends BaseModel {
  public order: number;

  public name: string;

  constructor(data: OfficeSubType) {
    super(data);

    ({ id: this.id, name: this.name, order: this.order } = data);
  }
}

class OfficeSubTypeCommand implements Omit<OfficeSubType, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt'> {
  public name: string;

  public order: number;

  constructor(data: OfficeSubTypeCommand) {
    ({ name: this.name, order: this.order } = data);
  }
}

export class CreateOfficeSubTypeCommand extends OfficeSubTypeCommand {
  constructor(data: CreateOfficeSubTypeCommand) {
    super(data);
  }
}

export class UpdateOfficeSubTypeCommand extends OfficeSubTypeCommand {
  public id: number;

  constructor(data: UpdateOfficeSubTypeCommand) {
    super(data);
    this.id = data.id;
  }
}
