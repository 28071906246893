import { LocatorService } from '../shared/services';
import { UtilService } from '../shared/services/util.service';
import { ISOCountryCode3Letters } from '../shared/types';
import { BaseModel } from './base.model';
import { Country } from './country.model';
import { Address } from './professional.model';
import { Title } from './title.model';

export class Person extends BaseModel {
  observations?: string;
  firstName?: string;
  lastName?: string;
  country?: Country;
  email?: string;
  phone?: string;
  mobilePhone?: string;
  isEnabled: boolean;
  title?: Title;
  lastReview?: string;
  address?: Address;

  private utilService: UtilService;

  constructor(
    data: Omit<Person, 'utilService' | 'parsedPhone' | 'parsedMobilePhone' | 'getFullName' | 'fullNameWithTitle'>,
  ) {
    super(data);

    ({
      email: this.email,
      phone: this.phone,
      mobilePhone: this.mobilePhone,
      isEnabled: this.isEnabled,
      observations: this.observations,
      lastReview: this.lastReview,
    } = data);

    //this.firstName = data.firstName?.trim()?.replaceAll('"', '');
    //this.lastName = data.lastName?.trim()?.replaceAll('"', '');
    this.firstName = data.firstName?.replaceAll('"', '');
    this.lastName = data.lastName?.replaceAll('"', '');

    if (data.country) {
      this.country = new Country(data.country);
    }

    if (data.title) {
      this.title = new Title(data.title);
    }

    this.utilService = LocatorService.injector.get(UtilService);
  }

  public parsedPhone(countryCode?: ISOCountryCode3Letters): string {
    return this.utilService.phoneNumberToIntlFormat(this.phone, countryCode || this.country?.isoCode);
  }

  public parsedMobilePhone(countryCode?: ISOCountryCode3Letters): string {
    return this.utilService.phoneNumberToIntlFormat(this.mobilePhone, countryCode || this.country?.isoCode);
  }

  get getFullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get fullNameWithTitle(): string {
    const title = this.title?.name ? `${this.title?.name} ` : '';
    return `${title} ${this.firstName} ${this.lastName}`;
  }

  public static createNewBlankPerson(): Person {
    return new Person({
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      mobilePhone: '',
      observations: '',
      isEnabled: true,
      lastReview: '',
      country: undefined,
      title: new Title({ id: 0, name: '', countryIds: [] }),
      address: new Address({ addressLine: '', province: undefined, postalCode: '', town: '' }),
    });
  }
}

export class PersonCommand {
  firstName?: string;
  lastName?: string;
  countryId?: number;
  email?: string;
  phone?: string;
  mobilePhone?: string;
  observations?: string;
  titleId?: number;
  isEnabled: boolean;

  constructor(data: PersonCommand) {
    ({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      mobilePhone: this.mobilePhone,
      observations: this.observations,
      countryId: this.countryId,
      titleId: this.titleId,
      isEnabled: this.isEnabled,
    } = data);
  }
}

export class CreatePersonCommand extends PersonCommand {
  constructor(data: PersonCommand) {
    super(data);
  }
}

export class UpdatePersonCommand extends PersonCommand {
  id: number;

  constructor(data: UpdatePersonCommand) {
    super(data);
    this.id = data.id;
  }
}
