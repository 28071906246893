import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateRoleCommand, Role } from 'src/app/models';

@Component({
  selector: 'app-job-roles-form',
  templateUrl: './job-roles-form.component.html',
  styleUrls: ['./job-roles-form.component.scss'],
})
export class JobRolesFormComponent implements OnInit {
  public jobRole: Role | undefined = undefined;

  public mode = 'create';

  public addJobRoleEnabled = false;

  public currentName = '';

  public command: CreateRoleCommand = new CreateRoleCommand({
    name: '',
  });

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data) {
      this.mode = 'edit';
      this.fillData();
    } else {
      this.mode = 'create';
    }
  }

  private fillData() {
    this.jobRole = this.config.data.jobRole;
    if (this.jobRole != undefined) {
      this.currentName = this.jobRole.name;
      this.command.name = this.jobRole.name;
    }
  }

  public createClicked(): void {
    if (this.addJobRoleEnabled) {
      this.ref.close(this.command);
    }
  }

  public cancelClicked(): void {
    this.ref.close();
  }

  public nameChanged(): void {
    this.checkIfAddJobRoleEnabled();
  }

  public checkIfAddJobRoleEnabled(): void {
    if (this.mode === 'edit') {
      this.addJobRoleEnabled =
        this.command.name !== undefined &&
        this.command.name.trim().length !== 0 &&
        this.command.name.trim() !== this.currentName.trim();
    } else {
      this.addJobRoleEnabled = this.command.name !== undefined && this.command.name.trim().length !== 0;
    }
  }
}
