import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Office } from '../../../models';
import { IOfficesApiFilters, IOfficesFilter, ISearchQuery } from '../../../shared/interfaces';
import { ApiService, ToastService } from '../../../shared/services';
import { UtilService } from '../../../shared/services/util.service';
@Component({
  selector: 'app-offices-table',
  templateUrl: './offices-table.component.html',
  styleUrls: ['./offices-table.component.scss'],
})
export class OfficesTableComponent implements OnDestroy {
  @Input() filters: IOfficesFilter | null = {};
  public first = 0;

  public totalRecords = 0;

  public page = 1;

  public pageSize = environment.defaultPageSize;

  public availablePageSizes = environment.availablePageSizes;

  public officesResult?: ISearchQuery<Office[]>;

  public offices: Office[] = [];

  public selectedProducts: Office[] = [];

  public exportColumns: { title: string; dataKey: string }[] = [
    { dataKey: 'name', title: 'Name' },
    { dataKey: 'address', title: 'Address' },
    { dataKey: 'lastReview', title: 'Last review' },
  ];

  public isLoading = true;

  private subscriptions: Subscription = new Subscription();

  private tableFilters: LazyLoadEvent = {};

  private isFirstLoad = true;

  @Output() public apiFiltersBuilt: EventEmitter<IOfficesApiFilters> = new EventEmitter<IOfficesApiFilters>();

  constructor(
    private readonly apiService: ApiService,
    private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly utilService: UtilService,
  ) {}

  load(filters: IOfficesFilter): void {
    console.log('office table - load', filters);
    this.filters = filters;
    this.buildOfficesTableData({ ...this.tableFilters, sortField: undefined, sortOrder: undefined });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  next() {
    this.first = this.first + this.pageSize;
  }

  prev() {
    this.first = this.first - this.pageSize;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.officesResult?.count ? this.first === this.officesResult.count - this.pageSize : true;
  }

  isFirstPage(): boolean {
    return this.officesResult?.count ? this.first === 0 : true;
  }

  onLazyLoad(event: LazyLoadEvent) {
    console.log('office table - onLazyLoad', event);
    if (!this.isFirstLoad) {
      this.isLoading = true;
      this.tableFilters = event;
      this.buildOfficesTableData(event);
    }
  }

  exportPdf() {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((autoTable) => {
        const doc = new jsPDF.default('p', 'cm');
        autoTable.default(doc, {
          head: [this.exportColumns.map((col) => col.title)],
          body: [this.offices.map((office) => Object.values(office))],
        });
        doc.save('offices-table.pdf');
      });
    });
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.offices);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: Buffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.utilService.saveAsExcelFile(excelBuffer, 'offices-table');
    });
  }

  translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${this.totalRecords} entries`;
  }

  goToOfficeDetailsPage(data: Office): void {
    this.router.navigate(['companies', 'office', data.id]);
  }

  private buildOfficesTableData(filters: LazyLoadEvent): void {
    if (!this.isLoading) {
      this.isLoading = true;
    }

    const apiFilters = this.buildApiFilters(filters);
    const subs = this.apiService.offices.search(apiFilters).subscribe({
      next: (data) => {
        this.totalRecords = data.count;
        this.offices = data.items;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We could not get offices from the API`,
        });
      },
    });

    this.subscriptions.add(subs);
  }

  private buildApiFilters(filters: LazyLoadEvent): IOfficesApiFilters {
    const { first = this.first, rows = this.pageSize, sortField, sortOrder } = filters;
    const page = rows ? (first + rows) / rows : 0;
    let apiFilters: IOfficesApiFilters = {
      page,
      pageSize: rows,
    };

    if (sortField && sortOrder) {
      apiFilters = {
        ...apiFilters,
        orderBy: { field: sortField, order: sortOrder === 1 ? 'asc' : 'desc' },
      };
    }

    if (this.filters) {
      const {
        name,
        locations,
        officeTypes,
        officeTypesOperation,
        enabled,
        onlyHeadquarters,
        sectors,
        brands,
        observations,
        companyId,
        networkId,
        holdingId,
      } = this.filters;

      if (name) {
        apiFilters = {
          ...apiFilters,
          name,
        };
      }

      if (locations) {
        apiFilters = {
          ...apiFilters,
          locations,
        };
      }

      if (officeTypes) {
        apiFilters = {
          ...apiFilters,
          officeTypes,
        };
      }

      if (sectors) {
        apiFilters = {
          ...apiFilters,
          sectors,
        };
      }

      if (brands) {
        apiFilters = {
          ...apiFilters,
          brands,
        };
      }

      if (officeTypesOperation) {
        apiFilters = {
          ...apiFilters,
          officeTypesOperation,
        };
      }

      if (enabled != null) {
        apiFilters = {
          ...apiFilters,
          enabled,
        };
      }

      if (onlyHeadquarters != null) {
        apiFilters = {
          ...apiFilters,
          onlyHeadquarters,
        };
      }

      if (observations) {
        apiFilters = {
          ...apiFilters,
          observations,
        };
      }

      if (companyId) {
        apiFilters = {
          ...apiFilters,
          companyId,
        };
      }

      if (networkId) {
        apiFilters = {
          ...apiFilters,
          networkId,
        };
      }

      if (holdingId) {
        apiFilters = {
          ...apiFilters,
          holdingId,
        };
      }
    }

    if (this.isFirstLoad) {
      apiFilters = {
        ...apiFilters,
        enabled: true,
      };
      this.isFirstLoad = false;
    }

    this.apiFiltersBuilt.emit(apiFilters);
    return apiFilters;
  }
}
