import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Office, UpsertAdvertiserSubSectorsCommand } from '../../../../models';
import { ApiService, ToastService } from '../../../../shared/services';
import { SubsectorTreenodeSelector } from '../advertiser-subsectors/subsector-treenode-selector';

@Component({
  selector: 'app-advertiser-subsectors-form',
  templateUrl: './advertiser-subsectors-form.component.html',
  styleUrls: ['./advertiser-subsectors-form.component.scss'],
})
export class AdvertiserSubsectorsFormComponent implements OnInit {
  public office: Office | undefined = undefined;
  public selectedSubSectors: TreeNode[] = [];
  public sectorNodes: TreeNode[] = [];
  public selector: SubsectorTreenodeSelector | undefined = undefined;

  constructor(
    private apiService: ApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public toastService: ToastService,
  ) {}

  public onSectorsChanges(value: TreeNode[]): void {
    this.selectedSubSectors = value;
  }

  ngOnInit(): void {
    if (this.config.data.office) {
      this.office = this.config.data.office;
    }

    this.apiService.sectors.find().subscribe({
      next: (sectors) => {
        this.sectorNodes = SubsectorTreenodeSelector.mapSectorsToTreeNodes(sectors);
        if (this.office && this.office.advertiser) {
          this.selector = new SubsectorTreenodeSelector(this.sectorNodes, this.office);
          this.selector.setSelectedSubsectors();
        }
      },
      error: (error: unknown) => {
        console.error(error);
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We couldn't get sectors from the API`,
        });
      },
    });
  }

  updateClicked() {
    if (this.office && this.office.advertiser) {
      const command = new UpsertAdvertiserSubSectorsCommand();
      command.subSectorIds = this.selector?.selectedSubSectors?.map((node) => node.data) as number[];
      this.ref.close(command);
    }
  }

  cancelClicked() {
    this.ref.close();
  }
}
