<div *ngIf="people">
  <p-table
    class="unassigned-table"
    #dt
    [value]="people"
    [paginator]="true"
    [(rows)]="pageSize"
    [(first)]="first"
    [showCurrentPageReport]="true"
    [currentPageReportTemplate]="translateCurrentPageReportTemplate()"
    [rowsPerPageOptions]="availablePageSizes"
    [totalRecords]="totalRecords"
    [resizableColumns]="false"
    [scrollable]="false"
    [lazy]="true"
    [loading]="isBusy"
    (onLazyLoad)="onLazyLoad($event)"
    selectionMode="multiple"
    [(selection)]="selectedPeople"
    [exportHeader]="'customExportHeader'"
  >
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between"></div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Title</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Last Review</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-person>
      <tr [pSelectableRow]="person" (click)="goToProfessionalDetailsPage(person)">
        <td>{{ person.title?.name || '-' }}</td>
        <td>{{ person.firstName }}</td>
        <td>{{ person.lastName }}</td>
        <td><i class="pi pi-calendar"></i> {{ person.lastReview | date: 'dd/MM/yy' }}</td>
      </tr>
    </ng-template>
  </p-table>

  <p-toast></p-toast>
  <p-confirmPopup></p-confirmPopup>
</div>
