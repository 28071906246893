import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../components/general/base/base.component';
import { Holding, HoldingCommand, Network, NetworkCommand } from '../../models';
import { ApiService, ToastService } from '../../shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { HoldingFormComponent } from '../../components/holdings/holding-form/holding-form.component';
import { DialogService } from 'primeng/dynamicdialog';
import { NetworkFormComponent } from '../../components/networks/network-form/network-form.component';

@Component({
  selector: 'app-network-details',
  templateUrl: './network-details.component.html',
  styleUrls: ['./network-details.component.scss'],
})
export class NetworkDetailsComponent extends BaseComponent implements OnInit {
  public network?: Network;
  public holdingDeletedConfirmed = false;

  constructor(
    private apiService: ApiService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    this.apiService.networks.getById(id).subscribe({
      next: (network) => {
        this.network = network;
        if (this.network.holdingId > 0) {
          this.apiService.holdings.getById(this.network.holdingId).subscribe({
            next: (holding) => {
              if (this.network) {
                this.network.holding = holding;
              }
            },
            error: () => {
              if (this.network) {
                this.network.holdingId = 0;
              }
            },
          });
        }
      },
      error: () => {
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We could not get networks from the API`,
        });
      },
    });
  }

  editClicked($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    const network = Object.create(this.network || {});
    const ref = this.dialogService.open(NetworkFormComponent, {
      header: `Edit network`,
      width: '600px',
      height: 'auto',
      data: {
        network: network,
        mode: 'edit',
      },
    });

    ref.onClose.subscribe((command: NetworkCommand) => {
      if (command) {
        this.apiService.networks.update(network.id, command).subscribe(
          (network) => {
            this.network = network;
            if (this.network.holdingId > 0) {
              this.apiService.holdings.getById(this.network.holdingId).subscribe({
                next: (holding) => {
                  if (this.network) {
                    this.network.holding = holding;
                  }
                },
                error: () => {
                  if (this.network) {
                    this.network.holdingId = 0;
                  }
                },
              });
            }
            this.toastService.send({
              severity: 'success',
              summary: 'Success',
              detail: 'Network updated',
            });
          },
          () => {
            this.toastService.send({
              severity: 'error',
              summary: 'Error',
              detail: 'We could not update the network',
            });
          },
        );
      }
    });
  }

  deleteClicked($event: boolean) {
    if ($event && this.network) {
      this.apiService.networks.delete(this.network.id).subscribe({
        next: () => {
          this.holdingDeletedConfirmed = true;
          this.toastService.send({
            severity: 'success',
            summary: 'Success',
            detail: 'Network deleted',
          });
        },
        error: () => {
          this.toastService.send({
            severity: 'error',
            summary: 'Error',
            detail: 'We could not delete the network',
          });
        },
      });
    }
  }

  navigateToNetworksHoldings() {
    if (this.network) {
      this.router.navigate(['/networks-holdings']);
    }
  }
}
