import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOfficesApiFilters, IPrimengCustomEvent, ISearchQuery } from '../../../shared/interfaces';
import { TreeNode } from 'primeng/api';
import { Observable } from 'rxjs';
import { ToastService } from '../../../shared/services';
import { ScrollerOptions } from 'primeng/scroller';

@Component({
  selector: 'app-autocomplete-virtualscroll-search-filters',
  templateUrl: './autocomplete-virtualscroll-search-filters.component.html',
  styleUrls: ['./autocomplete-virtualscroll-search-filters.component.scss'],
})
export class AutocompleteVirtualscrollSearchFiltersComponent<T> implements OnInit {
  public selectedItem?: T;
  public filteredResult: T[] = [];
  public itemResult: T[] = [];
  public itemPage = 1;
  public itemsPageSize = 15;
  public itemLoader = false;
  private itemQuery?: string;
  @Input() public selectedCountries: TreeNode[] = [];
  @Input() public searchFunction: (
    query: string | undefined,
    countryIds?: Array<number>,
  ) => Observable<ISearchQuery<T[]>>;
  @Input() public countryFilterFunction: (() => number[]) | undefined;
  @Output() public selectedItemChanged: EventEmitter<T> = new EventEmitter<T>();
  @Input() public itemsName = 'items';
  @Input() public placeholderLabel = 'Items';
  @Input() public displayLabel = 'Items';
  @Input() public searchLocally = false;
  constructor(private toastService: ToastService) {
    this.searchFunction = () => {
      return new Observable<ISearchQuery<T[]>>();
    };
  }

  ngOnInit(): void {}

  public filterItems(event: IPrimengCustomEvent<Event, string>): void {
    const query = event.query;
    if (this.filteredResult.length > 0 && this.searchLocally) {
      this.filteredResult = this.itemResult.filter((item) => {
        return (<any>item).name.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      this.searchItems(query, 1, true);
    }
  }

  private searchItems(query?: string, page = this.itemPage, cleanResults = false): void {
    this.itemLoader = true;
    /* const filters: IOfficesApiFilters = { page, pageSize: this.itemsPageSize, name: query };
    if (this.selectedCountries) {
      filters.locations = this.selectedCountries.map((country) => {
        return { countryId: country.data };
      });
    }*/

    if (this.searchFunction) {
      const countryIds = this.countryFilterFunction ? this.countryFilterFunction() : undefined;
      this.searchFunction(query, countryIds).subscribe({
        next: (data) => {
          if (query !== this.itemQuery || cleanResults) {
            this.itemResult = [];
            this.filteredResult = [];
          }

          this.itemResult = [...this.itemResult, ...data.items];
          this.filteredResult = this.itemResult;
          this.itemQuery = query;
          this.itemLoader = false;
        },
        error: () => {
          this.toastService.send({
            severity: 'error',
            summary: $localize`Error`,
            detail: $localize`We could not get ${this.itemsName} from the API`,
          });
        },
      });
    }
  }

  public itemsVirtualScroll: ScrollerOptions = {
    onScrollIndexChange: (event: { first: number; last: number }) => {
      if (event.last === this.itemPage * this.itemsPageSize) {
        this.searchItems(this.itemQuery, (this.itemPage += 1));
      }
    },
    loading: this.itemLoader,
  };

  public onItemChange(event: T): void {
    this.selectedItem = event;
    this.selectedItemChanged.emit(this.selectedItem);
  }
}
