<div class="form-container" *ngIf="office && office.advertiser">
  <div class="col-12 flex start">
    <app-office-summary [office]="office"></app-office-summary>
  </div>
  <div class="col-12 flex start">
    <span class="p-float-label p-input-icon-right w-full">
      <p-autoComplete
        #autoComplete
        id="brands"
        [(ngModel)]="office.advertiser.brands"
        [suggestions]="filteredBrands"
        (completeMethod)="searchBrand($event)"
        [dropdown]="true"
        field="displayName"
        [multiple]="true"
        [style]="{ width: '100%' }"
        [inputStyle]="{ width: '100%' }"
        appendTo="body"
      ></p-autoComplete>
      <label for="brands">Brands</label>
    </span>
  </div>
  <div class="col-12 py-4 px-0 flex flex-row align-items-start justify-content-start">
    <div class="col-8">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="name"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="name"
          [(ngModel)]="brandCommand.name"
          (ngModelChange)="nameChanged()"
        />
        <label for="name" i18n>Brand Name</label>
      </span>
    </div>
    <div class="col-4 flex flex-row align-items-start justify-content-start">
      <button
        pButton
        label="Create Brand"
        type="button"
        (click)="createBrand()"
        class="p-button-raised p-button-warning standard-button-within-form"
        icon="pi pi-save"
        style="margin-top: 0 !important"
        [disabled]="!isBrandCommandEnabled"
      ></button>
    </div>
  </div>

  <div class="col-12">
    <button
      pButton
      label="Update Brands"
      type="button"
      (click)="updateClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
