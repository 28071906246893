import { Component, Input, OnInit } from '@angular/core';
import { IOfficesApiFilters, IOfficesFilter, ISearchQuery } from '../../../shared/interfaces';
import { environment } from '../../../../environments/environment';
import { Company, Office } from '../../../models';
import { Subscription } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';
import { ApiService, ToastService } from '../../../shared/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss'],
})
export class CompanyListComponent implements OnInit {
  @Input() filters: IOfficesFilter | null = {};
  public first = 0;
  public totalRecords = 0;
  public page = 1;
  public pageSize = environment.defaultPageSize;
  public availablePageSizes = environment.availablePageSizes;
  public companiesResult?: ISearchQuery<Company[]>;
  public companies: Company[] = [];
  public selectedProducts: Company[] = [];

  public exportColumns: { title: string; dataKey: string }[] = [
    { dataKey: 'isEnabled', title: 'Enabled' },
    { dataKey: 'name', title: 'Name' },
    { dataKey: 'lastReview', title: 'Last review' },
  ];

  public isLoading = true;
  private subscriptions: Subscription = new Subscription();
  private tableFilters: LazyLoadEvent = {};
  private isFirstLoad = true;

  constructor(
    private readonly apiService: ApiService,
    private readonly toastService: ToastService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {}

  next() {
    this.first = this.first + this.pageSize;
  }

  prev() {
    this.first = this.first - this.pageSize;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.companiesResult?.count ? this.first === this.companiesResult.count - this.pageSize : true;
  }

  isFirstPage(): boolean {
    return this.companiesResult?.count ? this.first === 0 : true;
  }

  onLazyLoad(event: LazyLoadEvent) {
    if (!this.isFirstLoad) {
      this.isLoading = true;
      this.tableFilters = event;
      this.buildCompaniesTableData(event);
    }
  }

  translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${this.totalRecords} entries`;
  }

  goToCompanyDetailsPage(data: Office): void {
    this.router.navigate(['companies', 'company', data.id]);
  }

  private buildCompaniesTableData(filters: LazyLoadEvent): void {
    if (!this.isLoading) {
      this.isLoading = true;
    }

    const apiFilters = this.buildApiFilters(filters);
    const subs = this.apiService.companies.search(apiFilters).subscribe({
      next: (data) => {
        this.totalRecords = data.count;
        this.companies = data.items;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We could not get companies from the API`,
        });
      },
    });

    this.subscriptions.add(subs);
  }

  private buildApiFilters(filters: LazyLoadEvent): IOfficesApiFilters {
    const { first = this.first, rows = this.pageSize, sortField, sortOrder } = filters;
    const page = rows ? (first + rows) / rows : 0;
    let apiFilters: IOfficesApiFilters = {
      page,
      pageSize: rows,
    };

    if (sortField && sortOrder) {
      apiFilters = {
        ...apiFilters,
        orderBy: { field: sortField, order: sortOrder === 1 ? 'asc' : 'desc' },
      };
    }

    if (this.filters) {
      const {
        name,
        locations,
        officeTypes,
        officeTypesOperation,
        enabled,
        onlyHeadquarters,
        sectors,
        brands,
        observations,
        companyId,
        networkId,
        holdingId,
      } = this.filters;

      if (name) {
        apiFilters = {
          ...apiFilters,
          name,
        };
      }

      if (locations) {
        apiFilters = {
          ...apiFilters,
          locations,
        };
      }

      if (officeTypes) {
        apiFilters = {
          ...apiFilters,
          officeTypes,
        };
      }

      if (sectors) {
        apiFilters = {
          ...apiFilters,
          sectors,
        };
      }

      if (brands) {
        apiFilters = {
          ...apiFilters,
          brands,
        };
      }

      if (officeTypesOperation) {
        apiFilters = {
          ...apiFilters,
          officeTypesOperation,
        };
      }

      if (enabled != null) {
        apiFilters = {
          ...apiFilters,
          enabled,
        };
      }

      if (onlyHeadquarters != null) {
        apiFilters = {
          ...apiFilters,
          onlyHeadquarters,
        };
      }

      if (observations) {
        apiFilters = {
          ...apiFilters,
          observations,
        };
      }

      if (companyId) {
        apiFilters = {
          ...apiFilters,
          companyId,
        };
      }

      if (networkId) {
        apiFilters = {
          ...apiFilters,
          networkId,
        };
      }

      if (holdingId) {
        apiFilters = {
          ...apiFilters,
          holdingId,
        };
      }
    }

    if (this.isFirstLoad) {
      apiFilters = {
        ...apiFilters,
        enabled: true,
      };
      this.isFirstLoad = false;
    }

    return apiFilters;
  }

  load(filters: IOfficesFilter): void {
    this.filters = filters;
    this.buildCompaniesTableData({ ...this.tableFilters, sortField: undefined, sortOrder: undefined });
  }
}
