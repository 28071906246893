<div class="form-container" *ngIf="office">
  <div class="col-12 flex start">
    <app-office-summary [office]="office"></app-office-summary>
  </div>

  <div class="col-12 flex align-items-end justify-content-start">
    <div class="col-12 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <p-autoComplete
          id="parentOffice"
          [(ngModel)]="selectedParent"
          [suggestions]="filteredParents"
          (completeMethod)="searchParent($event)"
          [dropdown]="true"
          field="fullName"
          (ngModelChange)="parentChanged()"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          appendTo="body"
        ></p-autoComplete>
        <label for="parentOffice">Parent Office</label>
      </span>
    </div>
  </div>

  <div class="col-12">
    <button
      pButton
      label="Fusion"
      type="button"
      (click)="fusionClicked()"
      [disabled]="!fusionOfficeEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
