import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateProvinceCommand, Province, UpdateProvinceCommand } from 'src/app/models';

@Component({
  selector: 'app-countries-provinces-form',
  templateUrl: './countries-provinces-form.component.html',
  styleUrls: ['./countries-provinces-form.component.scss'],
})
export class CountriesProvincesFormComponent implements OnInit {
  public mode: 'create' | 'edit' = 'create';

  public addProvinceEnabled = false;

  public currentName = '';

  public currentIsoCode = '';

  public currentRegionCode = '';

  public command: CreateProvinceCommand | UpdateProvinceCommand = {
    name: '',
    isoCode: '',
    regionCode: '',
    countryId: this.config.data.country.id,
  };

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data?.province) {
      this.mode = 'edit';
      this.fillData(this.config.data.province);
    } else {
      this.mode = 'create';
    }
  }

  private fillData(province: Province): void {
    this.currentName = province.name;
    this.currentIsoCode = province.isoCode;
    this.currentRegionCode = province.regionCode;
    this.command = province;
  }

  public createClicked(): void {
    if (this.addProvinceEnabled) {
      this.ref.close(this.command);
    }
  }

  public cancelClicked(): void {
    this.ref.close();
  }

  public nameChanged(): void {
    this.checkIfAddProvinceEnabled();
  }

  public isoCodeChanged(): void {
    this.checkIfAddProvinceEnabled();
  }

  public regionCodeChanged(): void {
    this.checkIfAddProvinceEnabled();
  }

  public checkIfAddProvinceEnabled(): void {
    if (this.mode === 'edit') {
      this.addProvinceEnabled = !!(
        (this.command.name &&
          this.command.name.trim().length &&
          this.command.name.trim() !== this.currentName?.trim()) ||
        (this.command.isoCode &&
          this.command.isoCode.trim().length &&
          this.command.isoCode.trim() !== this.currentIsoCode?.trim()) ||
        (this.command.regionCode &&
          this.command.regionCode.trim().length &&
          this.command.regionCode.trim() !== this.currentRegionCode?.trim())
      );
    } else {
      this.addProvinceEnabled = !!(this.command.name && this.command.name?.trim().length);
    }
  }
}
