<div class="px-1">
  <button
    *ngIf="hasRole('db-edit-offices')"
    pButton
    label="Delete"
    type="button"
    (click)="deleteClicked($event)"
    class="p-button-raised p-button-danger"
    icon="pi pi-plus-circle"
    style="margin-bottom: 10px"
  ></button>
</div>
<p-confirmPopup></p-confirmPopup>
