import { Component, Input, OnInit } from '@angular/core';
import { Holding, Network } from '../../../models';
import { TreeNode } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-network-structure',
  templateUrl: './network-structure.component.html',
  styleUrls: ['./network-structure.component.scss'],
})
export class NetworkStructureComponent implements OnInit {
  @Input() network: Network | undefined = undefined;
  public networkTreeNodes: TreeNode[] = [];

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (this.network) {
      this.networkTreeNodes = this.mapNetworkToTreeNodes(this.network);
    }
  }

  public mapNetworkToTreeNodes(network: Network): TreeNode[] {
    const networkNode: TreeNode = {
      label: network.name,
      icon: 'pi pi-sitemap',
      data: { type: 'network', id: network.id },
      expanded: true,
      children: [
        {
          label: 'Offices',
          data: { type: 'label', id: 'offices' },
          icon: 'pi pi-wifi',
          children: network.offices.map((office) => {
            return {
              label: office.fullName,
              icon: 'pi pi-wifi',
              data: { type: 'office', id: office.id },
            } as TreeNode;
          }),
        },
        {
          label: 'Companies',
          data: { type: 'label', id: 'companies' },
          icon: 'pi pi-building',
          children: network.companies.map((company) => {
            return {
              label: company.name,
              icon: 'pi pi-building',
              data: { type: 'company', id: company.id },
              children: [
                {
                  label: 'Offices',
                  data: { type: 'label', id: 'offices' },
                  icon: 'pi pi-wifi',
                  children: company.offices.map((office) => {
                    return {
                      label: office.fullName,
                      icon: 'pi pi-wifi',
                      data: { type: 'office', id: office.id },
                    } as TreeNode;
                  }),
                },
              ],
            } as TreeNode;
          }),
        },
      ],
    } as TreeNode;

    return [networkNode];
  }

  selectionChanged($event: any) {
    if ($event.node.data.type === 'office') {
      this.router.navigate(['companies', 'office', $event.node.data.id]);
    } else if ($event.node.data.type === 'company') {
      this.router.navigate(['companies', 'company', $event.node.data.id]);
    } else if ($event.node.data.type === 'network') {
      this.router.navigate(['networks-holdings', 'network', $event.node.data.id]);
    } else if ($event.node.data.type === 'holding') {
      this.router.navigate(['networks-holdings', 'holding', $event.node.data.id]);
    }
  }
}
