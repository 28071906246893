<p-table
  #dt
  id="scopen-holdings-table"
  [value]="filteredHoldings"
  [loading]="isLoading"
  [scrollable]="true"
  selectionMode="single"
>
  <ng-template pTemplate="caption">
    <div class="flex flex-row align-items-center justify-content-between">
      <div class="flex align-items-center justify-content-between">
        <p-selectButton
          [options]="enabledOptions"
          [(ngModel)]="selectedEnabledOptionValue"
          (onChange)="selectedValueChanged()"
          optionLabel="label"
          optionValue="value"
          [multiple]="false"
          class="pr-4"
        ></p-selectButton>
        <p-selectButton
          [options]="bigPlayerOptions"
          [(ngModel)]="selectedBigPlayerOptionValue"
          (onChange)="selectedValueChanged()"
          optionLabel="label"
          optionValue="value"
          [multiple]="false"
        ></p-selectButton>
      </div>
      <div class="flex align-items-center justify-content-between">
        <span class="p-float-label">
          <input
            id="holdings-search"
            type="text"
            pInputText
            [(ngModel)]="selectedSearchText"
            (ngModelChange)="searchTextChanged()"
            class="mr-2"
          />
          <label for="holdings-search">Search</label>
        </span>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th i18n>Enabled</th>
      <th i18n pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
      <th i18n>Big Player</th>
      <th class="field-lastReview" i18n pSortableColumn="lastReview">
        Last review<p-sortIcon field="lastReview"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr
      [class]="!rowData?.isEnabled ? 'row-disabled' : ''"
      [pSelectableRow]="rowData"
      (click)="goToHoldingDetailsPage(rowData)"
    >
      <td class="current">
        <i *ngIf="rowData?.isEnabled" class="pi pi-check"></i>
        <i *ngIf="!rowData?.isEnabled" class="pi pi-times"></i>
      </td>
      <td>{{ rowData?.name }}</td>
      <td class="current">
        <i *ngIf="rowData?.isBigPlayer" class="pi pi-check"></i>
        <i *ngIf="!rowData?.isBigPlayer" class="pi pi-times"></i>
      </td>
      <td><i class="pi pi-calendar"></i> {{ rowData?.lastReview | date: 'dd/MM/yy' }}</td>
    </tr>
  </ng-template>
</p-table>
