import { BaseModel } from './base.model';
import { Event } from './event.model';

export class Festival extends BaseModel {
  public event?: Event;
  public year: number;

  constructor(data: Festival) {
    super(data);
    this.year = data.year;

    if (data.event) {
      this.event = new Event(data.event);
    }
  }
}

export class FestivalCommand {
  eventId?: number;
  year: number | null;

  constructor(data: Pick<Festival, 'year'> & { eventId?: number }) {
    this.year = data.year;
    this.eventId = data.eventId;
  }
}

export class UpdateFestivalCommand extends FestivalCommand {
  id: number;

  constructor(data: Pick<Festival, 'id' | 'year'> & { eventId?: number }) {
    super(data);
    this.id = data.id;
  }
}
