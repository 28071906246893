import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { Country, CreateUserCommand, UpdateUserCommand, User } from 'src/app/models';
import { ISearchQuery } from 'src/app/shared/interfaces';
import { ApiService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { CountryService } from '../../../../shared/services/country.service';
import {
  ICountriesToAssign,
  UsersCountriesFormComponent,
} from '../users-countries-form/users-countries-form.component';
import { UsersFormComponent } from '../users-form/users-form.component';
import { BaseComponent } from '../../../general/base/base.component';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent extends BaseComponent implements OnInit {
  public users: User[] = [];

  public filteredUsers: User[] = [];

  public isLoading = true;

  public first = 0;

  public page = 1;

  public pageSize = 10;

  public availablePageSizes = environment.availablePageSizes;

  public usersResult?: ISearchQuery<User[]>;

  public selectedSearchText = '';

  public searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  public roles = [{ name: 'Admin' }, { name: 'Editor' }, { name: 'Viewer' }];

  public error = false;

  public countries: Country[] = [];

  constructor(
    private apiService: ApiService,
    private readonly countryService: CountryService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.refresh();
    this.searchText$.subscribe(() => {
      this.first = 0;
      this.filteredUsers = this.users.filter((user) =>
        user.username?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  public refresh(): void {
    this.users = [];
    this.isLoading = true;
    this.apiService.users.find().subscribe((users: User[]) => {
      this.users = users;
      this.filteredUsers = [...this.users];
      this.isLoading = false;
    });
    this.countryService.find({ countryId: this.globalCountryId, includeProvinces: true }).subscribe((countries) => {
      this.countries = countries;
    });
  }

  public createClicked(): void {
    const ref = this.dialogService.open(UsersFormComponent, {
      header: $localize`Add new User`,
      width: '30%',
      height: 'auto',
      data: {
        roles: this.roles,
      },
    });

    ref.onClose.subscribe((command: CreateUserCommand) => {
      if (command) {
        this.apiService.users.create(command).subscribe({
          next: (user) => {
            this.addUser(user);
            this.filteredUsers = [...this.users];
            this.messageService.add({
              severity: 'success',
              summary: 'Created',
              detail: $localize`You have added a new user`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while creating the user`,
            });
          },
        });
      }
    });
  }

  public editClicked($event: MouseEvent, user: User): void {
    $event.preventDefault();
    $event.stopPropagation();

    const ref = this.dialogService.open(UsersFormComponent, {
      header: $localize`Edit User`,
      width: '30%',
      height: 'auto',
      data: {
        user,
        roles: this.roles,
      },
    });

    ref.onClose.subscribe((command: UpdateUserCommand) => {
      if (command) {
        this.apiService.users.update(command.id, command).subscribe({
          next: (user) => {
            this.updateUser(user);
            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: $localize`You have updated the user`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while updating the user`,
            });
          },
        });
      }
    });
  }

  public confirmDelete($event: MouseEvent, user: User): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: $localize`Are you sure that you want to delete this user?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiService.users.delete(user.id).subscribe({
          next: () => {
            this.removeUser(user);
            this.filteredUsers = [...this.users];
            this.messageService.add({
              severity: 'success',
              summary: 'Deleted',
              detail: $localize`You have deleted the user`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while deleting the user`,
            });
          },
        });
      },
      reject: () => undefined,
    });
  }

  public addOrRemoveCountries($event: MouseEvent, user: User): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.countryService.find({ countryId: this.globalCountryId, includeProvinces: true }).subscribe((countries) => {
      const ref = this.dialogService.open(UsersCountriesFormComponent, {
        header: $localize`Assign countries to the user`,
        width: '45%',
        height: 'auto',
        data: {
          countries,
          user,
        },
      });

      ref.onClose.subscribe((countries: ICountriesToAssign) => {
        if (countries.countriesForUserToDelete.length) {
          countries.countriesForUserToDelete.forEach((country) => {
            this.countryService.deleteUserCountry(country.id, user.id).subscribe({
              next: () => {
                this.removeCountriesForUser(user, country.id);
              },
              error: () => {
                this.error = true;
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: $localize`An error occurred while saving`,
                });
              },
            });
          });
        }
        if (countries.countriesForUserToAdd.length) {
          countries.countriesForUserToAdd.forEach((country) => {
            this.countryService.addUserCountry(country.id, user.id).subscribe({
              next: () => {
                this.addCountriesForUser(user, country.id);
              },
              error: () => {
                this.error = true;
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: $localize`An error occurred while saving`,
                });
              },
            });
          });
        }
        if (!this.error) {
          this.messageService.add({
            severity: 'success',
            summary: 'Saved',
            detail: $localize`has been successfully saved`,
          });
        }
      });
    });
  }

  public addUser(user: User): void {
    this.users.push(user);
  }

  public removeUser(user: User): void {
    const index = this.users.findIndex((p) => p.id === user.id);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
  }

  public updateUser(user: User): void {
    const index = this.users.findIndex((u: User) => u.id === user.id);

    if (index >= 0) {
      this.users[index] = new User({ ...user, countryIds: this.users[index].countryIds });
    }
    this.filteredUsers = [...this.users];
  }

  public removeCountriesForUser(user: User, countryId: number): void {
    const index = user.countryIds.findIndex((id) => id === countryId);

    if (index >= 0) {
      user.countryIds.splice(index, 1);
    }
  }

  public addCountriesForUser(user: User, countryId: number): void {
    user.countryIds.push(countryId);
  }

  public next(): void {
    this.first = this.first + this.pageSize;
  }

  public prev(): void {
    this.first = this.first - this.pageSize;
  }

  public reset(): void {
    this.first = 0;
  }

  public isLastPage(): boolean {
    return this.usersResult?.count ? this.first === this.usersResult.count - this.pageSize : true;
  }

  public isFirstPage(): boolean {
    return this.usersResult?.count ? this.first === 0 : true;
  }

  public translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.filteredUsers.length
    } entries`;
  }

  public searchTextChanged(): void {
    this.searchText$.next(this.selectedSearchText);
  }
}
