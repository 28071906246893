<p-splitButton
  *ngIf="!isLoading"
  #splitExcel
  label="Excel"
  icon="pi pi-file-excel"
  class="p-button-raised"
  styleClass="p-button-raised excel-button mr-2 mb-2"
  (onClick)="splitExcel.onDropdownButtonClick($event)"
  [model]="excelOptions"
  [disabled]="isLoading"
>
  <span i18n *ngIf="isLoading" class="mr-2">wait...</span>
  <i *ngIf="isLoading" class="pi pi-spin pi-spinner mr-2"></i>
</p-splitButton>

<button
  *ngIf="isLoading"
  pButton
  label="Excel"
  type="button"
  class="p-button-raised excel-button mr-2 mb-2"
  icon="pi pi-file-excel"
  [disabled]="isLoading"
>
  <span i18n class="mr-2">wait...</span>
  <i class="pi pi-spin pi-spinner mr-2"></i>
</button>
