<div class="col-12 flex flex-column start">
  <div class="col-12 flex flex-row">
    <div class="col-6 py-0 flex flex-row start">
      <app-office-info [office]="office"></app-office-info>
    </div>
    <div class="col-6 py-6 px-7 flex flex-row align-items-start justify-content-end">
      <button
        *ngIf="hasRole('db-delete-offices')"
        pButton
        label="Fusion"
        type="button"
        (click)="editClicked($event)"
        class="p-button-raised p-button-warning"
        icon="pi pi-eject"
        style="margin-bottom: 10px"
      ></button>
    </div>
  </div>
  <div class="col-12 py-0 px-6 flex flex-row start">
    <div class="col-6 flex flex-column start">
      <div class="py-4 px-0 w-full">
        <app-agency-types [office]="office"></app-agency-types>
      </div>
    </div>
    <div class="col-6 flex flex-column start">
      <div class="py-4 px-0 w-full">
        <app-agency-services [office]="office"></app-agency-services>
      </div>
    </div>
  </div>
  <div class="col-12 py-0 px-7 flex flex-row start">
    <div class="py-0 px-0 w-full" *ngIf="office">
      <app-office-tabs [office]="office"></app-office-tabs>
    </div>
  </div>
</div>
<div *ngIf="!office">
  <div class="flex">
    <div class="flex justify-content-center align-content-center align-items-center w-full h-screen">
      <h4 i18n class="secondary-text" style="font-size: 2rem">
        wait...<i class="pi pi-spin pi-spinner inline-block secondary-text" style="font-size: 2rem"></i>
      </h4>
    </div>
  </div>
</div>
