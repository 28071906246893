<div class="form-container pt-3">
  <div class="col-12 p-2 pt-5">
    <span class="p-float-label p-input-icon-right w-full">
      <input
        id="name"
        type="text"
        pInputText
        class="p-inputtext-sm w-full"
        placeholder="name"
        [(ngModel)]="command.name"
        (ngModelChange)="nameChanged()"
      />
      <label for="name" i18n>Name</label>
    </span>
  </div>
  <div class="col-12 p-2 pt-5">
    <span class="p-float-label p-input-icon-right w-full">
      <input
        id="isocode"
        type="text"
        pInputText
        class="p-inputtext-sm w-full"
        placeholder="iso code"
        [(ngModel)]="command.isoCode"
        (ngModelChange)="isoCodeChanged()"
      />
      <label for="name" i18n>Iso Code</label>
    </span>
  </div>
  <div class="col-12 p-2 pt-5">
    <span class="p-float-label p-input-icon-right w-full">
      <input
        id="flagurl"
        type="text"
        pInputText
        class="p-inputtext-sm w-full"
        placeholder="flag url"
        [(ngModel)]="command.flagUrl"
        (ngModelChange)="flagUrlChanged()"
      />
      <label for="name" i18n>Flag Url</label>
    </span>
  </div>
  <div class="col-12">
    <button
      pButton
      *ngIf="mode === 'create'"
      label="Add Country"
      type="button"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-plus-circle"
      [disabled]="!addCountryEnabled"
      (click)="createClicked()"
    ></button>

    <button
      pButton
      *ngIf="mode === 'edit'"
      label="Update Country"
      type="button"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
      [disabled]="!addCountryEnabled"
      (click)="createClicked()"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
