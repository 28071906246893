import { Component, Input } from '@angular/core';
import { ApiService, ToastService } from '../../../../shared/services';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { Office, UpsertAdvertiserBrandsCommand } from '../../../../models';
import { AdvertiserBrandsFormComponent } from '../advertiser-brands-form/advertiser-brands-form.component';
import { BaseComponent } from '../../../general/base/base.component';

@Component({
  selector: 'app-advertiser-brands',
  templateUrl: './advertiser-brands.component.html',
  styleUrls: ['./advertiser-brands.component.scss'],
})
export class AdvertiserBrandsComponent extends BaseComponent {
  @Input() public office: Office | undefined = undefined;
  public isLoading = true;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
  ) {
    super();
  }

  updateClicked() {
    const office = Object.create(this.office || {});
    office.advertiser = Object.create(office.advertiser || {});
    office.advertiser.brands = Object.create(office.advertiser.brands || {});

    const ref = this.dialogService.open(AdvertiserBrandsFormComponent, {
      header: `Edit Advertiser Brands`,
      width: '700px',
      height: 'auto',
      data: {
        office: office,
      },
    });

    ref.onClose.subscribe((command: UpsertAdvertiserBrandsCommand) => {
      if (command && this.office) {
        this.isLoading = true;
        this.apiService.advertisers.updateBrands(this.office.id, command).subscribe(
          (advertiser) => {
            if (this.office) {
              this.office.advertiser = advertiser;
            }
            this.isLoading = false;
            this.toastService.send({
              severity: 'success',
              summary: 'Updated',
              detail: 'You have updated the brands for this advertiser',
            });
          },
          () => {
            this.isLoading = false;
            this.toastService.send({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error updating the brands for this advertiser',
            });
          },
        );
      }
    });
  }
}
