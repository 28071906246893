import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss'],
})
export class DeleteButtonComponent extends BaseComponent {
  @Input() public objectName?: string | undefined;
  @Output() public onDeleteClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private confirmationService: ConfirmationService) {
    super();
  }

  deleteClicked($event: MouseEvent) {
    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: `Are you sure that you want to delete this ${this.objectName}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDeleteClicked.emit(true);
      },
      reject: () => this.onDeleteClicked.emit(false),
    });
  }
}
