import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { ISearchQuery } from 'src/app/shared/interfaces';
import { environment } from 'src/environments/environment';
import { Country, CreateCountryCommand, UpdateCountryCommand } from '../../../../models';
import { CountryService } from '../../../../shared/services/country.service';
import { CountriesFormComponent } from '../countries-form/countries-form-component';
import { BaseComponent } from '../../../general/base/base.component';

@Component({
  selector: 'app-countries-table',
  templateUrl: './countries-table.component.html',
  styleUrls: ['./countries-table.component.scss'],
})
export class CountriesTableComponent extends BaseComponent implements OnInit {
  @Input() countries: Country[] = [];

  @Output() updateCountries = new EventEmitter<Country[]>();

  public filteredCountries: Country[] = [];

  public isLoading = true;

  public first = 0;

  public page = 1;

  public pageSize = 10;

  public availablePageSizes = environment.availablePageSizes;

  public countriesResult?: ISearchQuery<Country[]>;

  selectedSearchText = '';

  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private countryService: CountryService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.refresh();
    this.searchText$.subscribe(() => {
      this.first = 0;
      this.filteredCountries = this.countries.filter((country) =>
        country.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  refresh = () => {
    this.countries = [];
    this.isLoading = true;
    this.countryService
      .find({ countryId: this.globalCountryId, includeProvinces: true })
      .subscribe((countries: Country[]) => {
        this.countries = countries;
        this.filteredCountries = [...this.countries];
        this.updateCountries.emit(this.countries);
        this.isLoading = false;
      });
  };

  public createClicked(): void {
    const ref = this.dialogService.open(CountriesFormComponent, {
      header: $localize`Add new Country`,
      width: '30%',
      height: 'auto',
    });

    ref.onClose.subscribe((command: CreateCountryCommand) => {
      if (command) {
        this.countryService.create(command).subscribe({
          next: () => {
            this.refreshCountriesCollections();
            this.messageService.add({
              severity: 'success',
              summary: 'Created',
              detail: $localize`You have added a new country`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while creating the country`,
            });
          },
        });
      }
    });
  }

  public editClicked($event: MouseEvent, editedCountry: Country): void {
    $event.preventDefault();
    $event.stopPropagation();

    const ref = this.dialogService.open(CountriesFormComponent, {
      header: $localize`Edit Country`,
      width: '30%',
      height: 'auto',
      data: {
        country: editedCountry,
      },
    });

    ref.onClose.subscribe((command: UpdateCountryCommand) => {
      if (command) {
        this.countryService.update(command.id, command).subscribe({
          next: () => {
            this.refreshCountriesCollections();
            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: $localize`You have updated the country`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while updating the country`,
            });
          },
        });
      }
    });
  }

  public confirmDelete($event: MouseEvent, country: Country): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: $localize`Are you sure that you want to delete this country?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.countryService.remove(country.id).subscribe({
          next: () => {
            this.refreshCountriesCollections();
            this.messageService.add({
              severity: 'success',
              summary: 'Deleted',
              detail: $localize`You have deleted the country`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while deleting the country`,
            });
          },
        });
      },
      reject: () => undefined,
    });
  }

  public next(): void {
    this.first = this.first + this.pageSize;
  }

  public prev(): void {
    this.first = this.first - this.pageSize;
  }

  public reset(): void {
    this.first = 0;
  }

  public isLastPage(): boolean {
    return this.countriesResult?.count ? this.first === this.countriesResult.count - this.pageSize : true;
  }

  public isFirstPage(): boolean {
    return this.countriesResult?.count ? this.first === 0 : true;
  }

  public translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.filteredCountries.length
    } entries`;
  }

  public searchTextChanged(): void {
    this.searchText$.next(this.selectedSearchText);
  }

  private refreshCountriesCollections(): void {
    this.countries = this.countryService.countries;
    this.filteredCountries = [...this.countries];
    this.updateCountries.emit(this.countries);
  }
}
