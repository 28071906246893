<p-table
  #dt
  id="scopen-professionals-table"
  [value]="professionals"
  [paginator]="true"
  [(rows)]="pageSize"
  [(first)]="first"
  [showCurrentPageReport]="true"
  [currentPageReportTemplate]="translateCurrentPageReportTemplate()"
  [rowsPerPageOptions]="availablePageSizes"
  [totalRecords]="totalRecords"
  [resizableColumns]="false"
  [scrollable]="false"
  [lazy]="true"
  [loading]="isLoading"
  (onLazyLoad)="onLazyLoad($event)"
  selectionMode="multiple"
  [(selection)]="selectedProfessionals"
  [exportHeader]="'customExportHeader'"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th i18n>Title</th>
      <th class="field-firstName" i18n pSortableColumn="firstName">
        First Name <p-sortIcon field="firstName"></p-sortIcon>
      </th>
      <th class="field-lastName" i18n pSortableColumn="lastName">
        Last Name <p-sortIcon field="lastName"></p-sortIcon>
      </th>
      <th i18n>Company</th>
      <th i18n>Position</th>
      <th class="field-lastReview" i18n pSortableColumn="lastReview">
        Last Review <p-sortIcon field="lastReview"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr [pSelectableRow]="rowData" (click)="goToProfessionalDetailsPage(rowData)">
      <td>{{ rowData?.person?.title?.name }}</td>
      <td>{{ rowData?.person?.firstName }}</td>
      <td>{{ rowData?.person?.lastName }}</td>
      <td>{{ rowData?.office?.name || '---' }}</td>
      <td>{{ rowData?.positionName || '---' }}</td>
      <td><i class="pi pi-calendar"></i> {{ rowData?.person?.lastReview | date: 'dd/MM/yy' }}</td>
    </tr>
  </ng-template>
</p-table>
