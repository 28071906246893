import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Filter, Person, UpdatePersonFiltersCommand } from '../../../models';
import { ApiService } from '../../../shared/services';
import { BaseComponent } from '../../general/base/base.component';

@Component({
  selector: 'app-person-filters-form',
  templateUrl: './person-filters-form.component.html',
  styleUrls: ['./person-filters-form.component.scss'],
})
export class PersonFiltersFormComponent extends BaseComponent implements OnInit {
  public person: Person | undefined = undefined;
  public filters: Filter[] = [];
  public selectedFilters: Filter[] = [];

  constructor(private apiService: ApiService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    super();
  }

  ngOnInit(): void {
    this.apiService.filters.find(undefined, undefined, this.globalCountryId).subscribe((filters: Filter[]) => {
      this.filters = filters;

      if (this.config.data.person) {
        this.person = this.config.data.person;
      }

      if (this.config.data.filters) {
        this.config.data.filters.forEach((filter: Filter) => {
          const index = this.filters.findIndex((w: Filter) => w.id === filter.id);
          if (index > -1) {
            this.selectedFilters.push(this.filters[index]);
          }
        });
      }
    });
  }

  updateClicked() {
    if (this.person) {
      const command = new UpdatePersonFiltersCommand(
        this.person.id,
        this.selectedFilters.map((filter: Filter) => filter.id),
      );
      this.ref.close(command);
    }
  }

  cancelClicked() {
    this.ref.close();
  }
}
