<div class="col-12 flex px-7 flex-column start">
  <div class="col-6 py-0 flex flex-row start">
    <app-company-summary [company]="company"></app-company-summary>
  </div>
  <div class="col-12 flex flex-column start" *ngIf="company">
    <h3 class="px-2">Details</h3>
    <app-title-value-field title="research ID" [value]="company.researchId?.toString() || '---'"></app-title-value-field>
    <app-title-value-field
      title="network"
      [value]="company.network.name || '---'"
      *ngIf="company.network"
    ></app-title-value-field>
    <app-title-value-field
      title="holding"
      [value]="company.holding.name || '---'"
      *ngIf="company.holding"
    ></app-title-value-field>
    <app-title-value-field
      title="observations"
      [multipleRows]="true"
      [value]="company.observations || '---'"
    ></app-title-value-field>
  </div>
  <div class="col-6 py-0 flex flex-row justify-content-start py-3">
    <div class="px-1">
      <button
        *ngIf="hasRole('db-edit-offices')"
        pButton
        label="Edit"
        type="button"
        (click)="editClicked($event)"
        class="p-button-raised p-button-warning"
        icon="pi pi-pencil"
        style="margin-bottom: 10px"
      ></button>
    </div>
    <app-delete-button [objectName]="'company'" (onDeleteClicked)="deleteClicked($event)"></app-delete-button>
    <!--<div class="px-1">
        <button
          pButton
          label="Delete"
          type="button"
          (click)="deleteClicked($event)"
          class="p-button-raised p-button-danger"
          icon="pi pi-plus-circle"
          style="margin-bottom: 10px"
        ></button>
      </div>-->
  </div>
  <div class="col-12 py-0 px-0 flex flex-row start">
    <div class="py-4 px-0 w-full" *ngIf="company">
      <app-company-tabs [company]="company"></app-company-tabs>
    </div>
  </div>
</div>
<div *ngIf="!company">
  <div class="flex">
    <div class="flex justify-content-center align-content-center align-items-center w-full h-screen">
      <h4 i18n class="secondary-text" style="font-size: 2rem">
        wait...<i class="pi pi-spin pi-spinner inline-block secondary-text" style="font-size: 2rem"></i>
      </h4>
    </div>
  </div>
</div>

<p-dialog header="Company Deleted" [(visible)]="companyDeletedConfirmed" [style]="{ width: '25vw' }" [closable]="false">
  <app-company-summary [company]="company"></app-company-summary>
  <div class="flex flex-row justify-content-end">
    <div class="px-1">
      <button
        pButton
        label="Go to Offices & Companies"
        type="button"
        class="p-button-raised p-button-secondary navigate-to-network-holdings-button"
        (click)="navigateToCompanies()"
        icon="pi pi-users"
        style="margin-bottom: 10px"
      ></button>
    </div>
  </div>
</p-dialog>
