import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Company, Holding, Network, Office, Professional } from '../../../models';
import {
  IPrimengSelectButtonOptions,
  IProfessionalsApiFilters,
  IProfessionalsToExcel,
} from '../../../shared/interfaces';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../../../shared/services';
import { UtilService } from '../../../shared/services/util.service';
import { Router } from '@angular/router';
import { Workbook } from 'exceljs';
import capitalize from 'lodash.capitalize';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-professionals-multiple',
  templateUrl: './professionals-multiple.component.html',
  styleUrls: ['./professionals-multiple.component.scss'],
})
export class ProfessionalsMultipleComponent implements OnChanges, OnInit {
  @Input() company: Company | undefined = undefined;
  @Input() network: Network | undefined = undefined;
  @Input() holding: Holding | undefined = undefined;

  public professionals: Professional[] = [];

  public filteredProfessionals: Professional[] = [];

  enabledOptions: IPrimengSelectButtonOptions<'current' | 'all'>[] = [
    { label: 'Current', value: 'current' },
    { label: 'All', value: 'all' },
  ];

  selectedEnabledOptionValue = 'current';

  selectedSearchText = '';

  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  isLoading = false;

  constructor(
    private readonly apiService: ApiService,
    private readonly utilService: UtilService,
    private readonly router: Router,
    private readonly messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.searchText$.subscribe(() => {
      this.selectedEnabledOptionValueChanged();
      this.filteredProfessionals = this.filteredProfessionals.filter(
        (c) =>
          c.person?.firstName?.toLowerCase().includes(this.selectedSearchText.toLowerCase()) ||
          c.person?.lastName?.toLowerCase().includes(this.selectedSearchText.toLowerCase()) ||
          c.email?.toLowerCase().includes(this.selectedSearchText.toLowerCase()) ||
          c.phone?.toLowerCase().includes(this.selectedSearchText.toLowerCase()) ||
          c.mobilePhone?.toLowerCase().includes(this.selectedSearchText.toLowerCase()) ||
          c.positionName?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    let filters: IProfessionalsApiFilters | undefined = undefined;

    if (changes['company'] && changes['company'].currentValue) {
      filters = {
        page: 1,
        pageSize: 100000,
        companyId: this.company?.id,
        onlyCurrentProfessionals: false,
      };
    } else if (changes['network'] && changes['network'].currentValue) {
      filters = {
        page: 1,
        pageSize: 100000,
        networkId: this.network?.id,
        onlyCurrentProfessionals: false,
      };
    } else if (changes['holding'] && changes['holding'].currentValue) {
      filters = {
        page: 1,
        pageSize: 100000,
        holdingId: this.holding?.id,
        onlyCurrentProfessionals: false,
      };
    }

    if (filters) {
      this.isLoading = true;
      this.apiService.professionals.search(filters).subscribe({
        next: (data) => {
          this.professionals = data.items.sort((a, b) => {
            if (a.role && b.role) {
              const roleA = a.role?.name.toUpperCase();
              const roleB = b.role?.name.toUpperCase();
              if (roleA < roleB) {
                return -1;
              }
              if (roleA > roleB) {
                return 1;
              }
              return this.getOrderByFirstName(a, b);
            }
            return 0;
          });
          this.filteredProfessionals = this.professionals;
          this.selectedEnabledOptionValueChanged();
          this.isLoading = false;
        },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
        },
      });
    }
  }

  private getOrderByFirstName(a: Professional, b: Professional): number {
    if (a.person?.firstName && b.person?.firstName) {
      return a.person?.firstName?.localeCompare(b.person?.firstName) || this.getOrderByLastName(a, b);
    }
    return 0;
  }

  private getOrderByLastName(a: Professional, b: Professional): number {
    if (a.person?.lastName && b.person?.lastName) {
      return a.person?.lastName?.localeCompare(b.person?.lastName) || 0;
    }
    return 0;
  }

  public parseAddress(professional: Professional) {
    this.utilService.parseAddress(professional.person?.address);
  }

  navigateToPerson(professional: Professional) {
    this.router.navigate([`professionals/people/${professional.person?.id}`]);
  }

  selectedEnabledOptionValueChanged() {
    if (this.selectedEnabledOptionValue == 'current') {
      this.filteredProfessionals = this.professionals.filter((c) => c.isActive);
    } else {
      this.filteredProfessionals = this.professionals;
    }
  }

  searchTextChanged() {
    this.searchText$.next(this.selectedSearchText);
  }

  async exportExcel() {
    const { columns, rows } = this.getProfessionalsForExcel();
    const workbook = new Workbook();
    const workSheet = this.company
      ? workbook.addWorksheet(`Professionals from company ${this.company?.name}`)
      : this.network
      ? workbook.addWorksheet(`Professionals from network ${this.network?.name}`)
      : this.holding
      ? workbook.addWorksheet(`Professionals from holding ${this.holding?.name}`)
      : workbook.addWorksheet(`Professionals`);
    workSheet.columns = columns;
    workSheet.insertRow(1, null);
    workSheet.mergeCells('A1', 'M1');
    const titleRow = workSheet.getCell('A1');
    titleRow.value = 'CRM';
    titleRow.alignment = { horizontal: 'center', vertical: 'middle' };
    workSheet.addRows(rows);
    const headerRow = workSheet.getRow(2);
    headerRow.font = { bold: true };
    headerRow.alignment = { horizontal: 'left', vertical: 'middle' };
    const buffer = (await workbook.xlsx.writeBuffer()) as unknown as Buffer;
    const fileName = this.company
      ? `Professionals-from-company-${this.company?.name.replaceAll(' ', '_')}`
      : this.network
      ? `Professionals-from-network-${this.network?.name.replaceAll(' ', '_')}`
      : this.holding
      ? `Professionals-from-holding-${this.holding?.name.replaceAll(' ', '_')}`
      : `Professionals`;
    this.utilService.saveAsExcelFile(buffer, fileName);
  }

  private getProfessionalsForExcel(): {
    columns: { header: string; key: string; width: number }[];
    rows: IProfessionalsToExcel[];
  } {
    const rows = this.filteredProfessionals.map((professional) => professional.professionalForExcel(undefined));
    const keys = Object.keys(rows[0]);
    const columns = keys.map((key) => ({ header: capitalize(key), key, width: 30 }));
    return { columns, rows };
  }

  copyLinkToClipboard($event: any, data: string) {
    $event.preventDefault();
    $event.stopPropagation();
    if (data) {
      const dummyInput = document.createElement('input');
      document.body.appendChild(dummyInput);
      dummyInput.value = data;
      dummyInput.select();
      document.execCommand('copy');
      document.body.removeChild(dummyInput);
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `copied ${data}`,
      });
    }
  }
}
