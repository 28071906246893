import { BaseModel } from './base.model';

export class Studio extends BaseModel {
  public name: string;
  public fullName: string;
  public types: StudioType[] = [];

  constructor(data: Studio) {
    super(data);
    ({ name: this.name, fullName: this.fullName } = data);
    if (data.types && data.types.length) {
      this.types = data.types.map((t) => new StudioType(t));
    }
  }
}

export class StudioType extends BaseModel {
  public name: string;

  constructor(data: StudioType) {
    super(data);
    ({ name: this.name } = data);
  }
}

export class UpsertStudioTypesCommand {
  public studioTypeIds: number[] = [];
}
