import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../components/general/base/base.component';
import { Company, CompanyCommand } from '../../models';
import { ApiService, ToastService } from '../../shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { CompanyFormComponent } from '../../components/companies/company-form/company-form.component';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent extends BaseComponent implements OnInit {
  public company?: Company;
  public companyDeletedConfirmed = false;

  constructor(
    private apiService: ApiService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    this.apiService.companies.getById(id).subscribe({
      next: (company) => {
        this.company = company;
      },
      error: () => {
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We could not get companies from the API`,
        });
      },
    });
  }

  editClicked($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    const company = Object.create(this.company || {});
    const ref = this.dialogService.open(CompanyFormComponent, {
      header: `Edit company`,
      width: '50%',
      height: 'auto',
      data: {
        company: company,
        mode: 'edit',
      },
    });

    ref.onClose.subscribe((command: CompanyCommand) => {
      if (command) {
        this.apiService.companies.update(company.id, command).subscribe(
          (data) => {
            this.company = data;
            this.toastService.send({
              severity: 'success',
              summary: 'Updated',
              detail: 'You have updated this company',
            });
          },
          () => {
            this.toastService.send({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while updating the company',
            });
          },
        );
      }
    });
  }

  deleteClicked($event: boolean) {
    if ($event && this.company) {
      this.apiService.companies.delete(this.company.id).subscribe({
        next: () => {
          this.companyDeletedConfirmed = true;
          this.toastService.send({
            severity: 'success',
            summary: 'Success',
            detail: 'Company deleted',
          });
        },
        error: () => {
          this.toastService.send({
            severity: 'error',
            summary: 'Error',
            detail: 'We could not delete the company',
          });
        },
      });
    }
  }

  navigateToCompanies() {
    if (this.company) {
      this.router.navigate(['/companies']);
    }
  }
}
