import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { ScrollerOptions } from 'primeng/scroller';
import { Subscription } from 'rxjs';
import { Company, Holding, Network, Office } from '../../../models';
import {
  IHasEmailStateOptions,
  IOfficesApiFilters,
  IPrimengCustomEvent,
  IPrimengCustomSelectButtonEvent,
  IPrimengSelectButtonOptions,
  IProfessionalsFilter,
} from '../../../shared/interfaces';
import { ApiService, ToastService } from '../../../shared/services';
import { CountryService } from '../../../shared/services/country.service';
import { MainKeyFilterType, NodeFilterType, SubKeyFilterType } from '../../../shared/types';
import { BaseComponent } from '../../general/base/base.component';
import { Festival } from 'src/app/models/festival.model';

@Component({
  selector: 'app-professionals-filters',
  templateUrl: './professionals-filters.component.html',
  styleUrls: ['./professionals-filters.component.scss'],
})
export class ProfessionalsFiltersComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() isLoading = false;

  @Output() filters = new EventEmitter<IProfessionalsFilter>();

  public name = '';

  public lastName = '';

  public company = '';

  public email = '';

  public observations = '';
  public positionName = '';

  public hasEmailStateOptions = [
    { label: $localize`Yes`, value: true },
    { label: $localize`No`, value: false },
    { label: $localize`All`, value: undefined },
  ];

  public selectedHasEmailStateOption?: boolean;

  public currentProfessionalsStateOptions = [
    { label: $localize`Current`, value: true },
    { label: $localize`No`, value: false },
    { label: $localize`All`, value: undefined },
  ];

  public selectedCurrentProfessionalsStateOption?: boolean = true;

  public selectedCountries: TreeNode[] = [];

  public selectedProvinces: TreeNode[] = [];

  public countryNodes: TreeNode[] = [];

  public typeNodes: TreeNode[] = [];

  public selectedTypes: TreeNode[] = [];

  public filtersNodes: TreeNode[] = [];

  public selectedFilters: TreeNode[] = [];

  public wavesNodes: TreeNode[] = [];

  public festivalsNodes: TreeNode[] = [];

  public selectedWaves: TreeNode[] = [];

  public selectedFestivals: TreeNode[] = [];

  public selectedSubTypes: TreeNode[] = [];

  public selectedSectors: TreeNode[] = [];

  public displayPlaceHolder = false;

  public officesPage = 1;

  public officesPageSize = 15;

  public selectedOffice?: Office;

  public officesResult: Office[] = [];

  public officeLoader = false;

  public selectedCompany?: Company;
  public selectedNetwork?: Network;
  public selectedHolding?: Holding;

  public officesVirtualScroll: ScrollerOptions = {
    onScrollIndexChange: (event: { first: number; last: number }) => {
      if (event.last === this.officesPage * this.officesPageSize) {
        this.searchOffices(this.officeQuery, (this.officesPage += 1));
      }
    },
    loading: this.officeLoader,
  };

  public officeTypesOperationStateOptions = [
    { label: $localize`Or`, value: 'or' },
    { label: $localize`And`, value: 'and' },
  ];

  public selectedOfficeTypesOperationOption: 'or' | 'and' = 'or';

  public filtersStateOptions = [
    { label: $localize`Or`, value: 'or' },
    { label: $localize`And`, value: 'and' },
  ];

  public selectedFiltersStateOption: 'or' | 'and' = 'or';

  public wavesStateOptions = [
    { label: $localize`Or`, value: 'or' },
    { label: $localize`And`, value: 'and' },
  ];

  public selectedWavesStateOption: 'or' | 'and' = 'or';

  public festivalsStateOptions = [
    { label: $localize`Or`, value: 'or' },
    { label: $localize`And`, value: 'and' },
  ];

  public selectedFestivalsStateOption: 'or' | 'and' = 'or';

  private officeQuery?: string;

  private delayedFiltersTimeoutId?: number;

  private subscriptions = new Subscription();

  constructor(
    private apiService: ApiService,
    private readonly countryService: CountryService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadCountries();

    this.subscriptions.add(
      this.apiService.offices.types().subscribe({
        next: (types) => {
          this.typeNodes = types.map(
            (type) =>
              ({
                label: type.name,
                icon: 'pi pi-compass',
                data: type.id,
                children: type.subtypes.map(
                  (sub) =>
                    ({
                      label: sub.name,
                      icon: 'pi pi-check-circle',
                      data: sub.id,
                      parent: {
                        label: type.name,
                        icon: 'pi pi-compass',
                        data: type.id,
                      },
                    } as TreeNode),
                ),
              } as TreeNode),
          );
        },
        error: (error: unknown) => {
          console.error(error);
          this.toastService.send({
            severity: 'error',
            summary: $localize`Error`,
            detail: $localize`We couldn't get offices types from the API`,
          });
        },
      }),
    );

    this.subscriptions.add(
      this.apiService.filters.find(undefined, undefined, this.globalCountryId).subscribe({
        next: (filters) => {
          this.filtersNodes = filters.map(
            (filter) => ({ label: filter.name, icon: 'pi pi-check', data: filter.id } as TreeNode),
          );
        },
        error: (error: unknown) => {
          console.error(error);
          this.toastService.send({
            severity: 'error',
            summary: $localize`Error`,
            detail: $localize`We couldn't get filters from the API`,
          });
        },
      }),
    );

    this.subscriptions.add(
      this.apiService.waves.find(undefined, undefined, this.globalCountryId).subscribe({
        next: (waves) => {
          this.wavesNodes = waves.map((wave) => ({ label: wave.name, icon: 'pi pi-check', data: wave.id } as TreeNode));
        },
        error: (error: unknown) => {
          console.error(error);
          this.toastService.send({
            severity: 'error',
            summary: $localize`Error`,
            detail: $localize`We couldn't get waves from the API`,
          });
        },
      }),
    );

    this.subscriptions.add(
      this.apiService.festivals.find().subscribe({
        next: (festivals: Festival[]) => {
          this.festivalsNodes = festivals.map(
            (festival) =>
              ({
                label: `${festival.event?.name} - ${festival.year}`,
                icon: 'pi pi-check',
                data: festival.id,
              } as TreeNode),
          );
        },
        error: (error: unknown) => {
          console.error(error);
          this.toastService.send({
            severity: 'error',
            summary: $localize`Error`,
            detail: $localize`We couldn't get festivals from the API`,
          });
        },
      }),
    );

    this.subscriptions.add(
      this.globalCountryId$.subscribe({
        next: () => {
          this.loadCountries();
        },
        error: (error: unknown) => {
          console.error(error);
        },
      }),
    );

    this.createFilters();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onNameChanges(value: string): void {
    this.name = value;
    this.createFilters();
  }

  public onLastNameChanges(value: string): void {
    this.lastName = value;
    this.createFilters();
  }

  public onEmailChanges(value: string): void {
    this.email = value;
    this.createFilters();
  }

  public onCountryChanges(value: TreeNode[]): void {
    this.selectedCountries = value;
    this.displayPlaceHolder = false;

    if (!this.selectedCountries.length) {
      this.selectedProvinces = [];
    }

    this.createFilters();
  }

  public removeCountryItem(index: number) {
    this.selectedCountries.splice(index, 1);

    if (!this.selectedCountries.length) {
      this.selectedProvinces = [];
    }

    this.displayPlaceHolder = true;
    this.createFilters();
  }

  public onProvinceChanges(value: TreeNode[]): void {
    this.selectedProvinces = value;
    this.createFilters();
  }

  public createFilters(): void {
    const handler = () => {
      this.filters.emit({
        firstName: this.name,
        lastName: this.lastName,
        email: this.email,
        hasEmail: this.selectedHasEmailStateOption,
        locations: this.parseTreeNodeToFilterOptions('countryId', 'provinceId'),
        officeTypes: this.parseTreeNodeToFilterOptions('officeTypeId', 'agencyTypeId'),
        officeTypesOperation: this.selectedOfficeTypesOperationOption,
        onlyCurrentProfessionals: this.selectedCurrentProfessionalsStateOption,
        filterIds: this.selectedFilters.map((filter) => filter.data),
        filtersOperation: this.selectedFiltersStateOption,
        waveIds: this.selectedWaves.map((wave) => wave.data),
        wavesOperation: this.selectedWavesStateOption,
        festivalIds: this.selectedFestivals.map((festival) => festival.data),
        festivalsOperation: this.selectedFestivalsStateOption,
        observations: this.observations,
        officeId: this.selectedOffice?.id,
        companyId: this.selectedCompany?.id,
        networkId: this.selectedNetwork?.id,
        holdingId: this.selectedHolding?.id,
        positionName: this.positionName,
      });
    };

    if (this.delayedFiltersTimeoutId) {
      window.clearTimeout(this.delayedFiltersTimeoutId);
      this.delayedFiltersTimeoutId = undefined;
    }

    this.delayedFiltersTimeoutId = window.setTimeout(handler, 500);
  }

  public onHasEmailStateChange(event: IPrimengCustomSelectButtonEvent<IHasEmailStateOptions>): void {
    this.selectedHasEmailStateOption = event.option.value;
    this.createFilters();
  }

  public onCurrentProfessionalsStateChange(event: IPrimengCustomSelectButtonEvent<IHasEmailStateOptions>): void {
    this.selectedCurrentProfessionalsStateOption = event.option.value;
    this.createFilters();
  }

  public filterOffices(event: IPrimengCustomEvent<Event, string>): void {
    const query = event.query;
    this.searchOffices(query, 1, true);
  }

  public onTypesChanges(value: TreeNode[]): void {
    this.selectedTypes = value;

    if (!this.selectedTypes.length) {
      this.selectedSubTypes = [];
    }

    this.createFilters();
  }

  public onSubTypesChanges(value: TreeNode[]): void {
    this.selectedSubTypes = value;
    this.createFilters();
  }

  public onFiltersChanges(value: TreeNode[]): void {
    this.selectedFilters = value;
    this.createFilters();
  }

  public onWavesChanges(value: TreeNode[]): void {
    this.selectedWaves = value;
    this.createFilters();
  }

  public onFestivalsChanges(value: TreeNode[]): void {
    this.selectedFestivals = value;
    this.createFilters();
  }

  public onOfficeTypesOperationChange(
    event: IPrimengCustomSelectButtonEvent<IPrimengSelectButtonOptions<'or' | 'and'>>,
  ): void {
    this.selectedOfficeTypesOperationOption = event.option.value;
    this.createFilters();
  }

  public onFiltersOperationChange(
    event: IPrimengCustomSelectButtonEvent<IPrimengSelectButtonOptions<'or' | 'and'>>,
  ): void {
    this.selectedFiltersStateOption = event.option.value;
    this.createFilters();
  }
  public onWavesOperationChange(
    event: IPrimengCustomSelectButtonEvent<IPrimengSelectButtonOptions<'or' | 'and'>>,
  ): void {
    this.selectedWavesStateOption = event.option.value;
    this.createFilters();
  }

  public onFestivalsOperationChange(
    event: IPrimengCustomSelectButtonEvent<IPrimengSelectButtonOptions<'or' | 'and'>>,
  ): void {
    this.selectedFestivalsStateOption = event.option.value;
    this.createFilters();
  }

  public onOfficeChange(event: Office): void {
    this.selectedOffice = event;
    this.createFilters();
  }

  public onObservationsChange($event: string): void {
    this.observations = $event;
    this.createFilters();
  }

  public isAgency(): boolean {
    return !!this.selectedTypes.find((type) => type.label?.toLowerCase() === 'agency');
  }

  private searchOffices(query?: string, page = this.officesPage, cleanResults = false): void {
    this.officeLoader = true;
    const filters: IOfficesApiFilters = { page, pageSize: this.officesPageSize, name: query };
    if (this.selectedCountries) {
      filters.locations = this.selectedCountries.map((country) => {
        return { countryId: country.data };
      });
    }

    this.apiService.offices.search(filters).subscribe({
      next: (data) => {
        if (query !== this.officeQuery || cleanResults) {
          this.officesResult = [];
        }

        this.officesResult = [...this.officesResult, ...data.items];
        this.officeQuery = query;
        this.officeLoader = false;
      },
      error: () => {
        this.isLoading = false;
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We could not get offices from the API`,
        });
      },
    });
  }

  private parseTreeNodeToFilterOptions<T extends MainKeyFilterType, U extends SubKeyFilterType<T>>(
    mainKey: T,
    subKey: U,
  ): NodeFilterType<T>[] {
    const arr =
      mainKey === 'countryId'
        ? [...this.selectedProvinces, ...this.selectedCountries]
        : mainKey === 'officeTypeId'
        ? [...this.selectedSubTypes, ...this.selectedTypes]
        : this.selectedSectors;
    const parsed: NodeFilterType<T>[] = [];

    for (const node of arr) {
      if (
        !node.parent &&
        node.children &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        !parsed.some((value: any) => value[mainKey] === node.data && value[subKey])
      ) {
        parsed.push({ [mainKey]: node.data, [subKey]: undefined } as NodeFilterType<T>);
      }

      if (node.parent && !node.children) {
        parsed.push({ [mainKey]: node.parent.data, [subKey]: node.data } as NodeFilterType<T>);
      }
    }

    return parsed;
  }

  private loadCountries() {
    this.subscriptions.add(
      this.countryService.find({ countryId: this.globalCountryId, includeProvinces: true }).subscribe({
        next: (countries) => {
          this.countryNodes = countries.map(
            (country) =>
              ({
                label: country.name,
                icon: country.flagUrl,
                data: country.id,
                children: country.provinces.map(
                  (province) =>
                    ({
                      label: province.name,
                      icon: 'pi pi-map-marker',
                      data: province.id,
                      parent: { label: country.name, icon: 'pi pi-compass', data: country.id },
                    } as TreeNode),
                ),
              } as TreeNode),
          );

          if (this.globalCountryId || this.countryNodes.length == 1) {
            this.onCountryChanges(this.countryNodes);
          } else {
            this.onCountryChanges([]);
          }
        },
        error: (error: unknown) => {
          console.error(error);
          this.toastService.send({
            severity: 'error',
            summary: $localize`Error`,
            detail: $localize`We couldn't get countries from the API`,
          });
        },
      }),
    );
  }

  public holdingsSearchFunction = this.apiService.holdings.getAll;
  public networksSearchFunction = this.apiService.networks.getAll;
  public companiesSearchFunction = this.apiService.companies.getAll;

  public countryFilterFunction = () => {
    const countryIds: number[] = [];
    if (this.selectedCountries.length > 0) {
      this.selectedCountries.forEach((country) => {
        countryIds.push(country.data);
      });
    }
    return countryIds;
  };

  onHoldingItemChange($event: any) {
    this.selectedHolding = $event;
    this.createFilters();
  }

  onNetworkItemChange($event: any) {
    this.selectedNetwork = $event;
    this.createFilters();
  }

  onCompanyItemChange($event: any) {
    this.selectedCompany = $event;
    this.createFilters();
  }

  onPositionNameChange($event: any) {
    this.positionName = $event;
    this.createFilters();
  }
}
