import { Component, Input } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { IOfficesApiFilters } from '../../../shared/interfaces';
import { ApiService } from '../../../shared/services';
import { ExcelTooLargeComponent } from '../../general/excel-too-large/excel-too-large.component';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-office-advanced-excel',
  templateUrl: './office-advanced-excel.component.html',
  styleUrls: ['./office-advanced-excel.component.scss'],
})
export class OfficeAdvancedExcelComponent {
  @Input() filters: IOfficesApiFilters | undefined = undefined;
  @Input() officesCount: number | undefined = undefined;
  @Input() companiesCount: number | undefined = undefined;

  public isLoading = false;

  public excelOptions: MenuItem[] = [
    {
      icon: 'pi pi-file-excel',
      label: 'Offices',
      command: () => {
        this.downloadOfficesExcel();
      },
    },
    {
      icon: 'pi pi-file-excel',
      label: 'Companies',
      command: () => {
        this.downloadCompaniesExcel();
      },
    },
  ];

  constructor(private apiService: ApiService, private dialogService: DialogService) {}

  downloadOfficesExcel() {
    this.downloadExcel(this.officesCount, () => {
      this.downloadExcelFile(this.apiService.offices.getAdvancedExcel, 'offices-advanced.xlsx');
    });
  }

  downloadCompaniesExcel() {
    this.downloadExcel(this.companiesCount, () => {
      this.downloadExcelFile(this.apiService.companies.getExcel, 'companies.xlsx');
    });
  }

  downloadExcel(count: number | undefined, downloadFunc: () => void) {
    if (count && count <= 1000) {
      downloadFunc();
    } else if (count && count > 1000) {
      const ref = this.dialogService.open(ExcelTooLargeComponent, {
        header: `Long running process`,
        width: '30%',
        height: 'auto',
        data: {
          itemsCount: count,
        },
      });

      ref.onClose.subscribe((result: boolean) => {
        if (result) {
          downloadFunc();
        }
      });
    }
  }

  private downloadExcelFile(apiFunc: (filters: IOfficesApiFilters) => Observable<Blob>, fileName: string) {
    if (this.filters) {
      this.isLoading = true;
      apiFunc(this.filters).subscribe({
        next: (data: BlobPart) => {
          this.downloadFile(data, fileName);
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
    }
  }

  private downloadFile(data: BlobPart, fileName: string) {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
}
