<div class="form-container" *ngIf="office">
  <div class="col-12 flex start">
    <app-office-summary [office]="office"></app-office-summary>
  </div>

  <div class="col-12 flex start">
    <div class="col-6 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="year"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Year"
          [(ngModel)]="command.year"
          (ngModelChange)="yearChanged()"
        />
        <label for="email">Year</label>
      </span>
    </div>
    <div class="col-6 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="totalCount"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Number of Empoyees"
          [(ngModel)]="command.totalCount"
          (ngModelChange)="totalCountChanged()"
        />
        <label for="email">Number of Employees</label>
      </span>
    </div>
  </div>

  <div class="col-12">
    <button
      pButton
      *ngIf="mode === 'create'"
      label="Add Employees by Year"
      type="button"
      (click)="createClicked()"
      [disabled]="!addStatsEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-plus-circle"
    ></button>

    <button
      pButton
      *ngIf="mode === 'edit'"
      label="Update Employees by Year"
      type="button"
      (click)="createClicked()"
      [disabled]="!addStatsEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
