import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateProductCommand, Product } from '../../../../models';

@Component({
  selector: 'app-products-form',
  templateUrl: './products-form.component.html',
  styleUrls: ['./products-form.component.scss'],
})
export class ProductsFormComponent implements OnInit {
  public product: Product | undefined = undefined;

  public mode = 'create';

  public addProductEnabled = false;

  public currentName = '';

  public command: CreateProductCommand = new CreateProductCommand({
    name: '',
  });

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data) {
      this.mode = 'edit';
      this.fillData();
    } else {
      this.mode = 'create';
    }
  }

  private fillData() {
    this.product = this.config.data.product;
    if (this.product != undefined) {
      this.currentName = this.product.name;
      this.command.name = this.product.name;
    }
  }

  createClicked() {
    if (this.addProductEnabled) {
      this.ref.close(this.command);
    }
  }

  cancelClicked() {
    this.ref.close();
  }

  nameChanged() {
    this.checkIfAddProductEnabled();
  }

  checkIfAddProductEnabled() {
    if (this.mode === 'edit') {
      this.addProductEnabled =
        this.command.name !== undefined &&
        this.command.name.trim().length !== 0 &&
        this.command.name.trim() !== this.currentName.trim();
    } else {
      this.addProductEnabled = this.command.name !== undefined && this.command.name.trim().length !== 0;
    }
  }
}
