import { BaseModel } from './base.model';
import { Address } from './professional.model';
import { Country } from './country.model';
import { OfficeSubType } from './office-sub-type.model';

export class OfficeFusionDetail extends BaseModel {
  public name: string | undefined;
  public address: Address | undefined;
  public country: Country | undefined;
  public agencyTypes: OfficeSubType[] = [];

  constructor(data: OfficeFusionDetail) {
    super(data);
    ({ name: this.name } = data);

    if (data.address) {
      this.address = new Address(data.address);
    }

    if (data.country) {
      this.country = new Country(data.country);
    }

    if (data.agencyTypes && data.agencyTypes.length) {
      this.agencyTypes = data.agencyTypes.map((type) => new OfficeSubType(type)).sort((a, b) => a.order - b.order);
    }
  }

  public getAgencyTypes(): string {
    if (this.agencyTypes) {
      return this.agencyTypes.map((t) => t.name).join(', ');
    }
    return '-';
  }
}

export class OfficeFusionParent extends BaseModel {
  public name: string | undefined;

  constructor(data: OfficeFusionParent) {
    super(data);
    ({ name: this.name } = data);
  }
}

export class OfficeFusionCommand {
  public fusionParentId = 0;

  constructor(data: OfficeFusionCommand) {
    ({ fusionParentId: this.fusionParentId } = data);
  }
}
