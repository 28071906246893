import { BaseModel } from './base.model';

export class AgencyService extends BaseModel {
  public name: string;
  constructor(data: AgencyService) {
    super(data);

    ({ name: this.name } = data);
  }
}

export class AgencyServiceCommand implements Omit<AgencyService, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt'> {
  public name: string;

  constructor(data: AgencyServiceCommand) {
    ({ name: this.name } = data);
  }
}

export class CreateAgencyServiceCommand extends AgencyServiceCommand {
  constructor(data: CreateAgencyServiceCommand) {
    super(data);
  }
}

export class UpdateAgencyServiceCommand extends AgencyServiceCommand {
  public id: number;

  constructor(data: UpdateAgencyServiceCommand) {
    super(data);
    this.id = data.id;
  }
}
