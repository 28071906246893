import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AgencyService, Office, UpdateAgencyServicesCommand } from '../../../../models';
import { ApiService } from '../../../../shared/services';

@Component({
  selector: 'app-agency-services-form',
  templateUrl: './agency-services-form.component.html',
  styleUrls: ['./agency-services-form.component.scss'],
})
export class AgencyServicesFormComponent implements OnInit {
  public office: Office | undefined = undefined;
  public agencyServices: AgencyService[] = [];
  public selectedAgencyServices: AgencyService[] = [];

  constructor(private apiService: ApiService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.apiService.agencies.getAllServices().subscribe({
      next: (services: AgencyService[]) => {
        this.agencyServices = services;

        if (this.config.data.office) {
          this.office = this.config.data.office;
          this.office?.agency?.services.forEach((service: AgencyService) => {
            const index = this.agencyServices.findIndex((w: AgencyService) => w.id === service.id);
            if (index > -1) {
              this.selectedAgencyServices.push(this.agencyServices[index]);
            }
          });
        }
      },
    });
  }

  updateClicked() {
    if (this.office && this.office.agency) {
      const command = new UpdateAgencyServicesCommand(
        this.office.id,
        this.selectedAgencyServices.map((service: AgencyService) => service.id),
      );
      this.ref.close(command);
    }
  }

  cancelClicked() {
    this.ref.close();
  }
}
