import { BaseModel } from './base.model';
import { Brand } from './brand.model';
import { Sector } from './sector.model';
import { InfoadexRecord } from './infoadex-record.model';

export class Advertiser extends BaseModel {
  public name: string;
  public fullName: string;
  public brands: Brand[] = [];
  public sectors: Sector[] = [];
  public infoAdexRecords: InfoadexRecord[] = [];

  constructor(data: Advertiser) {
    super(data);

    ({ name: this.name, fullName: this.fullName } = data);

    if (data.brands && data.brands.length) {
      this.brands = data.brands.map((b) => new Brand(b));
    }

    if (data.sectors && data.sectors.length) {
      this.sectors = data.sectors.map((s) => new Sector(s));
    }

    if (data.infoAdexRecords && data.infoAdexRecords.length) {
      this.infoAdexRecords = data.infoAdexRecords.map((i) => new InfoadexRecord(i));
    }
  }
}

export class UpsertAdvertiserBrandsCommand {
  public brandIds: number[] = [];
}

export class UpsertAdvertiserSubSectorsCommand {
  public subSectorIds: number[] = [];
}
