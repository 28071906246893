import { Injectable } from '@angular/core';
import libPhoneNumber from 'libphonenumber-js';
import { Address } from '../../models';
import { CountryISOMapping3to2Letters } from '../constants';
import { ISOCountryCode2Letters, ISOCountryCode3Letters } from '../types';
import FileSaver from 'file-saver';

/**
 * UtilService
 *
 * This service should be a collection of useful functions and it
 * should never receive other services with dependency injection
 * because should be possible to build it manually inside any
 * constructor.
 */
@Injectable({
  providedIn: 'root',
})
export class UtilService {
  public phoneNumberToIntlFormat(phone?: string | number, countryCode2letters?: ISOCountryCode3Letters): string {
    if (phone) {
      const sentPhone = `${phone}`;
      const countryCode = countryCode2letters ? this.countryCode3to2letters(countryCode2letters) : undefined;

      let phoneNumber = libPhoneNumber(sentPhone, countryCode);

      if (phoneNumber) {
        return phoneNumber?.formatInternational();
      }

      if (/^\+/.test(`${phone}`)) {
        phoneNumber = libPhoneNumber(`${phone}`);

        if (phoneNumber) {
          return phoneNumber?.formatInternational();
        }
      }

      return sentPhone;
    }

    return '';
  }

  public countryCode3to2letters(threeLettersCountryCode: ISOCountryCode3Letters): ISOCountryCode2Letters {
    return CountryISOMapping3to2Letters[threeLettersCountryCode];
  }

  public parseAddress(address?: Address) {
    if (address && typeof address === 'object') {
      const fullAddress = [];
      if (address.addressLine) fullAddress.push(address.addressLine);
      if (address.postalCode) fullAddress.push(address.postalCode);
      if (address.town) fullAddress.push(address.town);
      if (address.province?.name) fullAddress.push(address.province?.name);
      if (address.country?.name) fullAddress.push(address.country?.name);
      return fullAddress.join(', ');
    } else if (address && typeof address === 'string') {
      return address;
    }

    return '';
  }

  saveAsExcelFile(buffer: Buffer, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  public createIndexesMapFromCollection<T>(collection: T, indexesMap: Map<number, number>) {
    if (Array.isArray(collection)) {
      collection.forEach((mt, index) => {
        indexesMap.set(mt.id, index);
      });
    } else {
      console.warn('collection param is not an array');
    }
  }
}
