<div class="col-12">
  <div class="col-12 justify-content-center surface-50" style="display: none">
    <app-offices-filters (filters)="onFilters($event)"></app-offices-filters>
  </div>
  <div class="col-12 justify-content-center w-full">
    <div class="col-12 px-3 flex flex-row justify-content-end align-items-end" style="height: 0">
      <p-splitButton
        *ngIf="hasRole('db-edit-offices')"
        #splitCreate
        label="New"
        icon="pi pi-plus"
        class="create-button"
        styleClass="p-button-raised p-button-warning mr-2 mb-2"
        (onClick)="splitCreate.onDropdownButtonClick($event)"
        [model]="createOptions"
      ></p-splitButton>
    </div>
    <p-tabView class="offices">
      <p-tabPanel header="Networks" i18n-header>
        <app-network-list [filters]="filters"></app-network-list>
      </p-tabPanel>
      <p-tabPanel header="Holdings" i18n-header>
        <app-holding-list [filters]="filters"></app-holding-list>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
