import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Country, Person, Title, UpdatePersonCommand } from '../../../models';
import { ApiService } from '../../../shared/services';
import { CountryService } from '../../../shared/services/country.service';
import { BaseComponent } from '../../general/base/base.component';
import { EmailConstants } from '../../../shared/constants';

@Component({
  selector: 'app-person-info-form',
  templateUrl: './person-info-form.component.html',
  styleUrls: ['./person-info-form.component.scss'],
})
export class PersonInfoFormComponent extends BaseComponent implements OnInit {
  public person: Person | undefined = undefined;
  public titles: Title[] = [];
  public countries: Country[] = [];

  public updatePersonEnabled = false;
  public enableOptions: [{ label: string; value: boolean }, { label: string; value: boolean }] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  public selectedEnableOption = this.enableOptions[0];
  public mode = 'edit';

  constructor(
    private apiService: ApiService,
    private readonly countryService: CountryService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.config.data.person) {
      this.person = this.config.data.person;

      if (this.config.data.mode) {
        this.mode = this.config.data.mode;
      }

      if (this.person) {
        if (this.mode == 'edit' && this.person.title instanceof Title) {
          this.titles.push(this.person.title);
        }

        if (this.person.country instanceof Country) {
          this.countries.push(this.person.country);
        }
        this.selectedEnableOption = this.person.isEnabled ? this.enableOptions[0] : this.enableOptions[1];
      }

      this.apiService.people.getTitles(this.globalCountryId).subscribe((titles) => {
        titles.forEach((title) => {
          if (this.titles.find((t) => t.id === title.id) === undefined) {
            this.titles.push(title);
          }
        });
        if (this.person) {
          this.person.title = this.titles.find((t) => t.id === this.person?.title?.id);
        }
        this.checkIfUpdatePersonEnabled();
      });

      this.countryService.find({ countryId: this.globalCountryId, includeProvinces: true }).subscribe((countries) => {
        countries.forEach((country) => {
          if (this.countries.find((c) => c.id === country.id) === undefined) {
            this.countries.push(country);
          }

          if (this.person) {
            if (this.person.country === undefined) {
              this.person.country = countries.find((c) => c.id == this.globalCountryId);
              if (this.person.country === undefined && countries.length == 1) {
                this.person.country = countries[0];
              }
            }
          }

          this.checkIfUpdatePersonEnabled();
        });
      });
    }
  }

  titleChanged() {
    this.checkIfUpdatePersonEnabled();
  }

  firstNameChanged() {
    this.checkIfUpdatePersonEnabled();
  }

  lastNameChanged() {
    this.checkIfUpdatePersonEnabled();
  }

  countryChanged() {
    this.checkIfUpdatePersonEnabled();
  }

  observationsChanged() {
    this.checkIfUpdatePersonEnabled();
  }

  phoneChanged() {
    this.checkIfUpdatePersonEnabled();
  }

  mobilePhoneChanged() {
    this.checkIfUpdatePersonEnabled();
  }

  emailChanged() {
    this.checkIfUpdatePersonEnabled();
  }

  updateClicked() {
    if (this.person && this.person.country && this.person.title) {
      const command = new UpdatePersonCommand({
        id: this.person.id,
        firstName: this.person?.firstName,
        lastName: this.person?.lastName,
        countryId: this.person?.country.id,
        titleId: this.person?.title.id,
        observations: this.person?.observations,
        phone: this.person?.phone,
        mobilePhone: this.person?.mobilePhone,
        email: this.person?.email,
        isEnabled: this.selectedEnableOption.value,
      });
      this.ref.close(command);
    } else {
      this.ref.close();
    }
  }

  cancelClicked() {
    this.ref.close();
  }

  private checkIfUpdatePersonEnabled() {
    if (this.person) {
      this.updatePersonEnabled =
        this.person.firstName != undefined &&
        this.person.firstName != '' &&
        this.person.lastName != undefined &&
        this.person.lastName != '' &&
        this.person.country != undefined &&
        this.person.country.id != undefined &&
        this.person.country.id != 0 &&
        this.person.title != undefined &&
        this.person.title.id != undefined &&
        this.person.title.id != 0 &&
        EmailConstants.isValidEmailIfNotMandatory(this.person.email);
    }
  }
}
