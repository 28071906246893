import { Advertiser } from './advertiser.model';
import { AgencyService } from './agency-service.model';
import { Agency } from './agency.model';
import { BaseModel } from './base.model';
import { Brand } from './brand.model';
import { format } from 'date-fns';

export class Collaboration extends BaseModel {
  public company?: string;

  public agency?: Agency;

  public advertiser?: Advertiser;

  public campaign?: string;

  public startDate?: Date;

  public endDate?: Date;

  public isActive?: boolean;

  public brands: Brand[] = [];

  public services: AgencyService[] = [];

  constructor(data: Collaboration) {
    super(data);

    ({
      campaign: this.campaign,
      startDate: this.startDate,
      endDate: this.endDate,
      isActive: this.isActive,
      isDeleted: this.isDeleted,
      company: this.company,
    } = data);

    if (data.agency) {
      this.agency = new Agency(data.agency);
    }

    if (data.advertiser) {
      this.advertiser = new Advertiser(data.advertiser);
    }

    if (data?.brands?.length) {
      this.brands = data.brands.map((brand) => new Brand(brand));
    }

    if (data?.services?.length) {
      this.services = data.services.map((service) => new AgencyService(service));
    }
  }

  public getBrandNames(): string {
    return this.brands.map((b) => b.name).join(', ');
  }

  public getServiceNames(): string {
    return this.services.map((s) => s.name).join(', ');
  }

  public getAdvertiserInfoAdexRecords(): string {
    if (this.advertiser) {
      return this.advertiser?.infoAdexRecords
        .map((i) => `${i.year}-> Ranking: ${i.ranking}, Investment: ${i.investment}`)
        .join(', ');
    }
    return '-';
  }

  public getAgencyTypes(): string {
    if (this.agency) {
      return this.agency.types.map((t) => t.name).join(', ');
    }
    return '-';
  }

  public agencyCollaborationForExcel() {
    return {
      current: this.isActive ? '✓' : '✕',
      company: this.company,
      startDate: this.startDate ? format(new Date(this.startDate), 'dd/MM/yyyy') : '',
      endDate: this.endDate ? format(new Date(this.endDate), 'dd/MM/yyyy') : '',
      brands: this.brands.reduce((acc, brand) => (!acc ? `${brand.name}` : `${acc}; ${brand.name}`), ''),
      campaign: this.campaign,
      services: this.services.reduce((acc, service) => (!acc ? `${service.name}` : `${acc}; ${service.name}`), ''),
    };
  }

  public advertiserCollaborationForExcel() {
    return {
      current: this.isActive ? '✓' : '✕',
      agency: this.company,
      agencyType: this.agency?.types.reduce((acc, type) => (!acc ? `${type.name}` : `${acc}, ${type.name}`), ''),
      startDate: this.startDate ? format(new Date(this.startDate), 'dd/MM/yyyy') : '',
      endDate: this.endDate ? format(new Date(this.endDate), 'dd/MM/yyyy') : '',
      campaign: this.campaign,
      brands: this.brands.reduce((acc, brand) => (!acc ? `${brand.name}` : `${acc}; ${brand.name}`), ''),
      services: this.services.reduce((acc, service) => (!acc ? `${service.name}` : `${acc}; ${service.name}`), ''),
    };
  }
}

export class CollaborationCommand extends BaseModel {
  public agencyId?: number;

  public advertiserId?: number;

  public campaign?: string;

  public startDate?: Date | undefined;

  public endDate?: Date | undefined;

  public isActive?: boolean;

  public brandIds: number[] = [];

  public agencyServiceIds: number[] = [];

  constructor(data: CollaborationCommand) {
    super(data);

    ({
      agencyId: this.agencyId,
      advertiserId: this.advertiserId,
      campaign: this.campaign,
      startDate: this.startDate,
      endDate: this.endDate,
      isActive: this.isActive,
    } = data);

    if (data?.brandIds?.length) {
      this.brandIds = data.brandIds;
    }

    if (data?.agencyServiceIds?.length) {
      this.agencyServiceIds = data.agencyServiceIds;
    }
  }

  public static createNewBlankCollaborationCommand(): CollaborationCommand {
    return new CollaborationCommand({
      id: 0,
      agencyId: 0,
      advertiserId: 0,
      campaign: '',
      startDate: undefined,
      endDate: undefined,
      isActive: true,
      brandIds: [],
      agencyServiceIds: [],
    });
  }
}
