<div class="form-container pt-3">
  <div class="col-3 pt-5">
    <span class="p-float-label p-input-icon-right w-full">
      <input
        id="name"
        type="text"
        pInputText
        class="p-inputtext-sm w-full"
        placeholder="name"
        [(ngModel)]="command.name"
        (ngModelChange)="nameChanged()"
      />
      <label for="name" i18n>Name</label>
    </span>
  </div>
  <div class="p-2">
    <p-pickList
      sourceHeader="Countries"
      targetHeader="Countries for the title"
      [source]="countries"
      [target]="countriesForTitle"
      [responsive]="true"
      [showSourceControls]="false"
      [showTargetControls]="false"
      (onMoveToTarget)="checkIfAddTitleEnabled()"
      (onMoveToSource)="checkIfAddTitleEnabled()"
      (onMoveAllToTarget)="checkIfAddTitleEnabled()"
      (onMoveAllToSource)="checkIfAddTitleEnabled()"
      [sourceStyle]="{ height: '250px' }"
      [targetStyle]="{ height: '250px' }"
    >
      <ng-template let-country pTemplate="item">
        <div style="float: left">
          <img src="{{ country.flagUrl }}" style="display: inline-block" width="30" />
          <div style="font-size: 14px; float: right; padding-top: 7px; margin-left: 10px">
            {{ country.name }}
          </div>
        </div>
      </ng-template>
    </p-pickList>
  </div>
  <div class="col-12">
    <button
      pButton
      *ngIf="mode === 'create'"
      label="Add Title"
      type="button"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-plus-circle"
      [disabled]="!addTitleEnabled"
      (click)="createClicked()"
    ></button>

    <button
      pButton
      *ngIf="mode === 'edit'"
      label="Update Title"
      type="button"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
      [disabled]="!addTitleEnabled"
      (click)="createClicked()"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
      (click)="cancelClicked()"
    ></button>
  </div>
</div>
