<p-table
  *ngIf="mode === 'agency'"
  class="collaborations-table"
  styleClass="p-datatable-sm"
  [value]="filteredCollaborations"
  [loading]="isLoading"
  [scrollable]="true"
  selectionMode="single"
>
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-between">
      <p-selectButton
        [options]="enabledOptions"
        [(ngModel)]="selectedEnabledOptionValue"
        (onChange)="selectedEnabledOptionValueChanged()"
        optionLabel="label"
        optionValue="value"
        [multiple]="false"
      ></p-selectButton>
      <span class="p-float-label">
        <input
          id="float-input-collaborations-agency"
          type="text"
          pInputText
          [(ngModel)]="selectedSearchText"
          (ngModelChange)="searchTextChanged()"
          class="mr-2"
        />
        <label for="float-input-collaborations-agency">Search</label>
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-file-excel"
          (click)="exportAgencyCollaborationsToExcel()"
          class="p-button-success mr-2"
          pTooltip="Export to Excel"
          tooltipPosition="bottom"
          label="Excel"
        ></button>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th class="current">Current</th>
      <th pSortableColumn="company"><p-sortIcon field="company"></p-sortIcon> Name</th>
      <th pSortableColumn="startDate" class="date"><p-sortIcon field="startDate"></p-sortIcon> Start</th>
      <th pSortableColumn="endDate" class="date"><p-sortIcon field="endDate"></p-sortIcon> End</th>
      <th>Brands</th>
      <th pSortableColumn="campaign"><p-sortIcon field="campaign"></p-sortIcon> Campaign</th>
      <th>Services</th>
      <th>InfoAdex</th>
      <th class="actions" *ngIf="hasRole('db-edit-offices')">Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-collaboration>
    <tr [pSelectableRow]="collaboration" (click)="goToOfficeDetailsPage($event, collaboration, 'advertiser')">
      <td class="current">
        <i *ngIf="collaboration.isActive" class="pi pi-check"></i>
        <i *ngIf="!collaboration.isActive" class="pi pi-times"></i>
      </td>
      <td>{{ collaboration.company }}</td>
      <td class="date"><i class="pi pi-calendar"></i> {{ collaboration.startDate | date: 'yyyy' }}</td>
      <td class="date"><i class="pi pi-calendar"></i> {{ collaboration.endDate | date: 'yyyy' }}</td>
      <td>{{ collaboration.getBrandNames() || '-' }}</td>
      <td class="campaign">{{ collaboration.campaign || '-' }}</td>
      <td>{{ collaboration.getServiceNames() || '-' }}</td>
      <td>{{ collaboration.getAdvertiserInfoAdexRecords() || '-' }}</td>
      <td class="actions" *ngIf="hasRole('db-edit-offices')">
        <button
          pButton
          type="button"
          icon="pi pi-file-edit"
          class="p-button-outlined p-button-warning"
          (click)="editClicked($event, collaboration)"
        ></button>
        <button
          pButton
          type="button"
          icon="pi pi-trash"
          class="p-button-outlined p-button-warning"
          (click)="confirmDelete($event, collaboration)"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div class="flex align-items-center justify-content-between">
      In total there are {{ collaborations ? collaborations.length : 0 }} collaborations.
    </div>
  </ng-template>
</p-table>

<p-table
  *ngIf="mode === 'advertiser'"
  class="collaborations-table"
  styleClass="p-datatable-sm"
  [value]="filteredCollaborations"
  [loading]="isLoading"
  [scrollable]="true"
  selectionMode="single"
>
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-between">
      <p-selectButton
        [options]="enabledOptions"
        [(ngModel)]="selectedEnabledOptionValue"
        (onChange)="selectedEnabledOptionValueChanged()"
        optionLabel="label"
        optionValue="value"
        [multiple]="false"
      ></p-selectButton>
      <span class="p-float-label">
        <input
          id="float-input-collaborations-advertiser"
          type="text"
          pInputText
          [(ngModel)]="selectedSearchText"
          (ngModelChange)="searchTextChanged()"
          class="mr-2"
        />
        <label for="float-input-collaborations-advertiser">Search</label>
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-file-excel"
          (click)="exportAdvertiserCollaborationsToExcel()"
          class="p-button-success mr-2"
          pTooltip="Export to Excel"
          tooltipPosition="bottom"
          label="Excel"
        ></button>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th class="current">Current</th>
      <th pSortableColumn="company"><p-sortIcon field="company"></p-sortIcon> Office</th>
      <th>Agency Types</th>
      <th pSortableColumn="startDate" class="date"><p-sortIcon field="startDate"></p-sortIcon> Start</th>
      <th pSortableColumn="endDate" class="date"><p-sortIcon field="endDate"></p-sortIcon> End</th>
      <th>Brands</th>
      <th pSortableColumn="campaign"><p-sortIcon field="campaign"></p-sortIcon> Campaign</th>
      <th>Services</th>
      <th class="actions" *ngIf="hasRole('db-edit-offices')">Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-collaboration>
    <tr [pSelectableRow]="collaboration" (click)="goToOfficeDetailsPage($event, collaboration, 'agency')">
      <td class="current">
        <i *ngIf="collaboration.isActive" class="pi pi-check"></i>
        <i *ngIf="!collaboration.isActive" class="pi pi-times"></i>
      </td>
      <td>{{ collaboration.company }}</td>
      <td>{{ collaboration.getAgencyTypes() }}</td>
      <td class="date"><i class="pi pi-calendar"></i> {{ collaboration.startDate | date: 'yyyy' }}</td>
      <td class="date"><i class="pi pi-calendar"></i> {{ collaboration.endDate | date: 'yyyy' }}</td>
      <td>{{ collaboration.getBrandNames() || '-' }}</td>
      <td>{{ collaboration.campaign || '-' }}</td>
      <td>{{ collaboration.getServiceNames() || '-' }}</td>
      <td class="actions" *ngIf="hasRole('db-edit-offices')">
        <button
          pButton
          type="button"
          icon="pi pi-file-edit"
          class="p-button-outlined p-button-warning"
          (click)="editClicked($event, collaboration)"
        ></button>
        <button
          pButton
          type="button"
          icon="pi pi-trash"
          class="p-button-outlined p-button-warning"
          (click)="confirmDelete($event, collaboration)"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div class="flex align-items-center justify-content-between">
      In total there are {{ collaborations ? collaborations.length : 0 }} collaborations.
    </div>
  </ng-template>
</p-table>

<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
