<div class="form-container" *ngIf="office && office.agency">
  <div class="col-12 flex start">
    <app-office-summary [office]="office"></app-office-summary>
  </div>
  <div class="col-12 flex start">
    <p-multiSelect
      class="autocomplete-full"
      [options]="agencyServices"
      [(ngModel)]="selectedAgencyServices"
      optionLabel="name"
      appendTo="body"
      [style]="{ 'max-width': '600px', width: '100%' }"
    ></p-multiSelect>
  </div>
  <div class="col-12">
    <button
      pButton
      label="Update Types"
      type="button"
      (click)="updateClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
