import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-excel-too-large',
  templateUrl: './excel-too-large.component.html',
  styleUrls: ['./excel-too-large.component.scss'],
})
export class ExcelTooLargeComponent implements OnInit {
  public itemsCount = 0;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data.itemsCount) {
      this.itemsCount = this.config.data.itemsCount;
    }
  }

  ok() {
    this.ref.close(true);
  }

  cancel() {
    this.ref.close(false);
  }
}
