import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TabMenuModule } from 'primeng/tabmenu';
import { ComponentsModule } from '../components/components.module';
import { AdminsComponent } from './admins/admins.component';
import { CompaniesComponent } from './companies/companies.component';
import { LoginComponent } from './login/login.component';
import { PageRoutingModule } from './page-routing.module';
import { ProfessionalsComponent } from './professionals/professionals.component';
import { PersonDetailsComponent } from './person-details/person-details.component';
import { OfficeDetailsComponent } from './office-details/office-details.component';
import { NetworksHoldingsComponent } from './networks-holdings/networks-holdings.component';
import { HoldingDetailsComponent } from './holding-details/holding-details.component';
import { NetworkDetailsComponent } from './network-details/network-details.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';

@NgModule({
  declarations: [
    CompaniesComponent,
    ProfessionalsComponent,
    AdminsComponent,
    LoginComponent,
    OfficeDetailsComponent,
    PersonDetailsComponent,
    NetworksHoldingsComponent,
    HoldingDetailsComponent,
    NetworkDetailsComponent,
    CompanyDetailsComponent,
  ],
  imports: [CommonModule, TabMenuModule, ButtonModule, ComponentsModule, FormsModule, PageRoutingModule],
  exports: [CompaniesComponent, ProfessionalsComponent, AdminsComponent, LoginComponent],
})
export class PagesModule {}
