import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Person, UpdatePersonWavesCommand, Wave } from '../../../models';
import { ApiService } from '../../../shared/services';
import { BaseComponent } from '../../general/base/base.component';

@Component({
  selector: 'app-person-waves-form',
  templateUrl: './person-waves-form.component.html',
  styleUrls: ['./person-waves-form.component.scss'],
})
export class PersonWavesFormComponent extends BaseComponent implements OnInit {
  public person: Person | undefined = undefined;
  public waves: Wave[] = [];
  public selectedWaves: Wave[] = [];

  constructor(private apiService: ApiService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    super();
  }

  ngOnInit(): void {
    this.apiService.waves.find(undefined, undefined, this.globalCountryId).subscribe((waves: Wave[]) => {
      this.waves = waves;

      if (this.config.data.person) {
        this.person = this.config.data.person;
      }

      if (this.config.data.waves) {
        this.config.data.waves.forEach((wave: Wave) => {
          const index = this.waves.findIndex((w: Wave) => w.id === wave.id);
          if (index > -1) {
            this.selectedWaves.push(this.waves[index]);
          }
        });
      }
    });
  }

  updateClicked() {
    if (this.person) {
      const command = new UpdatePersonWavesCommand(
        this.person.id,
        this.selectedWaves.map((wave: Wave) => wave.id),
      );
      this.ref.close(command);
    }
  }

  cancelClicked() {
    this.ref.close();
  }
}
