import { Component, OnInit } from '@angular/core';
import { Holding, HoldingCommand } from '../../models';
import { ApiService, ToastService } from '../../shared/services';
import { BaseComponent } from '../../components/general/base/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { OfficeFormComponent } from '../../components/offices/office-form/office-form.component';
import { DialogService } from 'primeng/dynamicdialog';
import { HoldingFormComponent } from '../../components/holdings/holding-form/holding-form.component';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-holding-details',
  templateUrl: './holding-details.component.html',
  styleUrls: ['./holding-details.component.scss'],
})
export class HoldingDetailsComponent extends BaseComponent implements OnInit {
  public holding?: Holding;
  public holdingDeletedConfirmed = false;

  constructor(
    private apiService: ApiService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    this.apiService.holdings.getById(id).subscribe({
      next: (holding) => {
        this.holding = holding;
      },
      error: () => {
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We could not get holdings from the API`,
        });
      },
    });
  }

  editClicked($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    const holding = Object.create(this.holding || {});
    const ref = this.dialogService.open(HoldingFormComponent, {
      header: `Edit holding`,
      width: '600px',
      height: 'auto',
      data: {
        holding: holding,
        mode: 'edit',
      },
    });

    ref.onClose.subscribe((command: HoldingCommand) => {
      if (command) {
        this.apiService.holdings.update(holding.id, command).subscribe(
          (holding) => {
            this.holding = holding;
            this.toastService.send({
              severity: 'success',
              summary: 'Success',
              detail: 'Holding updated',
            });
          },
          () => {
            this.toastService.send({
              severity: 'error',
              summary: 'Error',
              detail: 'We could not update the holding',
            });
          },
        );
      }
    });
  }

  deleteClicked($event: boolean) {
    if ($event && this.holding) {
      this.apiService.holdings.delete(this.holding.id).subscribe({
        next: () => {
          this.holdingDeletedConfirmed = true;
          this.toastService.send({
            severity: 'success',
            summary: 'Success',
            detail: 'Holding deleted',
          });
        },
        error: () => {
          this.toastService.send({
            severity: 'error',
            summary: 'Error',
            detail: 'We could not delete the holding',
          });
        },
      });
    }
  }

  navigateToNetworksHoldings() {
    if (this.holding) {
      this.router.navigate(['/networks-holdings']);
    }
  }
}
