import { Component, OnInit, Input } from '@angular/core';
import { Holding } from '../../../models';
import { TreeNode } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-holding-structure',
  templateUrl: './holding-structure.component.html',
  styleUrls: ['./holding-structure.component.scss'],
})
export class HoldingStructureComponent implements OnInit {
  @Input() holding: Holding | undefined = undefined;
  public holdingTreeNodes: TreeNode[] = [];

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (this.holding) {
      this.holdingTreeNodes = this.mapHoldingToTreeNodes(this.holding);
    }
  }

  public mapHoldingToTreeNodes(holding: Holding): TreeNode[] {
    const holdingNode: TreeNode = {
      label: holding.name,
      icon: 'pi pi-globe',
      data: { type: 'holding', id: holding.id },
      expanded: true,
      children: [
        {
          label: 'Offices',
          data: { type: 'label', id: 'offices' },
          icon: 'pi pi-wifi',
          children: holding.offices.map((office) => {
            return {
              label: office.fullName,
              icon: 'pi pi-wifi',
              data: { type: 'office', id: office.id },
            } as TreeNode;
          }),
        },
        {
          label: 'Companies',
          data: { type: 'label', id: 'companies' },
          icon: 'pi pi-building',
          children: holding.companies.map((company) => {
            return {
              label: company.name,
              icon: 'pi pi-building',
              data: { type: 'company', id: company.id },
              children: [
                {
                  label: 'Offices',
                  data: { type: 'label', id: 'offices' },
                  icon: 'pi pi-wifi',
                  children: company.offices.map((office) => {
                    return {
                      label: office.fullName,
                      icon: 'pi pi-wifi',
                      data: { type: 'office', id: office.id },
                    } as TreeNode;
                  }),
                },
              ],
            } as TreeNode;
          }),
        },
        {
          label: 'Networks',
          data: { type: 'label', id: 'networks' },
          icon: 'pi pi-sitemap',
          children: holding.networks.map((network) => {
            return {
              label: network.name,
              icon: 'pi pi-sitemap',
              data: { type: 'network', id: network.id },
              children: [
                {
                  label: 'Offices',
                  data: { type: 'label', id: 'offices' },
                  icon: 'pi pi-wifi',
                  children: network.offices.map((office) => {
                    return {
                      label: office.fullName,
                      icon: 'pi pi-wifi',
                      data: { type: 'office', id: office.id },
                    } as TreeNode;
                  }),
                },
                {
                  label: 'Companies',
                  data: { type: 'label', id: 'companies' },
                  icon: 'pi pi-building',
                  children: network.companies.map((company) => {
                    return {
                      label: company.name,
                      icon: 'pi pi-building',
                      data: { type: 'company', id: company.id },
                      children: [
                        {
                          label: 'Offices',
                          data: { type: 'label', id: 'offices' },
                          icon: 'pi pi-wifi',
                          children: company.offices.map((office) => {
                            return {
                              label: office.fullName,
                              icon: 'pi pi-wifi',
                              data: { type: 'office', id: office.id },
                            } as TreeNode;
                          }),
                        },
                      ],
                    } as TreeNode;
                  }),
                },
              ],
            } as TreeNode;
          }),
        },
      ],
    };

    return [holdingNode];
  }

  selectionChanged($event: any) {
    if ($event.node.data.type === 'office') {
      this.router.navigate(['companies', 'office', $event.node.data.id]);
    } else if ($event.node.data.type === 'company') {
      this.router.navigate(['companies', 'company', $event.node.data.id]);
    } else if ($event.node.data.type === 'network') {
      this.router.navigate(['networks-holdings', 'network', $event.node.data.id]);
    } else if ($event.node.data.type === 'holding') {
      this.router.navigate(['networks-holdings', 'holding', $event.node.data.id]);
    }
  }
}
