export class InfoadexRecord {
  id: number;
  year?: number;
  ranking?: number;
  investment?: number;

  constructor(data: InfoadexRecord) {
    ({ id: this.id, year: this.year, ranking: this.ranking, investment: this.investment } = data);
  }
}

export class InfoadexRecordCommand {
  year?: number;
  ranking?: number;
  investment?: number;
}
