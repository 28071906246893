<div class="form-container" *ngIf="holding">
  <div class="col-12 flex start">
    <app-holding-summary [holding]="holding"></app-holding-summary>
  </div>

  <div class="col-12 flex flex-row align-items-end justify-content-start">
    <div class="col-12 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="name"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Holding Name"
          [(ngModel)]="holding.name"
          (ngModelChange)="holdingNameChanged()"
        />
        <label for="name">Holding Name</label>
      </span>
    </div>
  </div>
  <div class="col-12 flex flex-row align-items-end justify-content-start">
    <div class="p-2">
      <span><small>Is Enabled?</small></span>
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-selectButton
          id="isEnabled"
          [options]="enableOptions"
          [(ngModel)]="selectedEnableOption"
          optionLabel="label"
          (ngModelChange)="enableChanged()"
        ></p-selectButton>
      </span>
    </div>
    <div class="p-2">
      <span><small>Is Big Player?</small></span>
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-selectButton
          id="isBigPlayer"
          [options]="bigPlayerOptions"
          [(ngModel)]="selectedBigPlayerOption"
          optionLabel="label"
          (ngModelChange)="bigPlayerChanged()"
        ></p-selectButton>
      </span>
    </div>
  </div>

  <div class="col-12">
    <button
      *ngIf="mode === 'edit'"
      pButton
      label="Update Holding"
      type="button"
      (click)="updateClicked()"
      [disabled]="!updateHoldingEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      *ngIf="mode === 'create'"
      pButton
      label="Create Holding"
      type="button"
      (click)="updateClicked()"
      [disabled]="!updateHoldingEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
