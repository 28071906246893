import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { Office, SetMediaExclusiveCommand } from '../../../../models';
import { ApiService, ToastService } from '../../../../shared/services';
import { MediaExclusiveFormComponent } from '../media-exclusive-form/media-exclusive-form.component';
import { BaseComponent } from '../../../general/base/base.component';

@Component({
  selector: 'app-media-info',
  templateUrl: './media-info.component.html',
  styleUrls: ['./media-info.component.scss'],
})
export class MediaInfoComponent extends BaseComponent implements OnInit {
  @Input() public office: Office | undefined = undefined;
  public isLoading = true;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
  ) {
    super();
  }

  updateClicked() {
    const office = Object.create(this.office || {});
    if (office.media) {
      office.media = Object.create(office.media || {});
      if (office.media.exclusive) {
        office.media.exclusive = Object.create(office.media.exclusive || {});
      }
    }

    const ref = this.dialogService.open(MediaExclusiveFormComponent, {
      header: `Set Media Exclusive`,
      width: '700px',
      height: 'auto',
      data: {
        office: office,
      },
    });

    ref.onClose.subscribe((command: SetMediaExclusiveCommand) => {
      if (command && this.office) {
        this.isLoading = true;
        this.apiService.medias.setExclusive(this.office.id, command).subscribe(
          (media) => {
            if (this.office) {
              this.office.media = media;
            }
            this.isLoading = false;
            this.toastService.send({
              severity: 'success',
              summary: 'Updated',
              detail: 'You have updated the exclusive for this media',
            });
          },
          () => {
            this.isLoading = false;
            this.toastService.send({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error updating the exclusive for this media',
            });
          },
        );
      }
    });
  }

  ngOnInit(): void {
    this.isLoading = false;
  }
}
