import { Component, Input, OnInit } from '@angular/core';
import { Company, Network } from '../../../models';
import { TreeNode } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-structure',
  templateUrl: './company-structure.component.html',
  styleUrls: ['./company-structure.component.scss'],
})
export class CompanyStructureComponent implements OnInit {
  @Input() company: Company | undefined = undefined;
  public companyTreeNodes: TreeNode[] = [];

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (this.company) {
      this.companyTreeNodes = this.mapCompanyToTreeNodes(this.company);
    }
  }

  public mapCompanyToTreeNodes(company: Company): TreeNode[] {
    const companyNode: TreeNode = {
      label: company.name,
      icon: 'pi pi-building',
      data: { type: 'company', id: company.id },
      expanded: true,
      children: [
        {
          label: 'Offices',
          data: { type: 'label', id: 'offices' },
          icon: 'pi pi-wifi',
          expanded: true,
          children: company.offices.map((office) => {
            return {
              label: office.fullName,
              icon: 'pi pi-wifi',
              data: { type: 'office', id: office.id },
              expanded: true,
            } as TreeNode;
          }),
        },
      ],
    } as TreeNode;

    return [companyNode];
  }

  selectionChanged($event: any) {
    if ($event.node.data.type === 'office') {
      this.router.navigate(['companies', 'office', $event.node.data.id]);
    } else if ($event.node.data.type === 'company') {
      this.router.navigate(['companies', 'company', $event.node.data.id]);
    } else if ($event.node.data.type === 'network') {
      this.router.navigate(['networks-holdings', 'network', $event.node.data.id]);
    } else if ($event.node.data.type === 'holding') {
      this.router.navigate(['networks-holdings', 'holding', $event.node.data.id]);
    }
  }
}
