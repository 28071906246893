import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AgencyService, CreateAgencyServiceCommand, UpdateAgencyServiceCommand } from '../../../../models';
import { ISearchQuery } from '../../../../shared/interfaces';
import { ApiService } from '../../../../shared/services';
import { AgenciesServicesFormComponent } from '../agencies-services-form/agencies-services-form.component';

@Component({
  selector: 'app-agencies-services-table',
  templateUrl: './agencies-services-table.component.html',
  styleUrls: ['./agencies-services-table.component.scss'],
})
export class AgenciesServicesTableComponent implements OnInit {
  public agencyServices: AgencyService[] = [];

  public filteredAgencyServices: AgencyService[] = [];

  public isLoading = true;

  public first = 0;

  public page = 1;

  public pageSize = 10;

  public availablePageSizes = environment.availablePageSizes;

  public agencyServicesResult?: ISearchQuery<AgencyService[]>;

  selectedSearchText = '';

  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.refresh();
    this.searchText$.subscribe(() => {
      this.first = 0;
      this.filteredAgencyServices = this.agencyServices.filter((service) =>
        service.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  refresh(): void {
    this.agencyServices = [];
    this.isLoading = true;
    this.apiService.agencies.getAllServices().subscribe({
      next: (agencyServices) => {
        this.agencyServices = agencyServices;
        this.filteredAgencyServices = [...this.agencyServices];
        this.isLoading = false;
      },
      error: (error: unknown) => {
        console.error(error);
      },
    });
  }

  public createClicked(): void {
    const ref = this.dialogService.open(AgenciesServicesFormComponent, {
      header: $localize`Add new agency services`,
      width: '30%',
      height: 'auto',
    });

    ref.onClose.subscribe({
      next: (command: CreateAgencyServiceCommand) => {
        if (command) {
          this.apiService.agencies.createService(command).subscribe({
            next: (service) => {
              this.addAgencyService(service);
              this.filteredAgencyServices = [...this.agencyServices];
              this.messageService.add({
                severity: 'success',
                summary: 'Created',
                detail: $localize`You have added a new agency service`,
              });
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: $localize`An error occurred while creating the agency service`,
              });
            },
          });
        }
      },
      error: (error: unknown) => {
        console.error(error);
      },
    });
  }

  public editClicked($event: MouseEvent, service: AgencyService): void {
    $event.preventDefault();
    $event.stopPropagation();

    const ref = this.dialogService.open(AgenciesServicesFormComponent, {
      header: $localize`Edit agency service`,
      width: '30%',
      height: 'auto',
      data: {
        service,
      },
    });

    ref.onClose.subscribe((command: UpdateAgencyServiceCommand) => {
      if (command) {
        this.apiService.agencies.updateService(command.id, command).subscribe({
          next: (service) => {
            this.updateAgencyService(service);
            this.filteredAgencyServices = [...this.agencyServices];
            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: $localize`You have updated the agency service`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while updating the agency service`,
            });
          },
        });
      }
    });
  }

  public confirmDelete($event: MouseEvent, service: AgencyService): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: $localize`Are you sure that you want to delete this agency service?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiService.agencies.deleteService(service.id).subscribe({
          next: () => {
            this.removeAgencyService(service);
            this.filteredAgencyServices = [...this.agencyServices];
            this.messageService.add({
              severity: 'success',
              summary: 'Deleted',
              detail: $localize`You have deleted the agency service`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while deleting the agency service`,
            });
          },
        });
      },
      reject: () => undefined,
    });
  }

  public addAgencyService(service: AgencyService): void {
    this.agencyServices.push(service);
  }

  public updateAgencyService(service: AgencyService): void {
    const index = this.agencyServices.findIndex((agencyService) => agencyService.id === service.id);

    if (index >= 0) {
      this.agencyServices[index] = service;
    }
  }

  public removeAgencyService(service: AgencyService): void {
    const index = this.agencyServices.findIndex((agencyService) => agencyService.id === service.id);

    if (index >= 0) {
      this.agencyServices.splice(index, 1);
    }
  }

  public next(): void {
    this.first = this.first + this.pageSize;
  }

  public prev(): void {
    this.first = this.first - this.pageSize;
  }

  public reset(): void {
    this.first = 0;
  }

  public isLastPage(): boolean {
    return this.agencyServicesResult?.count ? this.first === this.agencyServicesResult.count - this.pageSize : true;
  }

  public isFirstPage(): boolean {
    return this.agencyServicesResult?.count ? this.first === 0 : true;
  }

  public translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.filteredAgencyServices.length
    } entries`;
  }

  public searchTextChanged(): void {
    this.searchText$.next(this.selectedSearchText);
  }
}
