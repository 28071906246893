<div class="form-container" *ngIf="office">
  <div class="col-12 flex start">
    <app-office-summary [office]="office"></app-office-summary>
  </div>

  <div class="col-12 flex align-items-end justify-content-start">
    <div class="col-10 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="name"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Office Name"
          [(ngModel)]="office.name"
          (ngModelChange)="officeNameChanged()"
        />
        <label for="name">Office Name</label>
      </span>
    </div>
    <div class="col-2 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="establishedYear"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Year"
          [(ngModel)]="office.establishedYear"
        />
        <label for="establishedYear">Established Year</label>
      </span>
    </div>
  </div>

  <div class="col-12 flex align-items-end justify-content-start">
    <div class="col-3 p-2">
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-dropdown
          id="country"
          placeholder="Country"
          [options]="countries"
          [(ngModel)]="office.country"
          (ngModelChange)="countryChanged()"
          optionLabel="name"
          appendTo="body"
          class="w-full"
          [style]="{ width: '100%' }"
        ></p-dropdown>
        <label for="country">Country</label>
      </span>
    </div>
    <div class="col-5 p-2">
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-dropdown
          *ngIf="office && office.address && office.country"
          id="province"
          placeholder="Province"
          [options]="office.country.provinces"
          [(ngModel)]="office.address.province"
          optionLabel="name"
          appendTo="body"
          class="w-full"
          [style]="{ width: '100%' }"
        ></p-dropdown>
        <label *ngIf="office && office.address && office.country" for="province">Province</label>
      </span>
    </div>
    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="email"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Email"
          [(ngModel)]="office.email"
          (ngModelChange)="emailChanged()"
          email
        />
        <label for="email">Email</label>
      </span>
    </div>
  </div>

  <div class="col-12 flex align-items-end justify-content-start">
    <div class="col-6 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          *ngIf="office && office.address"
          id="address"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Address"
          [(ngModel)]="office.address.addressLine"
        />
        <label for="address">Address</label>
      </span>
    </div>
    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          *ngIf="office && office.address"
          id="town"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Town"
          [(ngModel)]="office.address.town"
        />
        <label for="town">Town</label>
      </span>
    </div>
    <div class="col-2 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          *ngIf="office && office.address"
          id="postalCode"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Zip Code"
          [(ngModel)]="office.address.postalCode"
        />
        <label for="postalCode">Zip Code</label>
      </span>
    </div>
  </div>

  <div class="col-12 flex align-items-end justify-content-start">
    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="web"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Web"
          [(ngModel)]="office.web"
        />
        <label for="web">Web</label>
      </span>
    </div>
    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="phone"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Phone"
          [(ngModel)]="office.phone"
        />
        <label for="phone">Phone</label>
      </span>
    </div>
    <div class="col-4 p-2">
      <!-- <span class="p-float-label p-input-icon-right w-full">
        <input
          id="fax"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Fax"
          [(ngModel)]="office.fax"
        />
        <label for="fax">Fax</label>
         </span>-->
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="groupName"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Group Name"
          [(ngModel)]="office.groupName"
        />
        <label for="groupName">Group Name</label>
      </span>
    </div>
  </div>

  <div class="col-12 flex align-items-start justify-content-start">
    <div class="col-4 p-2">
      <span class="p-float-label inline-block w-full">
        <p-autoComplete
          id="office-company"
          [ngModel]="selectedCompany"
          (ngModelChange)="onCompanyItemChange($event)"
          [suggestions]="companyResult"
          [virtualScroll]="true"
          [virtualScrollItemSize]="40"
          [virtualScrollOptions]="companiesVirtualScroll"
          (completeMethod)="filterCompanies($event)"
          i18n-field
          field="name"
          [dropdown]="true"
          placeholder="Select a company..."
          styleClass="w-full autocomplete-full"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
        >
        </p-autoComplete>
        <label i18n for="office-company" class="company-label">Company</label>
      </span>
    </div>
    <div class="col-4 p-2">
      <span class="p-float-label inline-block w-full">
        <p-autoComplete
          id="office-network"
          [ngModel]="selectedNetwork"
          (ngModelChange)="onNetworkItemChange($event)"
          [suggestions]="networkResult"
          [virtualScroll]="true"
          [virtualScrollItemSize]="40"
          [virtualScrollOptions]="networksVirtualScroll"
          (completeMethod)="filterNetworks($event)"
          i18n-field
          field="name"
          [dropdown]="true"
          placeholder="Select a network..."
          styleClass="w-full autocomplete-full"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
        >
        </p-autoComplete>
        <label i18n for="office-network" class="company-label">Network</label>
      </span>
    </div>
    <div class="col-4 p-2">
      <span class="p-float-label inline-block w-full">
        <p-autoComplete
          id="office-holding"
          [ngModel]="selectedHolding"
          (ngModelChange)="onHoldingItemChange($event)"
          [suggestions]="holdingResult"
          [virtualScroll]="true"
          [virtualScrollItemSize]="40"
          [virtualScrollOptions]="holdingsVirtualScroll"
          (completeMethod)="filterHoldings($event)"
          i18n-field
          field="name"
          [dropdown]="true"
          placeholder="Select a holding..."
          styleClass="w-full autocomplete-full"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
        >
        </p-autoComplete>
        <label i18n for="office-holding" class="company-label">Holdings</label>
      </span>
    </div>
  </div>

  <!--<div class="col-12 flex align-items-end justify-content-start">
    <div class="col-6 p-2">

    </div>
    <div class="col-6 p-2">
      &lt;!&ndash; <span class="p-float-label p-input-icon-right w-full">
        <input
          id="networkName"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Network Name"
          [(ngModel)]="office.networkName"
        />
        <label for="networkName">Network Name</label>
      </span>&ndash;&gt;
    </div>
  </div>-->

  <div class="col-12 flex align-items-end justify-content-start">
    <div class="col-12 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="observations"
          type="text"
          pInputTextarea
          class="p-inputtext-sm w-full"
          placeholder="Observations"
          [(ngModel)]="office.observations"
        />
        <label for="observations">Observations</label>
      </span>
    </div>
  </div>

  <div class="col-12 flex align-items-end justify-content-start">
    <div class="col-8 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <p-autoComplete
          id="officeTypes"
          [(ngModel)]="office.officeTypes"
          [suggestions]="filteredOfficeTypes"
          (completeMethod)="searchOfficeType($event)"
          [dropdown]="true"
          [multiple]="true"
          field="name"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          class="autocomplete-full"
          appendTo="body"
          (ngModelChange)="officeTypesChanged()"
        ></p-autoComplete>
        <label for="officeTypes">Company Types</label>
      </span>
    </div>
    <!-- <div class="col-2 p-2">
      <span><small>Is Headquarter?</small></span>
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-selectButton
          id="headquarter"
          [options]="isHeadquarterOptions"
          [(ngModel)]="selectedHeadquarterOption"
          optionLabel="label"
        ></p-selectButton>
      </span>
    </div>-->
    <div class="col-2 p-2">
      <span><small>Is Enabled?</small></span>
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-selectButton
          id="isEnabled"
          [options]="enableOptions"
          [(ngModel)]="selectedEnableOption"
          optionLabel="label"
        ></p-selectButton>
      </span>
    </div>
  </div>

  <div class="col-12">
    <button
      *ngIf="mode === 'edit'"
      pButton
      label="Update Office"
      type="button"
      (click)="updateClicked()"
      [disabled]="!updateOfficeEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      *ngIf="mode === 'create'"
      pButton
      label="Create Office"
      type="button"
      (click)="updateClicked()"
      [disabled]="!updateOfficeEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
