import { Pipe, PipeTransform } from '@angular/core';
import { UtcDateConverter } from './utc-date-converter';

@Pipe({
  name: 'utcDate',
})
export class UtcDatePipe implements PipeTransform {
  transform(value: Date | undefined): Date | undefined {
    return UtcDateConverter.convert(value);
  }
}
