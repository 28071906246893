import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'src/app/components/general/base/base.component';
import { CreateWaveCommand, UpdateWaveCommand, Wave } from 'src/app/models';
import { ISearchQuery } from 'src/app/shared/interfaces';
import { ApiService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { WavesFormComponent } from '../waves-form/waves-form.component';

@Component({
  selector: 'app-waves-table',
  templateUrl: './waves-table.component.html',
  styleUrls: ['./waves-table.component.scss'],
})
export class WavesTableComponent extends BaseComponent implements OnInit {
  public waves: Wave[] = [];

  public filteredWaves: Wave[] = [];

  public isLoading = true;

  public first = 0;

  public page = 1;

  public pageSize = 10;

  public availablePageSizes = environment.availablePageSizes;

  public wavesResult?: ISearchQuery<Wave[]>;

  public selectedSearchText = '';

  public searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.refresh();
    this.searchText$.subscribe(() => {
      this.first = 0;
      this.filteredWaves = this.waves.filter((wave) =>
        wave.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  private refresh(): void {
    this.waves = [];
    this.isLoading = true;
    this.apiService.waves.find(undefined, undefined, this.globalCountryId).subscribe({
      next: (waves) => {
        this.waves = waves;
        this.filteredWaves = [...this.waves];
        this.isLoading = false;
      },
      error: (error: unknown) => {
        console.error(error);
      },
    });
  }

  public createClicked(): void {
    this.apiService.products.find().subscribe((products) => {
      this.apiService.countries.find(this.globalCountryId, { includeProvinces: false }).subscribe((countries) => {
        const ref = this.dialogService.open(WavesFormComponent, {
          header: $localize`Add new wave`,
          width: '30%',
          height: 'auto',
          data: {
            countries,
            products,
            waves: this.waves,
          },
        });

        ref.onClose.subscribe({
          next: (command: CreateWaveCommand) => {
            if (command) {
              this.apiService.waves.create(command).subscribe({
                next: (wave) => {
                  this.addWave(wave);
                  this.filteredWaves = [...this.waves];
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Created',
                    detail: $localize`You have added a new wave`,
                  });
                },
                error: () => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: $localize`An error occurred while creating the wave`,
                  });
                },
              });
            }
          },
          error: (error: unknown) => {
            console.error(error);
          },
        });
      });
    });
  }

  public editClicked($event: MouseEvent, updatedWave: Wave): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.apiService.products.find().subscribe((products) => {
      this.apiService.countries.find(this.globalCountryId, { includeProvinces: false }).subscribe((countries) => {
        const ref = this.dialogService.open(WavesFormComponent, {
          header: $localize`Edit wave`,
          width: '30%',
          height: 'auto',
          data: {
            wave: updatedWave,
            products,
            countries,
            waves: this.waves,
          },
        });

        ref.onClose.subscribe((command: UpdateWaveCommand) => {
          if (command) {
            this.apiService.waves.update(command.id, command).subscribe({
              next: (wave) => {
                this.updateWave(wave);
                this.filteredWaves = [...this.waves];
                this.messageService.add({
                  severity: 'success',
                  summary: 'Updated',
                  detail: $localize`You have updated the wave`,
                });
              },
              error: () => {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: $localize`An error occurred while updating the wave`,
                });
              },
            });
          }
        });
      });
    });
  }

  public confirmDelete($event: MouseEvent, wave: Wave): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: $localize`Are you sure that you want to delete this wave?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiService.waves.delete(wave.id).subscribe({
          next: () => {
            this.removeWave(wave);
            this.filteredWaves = [...this.waves];
            this.messageService.add({
              severity: 'success',
              summary: 'Deleted',
              detail: $localize`You have deleted the wave`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while deleting the wave`,
            });
          },
        });
      },
      reject: () => undefined,
    });
  }

  public addWave(wave: Wave): void {
    this.waves.unshift(wave);
  }

  public updateWave(wave: Wave): void {
    const index = this.waves.findIndex((w) => w.id === wave.id);

    if (index >= 0) {
      this.waves[index] = wave;
    }
  }

  public removeWave(wave: Wave): void {
    const index = this.waves.findIndex((w) => w.id === wave.id);

    if (index >= 0) {
      this.waves.splice(index, 1);
    }
  }

  public next(): void {
    this.first = this.first + this.pageSize;
  }

  public prev(): void {
    this.first = this.first - this.pageSize;
  }

  public reset(): void {
    this.first = 0;
  }

  public isLastPage(): boolean {
    return this.wavesResult?.count ? this.first === this.wavesResult.count - this.pageSize : true;
  }

  public isFirstPage(): boolean {
    return this.wavesResult?.count ? this.first === 0 : true;
  }

  public translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.filteredWaves.length
    } entries`;
  }

  public searchTextChanged(): void {
    this.searchText$.next(this.selectedSearchText);
  }
}
