import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import libPhoneNumber from 'libphonenumber-js';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Office, UpdateOfficeCommand } from '../../../models';
import { ApiService, ToastService } from '../../../shared/services';
import { OfficeFormComponent } from '../office-form/office-form.component';
import { BaseComponent } from '../../general/base/base.component';
import { CountryService } from '../../../shared/services/country.service';

@Component({
  selector: 'app-office-info',
  templateUrl: './office-info.component.html',
  styleUrls: ['./office-info.component.scss'],
})
export class OfficeInfoComponent extends BaseComponent {
  @Input() public office: Office | undefined = undefined;

  public officeDeletedConfirmed = false;
  constructor(
    private apiService: ApiService,
    private readonly countryService: CountryService,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
  ) {
    super();
  }

  public phoneNumberToIntlFormat(phone?: string | number): string {
    let finalPhone = `${phone}`;
    if (!/^\+/.test(`${phone}`)) {
      finalPhone = `+${phone}`;
    }
    const phoneNumber = libPhoneNumber(finalPhone);
    if (phoneNumber) {
      return phoneNumber?.formatInternational();
    }
    return $localize`invalid: ${phone}`;
  }

  editClicked($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    const office = Object.create(this.office || {});
    office.address = Object.create(office.address || {});

    this.countryService.find({ includeProvinces: true }).subscribe((countries) => {
      const ref = this.dialogService.open(OfficeFormComponent, {
        header: `Edit office`,
        width: '50%',
        height: 'auto',
        data: {
          office: office,
          countries: countries,
        },
      });

      ref.onClose.subscribe((command: UpdateOfficeCommand) => {
        if (command) {
          this.apiService.offices.update(office.id, command).subscribe(
            (data) => {
              if (data.country || data.address?.country) {
                const country = data.country || data.address?.country;
                if (country) {
                  this.countryService.findById(country.id).subscribe((country) => {
                    data.country = country;
                    this.office = data;
                    this.toastService.send({
                      severity: 'success',
                      summary: 'Updated',
                      detail: 'You have updated this office',
                    });
                  });
                }
              } else {
                this.office = data;
                this.toastService.send({
                  severity: 'success',
                  summary: 'Updated',
                  detail: 'You have updated this office',
                });
              }
            },
            () => {
              this.toastService.send({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while updating the office',
              });
            },
          );
        }
      });
    });
  }

  deleteClicked($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: 'Are you sure that you want to delete this office?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.office) {
          this.apiService.offices.delete(this.office.id).subscribe({
            next: () => {
              this.toastService.send({
                severity: 'success',
                summary: 'Deleted',
                detail: 'You have deleted this office',
              });
              this.officeDeletedConfirmed = true;
              //this.router.navigate(['/professionals']);
            },
            error: () => {
              this.toastService.send({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while deleting the office',
              });
            },
          });
        }
      },
      reject: () => undefined,
    });
  }

  navigateToOffices() {
    this.router.navigate(['/companies']);
  }
}
