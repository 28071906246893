<div class="section-container" *ngIf="office && office.advertiser">
  <div class="flex align-items-start justify-content-between">
    <h3>Advertiser Sectors</h3>
    <button
      *ngIf="hasRole('db-edit-offices')"
      pButton
      label="Edit Sectors"
      type="button"
      (click)="updateClicked()"
      class="p-button-raised p-button-warning"
      icon="pi pi-save"
      style="margin-bottom: 10px"
    ></button>
  </div>

  <p-tree [value]="sectorsTreeNodes"></p-tree>
</div>
