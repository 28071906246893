export class UtcDateConverter {
  public static convert(value: Date | undefined): Date | undefined {
    if (!value) return undefined;

    return new Date(
      Date.UTC(
        value.getFullYear(),
        value.getMonth(),
        value.getDate(),
        value.getHours(),
        value.getMinutes(),
        value.getSeconds(),
        value.getMilliseconds(),
      ),
    );
  }
}
