import { BehaviorSubject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';

import { ApiService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { Festival, FestivalCommand, UpdateFestivalCommand } from 'src/app/models/festival.model';
import { ISearchQuery } from 'src/app/shared/interfaces';
import { FestivalsFormComponent } from '../festivals-form/festivals-form.component';
import { Event } from 'src/app/models/event.model';

@Component({
  selector: 'app-festivals-table',
  templateUrl: './festivals-table.component.html',
  styleUrls: ['./festivals-table.component.scss'],
})
export class FestivalsTableComponent implements OnInit {
  public festivals: Festival[] = [];
  public filteredFestivals: Festival[] = [];

  public isLoading = true;

  public first = 0;
  public page = 1;
  public pageSize = 10;
  public availablePageSizes: number[] = environment.availablePageSizes;
  public festivalsResult?: ISearchQuery<Festival[]>;

  public selectedSearchText = '';
  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.refresh();
    this.searchText$.subscribe(() => {
      this.first = 0;
      this.filteredFestivals = this.festivals.filter((festival: Festival) =>
        festival.event?.name.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  refresh(): void {
    this.festivals = [];
    this.isLoading = true;
    this.apiService.festivals.find().subscribe({
      next: (festivals: Festival[]) => {
        this.festivals = festivals;
        this.filteredFestivals = [...this.festivals];
        this.isLoading = false;
      },
    });
  }

  createClicked() {
    this.apiService.events.find().subscribe((events: Event[]) => {
      const ref = this.dialogService.open(FestivalsFormComponent, {
        header: $localize`Add new festival`,
        width: '30%',
        height: 'auto',
        data: {
          events: events,
          festivals: this.festivals,
        },
      });

      ref.onClose.subscribe({
        next: (command: FestivalCommand) => {
          if (command) {
            this.apiService.festivals.create(command).subscribe({
              next: (festival: Festival) => {
                this.addFestival(festival);
                this.filteredFestivals = [...this.festivals];
                this.messageService.add({
                  severity: 'success',
                  summary: 'Created',
                  detail: $localize`You have added a new festival`,
                });
              },
              error: () => {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: `An error occurred while creating the festival`,
                });
              },
            });
          }
        },
        error: (error: unknown) => console.error(error),
      });
    });
  }

  editClicked($event: MouseEvent, updatedFestival: Festival) {
    $event.preventDefault();
    $event.stopPropagation();
    this.apiService.events.find().subscribe((events: Event[]) => {
      const ref = this.dialogService.open(FestivalsFormComponent, {
        header: `Edit Project`,
        width: '30%',
        height: 'auto',
        data: {
          festival: updatedFestival,
          events,
          festivals: this.festivals,
        },
      });

      ref.onClose.subscribe((command: UpdateFestivalCommand) => {
        if (command) {
          this.apiService.festivals.update(command).subscribe({
            next: (festival: Festival) => {
              this.updateFestival(festival);
              this.filteredFestivals = [...this.festivals];
              this.messageService.add({
                severity: 'success',
                summary: 'Updated',
                detail: $localize`You have updated the festival`,
              });
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: $localize`An error occurred while updating the festival`,
              });
            },
          });
        }
      });
    });
  }

  confirmDelete($event: MouseEvent, festival: Festival) {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: $localize`Are you sure that you want to delete this festival?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiService.festivals.delete(festival.id).subscribe({
          next: () => {
            this.removeFestival(festival);
            this.filteredFestivals = [...this.festivals];
            this.messageService.add({
              severity: 'success',
              summary: 'Deleted',
              detail: $localize`You have deleted the festival`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while deleting the festival`,
            });
          },
        });
      },
      reject: () => undefined,
    });
  }

  public addFestival(festival: Festival) {
    this.festivals.unshift(festival);
  }

  public updateFestival(festival: Festival) {
    const index = this.festivals.findIndex((f) => f.id === festival.id);
    if (index >= 0) {
      this.festivals[index] = festival;
    }
  }

  public removeFestival(festival: Festival) {
    const index = this.festivals.findIndex((f) => f.id === festival.id);
    if (index >= 0) {
      this.festivals.splice(index, 1);
    }
  }

  next() {
    this.first = this.first + this.pageSize;
  }

  prev() {
    this.first = this.first - this.pageSize;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.festivalsResult?.count ? this.first === this.festivalsResult.count - this.pageSize : true;
  }

  isFirstPage(): boolean {
    return this.festivalsResult?.count ? this.first === 0 : true;
  }

  translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.filteredFestivals.length
    } entries`;
  }

  searchTextChanged() {
    this.searchText$.next(this.selectedSearchText);
  }
}
