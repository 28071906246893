<div class="p-4 py-8 w-10 mx-auto my-0">
  <div class="flex align-items-start justify-content-between">
    <h3 i18n>Subsectors</h3>
    <button
      pButton
      *ngIf="sector"
      label="Add Subsector"
      type="button"
      class="p-button-raised p-button-warning"
      icon="pi pi-plus-circle"
      style="margin-bottom: 10px"
      (click)="createClicked()"
      [disabled]="!sector"
    ></button>
  </div>
  <p-table
    #dt
    class="subsectors-table"
    styleClass="p-datatable-sm"
    [value]="filteredSubsectors"
    [paginator]="sector ? true : false"
    [(rows)]="pageSize"
    [(first)]="first"
    [showCurrentPageReport]="true"
    [currentPageReportTemplate]="translateCurrentPageReportTemplate()"
    [resizableColumns]="true"
    [rowsPerPageOptions]="availablePageSizes"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between py-3">
        <span class="p-float-label w-3">
          <p-dropdown
            id="sector"
            placeholder="Sector"
            [options]="sectors"
            [(ngModel)]="sector"
            (ngModelChange)="setSubsectors()"
            optionLabel="name"
            styleClass="w-full"
            appendTo="body"
          ></p-dropdown>
          <label for="sector" i18n>Sector</label>
        </span>
        <div class="pt-3 pr-8 mr-8">
          <span *ngIf="!sector" class="pr-8 mr-8" i18n>To add, modify or remove subsectors, select a sector</span>
        </div>
        <span [hidden]="!sector" class="p-float-label">
          <input
            [hidden]="!sector"
            id="float-input"
            type="text"
            pInputText
            [disabled]="!sector"
            [(ngModel)]="selectedSearchText"
            (ngModelChange)="searchTextChanged()"
          />
          <label [hidden]="!sector" for="float-input" i18n>Search</label>
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngIf="sector" class="current" i18n>Name</th>
        <th *ngIf="sector" class="current text-right pr-6" i18n>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-subsector>
      <tr>
        <td>{{ subsector.name }}</td>
        <td class="actions text-right">
          <button
            pButton
            type="button"
            icon="pi pi-file-edit"
            class="p-button-outlined p-button-warning"
            (click)="editClicked($event, subsector)"
          ></button>
          <button
            pButton
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-warning"
            (click)="confirmDelete($event, subsector)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-toast></p-toast>
  <p-confirmPopup></p-confirmPopup>
</div>
