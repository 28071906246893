<div class="col-12 flex flex-column">
  <div class="col-12 flex flex-row">
    <div class="col-12 flex flex-column">
      <p>
        The query you are about to export to Excel has <strong>{{ itemsCount }} items</strong>. It could take a few
        seconds to complete, or even a minute.
      </p>
      <p>While the file is being generated, you can keep using the application normally.</p>
    </div>
  </div>
  <div class="col-12 flex flex-row align-content-end justify-content-end">
    <button
      pButton
      label="I'll filter the results first"
      type="button"
      (click)="cancel()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
    <button
      pButton
      label="Continue"
      type="button"
      (click)="ok()"
      class="p-button-raised excel-button standard-button-within-form"
      icon="pi pi-file-excel"
    ></button>
  </div>
</div>
