<div class="section-container" *ngIf="office && office.media">
  <div class="flex align-items-start justify-content-between">
    <h3>Exclusive</h3>
    <button
      *ngIf="hasRole('db-edit-offices')"
      pButton
      label="Set Exclusive"
      type="button"
      (click)="updateClicked()"
      class="p-button-raised p-button-warning"
      icon="pi pi-save"
      style="margin-bottom: 10px"
    ></button>
  </div>

  <p-chip *ngIf="office.media.exclusive">
    {{ office.media.exclusive.fullName }}
  </p-chip>
  <h4 *ngIf="!office.media.exclusive">No exclusive assigned</h4>
</div>
