import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateMediaTypeCommand, MediaType, UpdateMediaTypeCommand } from '../../../../models';

@Component({
  selector: 'app-media-types-form',
  templateUrl: './media-types-form.component.html',
  styleUrls: ['./media-types-form.component.scss'],
})
export class MediaTypesFormComponent implements OnInit {
  public mode: 'create' | 'edit' = 'create';

  public addMediaTypeEnabled = false;

  public currentName = '';

  public command: CreateMediaTypeCommand | UpdateMediaTypeCommand = {
    name: '',
  };

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data?.mediaType) {
      this.mode = 'edit';
      this.fillData(this.config.data.mediaType);
    } else {
      this.mode = 'create';
    }
  }

  private fillData(mediaType: MediaType) {
    this.currentName = mediaType.name;
    this.command = mediaType;
  }

  public createClicked(): void {
    if (this.addMediaTypeEnabled) {
      this.ref.close(this.command);
    }
  }

  public cancelClicked(): void {
    this.ref.close();
  }

  public nameChanged(): void {
    this.isAddMediaTypeEnabled();
  }

  public isAddMediaTypeEnabled(): void {
    if (this.mode === 'edit') {
      this.addMediaTypeEnabled = !!(
        this.command.name &&
        this.command.name.trim().length &&
        this.command.name.trim() !== this.currentName.trim()
      );
    } else {
      this.addMediaTypeEnabled = !!(this.command.name && this.command.name.trim().length);
    }
  }
}
