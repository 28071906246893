import { BaseModel } from './base.model';
import { Holding } from './holding.model';
import { Network } from './network.model';
import { Office } from './office.model';

export class Company extends BaseModel {
  public name: string;

  public observations: string;

  public list: string;

  public researchId: number;

  public offices: Office[] = [];

  public networkId: number;

  public network?: Network;

  public holdingId: number;

  public holding?: Holding;

  public isEnabled: boolean;
  public lastReview?: string;

  constructor(data: Company) {
    super(data);

    ({
      name: this.name,
      observations: this.observations,
      list: this.list,
      researchId: this.researchId,
      networkId: this.networkId,
      holdingId: this.holdingId,
      isEnabled: this.isEnabled,
      lastReview: this.lastReview,
    } = data);

    if (data.network) {
      this.network = new Network(data.network);
    }

    if (data.holding) {
      this.holding = new Holding(data.holding);
    }

    if (data.offices && data.offices.length) {
      this.offices = data.offices.map((office) => new Office(office));
    }
  }

  public static createNewBlankCompany(): Company {
    return new Company({
      id: 0,
      name: '',
      observations: '',
      list: '',
      researchId: 0,
      networkId: 0,
      holdingId: 0,
      isEnabled: true,
      lastReview: '',
      offices: [],
    });
  }
}

export class CompanyCommand {
  public name: string;
  public observations: string;
  public list: string;
  public researchId: number | undefined;
  public networkId: number | undefined;
  public holdingId: number | undefined;
  public isEnabled: boolean;

  constructor(data: CompanyCommand) {
    ({
      name: this.name,
      observations: this.observations,
      list: this.list,
      researchId: this.researchId,
      networkId: this.networkId,
      holdingId: this.holdingId,
      isEnabled: this.isEnabled,
    } = data);
  }
}
