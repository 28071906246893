<app-agencies-services-table
  *ngIf="routerActive !== undefined && routerActive.startsWith('agency-services')"
></app-agencies-services-table>
<app-agencies-types-table
  *ngIf="routerActive !== undefined && routerActive.startsWith('agency-types')"
></app-agencies-types-table>
<div class="col-12" *ngIf="routerActive !== undefined && routerActive.startsWith('sectors')">
  <div class="col-12 justify-content-center w-full py-5">
    <p-tabView class="sectorsandsubsectors-tabs" *ngIf="true">
      <p-tabPanel header="Sectors">
        <app-sectors-table (updateSectors)="updateSectors($event)"></app-sectors-table>
      </p-tabPanel>
      <p-tabPanel header="Subsectors">
        <app-subsectors-table [sectors]="sectors"></app-subsectors-table>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
<app-brands-table *ngIf="routerActive !== undefined && routerActive.startsWith('brands')"> </app-brands-table>
<div class="col-12" *ngIf="routerActive !== undefined && routerActive.startsWith('countries')">
  <div class="col-12 justify-content-center w-full py-5">
    <p-tabView class="countriesandprovinces-tabs" *ngIf="true">
      <p-tabPanel header="Countries">
        <app-countries-table (updateCountries)="updateCountries($event)"></app-countries-table>
      </p-tabPanel>
      <p-tabPanel header="Provinces">
        <app-countries-provinces-table [countries]="countries"></app-countries-provinces-table>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
<app-events-table *ngIf="routerActive !== undefined && routerActive.startsWith('events')"></app-events-table>
<app-festivals-table *ngIf="routerActive !== undefined && routerActive.startsWith('festivals')"></app-festivals-table>
<app-filters-table *ngIf="routerActive !== undefined && routerActive.startsWith('filters')"></app-filters-table>
<app-products-table *ngIf="routerActive !== undefined && routerActive.startsWith('products')"></app-products-table>
<app-job-roles-table *ngIf="routerActive !== undefined && routerActive.startsWith('job-roles')"></app-job-roles-table>
<app-media-types-table
  *ngIf="routerActive !== undefined && routerActive.startsWith('media-types')"
></app-media-types-table>
<app-titles-table *ngIf="routerActive !== undefined && routerActive.startsWith('titles')"></app-titles-table>
<app-users-table *ngIf="routerActive !== undefined && routerActive.startsWith('users')"></app-users-table>
<app-waves-table *ngIf="routerActive !== undefined && routerActive.startsWith('waves')"></app-waves-table>
