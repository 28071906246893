import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreatePersonJuryCommand, Jury, Office, Person, Professional, UpdatePersonJuryCommand } from '../../../models';
import { ApiService } from '../../../shared/services';
import { Festival } from 'src/app/models/festival.model';

@Component({
  selector: 'app-person-juries-form',
  templateUrl: './person-juries-form.component.html',
  styleUrls: ['./person-juries-form.component.scss'],
})
export class PersonJuriesFormComponent implements OnInit {
  public person: Person | undefined = undefined;
  public professionals: Professional[] = [];
  public jury: Jury | undefined = undefined;

  public mode: 'create' | 'edit' = 'create';

  public festivals: Festival[] = [];
  public selectedFestival?: Festival;
  public currentFestivalId?: number = 0;

  public companies: Office[] = [];
  public companyOptions: Office[] = [];
  public selectedCompany: Office | undefined = undefined;
  public positionOptions: Professional[] = [];
  public selectedPosition: Professional | undefined = undefined;
  public addJuryEnabled = false;

  public command: CreatePersonJuryCommand | UpdatePersonJuryCommand = new CreatePersonJuryCommand({
    festivalId: 0,
  });

  constructor(public apiService: ApiService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data.person) {
      this.person = this.config.data.person;
    }

    if (this.config.data.festivals) {
      this.festivals = this.config.data.festivals;
    }

    if (this.config.data.professionals) {
      this.professionals = this.config.data.professionals;
      const companies = this.professionals.map((professional) => professional.office) as Office[];
      companies.forEach((company) => {
        if (!this.companies.find((c) => c.id == company.id)) {
          this.companies.push(company);
        }
      });

      this.companyOptions.unshift({ id: 0, name: 'No company' } as Office, ...this.companies);
      this.positionOptions.unshift({ id: 0, positionName: 'No position' } as Professional, ...this.professionals);
    }

    if (this.config.data.jury) {
      this.mode = 'edit';
      this.fillData(this.config.data.jury);
    } else {
      this.mode = 'create';
    }
  }

  private fillData(jury: Jury) {
    this.jury = jury;
    if (this.jury != undefined) {
      this.selectedCompany = this.companies.find((company) => company.name == this.jury?.company);
      this.selectedPosition = this.professionals.find(
        (professional) => professional.positionName == this.jury?.position,
      );
      this.selectedFestival = this.festivals.find((festival) => festival.id == this.jury?.festival?.id);
      this.currentFestivalId = this.jury?.festival?.id;
      this.command = new UpdatePersonJuryCommand(this.jury);
    }
  }

  createClicked() {
    if (this.addJuryEnabled) {
      this.command.company = this.selectedCompany?.name;
      this.command.position = this.selectedPosition?.positionName;
      this.command.festivalId = this.selectedFestival?.id;
      this.ref.close(this.command);
    }
  }

  private checkIfAddJuryShouldBeEnabled() {
    if (this.mode === 'edit') {
      this.addJuryEnabled = !!(
        this.selectedFestival?.id &&
        (this.selectedFestival?.id !== this.currentFestivalId ||
          this.selectedCompany?.id !== this.jury?.company ||
          this.selectedPosition?.id !== this.jury?.position)
      );
    } else {
      this.addJuryEnabled = !!this.selectedFestival?.id;
    }
  }

  cancelClicked() {
    this.ref.close();
  }

  festivalChanged() {
    this.checkIfAddJuryShouldBeEnabled();
  }

  companyChanged() {
    if (this.selectedCompany?.id === 0) {
      this.selectedCompany = undefined;
    }
    this.checkIfAddJuryShouldBeEnabled();
  }

  positionChanged() {
    if (this.selectedPosition?.id === 0) {
      this.selectedPosition = undefined;
    }
    this.checkIfAddJuryShouldBeEnabled();
  }
}
