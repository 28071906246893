import { HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { filter } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IProfileInfo } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private readonly router: Router, private readonly oauthService: OAuthService) {
    this.configureAuth();

    this.oauthService.events.pipe(filter((e) => e.type === 'token_received')).subscribe(() => {
      this.router.navigate(['/']);
    });

    if (!this.oauthService.hasValidAccessToken()) {
      this.router.navigate(['login']);
    }
  }

  public async login(user: string, password: string): Promise<TokenResponse> {
    return this.oauthService.fetchTokenUsingPasswordFlow(
      user,
      password,
      new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    );
  }

  public logout(): void {
    return this.oauthService.logOut();
  }

  public loadUserProfile(): Promise<IProfileInfo> {
    return this.oauthService.loadUserProfile() as Promise<IProfileInfo>;
  }

  private configureAuth() {
    this.oauthService.configure(this.getAuthConfig());
    // this.oauthService.setStorage(localStorage);
    this.oauthService.setupAutomaticSilentRefresh();
  }

  private getAuthConfig(): AuthConfig {
    const authCodeFlowConfig: AuthConfig = {
      issuer: environment.auth.issuer,
      redirectUri: window.location.origin + '/',
      clientId: environment.auth.clientId,
      responseType: 'code',
      scope: 'openid profile offline_access',
      showDebugInformation: isDevMode(),
      loginUrl: window.location.origin + '/signin',
      dummyClientSecret: 'abc',
      tokenEndpoint: environment.auth.tokenEndpoint,
      silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html',
      userinfoEndpoint: environment.auth.userInfoEndpoint,
      requireHttps: environment.auth.requireHttps,
      oidc: false,
    };
    return authCodeFlowConfig;
  }
}
