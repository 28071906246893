import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Event } from 'src/app/models/event.model';
import { Festival, FestivalCommand, UpdateFestivalCommand } from 'src/app/models/festival.model';

@Component({
  selector: 'app-festivals-form',
  templateUrl: './festivals-form.component.html',
  styleUrls: ['./festivals-form.component.scss'],
})
export class FestivalsFormComponent implements OnInit {
  public mode: 'create' | 'edit' = 'create';
  public addFestivalEnabled = false;
  public alreadyExists = false;

  public currentEventId?: number = 0;
  public currentYear: number | null = 0;

  public event?: Event;
  public events: Event[] = [];

  public command: FestivalCommand | UpdateFestivalCommand = new FestivalCommand({
    year: 0,
    eventId: 0,
  });

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.events = this.config.data?.events;
    if (this.config.data?.festival) {
      this.mode = 'edit';
      this.fillData(this.config.data.festival);
    } else {
      this.mode = 'create';
    }
  }

  private fillData(festival: Festival): void {
    this.currentEventId = festival.event?.id;
    this.event = festival.event;
    this.currentYear = festival.year;
    this.command = new UpdateFestivalCommand(festival);
  }

  createClicked(): void {
    if (this.addFestivalEnabled) {
      this.command.eventId = this.event?.id;
      this.ref.close(this.command);
    }
  }

  cancelClicked(): void {
    this.ref.close();
  }

  elementChanged(): void {
    this.checkIfAlreadyExists();
    this.checkIfAddFestivalEnabled();
  }

  checkIfAlreadyExists(): void {
    if (this.event && this.command.year) {
      this.alreadyExists = this.config.data.festivals.find(
        (festival: Festival) => festival.event?.id === this.event?.id && festival.year === this.command.year,
      );
    }
  }

  checkIfAddFestivalEnabled(): void {
    if (this.mode === 'edit') {
      this.addFestivalEnabled = !!(
        (this.event?.id && this.event?.id !== this.currentEventId) ||
        (this.command.year && this.command.year !== this.currentYear) ||
        !this.alreadyExists
      );
    } else {
      this.addFestivalEnabled = !!(this.event?.id && this.command.year && !this.alreadyExists);
    }
  }
}
