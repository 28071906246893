import { Component, enableProdMode, Injector, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from '../environments/environment';
import { AuthService, LocatorService } from './shared/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private readonly primengConfig: PrimeNGConfig,
    private readonly titleService: Title,
    private readonly injector: Injector,
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {
    LocatorService.injector = this.injector;
  }

  ngOnInit(): void {
    this.titleService.setTitle(environment.siteTitle);
    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation({});
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    if (environment.production) {
      enableProdMode();
    }
  }
}
