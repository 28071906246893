<div *ngIf="person">
  <div class="flex align-items-start justify-content-between">
    <h3>Filters</h3>
    <button
      *ngIf="hasRole('db-edit-people')"
      pButton
      label="Edit Filters"
      type="button"
      (click)="updateClicked()"
      class="p-button-raised p-button-warning"
      icon="pi pi-save"
      style="margin-bottom: 10px"
    ></button>
  </div>

  <p-chip *ngFor="let filter of filters">
    {{ filter.name }}
  </p-chip>
  <h4 *ngIf="!filters || filters.length === 0">No filters assigned</h4>
</div>
