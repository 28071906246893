import { Component, OnInit } from '@angular/core';
import { EmployeeStatsCommand, Office } from '../../../models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-office-employees-form',
  templateUrl: './office-employees-form.component.html',
  styleUrls: ['./office-employees-form.component.scss'],
})
export class OfficeEmployeesFormComponent implements OnInit {
  public office: Office | undefined = undefined;
  public command: EmployeeStatsCommand = new EmployeeStatsCommand({ id: 0, year: '0', totalCount: '0' });
  public addStatsEnabled = false;
  public mode = 'create';

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  totalCountChanged() {
    this.command.totalCount = this.command.totalCount.replace(/[^0-9]/g, '');
    this.checkIfAddStatsEnabled();
  }

  yearChanged() {
    this.command.year = this.command.year.replace(/[^0-9]/g, '');
    this.checkIfAddStatsEnabled();
  }

  ngOnInit(): void {
    if (this.config.data.office) {
      this.office = this.config.data.office;
      if (this.config.data.stats) {
        this.mode = 'edit';

        this.command.id = this.config.data.stats.id;
        this.command.year = this.config.data.stats.year;
        this.command.totalCount = this.config.data.stats.totalCount;
      }
    }
  }

  createClicked() {
    this.ref.close(this.command);
  }

  cancelClicked() {
    this.ref.close();
  }

  private checkIfAddStatsEnabled() {
    this.addStatsEnabled = parseInt(this.command.totalCount) > 0 && parseInt(this.command.year) > 0;
  }
}
