import { BaseModel } from './base.model';

export class Brand extends BaseModel {
  public name: string;
  public get displayName(): string {
    return this.name.length > 60 ? this.name.slice(0, 60 - 1) + '...' : this.name;
  }

  constructor(data: Brand) {
    super(data);
    ({ name: this.name } = data);
  }
}

export class BrandCommand {
  public name: string;

  constructor(data: BrandCommand) {
    ({ name: this.name } = data);
  }
}

export class CreateBrandCommand extends BrandCommand {
  constructor(data: CreateBrandCommand) {
    super(data);
  }
}

export class UpdateBrandCommand extends BrandCommand {
  public id: number;

  constructor(data: UpdateBrandCommand) {
    super(data);
    this.id = data.id;
  }
}
