import { BaseModel } from './base.model';
import { Country } from './country.model';
import { Product } from './product.model';

export class Wave extends BaseModel {
  public name: string;
  public year: number | null;
  public country?: Country;
  public product?: Product;

  constructor(data: Wave) {
    super(data);

    ({ name: this.name, year: this.year } = data);

    if (data.country) {
      this.country = new Country(data.country);
    }

    if (data.product) {
      this.product = new Product(data.product);
    }
  }
}

class WaveCommand {
  public year: number | null;
  public countryId?: number;
  public productId?: number;

  constructor(data: Pick<Wave, 'year'> & { countryId?: number; productId?: number }) {
    ({ year: this.year, countryId: this.countryId, productId: this.productId } = data);
  }
}

export class CreateWaveCommand extends WaveCommand {
  constructor(data: Pick<Wave, 'year'> & { countryId?: number; productId?: number }) {
    super(data);
  }
}

export class UpdateWaveCommand extends WaveCommand {
  public id: number;

  constructor(data: Pick<Wave, 'id' | 'year'> & { countryId?: number; productId?: number }) {
    super(data);
    this.id = data.id;
  }
}

export class UpdatePersonWavesCommand {
  public personId: number;
  public waveIds: number[];

  constructor(personId: number, waveIds: number[]) {
    this.personId = personId;
    this.waveIds = waveIds;
  }
}
