import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Office, OfficeSubType, OfficeType, UpdateAgencyTypesCommand } from '../../../../models';
import { ApiService } from '../../../../shared/services';

@Component({
  selector: 'app-agency-types-form',
  templateUrl: './agency-types-form.component.html',
  styleUrls: ['./agency-types-form.component.scss'],
})
export class AgencyTypesFormComponent implements OnInit {
  public office: Office | undefined = undefined;
  public agencyTypes: OfficeSubType[] = [];
  public selectedAgencyTypes: OfficeSubType[] = [];

  constructor(private apiService: ApiService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.apiService.offices.types().subscribe((types: OfficeType[]) => {
      types
        .find((type: OfficeType) => type.name === 'agency')
        ?.subtypes.forEach((subType: OfficeSubType) => {
          this.agencyTypes.push(subType);
        });

      if (this.config.data.office) {
        this.office = this.config.data.office;
        this.office?.agency?.types.forEach((type: OfficeSubType) => {
          const index = this.agencyTypes.findIndex((w: OfficeSubType) => w.id === type.id);
          if (index > -1) {
            this.selectedAgencyTypes.push(this.agencyTypes[index]);
          }
        });
      }
    });
  }

  updateClicked() {
    if (this.office && this.office.agency) {
      const command = new UpdateAgencyTypesCommand(
        this.office.id,
        this.selectedAgencyTypes.map((subType: OfficeSubType) => subType.id),
      );
      this.ref.close(command);
    }
  }

  cancelClicked() {
    this.ref.close();
  }
}
