import { Component, OnInit } from '@angular/core';
import { Holding, HoldingCommand } from '../../../models';
import { BaseComponent } from '../../general/base/base.component';
import { ApiService } from '../../../shared/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-holding-form',
  templateUrl: './holding-form.component.html',
  styleUrls: ['./holding-form.component.scss'],
})
export class HoldingFormComponent extends BaseComponent implements OnInit {
  public holding: Holding | undefined = undefined;
  public mode = 'edit';

  public enableOptions: [{ label: string; value: boolean }, { label: string; value: boolean }] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  public selectedEnableOption = this.enableOptions[0];

  public bigPlayerOptions: [{ label: string; value: boolean }, { label: string; value: boolean }] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  public selectedBigPlayerOption = this.bigPlayerOptions[1];

  public updateHoldingEnabled = false;

  constructor(public apiService: ApiService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    super();
  }

  ngOnInit(): void {
    if (this.config.data.holding) {
      const holding = this.config.data.holding;
      const mode = this.config.data.mode;
      if (mode) {
        this.mode = mode;
      }

      if (holding) {
        this.holding = holding;
        this.selectedEnableOption =
          this.enableOptions.find((x) => x.value === holding.isEnabled) || this.enableOptions[0];
        this.selectedBigPlayerOption =
          this.bigPlayerOptions.find((x) => x.value === holding.isBigPlayer) || this.bigPlayerOptions[1];
      }
    }
  }

  holdingNameChanged() {
    this.checkIfUpdateHoldingEnabled();
  }

  cancelClicked() {
    this.ref.close();
  }

  updateClicked() {
    if (this.holding) {
      this.holding.isEnabled = this.selectedEnableOption.value;
      this.holding.isBigPlayer = this.selectedBigPlayerOption.value;

      const command: HoldingCommand = new HoldingCommand({
        name: this.holding.name,
        isEnabled: this.holding.isEnabled,
        isBigPlayer: this.holding.isBigPlayer,
      });

      this.ref.close(command);
    }
  }

  bigPlayerChanged() {
    if (this.selectedBigPlayerOption.value) {
      this.holding!.isBigPlayer = true;
    }
    this.checkIfUpdateHoldingEnabled();
  }

  private checkIfUpdateHoldingEnabled() {
    if (this.holding) {
      this.updateHoldingEnabled = this.holding.name !== '';
    }
  }

  enableChanged() {
    this.checkIfUpdateHoldingEnabled();
  }
}
