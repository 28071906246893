import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Holding } from '../../../models';
import { IProfessionalsFilter } from '../../../shared/interfaces';
import { BaseComponent } from '../../general/base/base.component';
import { ProfessionalsTableComponent } from '../../professionals/professionals-table/professionals-table.component';

@Component({
  selector: 'app-holding-tabs',
  templateUrl: './holding-tabs.component.html',
  styleUrls: ['./holding-tabs.component.scss'],
})
export class HoldingTabsComponent extends BaseComponent implements OnInit {
  @Input() holding: Holding | undefined = undefined;
  public filters: IProfessionalsFilter | undefined;
  @ViewChild(ProfessionalsTableComponent) professionalsTable?: ProfessionalsTableComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.filters = {
      holdingId: this.holding?.id,
    };
    if (this.globalCountryId) {
      this.filters.locations = [{ countryId: this.globalCountryId }];
    }
  }
}
