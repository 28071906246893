import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Country, User } from 'src/app/models';

export interface ICountriesToAssign {
  countriesForUserToAdd: Country[];
  countriesForUserToDelete: Country[];
}

@Component({
  selector: 'app-users-countries-form',
  templateUrl: './users-countries-form.component.html',
  styleUrls: ['./users-countries-form.component.scss'],
})
export class UsersCountriesFormComponent implements OnInit {
  public countries: Country[] = [];

  public countriesForUser: Country[] = [];

  public currentCountriesForUser: Country[] = [];

  public countriesForUserToDelete: Country[] = [];

  public countriesForUserToAdd: Country[] = [];

  public countriesToAssign: ICountriesToAssign = {
    countriesForUserToAdd: [],
    countriesForUserToDelete: [],
  };

  public user?: User;

  public saveButtonDisable = true;

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  ngOnInit() {
    this.countries = this.config.data.countries;
    const countryIdsForUser = this.config.data.user.countryIds;
    this.user = this.config.data.user;
    for (let i = 0; i < countryIdsForUser.length; i++) {
      const index = this.countries.findIndex((c) => c.id === countryIdsForUser[i]);

      if (index >= 0) {
        this.countriesForUser.push(this.countries[index]);
        this.countries.splice(index, 1);
      }
    }
    this.currentCountriesForUser = [...this.countriesForUser];
  }

  saveClicked(): void {
    if (this.currentCountriesForUser.length) {
      this.currentCountriesForUser.forEach((country) => {
        if (!this.countriesForUser.includes(country)) {
          this.countriesForUserToDelete.push(country);
        }
      });
    }
    if (this.countriesForUser.length) {
      this.countriesForUser.forEach((country) => {
        if (!this.currentCountriesForUser.includes(country)) {
          this.countriesForUserToAdd.push(country);
        }
      });
    }
    this.countriesToAssign.countriesForUserToAdd = this.countriesForUserToAdd;
    this.countriesToAssign.countriesForUserToDelete = this.countriesForUserToDelete;

    if (!this.saveButtonDisable) {
      this.ref.close(this.countriesToAssign);
    }
  }

  cancelClicked(): void {
    this.ref.close();
  }

  checkIfSaveButtonDisable(): void {
    if (this.countriesForUser.length > 1) {
      this.orderCountriesById(this.countriesForUser);
    }
    if (this.countriesForUser.length > 1) {
      this.orderCountriesById(this.currentCountriesForUser);
    }
    this.saveButtonDisable =
      this.countriesForUser.length === this.currentCountriesForUser.length &&
      this.countriesForUser.every((country, index) => {
        return country.id === this.currentCountriesForUser[index].id;
      });
  }

  orderCountriesById(countries: Country[]): void {
    countries.sort((countryA, countryB) => {
      if (countryA.id > countryB.id) {
        return 1;
      }
      if (countryA.id < countryB.id) {
        return -1;
      }
      return 0;
    });
  }
}
