import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { CreateOfficeSubTypeCommand, OfficeSubType, UpdateOfficeSubTypeCommand } from '../../../../models';
import { ISearchQuery } from '../../../../shared/interfaces';
import { ApiService } from '../../../../shared/services';
import { AgenciesTypesFormComponent } from '../agencies-types-form/agencies-types-form.component';

@Component({
  selector: 'app-agencies-types-table',
  templateUrl: './agencies-types-table.component.html',
  styleUrls: ['./agencies-types-table.component.scss'],
})
export class AgenciesTypesTableComponent implements OnInit {
  public agencyTypes: OfficeSubType[] = [];

  public filteredAgencyTypes: OfficeSubType[] = [];

  public isLoading = true;

  public first = 0;

  public page = 1;

  public pageSize = 10;

  public availablePageSizes = environment.availablePageSizes;

  public agencyTypesResult?: ISearchQuery<OfficeSubType[]>;

  selectedSearchText = '';

  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.refresh();
    this.searchText$.subscribe(() => {
      this.first = 0;
      this.filteredAgencyTypes = this.agencyTypes.filter((type) =>
        type.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });
  }

  refresh(): void {
    this.agencyTypes = [];
    this.isLoading = true;
    this.apiService.agencies.getAllTypes().subscribe({
      next: (agencyTypes) => {
        this.agencyTypes = agencyTypes;
        this.filteredAgencyTypes = [...this.agencyTypes];
        this.isLoading = false;
      },
      error: (error: unknown) => {
        console.error(error);
      },
    });
  }

  public createClicked(): void {
    const ref = this.dialogService.open(AgenciesTypesFormComponent, {
      header: $localize`Add new agency type`,
      width: '30%',
      height: 'auto',
    });

    ref.onClose.subscribe({
      next: (command: CreateOfficeSubTypeCommand) => {
        if (command) {
          this.apiService.agencies.createType(command).subscribe({
            next: (agencyType) => {
              this.addAgencyType(agencyType);
              this.filteredAgencyTypes = [...this.agencyTypes];
              this.messageService.add({
                severity: 'success',
                summary: 'Created',
                detail: $localize`You have added a new agency type`,
              });
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: $localize`An error occurred while creating the agency type`,
              });
            },
          });
        }
      },
      error: (error: unknown) => {
        console.error(error);
      },
    });
  }

  public editClicked($event: MouseEvent, agencyType: OfficeSubType): void {
    $event.preventDefault();
    $event.stopPropagation();

    const ref = this.dialogService.open(AgenciesTypesFormComponent, {
      header: $localize`Edit agency type`,
      width: '30%',
      height: 'auto',
      data: {
        agencyType,
      },
    });

    ref.onClose.subscribe((command: UpdateOfficeSubTypeCommand) => {
      if (command) {
        this.apiService.agencies.updateType(command.id, command).subscribe({
          next: (agencyType) => {
            this.updateAgencyType(agencyType);
            this.filteredAgencyTypes = [...this.agencyTypes];
            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: $localize`You have updated the agency type`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while updating the agency type`,
            });
          },
        });
      }
    });
  }

  public confirmDelete($event: MouseEvent, agencyType: OfficeSubType): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: $localize`Are you sure that you want to delete this agency type?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.apiService.agencies.deleteType(agencyType.id).subscribe({
          next: () => {
            this.removeAgencyType(agencyType);
            this.filteredAgencyTypes = [...this.agencyTypes];
            this.messageService.add({
              severity: 'success',
              summary: 'Deleted',
              detail: $localize`You have deleted the agency type`,
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: $localize`An error occurred while deleting the agency type`,
            });
          },
        });
      },
      reject: () => undefined,
    });
  }

  public addAgencyType(agencyType: OfficeSubType): void {
    this.agencyTypes.push(agencyType);
  }

  public updateAgencyType(agencyType: OfficeSubType): void {
    const index = this.agencyTypes.findIndex((type) => type.id === agencyType.id);

    if (index >= 0) {
      this.agencyTypes[index] = agencyType;
    }
  }

  public removeAgencyType(agencyType: OfficeSubType): void {
    const index = this.agencyTypes.findIndex((type) => type.id === agencyType.id);

    if (index >= 0) {
      this.agencyTypes.splice(index, 1);
    }
  }

  public next(): void {
    this.first = this.first + this.pageSize;
  }

  public prev(): void {
    this.first = this.first - this.pageSize;
  }

  public reset(): void {
    this.first = 0;
  }

  public isLastPage(): boolean {
    return this.agencyTypesResult?.count ? this.first === this.agencyTypesResult.count - this.pageSize : true;
  }

  public isFirstPage(): boolean {
    return this.agencyTypesResult?.count ? this.first === 0 : true;
  }

  public translateCurrentPageReportTemplate(): string {
    return $localize`Showing ${this.first + 1} to ${this.first + this.pageSize} of ${
      this.filteredAgencyTypes.length
    } entries`;
  }

  public searchTextChanged(): void {
    this.searchText$.next(this.selectedSearchText);
  }
}
