import { Observable } from 'rxjs';
import { Advertiser, Agency, Exclusive, Media, Office, Studio } from '../../models';
import { ApiService, ToastService } from '../../shared/services';
import { OfficeTypeNames } from '../../shared/types';

export class OfficeTypesService {
  public office?: Office;

  constructor(private apiService: ApiService, private toastService: ToastService) {}

  public loadOfficeTypes(office: Office): void {
    this.office = office;
    const services: OfficeTypeService<Agency | Advertiser | Media | Exclusive | Studio>[] = [];
    const config = new OfficeTypeServiceConfig(this.office, this.apiService, this.toastService);

    services.push(new AgencyOfficeTypeService(config));
    services.push(new AdvertiserOfficeTypeService(config));
    services.push(new MediaOfficeTypeService(config));
    services.push(new ExclusiveOfficeTypeService(config));
    services.push(new StudioOfficeTypeService(config));

    services.forEach((service) => {
      service.loadOfficeType();
    });
  }
}

export class OfficeTypeServiceConfig {
  public office: Office;
  public apiService: ApiService;
  public toastService: ToastService;

  constructor(office: Office, apiService: ApiService, toastService: ToastService) {
    this.office = office;
    this.apiService = apiService;
    this.toastService = toastService;
  }
}

export abstract class OfficeTypeService<T> {
  public office?: Office;

  public abstract officeTypeName: string;

  public abstract apiCall(id: number): Observable<T>;

  public abstract setOfficeCallback(result: T, office: Office): void;

  public getErrorMessage(): string {
    return `We couldn't get ${this.officeTypeName} details for this office`;
  }

  constructor(office: Office, private toastService: ToastService) {
    this.office = office;
  }

  public loadOfficeType(): void {
    this.setOfficeType(this.officeTypeName, this.apiCall, this.setOfficeCallback);
  }

  private findAgencyType(name: string): { id: number; name: OfficeTypeNames } | undefined {
    return this.office?.officeTypes.find((type) => type.name === name);
  }

  protected setOfficeType<T>(
    typeName: string,
    apiCall: (id: number) => Observable<T>,
    callback: (result: T, office: Office) => void,
  ): void {
    if (this.office) {
      const agencyType = this.findAgencyType(typeName);
      if (agencyType) {
        apiCall(this.office?.id).subscribe({
          next: (result) => {
            if (this.office) {
              callback(result, this.office);
            }
          },
          error: () => {
            this.showError(this.getErrorMessage());
          },
        });
      }
    }
  }

  private showError(detail: string): void {
    this.toastService.send({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }
}

export class ExclusiveOfficeTypeService extends OfficeTypeService<Exclusive> {
  public officeTypeName = 'exclusive';
  public apiCall: (id: number) => Observable<Exclusive>;

  constructor(config: OfficeTypeServiceConfig) {
    super(config.office, config.toastService);
    this.apiCall = config.apiService.exclusives.getById;
  }

  public setOfficeCallback(exclusive: Exclusive, office: Office): void {
    office.exclusive = exclusive;
  }
}

export class MediaOfficeTypeService extends OfficeTypeService<Media> {
  public officeTypeName = 'media';
  public apiCall: (id: number) => Observable<Media>;

  constructor(config: OfficeTypeServiceConfig) {
    super(config.office, config.toastService);
    this.apiCall = config.apiService.medias.getById;
  }

  public setOfficeCallback(media: Media, office: Office): void {
    office.media = media;
  }
}

export class AdvertiserOfficeTypeService extends OfficeTypeService<Advertiser> {
  public officeTypeName = 'advertiser';
  public apiCall: (id: number) => Observable<Advertiser>;

  constructor(config: OfficeTypeServiceConfig) {
    super(config.office, config.toastService);
    this.apiCall = config.apiService.advertisers.getById;
  }

  public setOfficeCallback(advertiser: Advertiser, office: Office): void {
    office.advertiser = advertiser;
  }
}

export class AgencyOfficeTypeService extends OfficeTypeService<Agency> {
  public officeTypeName = 'agency';
  public apiCall: (id: number) => Observable<Agency>;

  constructor(config: OfficeTypeServiceConfig) {
    super(config.office, config.toastService);
    this.apiCall = config.apiService.agencies.getById;
  }

  public setOfficeCallback(agency: Agency, office: Office): void {
    office.agency = agency;
  }
}

export class StudioOfficeTypeService extends OfficeTypeService<Studio> {
  public officeTypeName = 'studio';
  public apiCall: (id: number) => Observable<Studio>;

  constructor(config: OfficeTypeServiceConfig) {
    super(config.office, config.toastService);
    this.apiCall = config.apiService.studios.getById;
  }

  public setOfficeCallback(studio: Studio, office: Office): void {
    office.studio = studio;
  }
}
