export const environment = {
  production: true,
  defaultPageSize: 50,
  availablePageSizes: [10, 25, 50, 100],
  cacheExpireMinutes: 10,
  siteTitle: 'SCOPEN Database',
  apiAuth: 'https://scopen-idp.azurewebsites.net/auth/realms/scopenpro/protocol/openid-connect/token',
  apiBase: 'https://scopen-db-api-pro.azurewebsites.net/api/v1',
  apiOffices: '/offices',
  apiCountries: '/countries',
  apiSectors: '/sectors',
  apiBrands: '/brands',
  apiAgencies: '/agencies',
  apiAdvertisers: '/advertisers',
  apiCollaborations: '/collaborations',
  apiPeople: '/people',
  apiProfessionals: '/professionals',
  apiWaves: '/waves',
  apiEvents: '/events',
  apiFestivals: '/festivals',
  apiFilters: '/filters',
  apiMedias: '/medias',
  apiExclusives: '/exclusives',
  apiMediaTypes: '/mediatypes',
  apiStudios: '/studios',
  apiStudioTypes: '/studio-types',
  apiProducts: '/products',
  apiUsers: '/users',
  apiJobRoles: '/jobroles',
  apiCompanies: '/companies',
  apiNetworks: '/networks',
  apiHoldings: '/holdings',
  apiAdvertiserBrands: '/advertiser-brands',
  auth: {
    issuer: 'https://scopen-idp.azurewebsites.net/auth/realms/scopenpro',
    clientId: 'scopen-database-web',
    tokenEndpoint: 'https://scopen-idp.azurewebsites.net/auth/realms/scopenpro/protocol/openid-connect/token',
    userInfoEndpoint: 'https://scopen-idp.azurewebsites.net/auth/realms/scopenpro/protocol/openid-connect/userinfo',
    requireHttps: false,
    allowedUrls: ['http://scopen-db-api-pro.azurewebsites.net', 'https://scopen-db-api-pro.azurewebsites.net'],
  },
  globeIconUrl: 'https://scopenprostorage.blob.core.windows.net/scopendbfiles/country_flags/globe.png',
};
