import { Component, Input } from '@angular/core';
import { Holding } from '../../../models';

@Component({
  selector: 'app-holding-summary',
  templateUrl: './holding-summary.component.html',
  styleUrls: ['./holding-summary.component.scss'],
})
export class HoldingSummaryComponent {
  @Input() public holding: Holding | undefined = undefined;
}
