<p-tabView class="company-tabs" *ngIf="company && filters">
  <p-tabPanel header="Professionals">
    <!-- <app-professionals-table *ngIf="filters" #professionalsTable [filters]="filters"></app-professionals-table>-->
    <app-professionals-multiple [company]="company"></app-professionals-multiple>
  </p-tabPanel>
  <p-tabPanel header="Structure">
    <app-company-structure [company]="company"></app-company-structure>
  </p-tabPanel>
  <p-tabPanel header="Clients & Services">
    <app-collaborations-company-table [mode]="'agency'" id="collaborations-table-agency" [company]="company">
    </app-collaborations-company-table>
  </p-tabPanel>
  <p-tabPanel header="Received Services">
    <app-collaborations-company-table [mode]="'advertiser'" id="collaborations-table-advertiser" [company]="company">
    </app-collaborations-company-table>
  </p-tabPanel>
</p-tabView>
