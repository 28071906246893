import { LocatorService } from '../shared/services';
import { UtilService } from '../shared/services/util.service';
import { OfficeTypeNames } from '../shared/types';
import { Advertiser } from './advertiser.model';
import { Agency } from './agency.model';
import { BaseModel } from './base.model';
import { Company } from './company.model';
import { Country } from './country.model';
import { Exclusive } from './exclusive.model';
import { Media } from './media.model';
import { OfficeSubType } from './office-sub-type.model';
import { Address } from './professional.model';
import { Studio } from './studio.model';
import { Holding } from './holding.model';
import { Network } from './network.model';
import { OfficeFusionDetail, OfficeFusionParent } from './office-fusion-detail.model';

export class EmployeeStats extends BaseModel {
  public year: string;

  public totalCount: string;

  constructor(data: EmployeeStats) {
    super(data);

    ({ year: this.year, totalCount: this.totalCount } = data);
  }
}

export class EmployeeStatsCommand extends BaseModel {
  public year: string;
  public totalCount: string;

  constructor(data: EmployeeStatsCommand) {
    super(data);
    ({ year: this.year, totalCount: this.totalCount } = data);
  }
}

export class OfficeType extends BaseModel {
  public name: string;

  public subtypes: OfficeSubType[] = [];

  constructor(data: OfficeType) {
    super(data);

    ({ id: this.id, name: this.name } = data);

    if (data.subtypes && data.subtypes.length) {
      this.subtypes = data.subtypes.map((sub) => new OfficeSubType(sub));
    }
  }
}

export class Office extends BaseModel {
  public name: string;

  public fullName: string;

  public address?: Address;

  public countryId?: number;

  public country?: Country;

  public phone?: string;

  public fax?: string;

  public web?: string;

  public email?: string;

  public observations?: string;

  public isHeadQuarter: boolean;

  public companyId?: number;

  public company?: Company;
  public holdingId?: number;
  public holding?: Holding;

  public networkId?: number;
  public network?: Network;

  public officeTypes: { id: number; name: OfficeTypeNames }[] = [];

  public lastReview?: string;

  public isEnabled: boolean;

  public establishedYear?: number;

  public groupName?: string;
  public networkName?: string;
  public fusionParentId?: number;
  public fusionParent?: OfficeFusionParent;
  public fusionChildren?: OfficeFusionDetail[];

  public agency?: Agency;

  public advertiser?: Advertiser;

  public media?: Media;

  public exclusive?: Exclusive;

  public studio?: Studio;

  private utilService: UtilService;

  constructor(data: Omit<Office, 'parsedAddress' | 'utilService'>) {
    super(data);

    ({
      officeTypes: this.officeTypes,
      companyId: this.companyId,
      email: this.email,
      fax: this.fax,
      holdingId: this.holdingId,
      id: this.id,
      isHeadQuarter: this.isHeadQuarter,
      lastReview: this.lastReview,
      name: this.name,
      fullName: this.fullName,
      networkId: this.networkId,
      observations: this.observations,
      phone: this.phone,
      web: this.web,
      countryId: this.countryId,
      isEnabled: this.isEnabled,
      establishedYear: this.establishedYear,
      groupName: this.groupName,
      networkName: this.networkName,
      fusionParentId: this.fusionParentId,
    } = data);

    if (data.address) {
      this.address = new Address(data.address);
    }

    if (data.country) {
      this.country = new Country(data.country);
    }

    if (data.company) {
      this.company = new Company(data.company);
    }

    if (data.network) {
      this.network = new Network(data.network);
    }

    if (data.holding) {
      this.holding = new Holding(data.holding);
    }

    if (data.agency) {
      this.agency = new Agency(data.agency);
    }

    if (data.advertiser) {
      this.advertiser = new Advertiser(data.advertiser);
    }

    if (data.media) {
      this.media = new Media(data.media);
    }

    if (data.exclusive) {
      this.exclusive = new Exclusive(data.exclusive);
    }

    if (data.studio) {
      this.studio = new Studio(data.studio);
    }

    if (data.fusionParent) {
      this.fusionParent = new OfficeFusionParent(data.fusionParent);
    }

    if (data.fusionChildren && data.fusionChildren.length) {
      this.fusionChildren = data.fusionChildren.map((child) => new OfficeFusionDetail(child));
    }

    this.utilService = LocatorService.injector.get(UtilService);
  }

  get parsedAddress(): string {
    if (this.utilService) {
      return this.utilService.parseAddress(this.address);
    }
    return '';
  }

  public static createNewBlankOffice(): Office {
    return new Office({
      countryId: undefined,
      id: 0,
      name: '',
      fullName: '',
      address: new Address({ addressLine: '', province: undefined, postalCode: '', town: '' }),
      country: new Country({
        id: 0,
        name: '',
        flagUrl: '',
        isoCode: 'ESP',
        provinces: [{ id: 0, name: '', isoCode: '', regionCode: '', countryId: 0, country: undefined }],
      }),
      company: undefined,
      phone: '',
      fax: '',
      web: '',
      email: '',
      observations: '',
      isHeadQuarter: false,
      companyId: undefined,
      holdingId: undefined,
      networkId: undefined,
      officeTypes: [],
      lastReview: '',
      isEnabled: true,
      establishedYear: 0,
      agency: undefined,
      advertiser: undefined,
      media: undefined,
      exclusive: undefined,
      studio: undefined,
    });
  }
}

export class OfficeCommand {
  public name: string;
  public isHeadQuarter: boolean;
  public companyId?: number;
  public holdingId?: number;
  public networkId?: number;
  public officeTypeIds: number[] = [];
  public enabled: boolean;
  public establishedYear?: number;
  public address?: string;
  public countryId: number;
  public phone?: string;
  public fax?: string;
  public web?: string;
  public email?: string;
  public observations?: string;
  public provinceId?: number;
  public postalCode?: string;
  public town?: string;
  public groupName?: string;
  public networkName?: string;

  constructor(data: OfficeCommand) {
    ({
      name: this.name,
      isHeadQuarter: this.isHeadQuarter,
      companyId: this.companyId,
      holdingId: this.holdingId,
      networkId: this.networkId,
      officeTypeIds: this.officeTypeIds,
      enabled: this.enabled,
      establishedYear: this.establishedYear,
      address: this.address,
      countryId: this.countryId,
      phone: this.phone,
      fax: this.fax,
      web: this.web,
      email: this.email,
      observations: this.observations,
      provinceId: this.provinceId,
      postalCode: this.postalCode,
      town: this.town,
      groupName: this.groupName,
      networkName: this.networkName,
    } = data);
  }
}

export class CreateOfficeCommand extends OfficeCommand {
  constructor(data: CreateOfficeCommand) {
    super(data);
  }
}

export class UpdateOfficeCommand extends OfficeCommand {
  public id: number;

  constructor(data: UpdateOfficeCommand) {
    super(data);

    ({ id: this.id } = data);
  }
}
