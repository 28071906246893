<div class="form-container pt-3">
  <span
    style="
      display: block;
      font-family: Mark;
      font-size: 1rem;
      color: rgb(64, 61, 61);
      padding-left: 16px;
      padding-top: 10px;
      padding-bottom: 15px;
      justify-content: center;
    "
  >
    <span style="font-family: Mark; font-size: 1rem; color: black">Name: </span> {{ product ? product.name : '' }}
    {{ country ? ' - ' + country.name : '' }} {{ command.year ? ' - ' + command.year : '' }}</span
  >
  <div class="col-9 pl-3 p-4">
    <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
      <p-dropdown
        id="product"
        placeholder="Product"
        optionLabel="name"
        appendTo="body"
        class="w-full"
        [style]="{ width: '100%' }"
        [options]="products"
        [(ngModel)]="product"
        (ngModelChange)="productChanged()"
      ></p-dropdown>
      <label for="product">Product</label>
    </span>
  </div>
  <div class="col-9 pl-3 p-4">
    <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
      <p-dropdown
        id="country"
        placeholder="Country"
        [options]="countries"
        [(ngModel)]="country"
        (ngModelChange)="countryChanged()"
        optionLabel="name"
        appendTo="body"
        class="w-full"
        [style]="{ width: '100%' }"
      ></p-dropdown>
      <label for="country">Country</label>
    </span>
  </div>
  <div class="col-9 pl-3 p-4">
    <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
      <p-inputNumber
        [(ngModel)]="command.year"
        (ngModelChange)="yearChanged()"
        [showButtons]="true"
        [min]="0"
        placeholder="year"
        i18n-placeholder
      ></p-inputNumber>
      <label for="year" i18n>Year</label>
    </span>
  </div>
  <span
    style="
      display: block;
      font-family: Mark;
      font-size: 1rem;
      color: rgb(64, 61, 61);
      padding-left: 16px;
      padding-top: 10px;
      padding-bottom: 15px;
      justify-content: center;
    "
  >
    <span *ngIf="alreadyExist" style="font-family: Mark; font-size: 1rem; color: rgb(253, 1, 1)">
      This wave already exist</span
    >
    <div class="col-12">
      <button
        pButton
        *ngIf="mode === 'create'"
        label="Add Wave"
        i18n-label
        type="button"
        class="p-button-raised p-button-warning standard-button-within-form"
        icon="pi pi-plus-circle"
        [disabled]="!addWaveEnabled"
        (click)="createClicked()"
      ></button>

      <button
        pButton
        *ngIf="mode === 'edit'"
        label="Update Wave"
        i18n-label
        type="button"
        class="p-button-raised p-button-warning standard-button-within-form"
        icon="pi pi-save"
        [disabled]="!addWaveEnabled"
        (click)="createClicked()"
      ></button>

      <button
        pButton
        label="Cancel"
        i18n-label
        type="button"
        (click)="cancelClicked()"
        class="p-button-raised p-button-warning standard-button-within-form"
        icon="pi pi-times"
      ></button>
    </div>
  </span>
</div>
