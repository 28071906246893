import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Company, Country, Holding, Network, Office, OfficeType, Province, UpdateOfficeCommand } from '../../../models';
import { IOfficesApiFilters, IPrimengCustomEvent, ISearchQuery } from '../../../shared/interfaces';
import { ApiService, ToastService } from '../../../shared/services';
import { BaseComponent } from '../../general/base/base.component';
import { Observable } from 'rxjs';
import { ScrollerOptions } from 'primeng/scroller';
import { EmailConstants } from '../../../shared/constants';

@Component({
  selector: 'app-office-form',
  templateUrl: './office-form.component.html',
  styleUrls: ['./office-form.component.scss'],
})
export class OfficeFormComponent extends BaseComponent implements OnInit {
  public office: Office | undefined = undefined;
  public countries: Country[] = [];
  public mode = 'edit';

  public updateOfficeEnabled = false;
  public enableOptions: [{ label: string; value: boolean }, { label: string; value: boolean }] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  public selectedEnableOption = this.enableOptions[0];

  public isHeadquarterOptions: [{ label: string; value: boolean }, { label: string; value: boolean }] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  public selectedHeadquarterOption = this.isHeadquarterOptions[0];

  public filteredOfficeTypes: OfficeType[] = [];
  public officeTypes: OfficeType[] = [];

  public selectedCompany?: Company;
  public selectedNetwork?: Network;
  public selectedHolding?: Holding;

  public companyResult: Company[] = [];
  public networkResult: Network[] = [];
  public holdingResult: Holding[] = [];

  public itemPage = 1;
  public itemsPageSize = 15;
  public companyLoader = false;
  public networkLoader = false;
  public holdingLoader = false;
  private companyQuery?: string;
  private networkQuery?: string;
  private holdingQuery?: string;

  constructor(
    public apiService: ApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.config.data.office) {
      const office = this.config.data.office;
      const mode = this.config.data.mode;
      if (mode) {
        this.mode = mode;
      }

      if (office) {
        const countries: Country[] = this.config.data.countries;
        this.initOfficeAndAddress(office, countries);

        if (this.office) {
          this.selectedEnableOption = this.office.isEnabled ? this.enableOptions[0] : this.enableOptions[1];
          this.selectedHeadquarterOption = this.office.isHeadQuarter
            ? this.isHeadquarterOptions[0]
            : this.isHeadquarterOptions[1];

          this.companyResult.unshift(this.getEmptyCompany());
          if (this.office.company) {
            const company = new Company(this.office.company);
            this.companyResult.push(company);
            this.selectedCompany = company;
          }

          this.networkResult.unshift(this.getEmptyNetwork());
          if (this.office.network) {
            const network = new Network(this.office.network);
            this.networkResult.push(network);
            this.selectedNetwork = network;
          }

          this.holdingResult.unshift(this.getEmptyHolding());
          if (this.office.holding) {
            const holding = new Holding(this.office.holding);
            this.holdingResult.push(holding);
            this.selectedHolding = holding;
          }

          this.checkIfUpdateOfficeEnabled();
        }
      }
    }

    this.apiService.offices.types().subscribe((officeTypes: OfficeType[]) => {
      officeTypes.forEach((officeType) => {
        this.officeTypes.push(officeType);
      });
      this.filteredOfficeTypes = this.officeTypes;
    });
  }

  private initOfficeAndAddress(office: Office, countries: Country[]) {
    if (office) {
      office.country = countries.find((c) => c.id === office?.country?.id);
      if (office.country === undefined) {
        office.country = countries.find((c) => c.id === office?.address?.country?.id);
        if (office.country === undefined) {
          office.country = countries.find((c) => c.id == this.globalCountryId);
          if (office.country === undefined && countries.length == 1) {
            office.country = countries[0];
          }
        }
      }

      if (office.address) {
        office.address.province = office.country?.provinces.find(
          (p: Province) => p.id === office.address?.province?.id,
        );
      }

      this.countries = countries;
      this.office = office;
    }
  }

  private checkIfUpdateOfficeEnabled() {
    if (this.office) {
      this.updateOfficeEnabled =
        this.office.name != undefined &&
        this.office.name != '' &&
        this.office.country != undefined &&
        this.office.country.id != 0 &&
        this.office.officeTypes != undefined &&
        this.office.officeTypes.length > 0 &&
        EmailConstants.isValidEmailIfNotMandatory(this.office.email);
    }
  }

  searchOfficeType($event: IPrimengCustomEvent) {
    this.filteredOfficeTypes = this.officeTypes.filter((officeType) =>
      officeType.name.toLowerCase().includes($event.query.toLowerCase()),
    );
  }

  updateClicked() {
    if (this.office && this.office.country && this.office.officeTypes && this.office.officeTypes.length > 0) {
      const command = new UpdateOfficeCommand({
        id: this.office.id,
        name: this.office.name,
        countryId: this.office.country.id,
        officeTypeIds: this.office.officeTypes.map((ot) => ot.id),
        enabled: this.selectedEnableOption.value,
        isHeadQuarter: this.selectedHeadquarterOption.value,
        phone: this.office.phone,
        fax: this.office.fax,
        email: this.office.email,
        address: this.office.address?.addressLine,
        establishedYear: this.office.establishedYear,
        postalCode: this.office.address?.postalCode,
        provinceId: this.office.address?.province?.id,
        town: this.office.address?.town,
        companyId: this.selectedCompany
          ? this.selectedCompany?.id > 0
            ? this.selectedCompany?.id
            : undefined
          : undefined,
        networkId: this.selectedNetwork
          ? this.selectedNetwork?.id > 0
            ? this.selectedNetwork?.id
            : undefined
          : undefined,
        holdingId: this.selectedHolding
          ? this.selectedHolding?.id > 0
            ? this.selectedHolding?.id
            : undefined
          : undefined,
        web: this.office.web,
        observations: this.office.observations,
        groupName: this.office.groupName,
        networkName: this.office.networkName,
      });
      this.ref.close(command);
    } else {
      this.ref.close();
    }
  }

  cancelClicked() {
    this.ref.close();
  }

  countryChanged() {
    if (this.office?.address) {
      this.office.address.country = this.office?.country;
    }
    this.checkIfUpdateOfficeEnabled();
  }

  officeNameChanged() {
    this.checkIfUpdateOfficeEnabled();
  }

  officeTypesChanged() {
    this.checkIfUpdateOfficeEnabled();
  }

  onCompanyItemChange($event: Company) {
    this.selectedCompany = $event;
    this.checkIfUpdateOfficeEnabled();
  }

  onNetworkItemChange($event: Network) {
    this.selectedNetwork = $event;
    this.checkIfUpdateOfficeEnabled();
  }

  onHoldingItemChange($event: Holding) {
    this.selectedHolding = $event;
    this.checkIfUpdateOfficeEnabled();
  }

  public filterCompanies(event: IPrimengCustomEvent<Event, string>): void {
    const query = event.query;
    this.searchCompany(query, 1, true, true);
  }

  public filterNetworks(event: IPrimengCustomEvent<Event, string>): void {
    const query = event.query;
    this.searchNetwork(query, 1, true);
  }

  public filterHoldings(event: IPrimengCustomEvent<Event, string>): void {
    const query = event.query;
    this.searchHoldings(query, 1, true);
  }

  private searchCompany(query?: string, page = this.itemPage, cleanResults = false, showAll = false): void {
    this.companyLoader = true;
    const countryIds: number[] | undefined = this.globalCountryId ? [this.globalCountryId] : undefined;
    this.apiService.companies.getAll(query, countryIds, showAll).subscribe({
      next: (data) => {
        if (query !== this.companyQuery || cleanResults) {
          this.companyResult = [];
        }

        this.companyResult = [...this.companyResult, ...data.items];
        this.companyQuery = query;
        this.companyLoader = false;
        this.companyResult.unshift(this.getEmptyCompany());
      },
      error: () => {
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We could not get Companies from the API`,
        });
      },
    });
  }

  private searchNetwork(query?: string, page = this.itemPage, cleanResults = false): void {
    this.networkLoader = true;
    this.apiService.networks.getAll(query).subscribe({
      next: (data) => {
        if (query !== this.networkQuery || cleanResults) {
          this.networkResult = [];
        }

        this.networkResult = [...this.networkResult, ...data.items];
        this.networkQuery = query;
        this.networkLoader = false;
        this.networkResult.unshift(this.getEmptyNetwork());
      },
      error: () => {
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We could not get Networks from the API`,
        });
      },
    });
  }

  private searchHoldings(query?: string, page = this.itemPage, cleanResults = false): void {
    this.holdingLoader = true;
    this.apiService.holdings.getAll(query).subscribe({
      next: (data) => {
        if (query !== this.holdingQuery || cleanResults) {
          this.holdingResult = [];
        }

        this.holdingResult = [...this.holdingResult, ...data.items];
        this.holdingQuery = query;
        this.holdingLoader = false;
        this.holdingResult.unshift(this.getEmptyHolding());
      },
      error: () => {
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We could not get Holdings from the API`,
        });
      },
    });
  }

  public companiesVirtualScroll: ScrollerOptions = {
    onScrollIndexChange: (event: { first: number; last: number }) => {
      if (event.last === this.itemPage * this.itemsPageSize) {
        this.searchCompany(this.companyQuery, (this.itemPage += 1));
      }
    },
    loading: this.companyLoader,
  };

  public networksVirtualScroll: ScrollerOptions = {
    onScrollIndexChange: (event: { first: number; last: number }) => {
      if (event.last === this.itemPage * this.itemsPageSize) {
        this.searchNetwork(this.networkQuery, (this.itemPage += 1));
      }
    },
    loading: this.networkLoader,
  };

  public holdingsVirtualScroll: ScrollerOptions = {
    onScrollIndexChange: (event: { first: number; last: number }) => {
      if (event.last === this.itemPage * this.itemsPageSize) {
        this.searchHoldings(this.holdingQuery, (this.itemPage += 1));
      }
    },
    loading: this.holdingLoader,
  };

  private getEmptyHolding(): Holding {
    return new Holding({
      id: 0,
      name: '-- No holding --',
      isEnabled: true,
      isBigPlayer: false,
      offices: [],
      companies: [],
      networks: [],
    });
  }

  private getEmptyCompany(): Company {
    return new Company({
      id: 0,
      name: '-- No company --',
      isEnabled: true,
      offices: [],
      list: '',
      researchId: 0,
      observations: '',
      networkId: 0,
      holdingId: 0,
    });
  }

  private getEmptyNetwork(): Network {
    return new Network({ id: 0, name: '-- No network --', isEnabled: true, offices: [], holdingId: 0, companies: [] });
  }

  emailChanged() {
    this.checkIfUpdateOfficeEnabled();
  }
}
