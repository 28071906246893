import { BaseModel } from './base.model';

export class Event extends BaseModel {
  public name: string;

  constructor(data: Event) {
    super(data);

    this.name = data.name;
  }
}

export class EventCommand {
  name: string;

  constructor(data: EventCommand) {
    this.name = data.name;
  }
}

export class UpdateEventCommand extends EventCommand {
  id: number;

  constructor(data: UpdateEventCommand) {
    super(data);
    this.id = data.id;
  }
}
