import { Component, Input } from '@angular/core';
import { Network } from '../../../models';

@Component({
  selector: 'app-network-summary',
  templateUrl: './network-summary.component.html',
  styleUrls: ['./network-summary.component.scss'],
})
export class NetworkSummaryComponent {
  @Input() public network: Network | undefined = undefined;
}
