import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmailValidatorDirective } from './directives/email-validator.directive';
import { UtcDatePipe } from './pipes/utc-date.pipe';

@NgModule({
  declarations: [EmailValidatorDirective, UtcDatePipe],
  imports: [CommonModule],
  exports: [EmailValidatorDirective, UtcDatePipe],
})
export class SharedModule {}
