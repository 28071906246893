import { ISOCountryCode3Letters } from '../shared/types';
import { BaseModel } from './base.model';

export class Province extends BaseModel {
  public name: string;

  public isoCode: string;

  public regionCode: string;

  public countryId: number;

  public country?: Country;

  constructor(data: Province) {
    super(data);

    ({ countryId: this.countryId, isoCode: this.isoCode, name: this.name, regionCode: this.regionCode } = data);

    if (data.country) {
      this.country = new Country(data.country);
    }
  }
}

export type CreateProvinceCommand = Pick<Province, 'name' | 'isoCode' | 'regionCode' | 'countryId'>;
export type UpdateProvinceCommand = Pick<Province, 'id' | 'name' | 'isoCode' | 'regionCode' | 'countryId'>;

export class Country extends BaseModel {
  public name: string;

  public isoCode?: ISOCountryCode3Letters;

  public flagUrl?: string;

  public provinces: Province[] = [];

  private provinceIndexesMap = new Map<number, number>();

  constructor(data: Pick<Country, 'id' | 'flagUrl' | 'isoCode' | 'name' | 'provinces'>) {
    super(data);

    ({ flagUrl: this.flagUrl, isoCode: this.isoCode, name: this.name } = data);

    if (data.provinces && data.provinces.length) {
      this.provinces = data.provinces.map((province) => new Province(province));
      this.provinces.forEach((province, index) => {
        this.provinceIndexesMap.set(province.id, index);
      });
    }
  }

  public getProvinceIndex(provinceId: number): number | undefined {
    return this.provinceIndexesMap.get(provinceId);
  }

  public addProvinceIndex(provinceId: number, index: number): void {
    this.provinceIndexesMap.set(provinceId, index);
  }

  public removeProvinceIndex(provinceId: number): void {
    this.provinceIndexesMap.delete(provinceId);
  }
}

export type CreateCountryCommand = Pick<Country, 'name' | 'isoCode' | 'flagUrl' | 'provinces'>;
export type UpdateCountryCommand = Pick<Country, 'id' | 'name' | 'isoCode' | 'flagUrl' | 'provinces'>;
