import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Country } from 'src/app/models';
import { CreateFilterCommand, Filter } from '../../../../models/filter.model';

@Component({
  selector: 'app-filters-form',
  templateUrl: './filters-form.component.html',
  styleUrls: ['./filters-form.component.scss'],
})
export class FiltersFormComponent implements OnInit {
  public filter: Filter | undefined = undefined;

  public country: Country | undefined = undefined;

  public countries: Country[] = [];

  public mode = 'create';

  public addFilterEnabled = false;

  public currentName = '';

  public currentCountry? = {};

  public command: CreateFilterCommand = new CreateFilterCommand({
    name: '',
    countryId: 0,
  });

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.countries = this.config.data.countries;
    if (this.config.data.filter) {
      this.mode = 'edit';
      this.fillData();
    } else {
      this.mode = 'create';
    }
  }

  private fillData() {
    this.filter = this.config.data.filter;
    if (this.filter != undefined) {
      this.initFilter(this.filter);
    }
  }

  createClicked() {
    if (this.country) {
      this.command.countryId = this.country.id;
    }
    if (this.addFilterEnabled) {
      this.ref.close(this.command);
    }
  }

  cancelClicked() {
    this.ref.close();
  }

  initFilter(filter: Filter) {
    this.country = this.countries.find((c) => c.id === this.config?.data?.filter?.countryId);
    this.currentCountry = this.country;
    if (this.country) {
      this.command.name = filter.name.substring(0, filter.name.length - this.country.name.length - 3);
      this.currentName = this.command.name;
    }
  }

  nameChanged() {
    this.checkIfAddFilterEnabled();
  }

  countryChanged() {
    this.checkIfAddFilterEnabled();
  }

  checkIfAddFilterEnabled() {
    if (this.mode === 'edit') {
      this.addFilterEnabled =
        (this.command.name !== undefined &&
          this.command.name.trim().length !== 0 &&
          this.command.name.trim() !== this.currentName.trim()) ||
        this.country !== this.currentCountry;
    } else {
      this.addFilterEnabled =
        this.command.name !== undefined && this.command.name.trim().length !== 0 && this.country !== undefined;
    }
  }
}
