<div class="col-12 py-4 px-6">
  <div *ngIf="office">
    <div class="col-12 flex flex-row align-items-start justify-content-start">
      <app-office-summary [office]="office" [isTitle]="true"></app-office-summary>
    </div>
    <div class="col-12 px-0 flex flex-column align-items-start justify-content-start">
      <div class="col-12 px-0 flex flex-row align-items-start justify-content-start">
        <div class="col-12 flex flex-column align-items-start justify-content-start">
          <h3>Location</h3>
          <app-title-value-field title="address" [value]="office.address?.addressLine || '---'"></app-title-value-field>
          <app-title-value-field
            title="province"
            [value]="office.address?.province?.name || '---'"
          ></app-title-value-field>
          <app-title-value-field title="town" [value]="office.address?.town || '---'"></app-title-value-field>
          <app-title-value-field title="zip code" [value]="office.address?.postalCode || '---'"></app-title-value-field>
          <app-title-value-field
            title="country"
            [value]="office.address?.country?.name || '---'"
          ></app-title-value-field>
        </div>
        <div class="col-12 flex flex-column align-items-start justify-content-start">
          <h3>Contact</h3>
          <app-title-value-field title="phone" [value]="office.phone || '---'"></app-title-value-field>
          <!-- <app-title-value-field title="fax" [value]="office.fax || '-&#45;&#45;'"></app-title-value-field>-->
          <app-title-value-field title="email" [value]="office.email || '---'"></app-title-value-field>
          <app-title-value-field title="web" [value]="office.web || '---'"></app-title-value-field>
        </div>
      </div>
      <div class="col-12 px-0 flex flex-row align-items-start justify-content-start">
        <div class="col-12 flex flex-column align-items-start justify-content-start">
          <h3>Details</h3>
          <app-title-value-field
            title="company"
            [value]="office.company.name || '---'"
            *ngIf="office.company"
          ></app-title-value-field>
          <app-title-value-field
            title="network"
            [value]="office.network.name || '---'"
            *ngIf="office.network"
          ></app-title-value-field>
          <app-title-value-field
            title="holding"
            [value]="office.holding.name || '---'"
            *ngIf="office.holding"
          ></app-title-value-field>
          <app-title-value-field title="group" [value]="office.groupName || '---'"></app-title-value-field>
          <!--app-title-value-field title="network" [value]="office.networkName || '---'"></app-title-value-field-->
          <app-title-value-field
            title="established year"
            [value]="office.establishedYear ? office.establishedYear.toString() : '---'"
          ></app-title-value-field>
          <app-title-value-field
            title="observations"
            [multipleRows]="true"
            [value]="office.observations || '---'"
          ></app-title-value-field>
        </div>
      </div>

      <div class="col-12 px-0 flex flex-row align-items-start justify-content-start" *ngIf="office.fusionParent">
        <div class="col-12 flex flex-column align-items-start justify-content-start">
          <h3>Fusion</h3>
          <app-title-value-field
            title="parent"
            [value]="office.fusionParent.name || '---'"
            *ngIf="office.fusionParent"
          ></app-title-value-field>
        </div>
      </div>

      <div class="col-6 py-0 flex flex-row justify-content-start py-3">
        <div class="px-1">
          <button
            *ngIf="hasRole('db-edit-offices')"
            pButton
            label="Edit"
            type="button"
            (click)="editClicked($event)"
            class="p-button-raised p-button-warning"
            icon="pi pi-pencil"
            style="margin-bottom: 10px"
          ></button>
        </div>
        <div class="px-1">
          <button
            *ngIf="hasRole('db-delete-offices')"
            pButton
            label="Delete"
            type="button"
            (click)="deleteClicked($event)"
            class="p-button-raised p-button-danger"
            icon="pi pi-plus-circle"
            style="margin-bottom: 10px"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Office Deleted" [(visible)]="officeDeletedConfirmed" [style]="{ width: '25vw' }" [closable]="false">
  <app-office-summary [office]="office"></app-office-summary>
  <div class="flex flex-row justify-content-end">
    <div class="px-1">
      <button
        pButton
        label="Go to Offices & Companies"
        type="button"
        class="p-button-raised p-button-secondary navigate-to-professionals-button"
        (click)="navigateToOffices()"
        icon="pi pi-users"
        style="margin-bottom: 10px"
      ></button>
    </div>
  </div>
</p-dialog>
