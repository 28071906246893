import { Component, Input, OnInit } from '@angular/core';
import { IOfficesFilter, IPrimengSelectButtonOptions } from '../../../shared/interfaces';
import { environment } from '../../../../environments/environment';
import { Network, Office } from '../../../models';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';
import { ApiService, ToastService } from '../../../shared/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-network-list',
  templateUrl: './network-list.component.html',
  styleUrls: ['./network-list.component.scss'],
})
export class NetworkListComponent implements OnInit {
  @Input() filters: IOfficesFilter | null = {};
  public first = 0;
  public totalRecords = 0;
  public page = 1;
  public pageSize = 1000;
  public availablePageSizes = environment.availablePageSizes;
  public networks: Network[] = [];
  public filteredNetworks: Network[] = [];
  public isLoading = true;

  enabledOptions: IPrimengSelectButtonOptions<'enabled' | 'all'>[] = [
    { label: 'Enabled', value: 'enabled' },
    { label: 'All', value: 'all' },
  ];

  selectedEnabledOptionValue = 'enabled';

  selectedSearchText = '';
  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>(this.selectedSearchText);

  constructor(
    private readonly apiService: ApiService,
    private readonly toastService: ToastService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.searchText$.subscribe(() => {
      this.selectedValueChanged();
      this.filteredNetworks = this.filteredNetworks.filter((c) =>
        c.name?.toLowerCase().includes(this.selectedSearchText.toLowerCase()),
      );
    });

    this.apiService.networks.getAll().subscribe({
      next: (data) => {
        this.totalRecords = data.count;
        data.items.forEach((holding) => {
          this.networks.push(holding);
        });
        this.selectedValueChanged();
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.toastService.send({
          severity: 'error',
          summary: $localize`Error`,
          detail: $localize`We could not get networks from the API`,
        });
      },
    });
  }

  selectedValueChanged() {
    this.filteredNetworks = this.networks.filter((c) => {
      const isEnabled = this.selectedEnabledOptionValue === 'enabled';
      if (isEnabled) {
        return c.isEnabled;
      } else {
        return c;
      }
    });
  }

  goToNetworkDetailsPage(data: Office): void {
    this.router.navigate(['networks-holdings', 'network', data.id]);
  }

  load(filters: IOfficesFilter): void {
    this.filters = filters;
  }

  searchTextChanged() {
    this.searchText$.next(this.selectedSearchText);
  }

  public addNetwork(network: Network) {
    this.networks.push(network);
    this.networks.sort((filterA, filterB) => {
      if (filterA.name > filterB.name) {
        return 1;
      }
      if (filterA.name < filterB.name) {
        return -1;
      }
      return 0;
    });
    this.selectedValueChanged();
  }

  public updateNetwork(network: Network) {
    const index = this.networks.findIndex((f) => f.id === network.id);
    if (index >= 0) {
      this.networks[index] = network;
      this.selectedValueChanged();
    }
  }

  public removeNetwork(network: Network) {
    const index = this.networks.findIndex((f) => f.id === network.id);
    if (index >= 0) {
      this.networks.splice(index, 1);
      this.selectedValueChanged();
    }
  }
}
