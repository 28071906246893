<p-tabView class="office-tabs" *ngIf="office">
  <p-tabPanel header="Professionals">
    <app-office-professionals [office]="office"></app-office-professionals>
  </p-tabPanel>
  <p-tabPanel *ngIf="office.agency !== undefined" header="Clients & Services">
    <app-collaborations-section [office]="office" [mode]="'agency'"></app-collaborations-section>
  </p-tabPanel>
  <p-tabPanel *ngIf="office.advertiser !== undefined" header="Advertiser Data">
    <div class="col-12 py-0 flex flex-row start">
      <div class="col-6 py-0 flex flex-column start">
        <div class="col-12 flex flex-column start">
          <div class="py-4 px-0 w-full">
            <app-advertiser-subsectors [office]="office"></app-advertiser-subsectors>
          </div>
        </div>
        <div class="col-12 flex flex-column start">
          <div class="py-4 px-0 w-full">
            <app-advertiser-infoadex [office]="office"></app-advertiser-infoadex>
          </div>
        </div>
      </div>
      <div class="col-6 flex flex-column start">
        <div class="py-4 px-4 w-full">
          <app-advertiser-brands [office]="office"></app-advertiser-brands>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel *ngIf="office.advertiser !== undefined" header="Received Services">
    <app-collaborations-section [office]="office" [mode]="'advertiser'"></app-collaborations-section>
  </p-tabPanel>
  <p-tabPanel *ngIf="office.media !== undefined" header="Exclusive & Supports">
    <div class="col-12 py-0 px-0 flex flex-row start">
      <div class="col-6 px-0 flex flex-column start">
        <div class="py-4 px-2 w-full">
          <app-media-supports [office]="office"></app-media-supports>
        </div>
      </div>
      <div class="col-6 px-0 flex flex-column start">
        <div class="py-4 px-2 w-full">
          <app-media-info [office]="office"></app-media-info>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel *ngIf="office.exclusive !== undefined" header="Support Assigned">
    <div class="col-12 py-0 px-0 flex flex-row start">
      <div class="col-6 px-0 flex flex-column start">
        <div class="py-4 px-2 w-full">
          <app-exclusive-supports [office]="office"></app-exclusive-supports>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel *ngIf="office.studio !== undefined" header="Studio Types">
    <div class="col-12 py-0 px-0 flex flex-row start">
      <div class="col-6 px-0 flex flex-column start">
        <div class="py-4 px-2 w-full">
          <app-studio-types [office]="office"></app-studio-types>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel *ngIf="office.agency !== undefined" header="Employees by Year" i18n-header>
    <app-office-employees [office]="office"></app-office-employees>
  </p-tabPanel>
  <p-tabPanel *ngIf="office.fusionChildren !== undefined" header="Fusion Detail" i18n-header>
    <app-office-fusion-children [office]="office"></app-office-fusion-children>
  </p-tabPanel>
</p-tabView>
