import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Country, UserProfile } from '../../models';
import { ProfileRoles } from '../types';

@Injectable({
  providedIn: 'root',
})
export class IdentityService {
  private _user?: UserProfile;

  private _userSubject = new Subject<UserProfile>();

  private _globalCountryId?: number;

  private _globalCountryIdSubject = new Subject<number | undefined>();

  public globalCountryId$ = this._globalCountryIdSubject.asObservable();

  public user$ = this._userSubject.asObservable();

  constructor(private router: Router) {}

  get globalCountryId() {
    return this._globalCountryId;
  }

  set globalCountryId(value: number | undefined) {
    this._globalCountryId = value;
    this._globalCountryIdSubject.next(this._globalCountryId);
  }

  public setUser(profile: UserProfile): void {
    this._user = profile;
    this._userSubject.next(this._user);
    window.sessionStorage.setItem('user', JSON.stringify(this._user));
  }

  public getUser(): UserProfile | undefined {
    return this._user;
  }

  public hasRole(role: ProfileRoles): boolean {
    if (this._user) {
      return this._user.roles.includes(role);
    }

    return false;
  }

  public setGlobalCountry(country?: Country): void {
    const url = this.router.url.split('?')[0];

    this.router.navigateByUrl('/').then(() => {
      let extras = {};

      if (country?.id) {
        extras = { queryParams: { countryId: country?.id } };
      }

      this.router.navigate([url], extras).then(() => {
        this.globalCountryId = country?.id;
      });
    });
  }
}
