import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Country, UserProfile } from '../../../models';
import { AppPermissions } from '../../../shared/constants';
import { AuthService, LocatorService, ToastService } from '../../../shared/services';
import { CountryService } from '../../../shared/services/country.service';
import { IdentityService } from '../../../shared/services/identity.service';
import { BaseComponent } from '../base/base.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent extends BaseComponent implements OnInit {
  public items: MenuItem[] = [];

  public breadcrumb: MenuItem[] = [];

  public home: MenuItem = {};

  public menuButton: MenuItem[] = [];

  public adminMenuItems: MenuItem[] = [];

  public countryNodes: TreeNode<Country>[] = [];

  public selectedCountry?: TreeNode<Country>;

  public user?: UserProfile;

  public acronym = '';

  public displayPlaceHolder = false;

  private subscriptions = new Subscription();

  constructor(
    private readonly authService: AuthService,
    private readonly toastService: ToastService,
    private readonly countryService: CountryService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const countryId = params['countryId'];

      if (countryId) {
        this.globalCountryId = parseInt(countryId);
      }
    });

    this.menuButton = [{ label: $localize`Sign out`, icon: 'pi pi-sign-out', routerLink: ['/login'] }];

    this.subscriptions.add(
      this.countryService.find({ includeProvinces: true }).subscribe({
        next: (countries) => {
          this.buildCountryNodes(countries);
        },
        error: (error: unknown) => {
          console.error(error);
          this.toastService.send({
            severity: 'error',
            summary: $localize`Error`,
            detail: $localize`We couldn't get countries from the API`,
          });
        },
      }),
    );

    this.subscriptions.add(
      this.countryService.$countries.subscribe({
        next: (data) => {
          this.buildCountryNodes(data);
        },
        error: (error: unknown) => {
          console.error(error);
        },
      }),
    );

    setTimeout(async () => {
      ({ info: this.user } = await this.authService.loadUserProfile());
      const identityService = LocatorService.injector.get(IdentityService);

      identityService.user$.subscribe({
        next: (user) => {
          if (user) {
            this.items = [
              {
                label: $localize`Companies`,
                icon: 'pi pi-building',
                routerLink: ['/app/companies'],
                command: () => {
                  (
                    document.querySelector('a.p-panelmenu-header-link>span.pi-chevron-down') as HTMLAnchorElement
                  )?.click();
                },
              },
              {
                label: $localize`Networks & Holdings`,
                icon: 'pi pi-globe',
                routerLink: ['/app/networks-holdings'],
                command: () => {
                  (
                    document.querySelector('a.p-panelmenu-header-link>span.pi-chevron-down') as HTMLAnchorElement
                  )?.click();
                },
              },
              {
                label: $localize`Professionals`,
                icon: 'pi pi-users',
                routerLink: ['/app/professionals'],
                command: () => {
                  (
                    document.querySelector('a.p-panelmenu-header-link>span.pi-chevron-down') as HTMLAnchorElement
                  )?.click();
                },
              },
            ];

            this.adminMenuItems = [
              {
                label: $localize`Admin`,
                icon: 'pi pi-shield',
                items: [
                  {
                    label: $localize`Agency services`,
                    routerLink: ['/app/admin/agency-services'],
                    visible: this.hasRole(AppPermissions.DB_EDIT_AGENCY_SERVICES),
                  },
                  {
                    label: $localize`Agency types`,
                    routerLink: ['/app/admin/agency-types'],
                    visible: this.hasRole(AppPermissions.DB_EDIT_AGENCY_TYPES),
                  },
                  {
                    label: $localize`Brands`,
                    routerLink: ['/app/admin/brands'],
                    visible: this.hasRole(AppPermissions.DB_EDIT_BRANDS),
                  },
                  {
                    label: $localize`Countries`,
                    routerLink: ['/app/admin/countries'],
                    visible: this.hasRole(AppPermissions.DB_EDIT_COUNTRIES),
                  },
                  {
                    label: $localize`Events`,
                    routerLink: ['/app/admin/events'],
                    visible: this.hasRole(AppPermissions.DB_EDIT_EVENTS),
                  },
                  {
                    label: $localize`Festivals`,
                    routerLink: ['/app/admin/festivals'],
                    visible: this.hasRole(AppPermissions.DB_EDIT_FESTIVALS),
                  },
                  {
                    label: $localize`Filters`,
                    routerLink: ['/app/admin/filters'],
                    visible: this.hasRole(AppPermissions.DB_EDIT_FILTERS),
                  },
                  {
                    label: $localize`Media types`,
                    routerLink: ['/app/admin/media-types'],
                    visible: this.hasRole(AppPermissions.DB_EDIT_MEDIA_TYPES),
                  },
                  {
                    label: $localize`Position Types`,
                    routerLink: ['/app/admin/job-roles'],
                    visible: this.hasRole(AppPermissions.DB_EDIT_JOBROLES),
                  },
                  {
                    label: $localize`Projects`,
                    routerLink: ['/app/admin/products'],
                    visible: this.hasRole(AppPermissions.DB_EDIT_PROJECTS),
                  },
                  {
                    label: $localize`Sectors`,
                    routerLink: ['/app/admin/sectors'],
                    visible: this.hasRole(AppPermissions.DB_EDIT_SECTORS),
                  },
                  {
                    label: $localize`Titles`,
                    routerLink: ['/app/admin/titles'],
                    visible: this.hasRole(AppPermissions.DB_EDIT_TITLES),
                  },
                  {
                    label: $localize`Users`,
                    routerLink: ['/app/admin/users'],
                    visible: this.hasRole(AppPermissions.DB_EDIT_USERS),
                  },

                  {
                    label: $localize`Waves`,
                    routerLink: ['/app/admin/waves'],
                    visible: this.hasRole(AppPermissions.DB_EDIT_WAVES),
                  },
                ],
                visible: this.hasRole(AppPermissions.DB_ADMIN),
              },
            ];
          } else {
            this.router.navigate(['/login']);
          }
        },
        error: (error: unknown) => {
          console.error(error);
        },
      });

      identityService.setUser(this.user);

      if (this.user) {
        this.acronym = `${this.user.given_name.charAt(0).toUpperCase()}${this.user.family_name
          .charAt(0)
          .toUpperCase()}`;

        if (!this.toastService.welcomeMessageStatus) {
          this.toastService.send({
            severity: 'success',
            summary: $localize`Success`,
            detail: $localize`Welcome ${this.user.name}!`,
          });

          this.toastService.welcomeMessageStatus = true;
        }
      }
    }, 0);
  }

  public onCountryChanges(value: TreeNode): void {
    this.selectedCountry = value;
    this.displayPlaceHolder = false;
    this.setGlobalCountry(value.data);
  }

  private buildCountryNodes(countries: Country[]): void {
    this.countryNodes = countries.map(
      (country) =>
        ({
          label: country.name,
          icon: country.flagUrl,
          data: country,
        } as TreeNode),
    );

    if (this.countryNodes.length > 1) {
      this.countryNodes.push({
        label: $localize`All countries`,
        icon: environment.globeIconUrl,
        data: { id: 0, name: $localize`All countries`, flagUrl: 'pi pi-globe' },
      } as TreeNode);

      if (this.globalCountryId) {
        this.selectedCountry = this.countryNodes.find((x) => x.data?.id == this.globalCountryId);
      } else {
        this.selectedCountry = this.countryNodes.find((x) => x.data?.id === 0);
      }
    }
  }
}
