<div class="form-container" *ngIf="office && office.media">
  <div class="col-12 flex start">
    <app-office-summary [office]="office"></app-office-summary>
  </div>
  <div class="col-12 flex start w-full">
    <div class="col-12 p-2 w-full">
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-autoComplete
          id="exclusives"
          class="autocomplete-full"
          [(ngModel)]="office.media.exclusive"
          [suggestions]="filteredExclusives"
          (completeMethod)="searchExclusive($event)"
          [dropdown]="true"
          field="fullName"
          [multiple]="false"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          appendTo="body"
        ></p-autoComplete>
        <label for="exclusives">Exclusives</label>
      </span>
    </div>
  </div>

  <div class="col-12">
    <button
      pButton
      label="Update Exclusive"
      type="button"
      (click)="updateClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
