import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Office, Person } from '../../../models';
import { CreateProfessionalCommand, Professional } from '../../../models/professional.model';
import { Role } from '../../../models/role.model';
import { IOfficesApiFilters, IPrimengCustomEvent } from '../../../shared/interfaces';
import { ApiService } from '../../../shared/services';
import { BaseComponent } from '../../general/base/base.component';
import { EmailConstants } from '../../../shared/constants';
import { UtcDateConverter } from '../../../shared/pipes/utc-date-converter';

@Component({
  selector: 'app-person-professionals-form',
  templateUrl: './person-professionals-form.component.html',
  styleUrls: ['./person-professionals-form.component.scss'],
})
export class PersonProfessionalsFormComponent extends BaseComponent implements OnInit {
  public person: Person | undefined = undefined;
  public professional: Professional | undefined = undefined;

  public mode = 'create';

  public offices: Office[] = [];
  public selectedOffice: Office | undefined = undefined;

  public roles: Role[] = [
    { name: 'Staff', id: 1 },
    { name: 'Director', id: 2 },
  ];
  public selectedRole: Role = this.roles[0];

  public currentOptions = [
    { name: 'Current', value: true },
    { name: 'Old', value: false },
  ];
  public selectedCurrentOption = this.currentOptions[0];

  public addOfficeEnabled = false;

  public command: CreateProfessionalCommand = new CreateProfessionalCommand({
    personId: this.person == undefined ? 0 : this.person.id,
    officeId: 0,
    email: '',
    phone: '',
    positionName: '',
    endDate: undefined,
    mobilePhone: '',
    startDate: undefined,
    isActive: true,
    isActiveUpdateDate: undefined,
    roleId: 0,
  });

  private page = 1;
  private pageSize = 100;

  constructor(public apiService: ApiService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    super();
  }

  ngOnInit(): void {
    if (this.config.data.person) {
      this.person = this.config.data.person;
    }

    if (this.config.data.professional) {
      this.mode = 'edit';
      this.fillData();
    } else {
      this.mode = 'create';
    }
  }

  private fillData() {
    this.professional = this.config.data.professional;
    if (this.professional != undefined) {
      this.selectedOffice = this.professional.office;
      this.selectedRole = this.roles.find((role) => role.id == this.professional?.role?.id) || this.roles[0];
      this.command.positionName = this.professional.positionName;
      this.command.email = this.professional.email;
      this.command.phone = this.professional.phone;
      this.command.mobilePhone = this.professional.mobilePhone;
      this.command.startDate = this.professional.startDate ? new Date(this.professional.startDate) : undefined;
      this.command.endDate = this.professional.endDate ? new Date(this.professional.endDate) : undefined;
      this.command.isActive = this.professional.isActive;
      this.selectedCurrentOption =
        this.currentOptions.find((option) => option.value == this.professional?.isActive) || this.currentOptions[0];

      this.checkIfAddOfficeShouldBeEnabled();
    }
  }

  searchOffice($event: IPrimengCustomEvent) {
    const filters: IOfficesApiFilters = {
      name: $event.query,
      page: this.page,
      pageSize: this.pageSize,
      orderBy: {
        field: 'name',
        order: 'asc',
      },
      enabled: true,
    };

    if (this.globalCountryId) {
      filters.locations = [{ countryId: this.globalCountryId }];
    }

    this.apiService.offices.search(filters).subscribe((result) => {
      this.offices = result.items;
    });
  }

  private checkIfAddOfficeShouldBeEnabled() {
    this.addOfficeEnabled =
      this.selectedOffice != undefined &&
      this.selectedOffice.id != 0 &&
      this.command.positionName != undefined &&
      this.command.positionName != '' &&
      EmailConstants.isValidEmailIfNotMandatory(this.command.email);
  }

  createClicked() {
    if (this.person && this.selectedOffice) {
      this.command.personId = this.person.id;
      this.command.officeId = this.selectedOffice.id;
      this.command.roleId = this.selectedRole.id;
      this.command.isActive = this.selectedCurrentOption.value;

      this.command.startDate = UtcDateConverter.convert(this.command.startDate);
      this.command.endDate = UtcDateConverter.convert(this.command.endDate);

      this.ref.close(this.command);
    }
  }

  cancelClicked() {
    this.ref.close();
  }

  officeChanged() {
    this.checkIfAddOfficeShouldBeEnabled();
  }

  positionChanged() {
    this.checkIfAddOfficeShouldBeEnabled();
  }

  emailChanged() {
    this.checkIfAddOfficeShouldBeEnabled();
  }
}
