<div class="form-container pt-3">
  <div class="col-12 flex justify-content-center">
    <div class="col-12 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="name"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="name"
          i18n-placeholder
          [(ngModel)]="command.name"
          (ngModelChange)="nameChanged()"
        />
        <label for="name" i18n>Name</label>
      </span>
    </div>
  </div>
  <div class="col-12">
    <button
      pButton
      *ngIf="mode === 'create'"
      label="Add Media Type"
      i18n-label
      type="button"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-plus-circle"
      [disabled]="!addMediaTypeEnabled"
      (click)="createClicked()"
    ></button>

    <button
      pButton
      *ngIf="mode === 'edit'"
      label="Update Media Type"
      i18n-label
      type="button"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
      [disabled]="!addMediaTypeEnabled"
      (click)="createClicked()"
    ></button>

    <button
      pButton
      label="Cancel"
      i18n-label
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
