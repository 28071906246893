<div class="form-container pt-3">
  <div class="col-6 pl-3 p-4">
    <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
      <p-dropdown
        id="country"
        placeholder="Country"
        [options]="countries"
        [(ngModel)]="country"
        (ngModelChange)="countryChanged()"
        optionLabel="name"
        appendTo="body"
        class="w-full"
        [style]="{ width: '100%' }"
      ></p-dropdown>
      <label for="country">Country</label>
    </span>
  </div>
  <div class="col-12 flex justify-content-center">
    <div class="col-12 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="name"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="name"
          [(ngModel)]="command.name"
          (ngModelChange)="nameChanged()"
        />
        <label for="name" i18n>Name</label>
      </span>
    </div>
  </div>
  <div class="col-12 m-1">
    <button
      pButton
      *ngIf="mode === 'create'"
      label="Add Filter"
      type="button"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-plus-circle"
      [disabled]="!addFilterEnabled"
      (click)="createClicked()"
    ></button>

    <button
      pButton
      *ngIf="mode === 'edit'"
      label="Update Filter"
      type="button"
      class="p-button-raised p-button-warning standard-button-within-form mt-0"
      icon="pi pi-save"
      [disabled]="!addFilterEnabled"
      (click)="createClicked()"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form mt-0"
      icon="pi pi-times"
    ></button>
  </div>
</div>
