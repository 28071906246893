import { AgencyService } from './agency-service.model';
import { BaseModel } from './base.model';
import { OfficeSubType } from './office-sub-type.model';

export class Agency extends BaseModel {
  public name: string;
  public fullName: string;
  public types: OfficeSubType[] = [];
  public services: AgencyService[] = [];

  constructor(data: Agency) {
    super(data);

    ({ name: this.name, fullName: this.fullName } = data);

    if (data.types && data.types.length) {
      this.types = data.types.map((type) => new OfficeSubType(type)).sort((a, b) => a.order - b.order);
    }

    if (data.services && data.services.length) {
      this.services = data.services.map((agency) => new AgencyService(agency));
    }
  }
}

export class UpdateAgencyTypesCommand {
  public agencyId: number;
  public agencyTypesIds: number[] = [];

  constructor(agencyId: number, agencyTypesIds: number[]) {
    this.agencyId = agencyId;
    this.agencyTypesIds = agencyTypesIds;
  }
}

export class UpdateAgencyServicesCommand {
  public agencyId: number;
  public agencyServicesIds: number[] = [];

  constructor(agencyId: number, agencyServicesIds: number[]) {
    this.agencyId = agencyId;
    this.agencyServicesIds = agencyServicesIds;
  }
}
