import { Component, Input, OnInit } from '@angular/core';
import { InfoadexRecordCommand, Office } from '../../../../models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-advertiser-infoadex-form',
  templateUrl: './advertiser-infoadex-form.component.html',
  styleUrls: ['./advertiser-infoadex-form.component.scss'],
})
export class AdvertiserInfoadexFormComponent implements OnInit {
  @Input() public office: Office | undefined = undefined;
  public command: InfoadexRecordCommand = new InfoadexRecordCommand();
  public mode = 'create';
  public addInfoAdexEnabled = false;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data.office) {
      this.office = this.config.data.office;
      this.mode = this.config.data.mode;
      const infoadex = this.config.data.infoAdex;

      if (this.mode === 'edit') {
        this.command.year = infoadex.year;
        this.command.ranking = infoadex.ranking;
        this.command.investment = infoadex.investment;
      }
    }
  }

  yearChanged() {
    this.checkIfAddInfoAdexEnabled();
  }

  rankingChanged() {
    this.checkIfAddInfoAdexEnabled();
  }

  investmentChanged() {
    this.checkIfAddInfoAdexEnabled();
  }

  cancelClicked() {
    this.ref.close();
  }

  createClicked() {
    if (this.addInfoAdexEnabled) {
      this.ref.close(this.command);
    }
  }

  checkIfAddInfoAdexEnabled() {
    this.addInfoAdexEnabled =
      this.command.year !== undefined &&
      this.command.year > 1800 &&
      this.command.ranking !== undefined &&
      this.command.ranking > 0 &&
      this.command.investment !== undefined &&
      this.command.investment > 0;
  }
}
