import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { differenceInMinutes } from 'date-fns';
import { map, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CreateMediaTypeCommand, MediaType, UpdateMediaTypeCommand } from '../../models';
import { IApiProblemDetails } from '../interfaces';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class MediaTypeService {
  private readonly url = `${environment.apiBase}${environment.apiMediaTypes}`;

  private collection: MediaType[] = [];

  private indexesMap: Map<number, number> = new Map();

  private lastCall?: number;

  constructor(private readonly http: HttpClient, private readonly utilService: UtilService) {
    console.group(MediaTypeService.name);
  }

  get mediaTypes() {
    return [...this.collection];
  }

  public find(): Observable<MediaType[]> {
    if (
      this.collection.length &&
      this.lastCall &&
      differenceInMinutes(Date.now(), this.lastCall, { roundingMethod: 'round' }) <= environment.cacheExpireMinutes
    ) {
      return of([...this.collection]);
    }

    return this._find().pipe(
      map((data) => {
        this.lastCall = Date.now();
        this.collection = data;
        this.utilService.createIndexesMapFromCollection(this.collection, this.indexesMap);

        return [...this.collection];
      }),
    );
  }

  public create(command: CreateMediaTypeCommand): Observable<MediaType> {
    return this._create(command).pipe(
      map((createdMediaType) => {
        this.collection.push(createdMediaType);
        this.indexesMap.set(createdMediaType.id, this.collection.length - 1);
        return createdMediaType;
      }),
    );
  }

  public update(id: number, command: UpdateMediaTypeCommand): Observable<MediaType> {
    return this._update(id, command).pipe(
      map((updatedMediaType) => {
        const index = this.indexesMap.get(id);

        if (index != null) {
          const found = this.collection[index];
          ({
            name: found.name,
            createdAt: found.createdAt,
            deletedAt: found.deletedAt,
            isDeleted: found.isDeleted,
            updatedAt: found.updatedAt,
          } = updatedMediaType);
        } else {
          console.warn(
            `${MediaTypeService.name}: we can't find the updated entity with id: ${id}, in the local collection`,
          );
        }

        return updatedMediaType;
      }),
    );
  }

  public remove(id: number): Observable<boolean | IApiProblemDetails> {
    return this._remove(id).pipe(
      map((res) => {
        if (res == null) {
          const index = this.indexesMap.get(id);

          if (index != null) {
            this.collection.splice(index, 1);
            this.indexesMap.delete(id);
          } else {
            console.warn(
              `${MediaTypeService.name}: we can't find the removed entity with id: ${id}, in the local collection`,
            );
          }

          return !res;
        }

        return res;
      }),
    );
  }

  private _find(page?: number, pageSize?: number): Observable<MediaType[]> {
    let url = this.url;

    if (page && pageSize) {
      url += `?page=${page}&pageSize=${pageSize}`;
    }
    return this.http.get<MediaType[]>(url).pipe(map((value) => value.map((mediaType) => new MediaType(mediaType))));
  }

  private _create(command: CreateMediaTypeCommand): Observable<MediaType> {
    return this.http.post<MediaType>(this.url, command).pipe(map((mediaType) => new MediaType(mediaType)));
  }

  private _update(id: number, command: UpdateMediaTypeCommand): Observable<MediaType> {
    return this.http.put<MediaType>(`${this.url}/${id}`, command).pipe(map((mediaType) => new MediaType(mediaType)));
  }

  private _remove(id: number): Observable<null | IApiProblemDetails> {
    return this.http.delete<null>(`${this.url}/${id}`);
  }
}
