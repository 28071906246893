import { BaseModel } from './base.model';

export class SubSector extends BaseModel {
  public name: string;

  constructor(data: SubSector) {
    super(data);

    ({ name: this.name } = data);
  }
}

export class SubSectorCommand {
  public name: string;

  constructor(data: SubSectorCommand) {
    ({ name: this.name } = data);
  }
}

export class CreateSubSectorCommand extends SubSectorCommand {
  constructor(data: CreateSubSectorCommand) {
    super(data);
  }
}

export class UpdateSubSectorCommand extends SubSectorCommand {
  public id: number;

  constructor(data: UpdateSubSectorCommand) {
    super(data);
    this.id = data.id;
  }
}

export class Sector extends BaseModel {
  public name: string;

  public subsectors: SubSector[] = [];

  constructor(data: Sector) {
    super(data);

    ({ name: this.name } = data);

    if (data.subsectors && data.subsectors.length) {
      this.subsectors = data.subsectors.map((sub) => new SubSector(sub));
    }
  }
}

export class SectorCommand {
  public name: string;

  public subsectors: SubSector[] = [];

  constructor(data: SectorCommand) {
    ({ name: this.name, subsectors: this.subsectors } = data);
  }
}

export class CreateSectorCommand extends SectorCommand {
  constructor(data: CreateSectorCommand) {
    super(data);
  }
}

export class UpdateSectorCommand extends SectorCommand {
  public id: number;

  constructor(data: UpdateSectorCommand) {
    super(data);
    this.id = data.id;
  }
}
