import { Component, Input } from '@angular/core';
import { Person } from '../../../models';

@Component({
  selector: 'app-person-summary',
  templateUrl: './person-summary.component.html',
  styleUrls: ['./person-summary.component.scss'],
})
export class PersonSummaryComponent {
  @Input() public person: Person | undefined = undefined;
  @Input() public isTitle = false;

  public personCssClass(): string {
    return this.isTitle ? 'person-name title' : 'person-name';
  }
}
