<div class="p-4 py-8 w-10 mx-auto my-0">
  <div class="flex align-items-start justify-content-between">
    <h3 i18n>Agency types</h3>
    <button
      pButton
      label="Add Agency Type"
      i18n-label
      type="button"
      class="p-button-raised p-button-warning"
      icon="pi pi-plus-circle"
      style="margin-bottom: 10px"
      (click)="createClicked()"
    ></button>
  </div>
  <p-table
    #dt
    class="job-roles-table"
    styleClass="p-datatable-sm"
    [value]="filteredAgencyTypes"
    [paginator]="true"
    [(rows)]="pageSize"
    [(first)]="first"
    [showCurrentPageReport]="true"
    [currentPageReportTemplate]="translateCurrentPageReportTemplate()"
    [resizableColumns]="true"
    [rowsPerPageOptions]="availablePageSizes"
    [loading]="isLoading"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-end py-3">
        <span class="p-float-label">
          <input
            id="float-input"
            type="text"
            pInputText
            [(ngModel)]="selectedSearchText"
            (ngModelChange)="searchTextChanged()"
          />
          <label for="float-input" i18n>Search</label>
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th class="current" i18n>Name</th>
        <th class="current" i18n>Order</th>
        <th class="current text-right pr-6" i18n>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-type>
      <tr>
        <td>{{ type.name }}</td>
        <td>{{ type.order }}</td>
        <td class="actions text-right">
          <button
            pButton
            type="button"
            icon="pi pi-file-edit"
            class="p-button-outlined p-button-warning"
            (click)="editClicked($event, type)"
          ></button>
          <button
            pButton
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-warning"
            (click)="confirmDelete($event, type)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-toast></p-toast>
  <p-confirmPopup></p-confirmPopup>
</div>
