<div class="form-container" *ngIf="company">
  <div class="col-12 flex start">
    <app-company-summary [company]="company"></app-company-summary>
  </div>

  <div class="col-12 flex align-items-end justify-content-start">
    <div class="col-12 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="name"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Office Name"
          [(ngModel)]="company.name"
          (ngModelChange)="companyNameChanged()"
        />
        <label for="name">Company Name</label>
      </span>
    </div>
  </div>
  <div class="col-12 flex align-items-end justify-content-start">
    <div class="col-4 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="researchId"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          placeholder="Research ID"
          [(ngModel)]="company.researchId"
        />
        <label for="researchId">Research ID</label>
      </span>
    </div>
    <div class="col-4 p-2">
      <span class="p-float-label inline-block w-full">
        <p-autoComplete
          id="office-network"
          [ngModel]="selectedNetwork"
          (ngModelChange)="onNetworkItemChange($event)"
          [suggestions]="networkResult"
          [virtualScroll]="true"
          [virtualScrollItemSize]="40"
          [virtualScrollOptions]="networksVirtualScroll"
          (completeMethod)="filterNetworks($event)"
          i18n-field
          field="name"
          [dropdown]="true"
          placeholder="Select a network..."
          styleClass="w-full autocomplete-full"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
        >
        </p-autoComplete>
        <label i18n for="office-network" class="company-label">Network</label>
      </span>
    </div>
    <div class="col-4 p-2">
      <span class="p-float-label inline-block w-full">
        <p-autoComplete
          id="office-holding"
          [ngModel]="selectedHolding"
          (ngModelChange)="onHoldingItemChange($event)"
          [suggestions]="holdingResult"
          [virtualScroll]="true"
          [virtualScrollItemSize]="40"
          [virtualScrollOptions]="holdingsVirtualScroll"
          (completeMethod)="filterHoldings($event)"
          i18n-field
          field="name"
          [dropdown]="true"
          placeholder="Select a holding..."
          styleClass="w-full autocomplete-full"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
        >
        </p-autoComplete>
        <label i18n for="office-holding" class="company-label">Holdings</label>
      </span>
    </div>
  </div>

  <div class="col-12 flex align-items-end justify-content-start">
    <div class="col-12 p-2">
      <span class="p-float-label p-input-icon-right w-full">
        <input
          id="observations"
          type="text"
          pInputTextarea
          class="p-inputtext-sm w-full"
          placeholder="Observations"
          [(ngModel)]="company.observations"
        />
        <label for="observations">Observations</label>
      </span>
    </div>
  </div>

  <div class="col-12 flex align-items-end justify-content-start">
    <div class="col-2 p-2">
      <span><small>Is Enabled?</small></span>
      <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
        <p-selectButton
          id="isEnabled"
          [options]="enableOptions"
          [(ngModel)]="selectedEnableOption"
          optionLabel="label"
        ></p-selectButton>
      </span>
    </div>
  </div>

  <div class="col-12">
    <button
      *ngIf="mode === 'edit'"
      pButton
      label="Update Company"
      type="button"
      (click)="updateClicked()"
      [disabled]="!updateCompanyEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      *ngIf="mode === 'create'"
      pButton
      label="Create Company"
      type="button"
      (click)="updateClicked()"
      [disabled]="!updateCompanyEnabled"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      (click)="cancelClicked()"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
    ></button>
  </div>
</div>
