<div *ngIf="person">
  <div class="flex align-items-start justify-content-between">
    <h3>Juries</h3>
    <button
      *ngIf="hasRole('db-edit-people')"
      pButton
      label="Add Jury"
      type="button"
      (click)="createClicked()"
      class="p-button-raised p-button-warning"
      icon="pi pi-plus-circle"
      style="margin-bottom: 10px"
    ></button>
  </div>
  <p-table
    class="person-juries-table"
    #dt
    styleClass="p-datatable-sm"
    [value]="juries"
    [loading]="isLoading"
    [scrollable]="true"
    selectionMode="single"
  >
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between"></div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Festival</th>
        <th>Company</th>
        <th>Position</th>
        <th class="actions" *ngIf="hasRole('db-edit-people')">Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-jury>
      <tr>
        <td>{{ jury.festival.event.name }} - {{ jury.festival.year }}</td>
        <td [ngClass]="{ disabled: !jury.company }">{{ jury.company ? jury.company : 'No company' }}</td>
        <td [ngClass]="{ disabled: !jury.position }">{{ jury.position ? jury.position : 'No position' }}</td>
        <td class="actions" *ngIf="hasRole('db-edit-people')">
          <button
            pButton
            type="button"
            icon="pi pi-file-edit"
            class="p-button-outlined p-button-warning"
            (click)="editClicked($event, jury)"
          ></button>
          <button
            pButton
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-warning"
            (click)="confirmDelete($event, jury)"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="flex align-items-center justify-content-between">
        In total there are {{ juries ? juries.length : 0 }} juries.
      </div>
    </ng-template>
  </p-table>

  <p-toast></p-toast>
  <p-confirmPopup></p-confirmPopup>
</div>
