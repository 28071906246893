import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { BaseComponent } from '../../components/general/base/base.component';
import { PersonInfoFormComponent } from '../../components/people/person-info-form/person-info-form.component';
import { ProfessionalsTableComponent } from '../../components/professionals/professionals-table/professionals-table.component';
import { Person, UpdatePersonCommand } from '../../models';
import { IProfessionalsApiFilters, IProfessionalsFilter } from '../../shared/interfaces';
import { ApiService, ToastService } from '../../shared/services';
import { ProfessionalsUnassignedComponent } from '../../components/professionals/professionals-unassigned/professionals-unassigned.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-professionals',
  templateUrl: './professionals.component.html',
  styleUrls: ['./professionals.component.scss'],
})
export class ProfessionalsComponent extends BaseComponent implements AfterViewChecked {
  @ViewChild(ProfessionalsTableComponent) professionalsTable?: ProfessionalsTableComponent;
  @ViewChild(ProfessionalsUnassignedComponent) unassignedTable?: ProfessionalsUnassignedComponent;

  public filters?: IProfessionalsFilter;

  public latestApiFilters: IProfessionalsApiFilters | undefined = undefined;
  public activeTabIndex = 0;

  public componentsReady = false;
  constructor(
    private readonly apiService: ApiService,
    private readonly toastService: ToastService,
    private readonly dialogService: DialogService,
    private readonly cd: ChangeDetectorRef,
    private readonly router: Router,
  ) {
    super();
  }

  ngAfterViewChecked(): void {
    if (!this.componentsReady && this.professionalsTable) {
      this.componentsReady = true;
      this.cd.detectChanges();
    }
  }

  public async onFilters(filters: IProfessionalsFilter): Promise<void> {
    this.filters = filters;
    if (this.activeTabIndex == 0) {
      if (this.professionalsTable) {
        this.professionalsTable.load(filters);
      }
    } else if (this.activeTabIndex == 1) {
      if (this.unassignedTable) {
        this.unassignedTable.load(filters);
      }
    }
  }

  isEven(num: number): boolean {
    return num % 2 === 0;
  }

  createPerson() {
    const ref = this.dialogService.open(PersonInfoFormComponent, {
      header: `Create Professional`,
      width: '50%',
      height: 'auto',
      data: {
        person: Person.createNewBlankPerson(),
        mode: 'create',
      },
    });

    ref.onClose.subscribe((command: UpdatePersonCommand) => {
      if (command) {
        this.apiService.people.create(command).subscribe({
          next: (person) => {
            this.unassignedTable?.addPerson(person);
            this.toastService.send({
              severity: 'success',
              summary: 'Created',
              detail: 'You have create a person',
            });
            this.router.navigate(['professionals', 'people', person.id]);
          },
          error: () => {
            this.toastService.send({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while creating the person',
            });
          },
        });
      }
    });
  }

  setLatestApiFilters($event: IProfessionalsApiFilters) {
    this.latestApiFilters = $event;
    console.log(this.latestApiFilters);
    /* if (this.unassignedTable) {
      this.unassignedTable.loadApiFilters(this.latestApiFilters)
    }*/
  }

  public setActiveIndex($event: number) {
    this.activeTabIndex = $event;
    this.onFilters(this.filters as IProfessionalsFilter);
  }
}
