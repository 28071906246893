import { BaseModel } from './base.model';
import { Exclusive } from './exclusive.model';
import { MediaType } from './media-type.model';

export class Media extends BaseModel {
  public name: string;
  public fullName: string;
  public supports: MediaSupport[] = [];
  public exclusive: Exclusive | undefined = undefined;

  constructor(data: Media) {
    super(data);
    ({ name: this.name, fullName: this.fullName } = data);
    if (data.supports && data.supports.length) {
      this.supports = data.supports.map((s) => new MediaSupport(s));
    }
    if (data.exclusive) {
      this.exclusive = new Exclusive(data.exclusive);
    }
  }

  public addSupport(support: MediaSupport) {
    this.supports.push(support);
  }

  public updateSupport(support: MediaSupport) {
    const index = this.supports.findIndex((s) => s.id === support.id);
    if (index >= 0) {
      this.supports[index] = support;
    }
  }

  public removeSupport(support: MediaSupport) {
    const index = this.supports.findIndex((s) => s.id === support.id);
    if (index >= 0) {
      this.supports.splice(index, 1);
    }
  }
}

export class MediaSupport extends BaseModel {
  public name: string;
  public type: MediaType | undefined = undefined;
  public mediaFullName: string | undefined = undefined;

  constructor(data: MediaSupport) {
    super(data);
    ({ name: this.name, mediaFullName: this.mediaFullName } = data);

    if (data.type) {
      this.type = new MediaType(data.type);
    }
  }
}

export class SetMediaExclusiveCommand {
  public exclusiveId: number;

  constructor(data: SetMediaExclusiveCommand) {
    ({ exclusiveId: this.exclusiveId } = data);
  }
}

export class MediaSupportCommand {
  public name: string;
  public mediaTypeId: number;

  constructor(data: MediaSupportCommand) {
    ({ name: this.name, mediaTypeId: this.mediaTypeId } = data);
  }
}
