import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  Brand,
  Company,
  CompanyCommand,
  Country,
  CreateBrandCommand,
  CreateCountryCommand,
  CreateFilterCommand,
  CreateOfficeCommand,
  CreateOfficeSubTypeCommand,
  CreatePersonCommand,
  CreatePersonJuryCommand,
  CreateProductCommand,
  CreateProfessionalCommand,
  CreateProvinceCommand,
  CreateRoleCommand,
  CreateSectorCommand,
  CreateSubSectorCommand,
  CreateTitleCommand,
  CreateUserCommand,
  CreateWaveCommand,
  EmployeeStats,
  EmployeeStatsCommand,
  Exclusive,
  Filter,
  Holding,
  HoldingCommand,
  InfoadexRecordCommand,
  Jury,
  Media,
  MediaSupport,
  MediaSupportCommand,
  Network,
  NetworkCommand,
  Office,
  OfficeSubType,
  OfficeType,
  Product,
  Professional,
  Province,
  Role,
  Sector,
  SetMediaExclusiveCommand,
  Studio,
  StudioType,
  SubSector,
  Title,
  UpdateAgencyServicesCommand,
  UpdateAgencyTypesCommand,
  UpdateBrandCommand,
  UpdateCountryCommand,
  UpdateFilterCommand,
  UpdateOfficeCommand,
  UpdateOfficeSubTypeCommand,
  UpdatePersonCommand,
  UpdatePersonFiltersCommand,
  UpdatePersonJuryCommand,
  UpdatePersonWavesCommand,
  UpdateProductCommand,
  UpdateProfessionalCommand,
  UpdateProvinceCommand,
  UpdateRoleCommand,
  UpdateSectorCommand,
  UpdateSubSectorCommand,
  UpdateTitleCommand,
  UpdateUserCommand,
  UpdateWaveCommand,
  UpsertAdvertiserBrandsCommand,
  UpsertAdvertiserSubSectorsCommand,
  UpsertStudioTypesCommand,
  User,
  Wave,
} from '../../models';
import { Advertiser } from '../../models';
import { AgencyService, CreateAgencyServiceCommand, UpdateAgencyServiceCommand } from '../../models';
import { Agency } from '../../models';
import { Collaboration, CollaborationCommand } from '../../models';
import { Person } from '../../models';
import { IOfficesApiFilters, IProfessionalsApiFilters, ISearchQuery } from '../interfaces';
import { OfficeFusionCommand } from '../../models/office-fusion-detail.model';
import { Festival, FestivalCommand, UpdateFestivalCommand } from 'src/app/models/festival.model';
import { Event, EventCommand, UpdateEventCommand } from 'src/app/models/event.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private officesUrl = `${environment.apiBase}${environment.apiOffices}`;
  private countriesUrl = `${environment.apiBase}${environment.apiCountries}`;
  private sectorsUrl = `${environment.apiBase}${environment.apiSectors}`;
  private brandsUrl = `${environment.apiBase}${environment.apiBrands}`;
  private agenciesUrl = `${environment.apiBase}${environment.apiAgencies}`;
  private advertisersUrl = `${environment.apiBase}${environment.apiAdvertisers}`;
  private collaborationsUrl = `${environment.apiBase}${environment.apiCollaborations}`;
  private peopleUrl = `${environment.apiBase}${environment.apiPeople}`;
  private professionalsUrl = `${environment.apiBase}${environment.apiProfessionals}`;
  private wavesUrl = `${environment.apiBase}${environment.apiWaves}`;
  private eventsUrl = `${environment.apiBase}${environment.apiEvents}`;
  private festivalsUrl = `${environment.apiBase}${environment.apiFestivals}`;
  private filtersUrl = `${environment.apiBase}${environment.apiFilters}`;
  private mediasUrl = `${environment.apiBase}${environment.apiMedias}`;
  private exclusivesUrl = `${environment.apiBase}${environment.apiExclusives}`;
  private studiosUrl = `${environment.apiBase}${environment.apiStudios}`;
  private studioTypesUrl = `${environment.apiBase}${environment.apiStudioTypes}`;
  private productsUrl = `${environment.apiBase}${environment.apiProducts}`;
  private usersUrl = `${environment.apiBase}${environment.apiUsers}`;
  private jobRolesUrl = `${environment.apiBase}${environment.apiJobRoles}`;
  private companiesUrl = `${environment.apiBase}${environment.apiCompanies}`;
  private networksUrl = `${environment.apiBase}${environment.apiNetworks}`;
  private holdingsUrl = `${environment.apiBase}${environment.apiHoldings}`;
  private advertiserBrandsUrl = `${environment.apiBase}${environment.apiAdvertiserBrands}`;

  constructor(private http: HttpClient) {}

  public offices = {
    search: (filters: IOfficesApiFilters): Observable<ISearchQuery<Office[]>> => {
      return this.http.post<ISearchQuery<Office[]>>(`${this.officesUrl}/search`, { ...filters }).pipe(
        map((value) => {
          const items = (value as ISearchQuery<Office[]>).items.map((item) => new Office(item));
          return { ...value, items };
        }),
      );
    },
    types: (): Observable<OfficeType[]> => {
      return this.http
        .get<OfficeType[]>(`${this.officesUrl}/types`)
        .pipe(map((value) => (value as OfficeType[]).map((officeType) => new OfficeType(officeType))));
    },
    findById: (id: number): Observable<Office | undefined> => {
      return this.http.get<Office>(`${this.officesUrl}/${id}`).pipe(
        map((value) => {
          if (!value) {
            return undefined;
          }
          return new Office(value as Office);
        }),
      );
    },
    professionalsByOffice: (id: number): Observable<ISearchQuery<Professional[]>> => {
      return this.http
        .get<ISearchQuery<Professional[]>>(`${this.officesUrl}/${id}/professionals?onlyCurrentProfessionals=false`)
        .pipe(
          map((value) => {
            const items = (value as ISearchQuery<Professional[]>).items.map(
              (professional) => new Professional(professional),
            );

            return { ...value, items };
          }),
        );
    },
    create: (command: CreateOfficeCommand): Observable<Office> => {
      return this.http.post<Office>(this.officesUrl, command).pipe(map((value) => new Office(value as Office)));
    },
    update: (id: number, command: UpdateOfficeCommand): Observable<Office> => {
      return this.http
        .put<Office>(`${this.officesUrl}/${id}`, command)
        .pipe(map((value) => new Office(value as Office)));
    },
    delete: (id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.officesUrl}/${id}`);
    },
    employeeStats: (id: number): Observable<EmployeeStats[]> => {
      return this.http
        .get<EmployeeStats[]>(`${this.officesUrl}/${id}/employeestats`)
        .pipe(map((employeeStats) => employeeStats.map((stat) => new EmployeeStats(stat))));
    },
    createEmployeeStats: (id: number, command: EmployeeStatsCommand): Observable<EmployeeStats> => {
      return this.http
        .post<EmployeeStats>(`${this.officesUrl}/${id}/employeestats`, command)
        .pipe(map((employeeStats) => new EmployeeStats(employeeStats)));
    },
    updateEmployeeStats: (id: number, statsId: number, command: EmployeeStatsCommand): Observable<EmployeeStats> => {
      return this.http
        .put<EmployeeStats>(`${this.officesUrl}/${id}/employeestats/${statsId}`, command)
        .pipe(map((employeeStats) => new EmployeeStats(employeeStats)));
    },
    deleteEmployeeStats: (id: number, statsId: number): Observable<boolean> => {
      return this.http
        .delete<boolean>(`${this.officesUrl}/${id}/employeestats/${statsId}`)
        .pipe(map((employeeStats) => employeeStats));
    },
    getAdvancedExcel: (filters: IOfficesApiFilters): Observable<Blob> => {
      return this.http.post(`${this.officesUrl}/excel`, { ...filters }, { responseType: 'blob' });
    },
    fusion: (id: number, command: OfficeFusionCommand): Observable<Office> => {
      return this.http
        .post<Office>(`${this.officesUrl}/${id}/fusion`, command)
        .pipe(map((value) => new Office(value as Office)));
    },
  };

  public countries = {
    find: (countryId?: number, query?: { includeProvinces: boolean }): Observable<Country[]> => {
      let url = `${this.countriesUrl}?includeProvinces=${!!query?.includeProvinces}`;
      if (countryId && countryId > 0) {
        url = url.concat(`&countryId=${countryId}`);
      }

      return this.http.get<Country[]>(url).pipe(map((value) => value.map((country) => new Country(country))));
    },
    getById: (id: number): Observable<Country> => {
      return this.http.get<Country>(`${this.countriesUrl}/${id}`).pipe(
        map((value) => {
          return new Country(value);
        }),
      );
    },
    create: (command: CreateCountryCommand): Observable<Country> => {
      return this.http.post<Country>(`${this.countriesUrl}`, command).pipe(
        map((value) => {
          return new Country(value);
        }),
      );
    },
    update: (id: number, command: UpdateCountryCommand): Observable<Country> => {
      return this.http.put<Country>(`${this.countriesUrl}/${id}`, command).pipe(
        map((value) => {
          return new Country(value);
        }),
      );
    },
    delete: (id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.countriesUrl}/${id}`);
    },
    createProvinceByCountryId: (countryId: number, command: CreateProvinceCommand): Observable<Province> => {
      return this.http.post<Province>(`${this.countriesUrl}/${countryId}/provinces`, command).pipe(
        map((value) => {
          return new Province(value);
        }),
      );
    },
    updateProvinceByContryId: (countryId: number, id: number, command: UpdateProvinceCommand): Observable<Province> => {
      return this.http.put<Province>(`${this.countriesUrl}/${countryId}/provinces/${id}`, command).pipe(
        map((value) => {
          return new Province(value);
        }),
      );
    },
    deleteProvinceByCountryId: (countryId: number, id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.countriesUrl}/${countryId}/provinces/${id}`);
    },
    deleteCountryByUserId: (countryId: number, userId: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.countriesUrl}/${countryId}/permissions/${userId}`);
    },
    addCountryByUserId: (countryId: number, userId: number): Observable<boolean> => {
      return this.http.post<boolean>(`${this.countriesUrl}/${countryId}/permissions/${userId}`, null);
    },
  };

  public sectors = {
    find: (): Observable<Sector[]> => {
      return this.http
        .get<Sector[]>(`${this.sectorsUrl}?includeSubsectors=true`)
        .pipe(map((value) => value.map((sector) => new Sector(sector))));
    },
    create: (command: CreateSectorCommand): Observable<Sector> => {
      return this.http.post<Sector>(`${this.sectorsUrl}`, command).pipe(
        map((value) => {
          return new Sector(value);
        }),
      );
    },
    update: (id: number, command: UpdateSectorCommand): Observable<Sector> => {
      return this.http.put<Sector>(`${this.sectorsUrl}/${id}`, command).pipe(
        map((value) => {
          return new Sector(value);
        }),
      );
    },
    delete: (id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.sectorsUrl}/${id}`);
    },
    createSubsectorBySectorId: (sectorId: number, command: CreateSubSectorCommand): Observable<SubSector> => {
      return this.http.post<SubSector>(`${this.sectorsUrl}/${sectorId}/subsectors`, command).pipe(
        map((value) => {
          return new SubSector(value);
        }),
      );
    },
    updateSubsectorBySectorId: (
      sectorId: number,
      id: number,
      command: UpdateSubSectorCommand,
    ): Observable<SubSector> => {
      return this.http.put<SubSector>(`${this.sectorsUrl}/${sectorId}/subsectors/${id}`, command).pipe(
        map((value) => {
          return new SubSector(value);
        }),
      );
    },
    deleteSubsectorBySectorId: (sectorId: number, id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.sectorsUrl}/${sectorId}/subsectors/${id}`);
    },
  };

  public brands = {
    find: (page?: number, pageSize?: number): Observable<Brand[]> => {
      let url = this.brandsUrl;
      if (page && pageSize) {
        url += `?page=${page}&pageSize=${pageSize}`;
      }
      return this.http.get<Brand[]>(url).pipe(map((value) => (value as Brand[]).map((brand) => new Brand(brand))));
    },
    findWithinAdvertisers: (countryId?: number, page?: number, pageSize?: number): Observable<Brand[]> => {
      const url = this.advertiserBrandsUrl;
      let params = new HttpParams();
      if (countryId && countryId > 0) {
        params = params.append('countryId', countryId.toString());
      }
      if (page && pageSize) {
        params = params.append('page', page.toString());
        params = params.append('pageSize', pageSize.toString());
      }
      return this.http
        .get<Brand[]>(url, { params })
        .pipe(map((value) => (value as Brand[]).map((brand) => new Brand(brand))));
    },
    create: (command: CreateBrandCommand): Observable<Brand> => {
      return this.http.post<Brand>(`${this.brandsUrl}`, command).pipe(
        map((value) => {
          return new Brand(value);
        }),
      );
    },
    update: (id: number, command: UpdateBrandCommand): Observable<Brand> => {
      return this.http.put<Brand>(`${this.brandsUrl}/${id}`, command).pipe(
        map((value) => {
          return new Brand(value);
        }),
      );
    },
    delete: (id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.brandsUrl}/${id}`);
    },
  };

  public agencies = {
    getAll: (page?: number, pageSize?: number): Observable<Agency[]> => {
      let url = this.agenciesUrl;
      if (page && pageSize) {
        url += `?page=${page}&pageSize=${pageSize}`;
      }
      return this.http.get<Agency[]>(url).pipe(map((value) => value.map((agency) => new Agency(agency))));
    },
    getCollaborations: (agencyId: number): Observable<Collaboration[]> => {
      return this.http.get<Collaboration[]>(`${this.agenciesUrl}/${agencyId}/collaborations`).pipe(
        map((value) => {
          return value.map((item) => new Collaboration(item));
        }),
      );
    },
    getById: (agencyId: number): Observable<Agency> => {
      return this.http.get<Agency>(`${this.agenciesUrl}/${agencyId}`).pipe(
        map((value) => {
          return new Agency(value);
        }),
      );
    },
    associateTypesToAgency: (agencyId: number, command: UpdateAgencyTypesCommand): Observable<Agency> => {
      return this.http.post<Agency>(`${this.agenciesUrl}/${agencyId}/types`, command);
    },
    associateServicesToAgency: (agencyId: number, command: UpdateAgencyServicesCommand): Observable<Agency> => {
      return this.http.post<Agency>(`${this.agenciesUrl}/${agencyId}/services`, command);
    },
    getAllServices: (): Observable<AgencyService[]> => {
      return this.http.get<AgencyService[]>(`${this.agenciesUrl}/services`).pipe(
        map((value) => {
          return value.map((item) => new AgencyService(item));
        }),
      );
    },
    createService: (command: CreateAgencyServiceCommand): Observable<AgencyService> => {
      return this.http
        .post<AgencyService>(`${this.agenciesUrl}/services`, command)
        .pipe(map((service) => new AgencyService(service)));
    },
    updateService: (id: number, command: UpdateAgencyServiceCommand): Observable<AgencyService> => {
      return this.http
        .put<AgencyService>(`${this.agenciesUrl}/services/${id}`, command)
        .pipe(map((service) => new AgencyService(service)));
    },
    deleteService: (id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.agenciesUrl}/services/${id}`);
    },
    getAllTypes: (): Observable<OfficeSubType[]> => {
      return this.http.get<OfficeSubType[]>(`${this.agenciesUrl}/types`).pipe(
        map((value) => {
          return value.map((item) => new OfficeSubType(item));
        }),
      );
    },
    createType: (command: CreateOfficeSubTypeCommand): Observable<OfficeSubType> => {
      return this.http
        .post<OfficeSubType>(`${this.agenciesUrl}/types`, command)
        .pipe(map((service) => new OfficeSubType(service)));
    },
    updateType: (id: number, command: UpdateOfficeSubTypeCommand): Observable<OfficeSubType> => {
      return this.http
        .put<OfficeSubType>(`${this.agenciesUrl}/types/${id}`, command)
        .pipe(map((service) => new OfficeSubType(service)));
    },
    deleteType: (id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.agenciesUrl}/types/${id}`);
    },
  };

  public advertisers = {
    getAll: (page?: number, pageSize?: number): Observable<Advertiser[]> => {
      let url = this.advertisersUrl;
      if (page && pageSize) {
        url += `?page=${page}&pageSize=${pageSize}`;
      }
      return this.http
        .get<Advertiser[]>(url)
        .pipe(map((value) => value.map((advertiser) => new Advertiser(advertiser))));
    },
    getById: (advertiserId: number): Observable<Advertiser> => {
      return this.http.get<Advertiser>(`${this.advertisersUrl}/${advertiserId}`).pipe(
        map((value) => {
          return new Advertiser(value);
        }),
      );
    },
    getCollaborations: (advertiserId: number): Observable<Collaboration[]> => {
      return this.http.get<Collaboration[]>(`${this.advertisersUrl}/${advertiserId}/collaborations`).pipe(
        map((value) => {
          return value.map((item) => new Collaboration(item));
        }),
      );
    },
    updateBrands: (advertiserId: number, command: UpsertAdvertiserBrandsCommand): Observable<Advertiser> => {
      return this.http.post<Advertiser>(`${this.advertisersUrl}/${advertiserId}/brands`, command).pipe(
        map((value) => {
          return new Advertiser(value);
        }),
      );
    },
    updateSubsectors: (advertiserId: number, command: UpsertAdvertiserSubSectorsCommand): Observable<Advertiser> => {
      return this.http.post<Advertiser>(`${this.advertisersUrl}/${advertiserId}/subsectors`, command).pipe(
        map((value) => {
          return new Advertiser(value);
        }),
      );
    },
    createInfoadex: (advertiserId: number, command: InfoadexRecordCommand): Observable<Advertiser> => {
      return this.http.post<Advertiser>(`${this.advertisersUrl}/${advertiserId}/infoadex`, command).pipe(
        map((value) => {
          return new Advertiser(value);
        }),
      );
    },
    updateInfoadex: (
      advertiserId: number,
      infoAdexId: number,
      command: InfoadexRecordCommand,
    ): Observable<Advertiser> => {
      return this.http.put<Advertiser>(`${this.advertisersUrl}/${advertiserId}/infoadex/${infoAdexId}`, command).pipe(
        map((value) => {
          return new Advertiser(value);
        }),
      );
    },
    deleteInfoadex: (advertiserId: number, infoadexId: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.advertisersUrl}/${advertiserId}/infoadex/${infoadexId}`);
    },
  };

  public collaborations = {
    create: (command: CollaborationCommand, view: string): Observable<Collaboration> => {
      return this.http.post<Collaboration>(`${this.collaborationsUrl}?view=${view}`, command);
    },
    delete: (collaborationId: number): Observable<Collaboration> => {
      return this.http.delete<Collaboration>(`${this.collaborationsUrl}/${collaborationId}`);
    },
    update: (collaborationId: number, command: CollaborationCommand, view: string): Observable<Collaboration> => {
      return this.http.put<Collaboration>(`${this.collaborationsUrl}/${collaborationId}?view=${view}`, command);
    },
  };

  public people = {
    getById: (id: number): Observable<Person | undefined> => {
      return this.http.get<Person>(`${this.peopleUrl}/${id}`).pipe(
        map((value) => {
          if (!value) {
            return undefined;
          }
          return new Person(value as Person);
        }),
      );
    },
    create: (command: CreatePersonCommand): Observable<Person> => {
      return this.http.post<Person>(`${this.peopleUrl}`, command).pipe(
        map((value) => {
          return new Person(value);
        }),
      );
    },
    update: (id: number, command: UpdatePersonCommand): Observable<Person> => {
      return this.http.put<Person>(`${this.peopleUrl}/${id}`, command).pipe(
        map((value) => {
          return new Person(value);
        }),
      );
    },
    delete: (id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.peopleUrl}/${id}`);
    },
    getProfessionals: (id: number): Observable<Professional[]> => {
      return this.http.get<Professional[]>(`${this.peopleUrl}/${id}/professionals`).pipe(
        map((value) => {
          return value.map((item) => new Professional(item));
        }),
      );
    },
    getWaves: (id: number): Observable<Wave[]> => {
      return this.http.get<Wave[]>(`${this.peopleUrl}/${id}/waves`).pipe(
        map((value) => {
          return value.map((item) => new Wave(item));
        }),
      );
    },
    updateWaves: (id: number, command: UpdatePersonWavesCommand): Observable<Wave[]> => {
      return this.http.post<Wave[]>(`${this.peopleUrl}/${id}/waves`, command).pipe(
        map((value) => {
          return value.map((item) => new Wave(item));
        }),
      );
    },
    getJuries: (id: number): Observable<Jury[]> => {
      return this.http.get<Jury[]>(`${this.peopleUrl}/${id}/juries`).pipe(
        map((value) => {
          return value.map((item) => new Jury(item));
        }),
      );
    },
    addJury: (id: number, command: CreatePersonJuryCommand): Observable<Jury> => {
      return this.http.post<Jury>(`${this.peopleUrl}/${id}/juries`, command).pipe(
        map((value) => {
          return new Jury(value);
        }),
      );
    },
    updateJury: (id: number, juryId: number, command: UpdatePersonJuryCommand): Observable<Jury> => {
      return this.http.put<Jury>(`${this.peopleUrl}/${id}/juries/${juryId}`, command).pipe(
        map((value) => {
          return new Jury(value);
        }),
      );
    },
    deleteJury: (id: number, juryId: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.peopleUrl}/${id}/juries/${juryId}`);
    },
    getFilters: (id: number): Observable<Filter[]> => {
      return this.http.get<Filter[]>(`${this.peopleUrl}/${id}/filters`).pipe(
        map((value) => {
          return value.map((item) => new Filter(item));
        }),
      );
    },
    updateFilters: (id: number, command: UpdatePersonFiltersCommand): Observable<Filter[]> => {
      return this.http.post<Filter[]>(`${this.peopleUrl}/${id}/filters`, command).pipe(
        map((value) => {
          return value.map((item) => new Filter(item));
        }),
      );
    },
    getTitles: (countryId?: number): Observable<Title[]> => {
      let url = `${this.peopleUrl}/titles`;
      if (countryId != null && countryId > 0) {
        url += `?countryId=${countryId}`;
      }
      return this.http.get<Title[]>(url).pipe(
        map((value) => {
          return value.map((item) => new Title(item));
        }),
      );
    },
    createTitle: (command: CreateTitleCommand): Observable<Title> => {
      const url = `${this.peopleUrl}/titles`;
      return this.http.post<Title>(url, command).pipe(map((value) => new Title(value)));
    },
    updateTitle: (id: number, command: UpdateTitleCommand): Observable<Title> => {
      const url = `${this.peopleUrl}/titles`;
      return this.http.put<Title>(`${url}/${id}`, command).pipe(map((value) => new Title(value)));
    },
    removeTitle: (id: number): Observable<boolean> => {
      const url = `${this.peopleUrl}/titles`;
      return this.http.delete<boolean>(`${url}/${id}`);
    },
    getUnassigned: (countryId?: number): Observable<Person[]> => {
      let url = `${this.peopleUrl}/unassigned`;
      if (countryId != null && countryId > 0) {
        url += `?countryId=${countryId}`;
      }
      return this.http.get<Person[]>(url).pipe(
        map((value) => {
          return value.map((item) => new Person(item));
        }),
      );
    },
    searchUnassigned: (filters: IOfficesApiFilters): Observable<ISearchQuery<Person[]>> => {
      return this.http.post<ISearchQuery<Person[]>>(`${this.peopleUrl}/unassigned/search`, { ...filters }).pipe(
        map((value) => {
          const items = value.items.map((person) => {
            return new Person(person);
          });
          return { ...value, items };
        }),
      );
    },
  };

  public professionals = {
    search: (filters: IOfficesApiFilters): Observable<ISearchQuery<Professional[]>> => {
      return this.http.post<ISearchQuery<Professional[]>>(`${this.peopleUrl}/search`, { ...filters }).pipe(
        map((value) => {
          const items = value.items.map((professional) => {
            return new Professional(professional);
          });
          return { ...value, items };
        }),
      );
    },
    create: (command: CreateProfessionalCommand): Observable<Professional> => {
      return this.http.post<Professional>(`${this.professionalsUrl}`, command).pipe(
        map((value) => {
          return new Professional(value);
        }),
      );
    },
    update: (id: number, command: UpdateProfessionalCommand): Observable<Professional> => {
      return this.http.put<Professional>(`${this.professionalsUrl}/${id}`, command).pipe(
        map((value) => {
          return new Professional(value);
        }),
      );
    },
    delete: (id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.professionalsUrl}/${id}`);
    },
    getAdvancedExcel: (filters: IProfessionalsApiFilters): Observable<Blob> => {
      return this.http.post(`${this.professionalsUrl}/excel-advanced`, { ...filters }, { responseType: 'blob' });
    },
    getBasicExcel: (filters: IProfessionalsApiFilters): Observable<Blob> => {
      return this.http.post(`${this.professionalsUrl}/excel-basic`, { ...filters }, { responseType: 'blob' });
    },
  };

  public waves = {
    find: (page?: number, pageSize?: number, countryId?: number): Observable<Wave[]> => {
      const queryFilters: string[] = [];
      let wavesUrl = `${this.wavesUrl}`;

      if (page != null) {
        queryFilters.push(`page=${page}`);
      }

      if (pageSize != null) {
        queryFilters.push(`pageSize=${pageSize}`);
      }

      if (countryId != null && countryId > 0) {
        queryFilters.push(`countryId=${countryId}`);
      }

      if (queryFilters.length) {
        wavesUrl += `?${queryFilters.join('&')}`;
      }

      return this.http.get<Wave[]>(wavesUrl).pipe(
        map((value) => {
          return value.map((item) => new Wave(item));
        }),
      );
    },
    create: (command: CreateWaveCommand): Observable<Wave> => {
      const url = this.wavesUrl;
      return this.http.post<Wave>(url, command).pipe(map((wave) => new Wave(wave)));
    },
    update: (id: number, command: UpdateWaveCommand): Observable<Wave> => {
      const url = this.wavesUrl;
      return this.http.put<Wave>(`${url}/${id}`, command).pipe(map((wave) => new Wave(wave)));
    },
    delete: (id: number): Observable<boolean> => {
      const url = this.wavesUrl;
      return this.http.delete<boolean>(`${url}/${id}`);
    },
  };

  public events = {
    find: (page?: number, pageSize?: number): Observable<Event[]> => {
      let url = this.eventsUrl;
      if (page && pageSize) {
        url += `?page=${page}&pageSize=${pageSize}`;
      }
      return this.http.get<Event[]>(url).pipe(map((value) => value.map((event) => new Event(event))));
    },
    create: (command: EventCommand): Observable<Event> => {
      return this.http.post<Event>(`${this.eventsUrl}`, command).pipe(
        map((value) => {
          return new Event(value);
        }),
      );
    },
    update: (command: UpdateEventCommand): Observable<Event> => {
      return this.http.put<Event>(`${this.eventsUrl}/${command.id}`, command).pipe(
        map((value) => {
          return new Event(value);
        }),
      );
    },
    delete: (id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.eventsUrl}/${id}`);
    },
  };

  public festivals = {
    find: (page?: number, pageSize?: number): Observable<Festival[]> => {
      let url = this.festivalsUrl;
      if (page && pageSize) {
        url += `?page=${page}&pageSize=${pageSize}`;
      }
      return this.http.get<Festival[]>(url).pipe(map((value) => value.map((festival) => new Festival(festival))));
    },
    create: (command: FestivalCommand): Observable<Festival> => {
      return this.http.post<Festival>(`${this.festivalsUrl}`, command).pipe(
        map((value) => {
          return new Festival(value);
        }),
      );
    },
    update: (command: UpdateFestivalCommand): Observable<Festival> => {
      return this.http.put<Festival>(`${this.festivalsUrl}/${command.id}`, command).pipe(
        map((value) => {
          return new Festival(value);
        }),
      );
    },
    delete: (id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.festivalsUrl}/${id}`);
    },
  };

  public filters = {
    find: (page?: number, pageSize?: number, countryId?: number): Observable<Filter[]> => {
      const queryFilters: string[] = [];
      let filtersUrl = `${this.filtersUrl}`;

      if (page != null) {
        queryFilters.push(`page=${page}`);
      }

      if (pageSize != null) {
        queryFilters.push(`pageSize=${pageSize}`);
      }

      if (countryId != null && countryId > 0) {
        queryFilters.push(`countryId=${countryId}`);
      }

      if (queryFilters.length) {
        filtersUrl += `?${queryFilters.join('&')}`;
      }

      return this.http.get<Filter[]>(filtersUrl).pipe(
        map((value) => {
          return value.map((item) => new Filter(item));
        }),
      );
    },
    create: (command: CreateFilterCommand): Observable<Filter> => {
      return this.http.post<Filter>(`${this.filtersUrl}`, command).pipe(
        map((value) => {
          return new Filter(value);
        }),
      );
    },
    update: (id: number, command: UpdateFilterCommand): Observable<Filter> => {
      return this.http.put<Filter>(`${this.filtersUrl}/${id}`, command).pipe(
        map((value) => {
          return new Filter(value);
        }),
      );
    },
    delete: (id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.filtersUrl}/${id}`);
    },
  };

  public medias = {
    getAll: (page?: number, pageSize?: number): Observable<Media[]> => {
      let url = this.mediasUrl;
      if (page && pageSize) {
        url += `?page=${page}&pageSize=${pageSize}`;
      }
      return this.http.get<Media[]>(url).pipe(map((value) => value.map((media) => new Media(media))));
    },
    getById: (mediaId: number): Observable<Media> => {
      return this.http.get<Media>(`${this.mediasUrl}/${mediaId}`).pipe(
        map((value) => {
          return new Media(value);
        }),
      );
    },
    setExclusive: (mediaId: number, command: SetMediaExclusiveCommand): Observable<Media> => {
      return this.http.post<Media>(`${this.mediasUrl}/${mediaId}/exclusive`, command).pipe(
        map((value) => {
          return new Media(value);
        }),
      );
    },
    getSupports: (mediaId: number): Observable<MediaSupport[]> => {
      return this.http.get<MediaSupport[]>(`${this.mediasUrl}/${mediaId}/supports`).pipe(
        map((value) => {
          return value.map((item) => new MediaSupport(item));
        }),
      );
    },
    createSupport: (mediaId: number, command: MediaSupportCommand): Observable<MediaSupport> => {
      return this.http.post<MediaSupport>(`${this.mediasUrl}/${mediaId}/supports`, command).pipe(
        map((value) => {
          return new MediaSupport(value);
        }),
      );
    },
    updateSupport: (mediaId: number, supportId: number, command: MediaSupportCommand): Observable<MediaSupport> => {
      return this.http.put<MediaSupport>(`${this.mediasUrl}/${mediaId}/supports/${supportId}`, command).pipe(
        map((value) => {
          return new MediaSupport(value);
        }),
      );
    },
    deleteSupport: (mediaId: number, supportId: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.mediasUrl}/${mediaId}/supports/${supportId}`);
    },
  };

  public exclusives = {
    getAll: (page?: number, pageSize?: number): Observable<Exclusive[]> => {
      let url = this.exclusivesUrl;
      if (page && pageSize) {
        url += `?page=${page}&pageSize=${pageSize}`;
      }
      return this.http.get<Exclusive[]>(url).pipe(map((value) => value.map((exclusive) => new Exclusive(exclusive))));
    },
    getById: (exclusiveId: number): Observable<Exclusive> => {
      return this.http.get<Exclusive>(`${this.exclusivesUrl}/${exclusiveId}`).pipe(
        map((value) => {
          return new Exclusive(value);
        }),
      );
    },
    getSupports: (exclusiveId: number): Observable<MediaSupport[]> => {
      return this.http.get<MediaSupport[]>(`${this.exclusivesUrl}/${exclusiveId}/supports`).pipe(
        map((value) => {
          return value.map((item) => new MediaSupport(item));
        }),
      );
    },
  };

  public studios = {
    getById: (studioId: number): Observable<Studio> => {
      return this.http.get<Studio>(`${this.studiosUrl}/${studioId}`).pipe(
        map((value) => {
          return new Studio(value);
        }),
      );
    },
    getAllTypes: (): Observable<StudioType[]> => {
      return this.http.get<StudioType[]>(this.studioTypesUrl).pipe(
        map((value) => {
          return value.map((item) => new StudioType(item));
        }),
      );
    },
    upsertTypes: (studioId: number, command: UpsertStudioTypesCommand): Observable<Studio> => {
      return this.http.post<Studio>(`${this.studiosUrl}/${studioId}/types`, command).pipe(
        map((value) => {
          return new Studio(value);
        }),
      );
    },
  };

  public products = {
    find: (page?: number, pageSize?: number): Observable<Product[]> => {
      let url = this.productsUrl;
      if (page && pageSize) {
        url += `?page=${page}&pageSize=${pageSize}`;
      }
      return this.http.get<Product[]>(url).pipe(map((value) => value.map((product) => new Product(product))));
    },
    create: (command: CreateProductCommand): Observable<Product> => {
      return this.http.post<Product>(`${this.productsUrl}`, command).pipe(
        map((value) => {
          return new Product(value);
        }),
      );
    },
    update: (id: number, command: UpdateProductCommand): Observable<Product> => {
      return this.http.put<Product>(`${this.productsUrl}/${id}`, command).pipe(
        map((value) => {
          return new Product(value);
        }),
      );
    },
    delete: (id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.productsUrl}/${id}`);
    },
  };

  public users = {
    find: (): Observable<User[]> => {
      const url = this.usersUrl;
      return this.http.get<User[]>(url).pipe(map((value) => value.map((user) => new User(user))));
    },
    create: (command: CreateUserCommand): Observable<User> => {
      const url = this.usersUrl;
      return this.http.post<User>(url, command).pipe(
        map((value) => {
          return new User(value);
        }),
      );
    },
    update: (id: number, command: UpdateUserCommand): Observable<User> => {
      const url = this.usersUrl;
      return this.http.put<User>(`${url}/${id}`, command).pipe(map((value) => new User(value)));
    },
    delete: (id: number): Observable<boolean> => {
      const url = this.usersUrl;
      return this.http.delete<boolean>(`${url}/${id}`);
    },
  };

  public jobRoles = {
    find: (): Observable<Role[]> => {
      const url = this.jobRolesUrl;
      return this.http.get<Role[]>(url).pipe(map((value) => value.map((jobRole) => new Role(jobRole))));
    },
    create: (command: CreateRoleCommand): Observable<Role> => {
      const url = this.jobRolesUrl;
      return this.http.post<Role>(url, command).pipe(map((value) => new Role(value)));
    },
    update: (id: number, command: UpdateRoleCommand): Observable<Role> => {
      const url = this.jobRolesUrl;
      return this.http.put<Role>(`${url}/${id}`, command).pipe(map((value) => new Role(value)));
    },
    delete: (id: number): Observable<boolean> => {
      const url = this.jobRolesUrl;
      return this.http.delete<boolean>(`${url}/${id}`);
    },
  };

  public companies = {
    search: (filters: IOfficesApiFilters): Observable<ISearchQuery<Company[]>> => {
      return this.http.post<ISearchQuery<Company[]>>(`${this.companiesUrl}/search`, { ...filters }).pipe(
        map((value) => {
          const items = (value as ISearchQuery<Company[]>).items.map((item) => new Company(item));
          return { ...value, items };
        }),
      );
    },
    getById: (companyId: number): Observable<Company> => {
      return this.http.get<Company>(`${this.companiesUrl}/${companyId}`).pipe(
        map((value) => {
          return new Company(value);
        }),
      );
    },
    getAll: (search?: string, countryIds?: Array<number>, showAll = false): Observable<ISearchQuery<Company[]>> => {
      let url = `${this.companiesUrl}`;
      const params = [];

      if (search) {
        params.push(`search=${search}`);
      }

      if (countryIds && countryIds.length > 0) {
        console.log(countryIds);
        const countryIdParams = countryIds.map((id) => `countryId=${id}`);
        params.push(...countryIdParams);
      }

      if (showAll) {
        params.push(`showAll=${showAll}`);
      }

      if (params.length > 0) {
        url += '?' + params.join('&');
      }
      return this.http.get<ISearchQuery<Company[]>>(url).pipe(
        map((value) => {
          const items = (value as ISearchQuery<Company[]>).items.map((item) => new Company(item));
          return { ...value, items };
        }),
      );
    },
    create: (command: CompanyCommand): Observable<Company> => {
      return this.http.post<Company>(`${this.companiesUrl}`, command).pipe(
        map((value) => {
          return new Company(value);
        }),
      );
    },
    update: (companyId: number, command: CompanyCommand): Observable<Company> => {
      return this.http.put<Company>(`${this.companiesUrl}/${companyId}`, command).pipe(
        map((value) => {
          return new Company(value);
        }),
      );
    },
    delete: (companyId: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.companiesUrl}/${companyId}`);
    },
    getCollaborations: (companyId: number, target: 'agency' | 'advertiser'): Observable<Collaboration[]> => {
      return this.http.get<Collaboration[]>(`${this.companiesUrl}/${companyId}/collaborations/${target}`).pipe(
        map((value) => {
          return value.map((item) => new Collaboration(item));
        }),
      );
    },
    getExcel: (filters: IOfficesApiFilters): Observable<Blob> => {
      return this.http.post(`${this.companiesUrl}/excel`, { ...filters }, { responseType: 'blob' });
    },
  };

  public networks = {
    search: (filters: IOfficesApiFilters): Observable<ISearchQuery<Network[]>> => {
      return this.http.post<ISearchQuery<Network[]>>(`${this.networksUrl}/search`, { ...filters }).pipe(
        map((value) => {
          const items = (value as ISearchQuery<Network[]>).items.map((item) => new Network(item));
          return { ...value, items };
        }),
      );
    },
    getById: (networkId: number): Observable<Network> => {
      return this.http.get<Network>(`${this.networksUrl}/${networkId}`).pipe(
        map((value) => {
          return new Network(value);
        }),
      );
    },
    getAll: (search?: string): Observable<ISearchQuery<Network[]>> => {
      let url = `${this.networksUrl}`;
      if (search) {
        url += `?search=${search}`;
      }
      return this.http.get<ISearchQuery<Network[]>>(url).pipe(
        map((value) => {
          const items = (value as ISearchQuery<Network[]>).items.map((item) => new Network(item));
          return { ...value, items };
        }),
      );
    },
    create: (command: NetworkCommand): Observable<Network> => {
      return this.http.post<Network>(`${this.networksUrl}`, command).pipe(
        map((value) => {
          return new Network(value);
        }),
      );
    },
    update: (id: number, command: NetworkCommand): Observable<Network> => {
      return this.http.put<Network>(`${this.networksUrl}/${id}`, command).pipe(
        map((value) => {
          return new Network(value);
        }),
      );
    },
    delete: (id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.networksUrl}/${id}`);
    },
  };

  public holdings = {
    search: (filters: IOfficesApiFilters): Observable<ISearchQuery<Holding[]>> => {
      return this.http.post<ISearchQuery<Holding[]>>(`${this.holdingsUrl}/search`, { ...filters }).pipe(
        map((value) => {
          const items = (value as ISearchQuery<Holding[]>).items.map((item) => new Holding(item));
          return { ...value, items };
        }),
      );
    },
    getById: (holdingId: number): Observable<Holding> => {
      return this.http.get<Holding>(`${this.holdingsUrl}/${holdingId}`).pipe(
        map((value) => {
          return new Holding(value);
        }),
      );
    },
    getAll: (search?: string): Observable<ISearchQuery<Holding[]>> => {
      let url = `${this.holdingsUrl}`;
      if (search) {
        url += `?search=${search}`;
      }
      return this.http.get<ISearchQuery<Holding[]>>(url).pipe(
        map((value) => {
          const items = (value as ISearchQuery<Holding[]>).items.map((item) => new Holding(item));
          return { ...value, items };
        }),
      );
    },
    create: (command: HoldingCommand): Observable<Holding> => {
      return this.http.post<Holding>(`${this.holdingsUrl}`, command).pipe(
        map((value) => {
          return new Holding(value);
        }),
      );
    },
    update: (id: number, command: HoldingCommand): Observable<Holding> => {
      return this.http.put<Holding>(`${this.holdingsUrl}/${id}`, command).pipe(
        map((value) => {
          return new Holding(value);
        }),
      );
    },
    delete: (id: number): Observable<boolean> => {
      return this.http.delete<boolean>(`${this.holdingsUrl}/${id}`);
    },
  };
}
