<div *ngIf="office && office.exclusive">
  <div class="flex align-items-start justify-content-between">
    <h3>Supports</h3>
  </div>
  <p-table
    class="exclusive-supports-table"
    #dt
    styleClass="p-datatable-sm"
    [value]="supports"
    [loading]="isLoading"
    [scrollable]="true"
    selectionMode="single"
  >
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between"></div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Company</th>
        <th>Support</th>
        <th>Media Type</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-support>
      <tr>
        <td>{{ support.mediaFullName }}</td>
        <td>{{ support.name }}</td>
        <td>{{ support.type?.name }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="flex align-items-center justify-content-between">
        In total there are {{ supports ? supports.length : 0 }} supports assigned.
      </div>
    </ng-template>
  </p-table>

  <p-toast></p-toast>
  <p-confirmPopup></p-confirmPopup>
</div>
