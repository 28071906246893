<div class="form-container pt-3">
  <div *ngIf="mode === 'create'" class="col-12 p-2 pt-5">
    <span class="p-float-label p-input-icon-right w-full">
      <input
        id="username"
        type="text"
        pInputText
        class="p-inputtext-sm w-full"
        placeholder="user name"
        [(ngModel)]="command.username"
        (ngModelChange)="usernameChanged()"
      />
      <label for="username" i18n>User Name</label>
    </span>
  </div>
  <div class="col-12 p-2 pt-5">
    <span class="p-float-label p-input-icon-right w-full">
      <input
        id="firstname"
        type="text"
        pInputText
        class="p-inputtext-sm w-full"
        placeholder="first name"
        [(ngModel)]="command.firstName"
        (ngModelChange)="firstNameChanged()"
      />
      <label for="firstname" i18n>First Name</label>
    </span>
  </div>
  <div class="col-12 p-2 pt-5">
    <span class="p-float-label p-input-icon-right w-full">
      <input
        id="lastname"
        type="text"
        pInputText
        class="p-inputtext-sm w-full"
        placeholder="last name"
        [(ngModel)]="command.lastName"
        (ngModelChange)="lastNameChanged()"
      />
      <label for="lastname" i18n>Last Name</label>
    </span>
  </div>
  <div class="col-12 p-2 pt-5">
    <span class="p-float-label p-input-icon-right w-full">
      <input
        id="password"
        type="password"
        pInputText
        class="p-inputtext-sm w-full"
        placeholder="password"
        [(ngModel)]="command.password"
        (ngModelChange)="passwordChanged()"
      />
      <label for="password" i18n>Password</label>
    </span>
  </div>
  <div class="col-6 pl-2 pt-5 p-4">
    <span class="p-float-label p-input-icon-right w-full flex flex-row justify-content-start">
      <p-dropdown
        id="role"
        placeholder="Role"
        [options]="roles"
        optionLabel="name"
        appendTo="body"
        class="w-full"
        [style]="{ width: '100%' }"
        [(ngModel)]="role"
        (ngModelChange)="roleChanged()"
      ></p-dropdown>
      <label for="role">Role</label>
    </span>
  </div>
  <div class="col-12">
    <button
      pButton
      *ngIf="mode === 'create'"
      label="Add User"
      type="button"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-plus-circle"
      [disabled]="!addUserEnabled"
      (click)="createClicked()"
    ></button>

    <button
      pButton
      *ngIf="mode === 'edit'"
      label="Update User"
      type="button"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-save"
      [disabled]="!addUserEnabled"
      (click)="createClicked()"
    ></button>

    <button
      pButton
      label="Cancel"
      type="button"
      class="p-button-raised p-button-warning standard-button-within-form"
      icon="pi pi-times"
      (click)="cancelClicked()"
    ></button>
  </div>
</div>
