import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Person, UpdatePersonCommand } from '../../../models/person.model';
import { ApiService } from '../../../shared/services';
import { PersonInfoFormComponent } from '../person-info-form/person-info-form.component';
import { BaseComponent } from '../../general/base/base.component';
import { CountryService } from '../../../shared/services/country.service';

@Component({
  selector: 'app-person-info',
  templateUrl: './person-info.component.html',
  styleUrls: ['./person-info.component.scss'],
})
export class PersonInfoComponent extends BaseComponent {
  @Input() public person?: Person;
  public personDeletedConfirmed = false;

  constructor(
    private apiService: ApiService,
    private readonly countryService: CountryService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
    super();
  }

  editClicked($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    const ref = this.dialogService.open(PersonInfoFormComponent, {
      header: `Edit Professional`,
      width: '50%',
      height: 'auto',
      data: {
        person: Object.create(this.person || {}),
      },
    });

    ref.onClose.subscribe((command: UpdatePersonCommand) => {
      if (this.person && command) {
        this.apiService.people.update(this.person.id, command).subscribe({
          next: (person) => {
            this.person = person;
            if (this.person && this.person.country) {
              this.countryService.findById(this.person.country?.id).subscribe((country) => {
                if (this.person) {
                  this.person.country = country;
                }
              });
            }

            this.messageService.add({
              severity: 'success',
              summary: 'Updated',
              detail: 'You have updated this person',
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'An error occurred while updating the person',
            });
          },
        });
      }
    });
  }

  deleteClicked($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.confirmationService.confirm({
      target: $event.target || undefined,
      message: 'Are you sure that you want to delete this person?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.person) {
          this.apiService.people.delete(this.person.id).subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Deleted',
                detail: 'You have deleted this person',
              });
              this.personDeletedConfirmed = true;
              //this.router.navigate(['/professionals']);
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while deleting the person',
              });
            },
          });
        }
      },
      reject: () => undefined,
    });
  }

  navigateToProfessionals() {
    this.router.navigate(['/professionals']);
  }
}
