<div class="grid grid-nogutter">
  <div class="col-12">
    <h5 i18n class="mt-2 mb-3">Search</h5>
  </div>

  <div class="col-12 flex flex-row align-content-end">
    <div class="col-2 p-2 flex flex-column justify-content-end align-content-end">
      <span class="p-float-label p-input-icon-right w-full">
        <i *ngIf="isLoading" class="pi pi-spin pi-spinner"></i>
        <input
          id="filter-name"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          i18n-placeholder
          placeholder="write the name that you want to find or filter"
          [ngModel]="name"
          (ngModelChange)="onNameChanges($event)"
        />
        <label i18n for="filter-name">First name</label>
      </span>
    </div>
    <div class="col-3 p-2 flex flex-column justify-content-end align-content-end">
      <span class="p-float-label p-input-icon-right w-full">
        <i *ngIf="isLoading" class="pi pi-spin pi-spinner"></i>
        <input
          id="filter-lastName"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          i18n-placeholder
          placeholder="write the lastname that you want to find or filter"
          [ngModel]="lastName"
          (ngModelChange)="onLastNameChanges($event)"
        />
        <label i18n for="filter-lastName">Last name</label>
      </span>
    </div>
    <div class="col-2 p-2 flex flex-column justify-content-end align-content-end">
      <div class="flex justify-content-center">
        <span class="p-float-label w-full inline-block">
          <p-multiSelect
            [disabled]="globalCountryId !== undefined && globalCountryId > 0"
            [options]="countryNodes"
            [ngModel]="selectedCountries"
            (ngModelChange)="onCountryChanges($event)"
            display="chip"
            styleClass="w-full"
          >
            <ng-template pTemplate="selectedItems">
              <div class="flex p-multiselect-token" *ngFor="let option of selectedCountries; index as i">
                <img [src]="option.icon" class="flag-selected mr-2" />
                <span class="p-multiselect-token-label">{{ option.label }}</span>
                <span
                  class="p-multiselect-token-icon pi pi-times-circle ng-star-inserted"
                  (click)="removeCountryItem(i)"
                ></span>
              </div>
              <div
                *ngIf="!selectedCountries || selectedCountries.length === 0"
                i18n
                [ngClass]="{ 'country-placeholder': displayPlaceHolder }"
              >
                Select a country
              </div>
            </ng-template>
            <ng-template let-country pTemplate="item">
              <div class="flex">
                <img [src]="country.icon" class="flag mr-2" />
                <div>{{ country.label }}</div>
              </div>
            </ng-template>
          </p-multiSelect>
          <label i18n for="filter-location">Countries</label>
        </span>
      </div>
    </div>
    <div class="col-2 p-2 flex flex-column justify-content-end align-content-end">
      <span class="p-float-label p-input-icon-right w-full">
        <i *ngIf="isLoading" class="pi pi-spin pi-spinner"></i>
        <input
          id="filter-email"
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          i18n-placeholder
          placeholder="write the email that you want to find or filter"
          [ngModel]="email"
          (ngModelChange)="onEmailChanges($event)"
        />
        <label i18n for="filter-email">Email</label>
      </span>
    </div>
    <div class="col-2 p-2 pl-3 flex flex-column justify-content-start align-content-start">
      <p i18n class="m-0">With email:</p>
      <p-selectButton
        [options]="hasEmailStateOptions"
        [ngModel]="selectedHasEmailStateOption"
        (onOptionClick)="onHasEmailStateChange($event)"
        optionLabel="label"
        optionValue="value"
        styleClass="no-shadow"
      ></p-selectButton>
    </div>
  </div>

  <div class="col-12 flex flex-row align-content-end">
    <div class="col-5 p-0 flex flex-column justify-content-end align-content-end">
      <div class="col-12 p-0 flex flex-row align-content-end">
        <div class="col-6 p-2 flex flex-column justify-content-end align-content-end">
          <span class="p-float-label inline-block w-full">
            <p-autoComplete
              [ngModel]="selectedOffice"
              (ngModelChange)="onOfficeChange($event)"
              [suggestions]="officesResult"
              [virtualScroll]="true"
              [virtualScrollItemSize]="40"
              [virtualScrollOptions]="officesVirtualScroll"
              (completeMethod)="filterOffices($event)"
              i18n-field
              field="fullName"
              [dropdown]="true"
              i18n-placeholder
              placeholder="Select an office from the dropdown or write its name"
              styleClass="w-full autocomplete-full"
              [style]="{ width: '100%' }"
              [inputStyle]="{ width: '100%' }"
            >
            </p-autoComplete>
            <label i18n for="filter-email" class="company-label">Office</label>
          </span>
        </div>
        <div class="col-6 p-2 flex flex-column justify-content-end align-content-end">
          <app-autocomplete-virtualscroll-search-filters
            displayLabel="Company"
            itemsName="Company"
            placeholderLabel="Select a company from the dropdown or write its name"
            [selectedCountries]="this.selectedCountries"
            [searchFunction]="companiesSearchFunction"
            [countryFilterFunction]="countryFilterFunction"
            (selectedItemChanged)="onCompanyItemChange($event)"
          >
          </app-autocomplete-virtualscroll-search-filters>
        </div>
      </div>
    </div>
    <div class="col-7 p-0 flex flex-column justify-content-end align-content-end">
      <div class="col-12 p-0 flex flex-row align-content-end">
        <div class="col-7 p-2 flex flex-column justify-content-end align-content-end">
          <span class="p-float-label w-12">
            <p-multiSelect
              id="filter-type"
              [options]="typeNodes"
              [ngModel]="selectedTypes"
              (ngModelChange)="onTypesChanges($event)"
              selectionMode="checkbox"
              display="chip"
              styleClass="w-full"
            >
              <ng-template let-type pTemplate="item">
                <div class="flex">
                  <i class="mr-2" [ngClass]="type.icon"></i>
                  <div>{{ type.label }}</div>
                </div>
              </ng-template>
            </p-multiSelect>
            <label i18n for="filter-type" [ngClass]="{ 'types-label': selectedTypes.length === 0 }">Type</label>
          </span>
          <span *ngIf="selectedTypes.length && isAgency()" class="p-float-label w-12 mt-4">
            <p-multiSelect
              [options]="selectedTypes"
              [group]="true"
              [ngModel]="selectedSubTypes"
              (ngModelChange)="onSubTypesChanges($event)"
              display="chip"
              styleClass="w-full"
              optionGroupChildren="children"
              i18n-placeholder
              placeholder="Select a sub-type from the list"
              id="filter-subtype"
            >
              <ng-template let-typeGroup pTemplate="group">
                <div class="flex">
                  <i [ngClass]="typeGroup.icon" class="mr-2"></i>
                  <div>{{ typeGroup.label }}</div>
                </div>
              </ng-template>
              <ng-template let-subType pTemplate="item">
                <div class="flex">
                  <i class="mr-2" [ngClass]="subType.icon"></i>
                  <div>{{ subType.label }}</div>
                </div>
              </ng-template>
            </p-multiSelect>
            <label *ngIf="selectedTypes.length" i18n for="filter-subtype">Sub-types</label>
          </span>
        </div>
        <div class="col-4 p-2 flex flex-column justify-content-end align-content-end">
          <!--p i18n class='m-0'>Operation:</p-->
          <p-selectButton
            [options]="officeTypesOperationStateOptions"
            [ngModel]="selectedOfficeTypesOperationOption"
            (onOptionClick)="onOfficeTypesOperationChange($event)"
            optionLabel="label"
            optionValue="value"
            styleClass="no-shadow"
          ></p-selectButton>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 flex flex-row align-content-start align-content-start">
    <div class="col-5 p-0 flex flex-column justify-content-start align-content-start">
      <div class="col-12 p-0 flex flex-row align-content-start justify-content-start">
        <div class="col-6 p-2 flex flex-column justify-content-start align-content-start">
          <!--p i18n class='m-0'>Current:</p-->
          <!--p-selectButton
            [options]="currentProfessionalsStateOptions"
            [ngModel]="selectedCurrentProfessionalsStateOption"
            (onOptionClick)="onCurrentProfessionalsStateChange($event)"
            optionLabel="label"
            optionValue="value"
            styleClass="no-shadow"
          ></p-selectButton-->
          <app-autocomplete-virtualscroll-search-filters
            displayLabel="Network"
            itemsName="Network"
            placeholderLabel="Select a network from the dropdown or write its name"
            [selectedCountries]="this.selectedCountries"
            [searchFunction]="networksSearchFunction"
            (selectedItemChanged)="onNetworkItemChange($event)"
          >
          </app-autocomplete-virtualscroll-search-filters>
        </div>
        <div class="col-6 p-2 flex flex-column justify-content-start align-content-start">
          <app-autocomplete-virtualscroll-search-filters
            displayLabel="Holding"
            itemsName="Holding"
            placeholderLabel="Select a holding from the dropdown or write its name"
            [selectedCountries]="this.selectedCountries"
            [searchFunction]="holdingsSearchFunction"
            (selectedItemChanged)="onHoldingItemChange($event)"
          >
          </app-autocomplete-virtualscroll-search-filters>
        </div>
      </div>
      <div class="col-12 p-0 flex flex-row align-content-start justify-content-start">
        <div class="col-6 p-2 flex flex-column justify-content-end align-content-end">
          <span class="p-float-label p-input-icon-right w-full">
            <i *ngIf="isLoading" class="pi pi-spin pi-spinner"></i>
            <input
              id="positionName"
              type="text"
              pInputText
              class="p-inputtext-sm w-full"
              placeholder="position"
              [ngModel]="positionName"
              (ngModelChange)="onPositionNameChange($event)"
            />
            <label i18n for="positionName">Position</label>
          </span>
        </div>
        <div class="col-6 p-2 flex flex-column justify-content-end align-content-end">
          <span class="p-float-label p-input-icon-right w-full">
            <i *ngIf="isLoading" class="pi pi-spin pi-spinner"></i>
            <input
              id="observations"
              type="text"
              pInputText
              class="p-inputtext-sm w-full"
              placeholder="observations"
              [ngModel]="observations"
              (ngModelChange)="onObservationsChange($event)"
            />
            <label i18n for="observations">Observations</label>
          </span>
        </div>
      </div>
    </div>
    <div class="col-7 p-0 flex flex-column justify-content-start align-content-start">
      <div class="col-12 p-0 flex flex-row align-content-start">
        <div class="col-7 flex flex-column justify-content-start align-content-start">
          <span class="p-float-label w-full">
            <p-multiSelect
              id="filter-filters"
              [options]="filtersNodes"
              [ngModel]="selectedFilters"
              (ngModelChange)="onFiltersChanges($event)"
              selectionMode="checkbox"
              display="chip"
              styleClass="w-full"
            >
              <ng-template let-filter pTemplate="item">
                <div class="flex">
                  <i class="mr-2" [ngClass]="filter.icon"></i>
                  <div>{{ filter.label }}</div>
                </div>
              </ng-template>
            </p-multiSelect>
            <label i18n for="filter-type" [ngClass]="{ 'filters-label': selectedFilters.length === 0 }">Filters</label>
          </span>
        </div>
        <div class="col-4 flex flex-column justify-content-end align-content-end">
          <!--p i18n class='mr-2'>Operation:</p-->
          <p-selectButton
            [options]="filtersStateOptions"
            [ngModel]="selectedFiltersStateOption"
            (onOptionClick)="onFiltersOperationChange($event)"
            optionLabel="label"
            optionValue="value"
            styleClass="no-shadow"
          ></p-selectButton>
        </div>
      </div>
      <div class="col-12 p-0 flex flex-row align-content-start">
        <div class="col-7 flex flex-column justify-content-end align-content-end">
          <span class="p-float-label w-12">
            <p-multiSelect
              id="filter-waves"
              [options]="wavesNodes"
              [ngModel]="selectedWaves"
              (ngModelChange)="onWavesChanges($event)"
              selectionMode="checkbox"
              display="chip"
              styleClass="w-full"
            >
              <ng-template let-wave pTemplate="item">
                <div class="flex">
                  <i class="mr-2" [ngClass]="wave.icon"></i>
                  <div>{{ wave.label }}</div>
                </div>
              </ng-template>
            </p-multiSelect>
            <label i18n for="filter-type" [ngClass]="{ 'waves-label': selectedWaves.length === 0 }">Waves</label>
          </span>
        </div>
        <div class="col-4 flex flex-column justify-content-end align-content-end">
          <!--p i18n class='mr-2'>Operation:</p-->
          <p-selectButton
            [options]="wavesStateOptions"
            [ngModel]="selectedWavesStateOption"
            (onOptionClick)="onWavesOperationChange($event)"
            optionLabel="label"
            optionValue="value"
            styleClass="no-shadow"
          ></p-selectButton>
        </div>
      </div>
      <div class="col-12 p-0 flex flex-row align-content-start">
        <div class="col-7 flex flex-column justify-content-end align-content-end">
          <span class="p-float-label w-12">
            <p-multiSelect
              id="filter-festivals"
              [options]="festivalsNodes"
              [ngModel]="selectedFestivals"
              (ngModelChange)="onFestivalsChanges($event)"
              selectionMode="checkbox"
              display="chip"
              styleClass="w-full"
            >
              <ng-template let-festival pTemplate="item">
                <div class="flex">
                  <i class="mr-2" [ngClass]="festival.icon"></i>
                  <div>{{ festival.label }}</div>
                </div>
              </ng-template>
            </p-multiSelect>
            <label i18n for="filter-type" [ngClass]="{ 'festival-label': selectedFestivals.length === 0 }">
              Juries
            </label>
          </span>
        </div>
        <div class="col-4 flex flex-column justify-content-end align-content-end">
          <!--p i18n class='mr-2'>Operation:</p-->
          <p-selectButton
            [options]="festivalsStateOptions"
            [ngModel]="selectedFestivalsStateOption"
            (onOptionClick)="onFestivalsOperationChange($event)"
            optionLabel="label"
            optionValue="value"
            styleClass="no-shadow"
          ></p-selectButton>
        </div>
      </div>
    </div>
  </div>
</div>
