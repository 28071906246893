import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Office, StudioType, UpsertStudioTypesCommand } from '../../../../models';
import { ApiService } from '../../../../shared/services';

@Component({
  selector: 'app-studio-types-form',
  templateUrl: './studio-types-form.component.html',
  styleUrls: ['./studio-types-form.component.scss'],
})
export class StudioTypesFormComponent implements OnInit {
  @Input() office: Office | undefined = undefined;
  public studioTypes: StudioType[] = [];
  public selectedStudioTypes: StudioType[] = [];

  constructor(private apiService: ApiService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  updateClicked() {
    if (this.office && this.office.studio) {
      const command = new UpsertStudioTypesCommand();
      command.studioTypeIds = this.selectedStudioTypes.map((studioType: StudioType) => studioType.id);
      this.ref.close(command);
    }
  }

  cancelClicked() {
    this.ref.close();
  }

  ngOnInit(): void {
    this.apiService.studios.getAllTypes().subscribe((types: StudioType[]) => {
      this.studioTypes = types;
      if (this.config.data.office) {
        this.office = this.config.data.office;
        this.office?.studio?.types.forEach((type: StudioType) => {
          const index = this.studioTypes.findIndex((w: StudioType) => w.id === type.id);
          if (index > -1) {
            this.selectedStudioTypes.push(this.studioTypes[index]);
          }
        });
      }
    });
  }
}
