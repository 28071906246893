import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MediaSupportCommand, MediaType, Office } from '../../../../models';
import { MediaTypeService } from '../../../../shared/services';

@Component({
  selector: 'app-media-supports-form',
  templateUrl: './media-supports-form.component.html',
  styleUrls: ['./media-supports-form.component.scss'],
})
export class MediaSupportsFormComponent implements OnInit {
  public office: Office | undefined = undefined;
  public command: MediaSupportCommand = new MediaSupportCommand({ name: '', mediaTypeId: 0 });
  public mediaTypes: MediaType[] = [];
  public selectedMediaType: MediaType | undefined = undefined;
  public mode = 'create';
  public addSupportEnabled = false;

  constructor(
    public mediaTypeService: MediaTypeService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    if (this.config.data.office) {
      this.office = this.config.data.office;
      if (this.config.data.support) {
        this.mode = 'edit';
        this.command.name = this.config.data.support.name;
        this.command.mediaTypeId = this.config.data.support.type?.id || 0;
      }

      this.mediaTypeService.find().subscribe((mediaTypes) => {
        mediaTypes.forEach((mediaType) => {
          this.mediaTypes.push(mediaType);
          if (mediaType.id === this.command.mediaTypeId) {
            this.selectedMediaType = mediaType;
          }
        });
      });
    }
  }

  supportChanged() {
    this.checkIfAddSupportEnabled();
  }

  mediaTypeChanged() {
    this.checkIfAddSupportEnabled();
  }

  cancelClicked() {
    this.ref.close();
  }

  createClicked() {
    this.command.mediaTypeId = this.selectedMediaType?.id || 0;
    this.ref.close(this.command);
  }

  private checkIfAddSupportEnabled() {
    this.addSupportEnabled =
      this.command.name != '' && this.selectedMediaType != undefined && this.selectedMediaType.id != 0;
  }
}
