<span class="p-float-label inline-block w-full">
  <p-autoComplete
    id="filter-autocomplete"
    [ngModel]="selectedItem"
    (ngModelChange)="onItemChange($event)"
    [suggestions]="filteredResult"
    [virtualScroll]="true"
    [virtualScrollItemSize]="40"
    [virtualScrollOptions]="itemsVirtualScroll"
    (completeMethod)="filterItems($event)"
    i18n-field
    field="name"
    [dropdown]="true"
    [placeholder]="placeholderLabel"
    styleClass="w-full autocomplete-full"
    [style]="{ width: '100%' }"
    [inputStyle]="{ width: '100%' }"
  >
  </p-autoComplete>
  <label i18n for="filter-autocomplete" class="company-label">{{ displayLabel }}</label>
</span>
