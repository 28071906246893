import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../../shared/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Brand, BrandCommand, Office, UpsertAdvertiserBrandsCommand } from '../../../../models';
import { IPrimengCustomEvent } from '../../../../shared/interfaces/primeng-scopen.interface';
import { AutoComplete } from 'primeng/autocomplete';
import { BaseComponent } from '../../../general/base/base.component';

@Component({
  selector: 'app-advertiser-brands-form',
  templateUrl: './advertiser-brands-form.component.html',
  styleUrls: ['./advertiser-brands-form.component.scss'],
})
export class AdvertiserBrandsFormComponent extends BaseComponent implements OnInit {
  public office: Office | undefined = undefined;
  public brands: Brand[] = [];
  public filteredBrands: Brand[] = [];
  @ViewChild(AutoComplete) autoComplete?: AutoComplete;

  public brandCommand: BrandCommand = new BrandCommand({ name: '' });
  public isBrandCommandEnabled = false;
  constructor(private apiService: ApiService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    super();
  }

  updateClicked() {
    if (this.office && this.office.advertiser) {
      const command = new UpsertAdvertiserBrandsCommand();
      command.brandIds = this.office.advertiser.brands.map((brand: Brand) => brand.id);
      this.ref.close(command);
    }
  }

  cancelClicked() {
    this.ref.close();
  }

  ngOnInit(): void {
    if (this.config.data.office) {
      this.office = this.config.data.office;
    }

    this.apiService.brands.findWithinAdvertisers(this.globalCountryId).subscribe((brands: Brand[]) => {
      this.brands = brands;
      this.office?.advertiser?.brands.forEach((brand: Brand) => {
        const index = this.brands.findIndex((w: Brand) => w.id === brand.id);
        if (index > -1) {
          this.filteredBrands.push(this.brands[index]);
        }
      });
    });
  }

  searchBrand($event: IPrimengCustomEvent) {
    this.filteredBrands = this.brands.filter((brand: Brand) =>
      brand.name.toLowerCase().includes($event.query.toLowerCase()),
    );
  }

  createBrand() {
    if (this.brandCommand.name.length > 0) {
      this.apiService.brands.create(this.brandCommand).subscribe((brand: Brand) => {
        this.brands.push(brand);
        this.filteredBrands.push(brand);
        this.office?.advertiser?.brands.push(brand);
        this.brandCommand = new BrandCommand({ name: '' });
        this.checkIfBrandCommandEnabled();
        if (this.autoComplete) {
          this.autoComplete.focusInput();
        }
      });
    }
  }

  nameChanged() {
    this.brandCommand.name = this.brandCommand.name.trim();
    this.checkIfBrandCommandEnabled();
  }

  private checkIfBrandCommandEnabled() {
    this.isBrandCommandEnabled = this.brandCommand.name.length > 0;
  }
}
