import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../general/base/base.component';
import { Office } from '../../../models';
import { IOfficesApiFilters, ISearchQuery } from '../../../shared/interfaces';
import { ApiService, ToastService } from '../../../shared/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OfficeFusionCommand } from '../../../models/office-fusion-detail.model';

@Component({
  selector: 'app-office-fusion-form',
  templateUrl: './office-fusion-form.component.html',
  styleUrls: ['./office-fusion-form.component.scss'],
})
export class OfficeFusionFormComponent extends BaseComponent implements OnInit {
  @Input() public office?: Office | undefined;

  public selectedParent?: Office | undefined;
  public filteredParents: Office[] = [];
  public fusionOfficeEnabled: boolean | undefined = false;

  constructor(
    private apiService: ApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.config.data.office) {
      this.office = this.config.data.office;
      this.checkFusionOfficeEnabled();
    }
  }

  searchParent($event: any) {
    this.searchParentOffices($event.query);
  }

  parentChanged() {
    this.checkFusionOfficeEnabled();
  }

  private checkFusionOfficeEnabled(): void {
    this.fusionOfficeEnabled =
      this.selectedParent && this.selectedParent.id > 0 && this.selectedParent.id !== this.office?.id;
  }

  private searchParentOffices(name: string): void {
    if (this.office) {
      const filters: IOfficesApiFilters = {
        page: 1,
        pageSize: 100,
        enabled: true,
      };
      if (name) {
        filters.name = name;
      }
      filters.officeTypes = this.office.officeTypes.map((ot) => {
        return { officeTypeId: ot.id };
      });
      if (this.globalCountryId) {
        filters.locations = [{ countryId: this.globalCountryId }];
      }

      this.apiService.offices.search(filters).subscribe({
        next: (response) => {
          this.filteredParents = response.items;
        },
      });
    }
  }

  cancelClicked() {
    this.ref.close();
  }

  fusionClicked() {
    if (this.office && this.selectedParent) {
      const command: OfficeFusionCommand = {
        fusionParentId: this.selectedParent.id,
      };
      this.ref.close(command);
    }
  }
}
