import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonJuriesComponent } from '../../components/people/person-juries/person-juries.component';
import { Professional } from '../../models';
import { Person } from '../../models/person.model';
import { ApiService } from '../../shared/services';
import { CountryService } from '../../shared/services/country.service';

@Component({
  selector: 'app-person-details',
  templateUrl: './person-details.component.html',
  styleUrls: ['./person-details.component.scss'],
})
export class PersonDetailsComponent implements OnInit {
  public person?: Person;
  @ViewChild(PersonJuriesComponent) personJuries!: PersonJuriesComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private readonly countryService: CountryService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    this.apiService.people.getById(id).subscribe({
      next: (data) => {
        if (data) {
          if (data.country) {
            this.countryService.findById(data.country.id).subscribe({
              next: (country) => {
                this.person = data;
                this.person.country = country;
              },
              error: (error) => {
                console.error(error);
              },
            });
          } else {
            this.person = data;
          }
        } else {
          this.router.navigate(['/app/access-denied']);
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  setProfessionals($event: Professional[]) {
    this.personJuries.professionals = $event;
  }

  updateLastReview($event: Date) {
    if (this.person) {
      this.person.lastReview = $event.toDateString();
    }
  }
}
