<div class="py-4 px-6">
  <div *ngIf="person">
    <app-person-summary [person]="person" [isTitle]="true"></app-person-summary>
    <div class="col-6 flex flex-column start">
      <p-fieldset class="personal-contact" legend="Personal Contact" [toggleable]="true" [collapsed]="true">
        <div class="flex flex-column start">
          <div class="flex flex-row start">
            <div class="col-4">
              <span><strong>Phone</strong></span>
            </div>
            <div class="col-4">
              <span><strong>Mobile</strong></span>
            </div>
            <div class="col-4">
              <span><strong>Email</strong></span>
            </div>
          </div>
          <div class="flex flex-row start">
            <div class="col-4">
              <span>{{ person.phone }}</span>
            </div>
            <div class="col-4">
              <span>{{ person.mobilePhone }}</span>
            </div>
            <div class="col-4">
              <span>{{ person.email }}</span>
            </div>
          </div>
        </div>
      </p-fieldset>
    </div>
    <div class="col-6 flex flex-column start py-4">
      <span><strong>Observations</strong></span>
      <p>{{ person.observations }}</p>
    </div>
    <div class="col-6 flex flex-row justify-content-start py-4">
      <div class="px-1">
        <button
          *ngIf="hasRole('db-edit-people')"
          pButton
          label="Edit"
          type="button"
          (click)="editClicked($event)"
          class="p-button-raised p-button-warning"
          icon="pi pi-plus-circle"
          style="margin-bottom: 10px"
        ></button>
      </div>
      <div class="px-1">
        <button
          *ngIf="hasRole('db-delete-people')"
          pButton
          label="Delete"
          type="button"
          (click)="deleteClicked($event)"
          class="p-button-raised p-button-danger"
          icon="pi pi-plus-circle"
          style="margin-bottom: 10px"
        ></button>
      </div>
    </div>
    <div *ngIf="!person">
      <div class="flex">
        <div class="flex justify-content-center align-content-center align-items-center w-full h-screen">
          <h4 i18n class="secondary-text" style="font-size: 2rem">
            wait...<i class="pi pi-spin pi-spinner inline-block secondary-text" style="font-size: 2rem"></i>
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Person Deleted" [(visible)]="personDeletedConfirmed" [style]="{ width: '25vw' }" [closable]="false">
  <app-person-summary [person]="person"></app-person-summary>
  <div class="flex flex-row justify-content-end">
    <div class="px-1">
      <button
        pButton
        label="Go to Professionals"
        type="button"
        class="p-button-raised p-button-secondary navigate-to-professionals-button"
        (click)="navigateToProfessionals()"
        icon="pi pi-users"
        style="margin-bottom: 10px"
      ></button>
    </div>
  </div>
</p-dialog>
