import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { IProfessionalsApiFilters } from '../../../shared/interfaces';
import { ApiService } from '../../../shared/services';
import { ExcelTooLargeComponent } from '../../general/excel-too-large/excel-too-large.component';

@Component({
  selector: 'app-professionals-advanced-excel',
  templateUrl: './professionals-advanced-excel.component.html',
  styleUrls: ['./professionals-advanced-excel.component.scss'],
})
export class ProfessionalsAdvancedExcelComponent {
  @Input() filters: IProfessionalsApiFilters | undefined = undefined;
  @Input() itemsCount: number | undefined = undefined;

  public isLoading = false;

  public excelOptions: MenuItem[] = [
    {
      icon: 'pi pi-file-excel',
      label: 'Basic Excel',
      command: () => {
        this.downloadExcel('basic');
      },
    },
    {
      icon: 'pi pi-file-excel',
      label: 'Advanced Excel',
      command: () => {
        this.downloadExcel('advanced');
      },
    },
  ];

  constructor(private apiService: ApiService, private dialogService: DialogService) {}

  downloadExcel(excelType: string) {
    if (this.itemsCount && this.itemsCount <= 1000) {
      this.downloadExcelFile(excelType);
    } else if (this.itemsCount && this.itemsCount > 1000) {
      const ref = this.dialogService.open(ExcelTooLargeComponent, {
        header: `Long running process`,
        width: '30%',
        height: 'auto',
        data: {
          itemsCount: this.itemsCount,
        },
      });

      ref.onClose.subscribe((result: boolean) => {
        if (result) {
          this.downloadExcelFile(excelType);
        }
      });
    }
  }

  private downloadExcelFile(excelType: string) {
    if (this.filters) {
      this.isLoading = true;
      const apiCall = this.getApiCall(excelType);
      apiCall(this.filters).subscribe(
        (data: BlobPart) => {
          this.downloadFile(data, excelType);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        },
      );
    }
  }

  private getApiCall(excelType: string): (filters: IProfessionalsApiFilters) => Observable<Blob> {
    switch (excelType) {
      case 'basic':
        return this.apiService.professionals.getBasicExcel;
      case 'advanced':
        return this.apiService.professionals.getAdvancedExcel;
      default:
        return this.apiService.professionals.getBasicExcel;
    }
  }

  private downloadFile(data: BlobPart, suffix: string) {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `professionals-${suffix}.xlsx`;
    link.click();
  }
}
