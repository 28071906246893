<div *ngIf="person">
  <div class="flex align-items-start justify-content-between">
    <h3>Waves</h3>
    <buttonØ
      *ngIf="hasRole('db-edit-people')"
      pButton
      label="Edit Waves"
      type="button"
      (click)="createClicked()"
      class="p-button-raised p-button-warning"
      icon="pi pi-save"
      style="margin-bottom: 10px"
    ></buttonØ>
  </div>

  <p-table
    #dt
    class="person-waves-table"
    styleClass="p-datatable-sm"
    [value]="waves"
    [loading]="isLoading"
    [scrollable]="true"
    selectionMode="single"
  >
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between"></div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Product</th>
        <th>Country</th>
        <th>Year</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-wave>
      <tr>
        <td>{{ wave.product?.name }}</td>
        <td>{{ wave.country.name }}</td>
        <td>{{ wave.year }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="flex align-items-center justify-content-between">
        In total there are {{ waves ? waves.length : 0 }} waves.
      </div>
    </ng-template>
  </p-table>

  <p-toast></p-toast>
  <p-confirmPopup></p-confirmPopup>
</div>
