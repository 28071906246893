import { BaseModel } from './base.model';

export class MediaType extends BaseModel {
  public name: string;

  constructor(data: MediaType) {
    super(data);
    ({ name: this.name } = data);
  }
}

export type CreateMediaTypeCommand = Pick<MediaType, 'name'>;
export type UpdateMediaTypeCommand = Pick<MediaType, 'id' | 'name'>;
