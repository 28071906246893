export abstract class BaseModel {
  public id: number;

  public isDeleted?: boolean;

  public createdAt?: Date;

  public updatedAt?: Date;

  public deletedAt?: Date;

  constructor(data: { id: number; createdAt?: Date; updatedAt?: Date; deletedAt?: Date; isDeleted?: boolean }) {
    ({
      id: this.id,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
      isDeleted: this.isDeleted,
    } = data);
  }
}
